/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useState } from 'react';
import { JobType, WorkOrder } from '../../../../API';
import theme from '../../../../Shared/Themes/theme';
import { CircularProgress } from '@mui/material';
import { LoaderTag } from '../ActionItems/ActionItems.Style';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { DatePickerWrapper } from '../../../../Shared/Components/Common/GoogleMap/GoogleMapChips.Style';
import moment from 'moment';
import { FacilityInterface } from '../../../Company/Models/Company.Model';
import AssociateOnTheJobAccordion from './AssociateOnTheJobAccordion';
import { Loader } from '../JobView/JobsView.styles';
import { DropdownWrapper, NoDataTag, Services, TitleWrapper } from '../WorkTasks/JobDetailsTasks.styles';
import { ISchedules } from '../../../../Shared/Models/WorkTask.model';
import { ScreenName } from '../../../../Shared/Components/Common/Accordion/Accordion';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { formatDate } from '../../../../Shared/Utilities/utils';
import { isEmpty } from 'lodash';

interface AssociateCardData {
  jobData?: WorkOrder;
  FacilityData?: FacilityInterface;
  jobId: string;
  jobsLoader?: boolean;
}
export interface SchedulesByDateQuery {
  workOrderScheduleId: string;
  workOrderId?: string | null;
  woScheduleMasterId: string;
  scheduleDate?: string | null;
  scheduleStartTime?: string | null;
  scheduleEndTime?: string | null;
  scheduleType?: string | null;
  dataStatus?: string | null;
  isRescheduled: boolean;
}

export interface AllShiftDetailsQuery {
  shiftDetailId: string;
  startTime: string;
  endTime: string;
  workOrderId: string;
  punchInGraceMinutes?: number | null;
  punchOutGraceMinutes?: number | null;
  scheduleStartDate?: string;
  scheduleEndDate?: string;
  scheduleName?: string | null;
  endDateOffset?: number | null;
  workOrder?: {
    __typename: 'ShiftDetailsWorkOrder';
    jobStartDate?: string | null;
    jobEndDate?: string | null;
    actualStartDate?: string | null;
    actualEndDate?: string | null;
  } | null;
}

const AssociateCard = ({ jobData, FacilityData, jobId, jobsLoader }: AssociateCardData) => {
  const [jobStartDate, setJobStartDate] = useState<string>('');
  const [jobEndDate, setJobEndDate] = useState<string>('');
  const [scheduleList] = useState<ISchedules[]>([]);
  const [accordionID, setSelectedAccordionID] = useState('');
  const [expanded, setExpanded] = useState<number | false>(0);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [schedules, setSchedules] = useState<SchedulesByDateQuery[]>([]);
  const [shiftDetails, setShiftDetails] = useState<AllShiftDetailsQuery[]>([]);

  const { t } = useTranslation(['assignJob', 'calendar', 'taskRejectionAndRework']);
  const { control, setValue } = useForm<{ specificDate: string | null }>({
    defaultValues: {
      specificDate: null,
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  useEffect(() => {
    if (!jobData) return;
    const {
      actualStartDate = '',
      actualEndDate = '',
      jobStartDate = '',
      jobEndDate = '',
    } = jobData || { actualStartDate: '', actualEndDate: '', jobStartDate: '', jobEndDate: '' };
    if ((actualStartDate && actualEndDate) || (jobStartDate && jobEndDate)) {
      let startDate: string = actualStartDate || jobStartDate || '';
      startDate = startDate.endsWith('Z') ? startDate.slice(0, startDate?.length - 1) : startDate;
      const endDate: string = actualEndDate || jobEndDate || '';
      const isCurrentJobInTheRangeOfJobDurationForRecurring = moment().isBetween(startDate, endDate);
      if (jobData?.jobType === JobType.Recurring && isCurrentJobInTheRangeOfJobDurationForRecurring) {
        let currentDeviceDate = new Date()?.toJSON();
        currentDeviceDate = currentDeviceDate?.slice(0, currentDeviceDate?.length - 1);
        setValue('specificDate', currentDeviceDate);
      } else {
        setValue('specificDate', startDate);
      }
    }
  }, [jobData]);

  const handleAccordion = (panel: number, id: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setSelectedAccordionID(id);
    setExpanded(newExpanded ? panel : false);
  };

  const getWorkOrderDetails = async () => {
    setIsDataLoading(true);
    try {
      if (jobData?.jobId && specificDate) {
        const workOrderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: jobData?.jobId });
        const workOrderResponseByJobId = workOrderResponse?.data?.filter(
          (data: { jobId: string }) => data?.jobId === jobData?.jobId
        );
        const startDate = new Date(workOrderResponseByJobId?.[0]?.jobStartDate);
        const endDate = new Date(workOrderResponseByJobId?.[0]?.jobEndDate);
        const formattedStartDate = formatDate(startDate.toISOString());
        const formattedEndDate = formatDate(endDate.toISOString());
        setJobStartDate(formattedStartDate);
        setJobEndDate(formattedEndDate);
      }
      setIsDataLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsDataLoading(false);
    }
  };

  const getSchedulesData = async () => {
    setIsDataLoading(true);
    try {
      if (jobData?.workOrderId && specificDate) {
        let workOrderId;
        if (jobData?.hasChild) {
          workOrderId = jobData?.subWorkOrders?.[0]?.workOrderId;
        } else {
          workOrderId = jobData?.workOrderId;
        }
        const scheduleDate = moment(specificDate).format('YYYY-MM-DD');
        const schedulesByDateResponse = await WorkOrdersApiService.getSchedulesByDate(
          workOrderId as string,
          scheduleDate
        );
        if (schedulesByDateResponse?.data && schedulesByDateResponse?.errors?.length === 0) {
          setSchedules(schedulesByDateResponse?.data);
          if (schedulesByDateResponse?.data?.[0]) {
            setSelectedAccordionID(schedulesByDateResponse?.data?.[0]?.workOrderScheduleId);
          }
        }

        const shiftDetailResponse = await WorkOrdersApiService.getallShiftDetails(workOrderId as string);
        setShiftDetails(shiftDetailResponse?.data);
      }
      setIsDataLoading(false);
    } catch (e) {
      console.log('error', e);
      setIsDataLoading(false);
    }
  };

  const renderTasksData = () => {
    let index = 0;
    return (
      <>
        <TitleWrapper sx={{ marginTop: '0rem' }}>
          <Services>{t('assignJob:assignedTeam')}</Services>
        </TitleWrapper>
        {jobsLoader ? (
          <Loader>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : schedules.length ? (
          schedules.map((dataItem) => {
            index = index + 1;
            const shiftData = shiftDetails.filter((data) => data?.shiftDetailId === dataItem?.woScheduleMasterId);
            const scheduleName = shiftData?.[0]?.scheduleName;
            const sectionTitle = !isEmpty(scheduleName) ? scheduleName : `Schedule #${index}`;
            return (
              <AssociateOnTheJobAccordion
                scheduleList={scheduleList}
                key={dataItem?.workOrderScheduleId}
                expanded={accordionID === dataItem?.workOrderScheduleId && expanded}
                handleAccordion={(e) => handleAccordion(e, dataItem?.workOrderScheduleId)}
                accordionObjNew={dataItem}
                screenName={ScreenName.JobDetails}
                jobData={jobData}
                FacilityData={FacilityData}
                jobId={jobId}
                jobsLoader={jobsLoader}
                specificDate={specificDate}
                shiftDetails={shiftDetails}
                isDataLoading={isDataLoading}
                sectionTitle={sectionTitle}
              />
            );
          })
        ) : (
          <NoDataTag>{t('noData:noSchedulesFound')}</NoDataTag>
        )}
      </>
    );
  };

  useEffect(() => {
    getSchedulesData();
  }, [jobData, specificDate]);

  useEffect(() => {
    getWorkOrderDetails();
  }, [specificDate, jobId]);

  return (
    <>
      <DropdownWrapper sx={{ justifyContent: 'flex-end', marginTop: '2rem' }}>
        {isDataLoading ? (
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        ) : (
          <DatePickerWrapper
            sx={{
              maxWidth: '13rem',
              '& .MuiOutlinedInput-root': {
                '& .MuiInputBase-input': {
                  padding: '0.5625rem 0 0.5625rem 1rem',
                  color: theme.palette.primary.dark,
                },
              },
            }}>
            <DateSelection
              requiredText={t('calendar:dateFieldIsRequired')}
              control={control}
              fieldName={'specificDate'}
              label={t('taskRejectionAndRework:selectDate')}
              isExcludePastDate={false}
              showSpecificRangeDates={{
                from: new Date(jobStartDate),
                to: new Date(jobEndDate),
              }}
              textFieldStyles={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                    borderWidth: 1,
                    borderColor: theme.palette.primary.dark,
                    borderRadius: '1rem',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.primary.dark,
                  '&.Mui-focused': {
                    color: theme.palette.primary.dark,
                  },
                },
              }}
            />
          </DatePickerWrapper>
        )}
      </DropdownWrapper>

      {jobsLoader || isDataLoading ? (
        <LoaderTag>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </LoaderTag>
      ) : (
        <>{renderTasksData()}</>
      )}
    </>
  );
};

export default AssociateCard;
