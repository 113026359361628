/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { Icons } from '../../../../Shared/Constants/Icons';
import {
  convertToReadableFormat,
  getAcronymName,
  getStoredCustomerDetails,
  AssignmentCardType,
  formatDate,
} from '../../../../Shared/Utilities/utils';
import { SearchFieldContainer, SearchInput } from '../../../../Shared/Components/Layout/styles';
import { ITeamMemberData } from './JobAssignmentCard';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import { CreateWorkOrderTeam } from '../../../../API';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import {
  AssignTeamDiv,
  buttonStyles,
  CardContainerWrapper,
  CardTitle,
  DatePickerContainer,
  HeadingText,
  LogoWrapper,
  TeamCard,
  TeamContainer,
} from './OneTimeJobDetails.Style';
import theme from '../../../../Shared/Themes/theme';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { useForm } from 'react-hook-form';
import ModifyPopUp from './ModifyPopUp';
import { isEmpty } from 'lodash';

interface ShiftDetails {
  shiftDetailId: string;
  startTime: string;
  endTime: string;
  workOrderId?: string;
  punchInGraceMinutes?: number | null;
  punchOutGraceMinutes?: number | null;
}
interface AssignTeam {
  teamData: ITeamMemberData[];
  shiftTimings: ShiftDetails[];
  setTeamData: (data: ITeamMemberData[]) => void;
  originalTeamData: ITeamMemberData[];
  setOriginalTeamData: (data: ITeamMemberData[]) => void;
  associateTeamData: ITeamMemberData[];
  setAssociateTeamData: (data: ITeamMemberData[]) => void;
  originalAssociateTeamData: ITeamMemberData[];
  setOriginalAssociateTeamData: (data: ITeamMemberData[]) => void;
  checkedSupervisors: ITeamMemberData[];
  setCheckedSupervisors: (data: ITeamMemberData[]) => void;
  checkedAssociates: ITeamMemberData[];
  setCheckedAssociates: (data: ITeamMemberData[]) => void;
  isTeamDataLoading: boolean;
  fetchAssociateTeam: (workOrderId: string) => Promise<void>;
  facilityId: string;
  workOrderId: string;
  onClose: (type: AssignmentCardType) => void;
  isViewOnly: boolean;
  fetchVendorTeam: (workOrderId: string) => Promise<void>;
  assignmentType: AssignmentCardType;
  workOrderData: any;
  shiftDetailId: string;
  renderAssignedSupervisorTeamData: (shiftId: string) => void;
  renderAssignedAssociateTeamData: (shiftId: string) => void;
  getWorkOrderDetails: () => Promise<void>;
  fetchDisableStartJobResult: any;
}

export const checkAlreadySelected = (data: ITeamMemberData[], memberId: string): boolean => {
  return data.some((prevMember) => prevMember.id === memberId);
};

export interface AssociateShiftDateProps {
  associateStartDate: string;
  associateEndDate: string;
  userId: string;
}

const AssignTeam = ({
  teamData,
  setTeamData,
  originalTeamData,
  setOriginalTeamData,
  associateTeamData,
  setAssociateTeamData,
  originalAssociateTeamData,
  setOriginalAssociateTeamData,
  checkedSupervisors,
  setCheckedSupervisors,
  checkedAssociates,
  setCheckedAssociates,
  isTeamDataLoading,
  workOrderId,
  onClose,
  fetchVendorTeam,
  fetchAssociateTeam,
  assignmentType,
  workOrderData,
  shiftDetailId,
  renderAssignedSupervisorTeamData,
  renderAssignedAssociateTeamData,
  getWorkOrderDetails,
  fetchDisableStartJobResult,
}: AssignTeam) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isRecommended, setIsRecommended] = useState(true);
  const [teamCardNumber, setTeamCardNumber] = useState<number>(0);
  const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);
  const [isDataSaving, setIsDataSaving] = useState<boolean>(false);
  const [snackbarStyle, setSnackBarStyle] = useState<boolean>(false);

  const { t } = useTranslation(['assignJob', 'oneTimeJob', 'calendar']);
  const { divider, buttonText, createJobButton, cancelButton } = buttonStyles;
  const [editAssociateShiftDate, setEditAssociateShiftDate] = useState<AssociateShiftDateProps>({
    userId: '',
    associateStartDate: '',
    associateEndDate: '',
  });

  const { control, getValues, reset } = useForm({
    // defaultValues: {
    //   startDate: workOrderData.actualStartDate ? formatDate(workOrderData.actualStartDate) : null,
    //   endDate: workOrderData.actualEndDate ? formatDate(workOrderData.actualEndDate) : null,
    // },
    defaultValues: {
      startDate: formatDate(workOrderData.actualStartDate),
      endDate: formatDate(workOrderData.actualEndDate),
    },
  });

  useEffect(() => {
    if (editAssociateShiftDate?.associateStartDate || editAssociateShiftDate?.associateEndDate) {
      reset({
        startDate: editAssociateShiftDate?.associateStartDate
          ? editAssociateShiftDate.associateStartDate
          : formatDate(workOrderData.actualStartDate),
        endDate: editAssociateShiftDate?.associateEndDate
          ? editAssociateShiftDate.associateEndDate
          : formatDate(workOrderData.actualEndDate),
      });
    }
  }, [editAssociateShiftDate, reset, workOrderData]);

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (type === 'supervisor') {
      if (event.target.value.trim()) {
        const searchResults = originalTeamData.filter((member) =>
          member?.name?.toLowerCase()?.includes(event.target.value.toLowerCase())
        );
        setIsRecommended(false);
        setTeamData(searchResults);
      } else {
        setTeamData(originalTeamData);
        setIsRecommended(true);
      }
    } else {
      if (event.target.value.trim()) {
        const searchResults = originalAssociateTeamData.filter((member) =>
          member?.name?.toLowerCase()?.includes(event.target.value.toLowerCase())
        );
        setIsRecommended(false);
        setAssociateTeamData(searchResults);
      } else {
        setAssociateTeamData(originalAssociateTeamData);
        setIsRecommended(true);
      }
    }
  };

  const handleSelectMember = (memberId: string, memberType: string) => {
    if (memberType === 'supervisor') {
      handleSelectSupervisor(memberId);
    } else {
      handleSelectAssociate(memberId);
    }
  };

  const handleSelectSupervisor = (memberId: string) => {
    const teamMembersData = [...teamData];
    const originalTeamMembersData = [...originalTeamData];
    const selectedMember = teamMembersData.find((member: ITeamMemberData) => member.id === memberId);
    if (selectedMember) {
      selectedMember.isChecked = !selectedMember?.isChecked;
      const selectedMemberindex = teamMembersData.findIndex((member: ITeamMemberData) => member.id === memberId);
      const originalSelectedMemberindex = originalTeamMembersData.findIndex(
        (member: ITeamMemberData) => member.id === memberId
      );

      if (selectedMemberindex !== -1) {
        teamMembersData[selectedMemberindex] = selectedMember;
        setTeamData(teamMembersData);
      }
      if (originalSelectedMemberindex !== -1) {
        originalTeamMembersData[originalSelectedMemberindex] = selectedMember;
        setOriginalTeamData(originalTeamMembersData);
      }
      const checkedSupervisorsList = originalTeamMembersData.filter((member: ITeamMemberData) => member.isChecked);
      setCheckedSupervisors(checkedSupervisorsList);
    }
  };

  const handleSelectAssociate = (memberId: string) => {
    const teamMembersData = [...associateTeamData];
    const originalTeamMembersData = [...originalAssociateTeamData];
    const selectedMember = teamMembersData.find((member: ITeamMemberData) => member.id === memberId);
    if (selectedMember) {
      selectedMember.isChecked = !selectedMember?.isChecked;
      const selectedMemberindex = teamMembersData.findIndex((member: ITeamMemberData) => member.id === memberId);
      const originalSelectedMemberindex = originalTeamMembersData.findIndex(
        (member: ITeamMemberData) => member.id === memberId
      );

      if (selectedMemberindex !== -1) {
        teamMembersData[selectedMemberindex] = selectedMember;
        setAssociateTeamData(teamMembersData);
      }
      if (originalSelectedMemberindex !== -1) {
        originalTeamMembersData[originalSelectedMemberindex] = selectedMember;
        setOriginalAssociateTeamData(originalTeamMembersData);
      }
      const checkedAssociatesList = originalTeamMembersData.filter((member: ITeamMemberData) => member.isChecked);
      setCheckedAssociates(checkedAssociatesList);
    }
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const handleDeleteAssociate = async (associateDetails: ITeamMemberData | undefined) => {
    try {
      setIsLoading(true);
      const userId = associateDetails?.userId ?? '';
      const deleteAssociateResponse = await WorkOrdersApiService.deleteTeamAssignmentWorkOrderTeam({
        workOrderId,
        userIds: [userId],
        woScheduleMasterId: shiftDetailId,
      });
      console.log('Team member deleted successfully', deleteAssociateResponse);
      handleCloseModal();
      const message =
        assignmentType === AssignmentCardType.AssignSupervisor
          ? t('assignJob:successfullyDeletedSupervisor')
          : t('assignJob:successfullyDeletedAssociate');
      setSnackBarStyle(true);
      setSnackbarMessage(message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTimeout(() => {
        onClose(assignmentType);
      }, 1000);
      if (assignmentType === AssignmentCardType.AssignSupervisor) {
        await fetchVendorTeam(workOrderId);
      } else {
        await fetchAssociateTeam(workOrderId);
      }

      if (assignmentType === AssignmentCardType.AssignAssociate) {
        renderAssignedAssociateTeamData(shiftDetailId);
      } else {
        renderAssignedSupervisorTeamData(shiftDetailId);
      }
      await getWorkOrderDetails();
      await fetchDisableStartJobResult();
    } catch (error) {
      console.error('Failed to delete team member', error);
    } finally {
      setIsLoading(false);
      setSnackBarStyle(false);
    }
  };

  const onConfirmSupervisors = async () => {
    try {
      setIsDataSaving(true);
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;
      const newlyAddedSupervisors = checkedSupervisors.filter((item) => !item.isAlreadyChecked);
      const createWorkOrderTeamInput: CreateWorkOrderTeam[] = newlyAddedSupervisors.map((item) => {
        if (item.teamId) {
          return {
            userId: item?.id || '',
            vendorId,
            roleId: item?.roleId as string,
            isSupervisor: item?.isSupervisor,
            teamId: item?.teamId,
          };
        }
        return {
          userId: item?.id || '',
          vendorId,
          roleId: item?.roleId as string,
          isSupervisor: item?.isSupervisor,
        };
      });
      const confirmedTeamResponse = await WorkOrdersApiService.addTeamAssignmentWorkOrderTeam({
        workOrderId,
        createScheduleAssignmentsInput: createWorkOrderTeamInput,
        woScheduleMasterId: shiftDetailId,
      });
      if (confirmedTeamResponse.errors?.length) {
        setSnackBarStyle(true);
        setSnackbarMessage(confirmedTeamResponse.errors[0].message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsDataSaving(false);
      } else {
        setSnackBarStyle(true);
        setSnackbarMessage(`${t('messages:successfullyAssignedSupervisor')}`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setIsDataSaving(false);
        fetchVendorTeam(workOrderId);
        setTimeout(() => {
          onClose(assignmentType);
        }, 1000);
        await getWorkOrderDetails();
        await fetchDisableStartJobResult();
      }
    } catch (e) {
      setIsDataSaving(false);
      console.log('Error:', e);
      setSnackBarStyle(false);
    }
  };

  const handleNextButton = () => {
    if (assignmentType !== AssignmentCardType.AssignSupervisor) {
      setTeamCardNumber(teamCardNumber + 1);
      setEditAssociateShiftDate({
        userId: '',
        associateStartDate: '',
        associateEndDate: '',
      });
    } else {
      if (assignmentType === AssignmentCardType.AssignSupervisor) {
        onConfirmSupervisors();
      }
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [deleteMember, setDeleteMember] = useState<ITeamMemberData>();

  const handleOpenModal = (member: ITeamMemberData) => {
    setDeleteMember(member);
    setModalOpen(true);
  };
  const handleCloseModal = () => setModalOpen(false);

  // useEffect(() => {
  //   fetchVendorTeam(workOrderId);
  // }, []);

  const renderTeamContent = (teamData: ITeamMemberData[]) => {
    return (
      <>
        {teamData?.length > 0 && (
          <AssignTeamDiv>
            {teamData?.map((member: ITeamMemberData) => {
              return (
                <>
                  <TeamCard
                    key={member.id}
                    onClick={() => {
                      if (!member.isAlreadyChecked) {
                        handleSelectMember(
                          member.id as string,
                          assignmentType === AssignmentCardType.AssignSupervisor ? 'supervisor' : 'associate'
                        );
                      }
                    }}>
                    <div style={{ display: 'flex' }}>
                      <LogoWrapper>
                        <div>{getAcronymName(member.name as string).toUpperCase()}</div>
                      </LogoWrapper>
                      <TeamContainer>
                        <div className="Title">{member.name}</div>
                        <div className="Position">{convertToReadableFormat(member.role)}</div>
                      </TeamContainer>
                    </div>
                    <div>
                      <Box>
                        {assignmentType === AssignmentCardType.AssignAssociate && member.isAlreadyChecked ? (
                          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                            <img
                              src={Icons.EditBlackIcon}
                              alt={t('altTexts:edit')}
                              style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                              onClick={() => {
                                setTeamCardNumber(teamCardNumber + 1);
                                setEditAssociateShiftDate({
                                  userId: member?.id ?? '',
                                  associateStartDate: member?.associateStartDate ?? '',
                                  associateEndDate: member?.associateEndDate ?? '',
                                });
                              }}
                            />
                            <img
                              src={Icons.DeleteIcon}
                              alt={t('altTexts:delete')}
                              style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenModal(member);
                              }}
                            />
                          </Box>
                        ) : member.isAlreadyChecked ? (
                          <img
                            src={Icons.DeleteIcon}
                            alt={t('altTexts:delete')}
                            style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenModal(member);
                            }}
                          />
                        ) : member.isChecked ? (
                          <img
                            src={Icons.CheckedIcon}
                            alt={t('altTexts:checked')}
                            style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                          />
                        ) : (
                          <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} style={{ cursor: 'pointer' }} />
                        )}
                      </Box>
                    </div>
                  </TeamCard>
                </>
              );
            })}
          </AssignTeamDiv>
        )}
      </>
    );
  };
  const parseDateString = (dateString: string | null): Date | null => {
    return dateString ? new Date(dateString) : null;
  };

  const disableNextButton = () => {
    if (assignmentType === AssignmentCardType.AssignSupervisor) {
      return checkedSupervisors?.length ? false : true;
    } else {
      return checkedAssociates?.length ? false : true;
    }
  };

  const toISOStringOrNull = (date: Date | null | undefined): string | null => {
    return date ? new Date(date).toISOString() : null;
  };

  const handleConfirmButton = async () => {
    setIsConfirmButtonLoading(true);
    try {
      const customerDetails = getStoredCustomerDetails();
      const vendorId = customerDetails.customerId;
      const startDate = getValues(FieldNames.startDate);
      const endDate = getValues(FieldNames.endDate);
      if (checkedAssociates.length) {
        const newlyAddedAssociates = checkedAssociates.filter((item) => !item.isAlreadyChecked);
        const editTeamMembers = checkedAssociates.filter((data) => data?.id === editAssociateShiftDate?.userId);
        let finalMembers;
        if (isEmpty(editAssociateShiftDate?.userId)) {
          finalMembers = newlyAddedAssociates;
        } else {
          finalMembers = editTeamMembers;
        }
        const createWorkOrderTeamInput: CreateWorkOrderTeam[] = finalMembers.map((item) => {
          const parsedStartDate = parseDateString(startDate);
          const parsedEndDate = parseDateString(endDate);
          if (item.teamId) {
            return {
              userId: item.id || '',
              vendorId,
              roleId: item.roleId as string,
              isSupervisor: item.isSupervisor,
              teamId: item.teamId,
              associateStartDate: toISOStringOrNull(parsedStartDate),
              associateEndDate: toISOStringOrNull(parsedEndDate),
            };
          }
          const newMemberInput: CreateWorkOrderTeam = {
            userId: item.id || '',
            vendorId,
            roleId: item.roleId as string,
            isSupervisor: item.isSupervisor,
          };
          if (!item.isSupervisor) {
            newMemberInput.associateStartDate = toISOStringOrNull(parsedStartDate);
            newMemberInput.associateEndDate = toISOStringOrNull(parsedEndDate);
          }
          return newMemberInput;
        });
        const confirmedTeamResponse = isEmpty(editAssociateShiftDate?.userId)
          ? await WorkOrdersApiService.addTeamAssignmentWorkOrderTeam({
              workOrderId,
              createScheduleAssignmentsInput: createWorkOrderTeamInput,
              woScheduleMasterId: shiftDetailId,
            })
          : await WorkOrdersApiService.updateTeamAssignmentWorkOrderTeam({
              workOrderId,
              updateScheduleAssignmentsInput: createWorkOrderTeamInput,
            });
        if (confirmedTeamResponse.errors?.length) {
          setSnackBarStyle(true);
          setSnackbarMessage(confirmedTeamResponse.errors[0].message);
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } else {
          setSnackBarStyle(true);
          setSnackbarMessage(`${t('messages:successfullyAssignedAssociate')}`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setTimeout(() => {
            onClose(assignmentType);
          }, 1000);
          await getWorkOrderDetails();
          await fetchDisableStartJobResult();
        }
      }
      setIsConfirmButtonLoading(false);
      setSnackBarStyle(false);
    } catch (e) {
      setIsConfirmButtonLoading(false);
      console.error('Error', e);
      setSnackBarStyle(false);
    }
  };

  useEffect(() => {
    disableNextButton();
  }, [teamCardNumber, checkedSupervisors, checkedAssociates]);

  return (
    <>
      <HeadingText>
        {teamCardNumber === 1
          ? t('assignJob:assignDuration')
          : assignmentType === AssignmentCardType.AssignSupervisor
            ? t('assignJob:assignSupervisors')
            : t('assignJob:assignAssociates')}
      </HeadingText>
      <Divider sx={divider} />
      {teamCardNumber === 1 ? (
        <Box sx={{ display: 'flex' }}>
          <DatePickerContainer>
            <DateSelection
              requiredText={t('oneTimeJob:startDateFieldReq')}
              control={control}
              fieldName={FieldNames.startDate}
              label={t('calendar:startDate')}
              iconColor="black"
              isExcludePastDate={false}
              showSpecificRangeDates={{
                from: workOrderData.actualStartDate && formatDate(workOrderData.actualStartDate),
                to: workOrderData.actualEndDate && formatDate(workOrderData.actualEndDate),
              }}
            />
            <DateSelection
              requiredText={t('oneTimeJob:endDateFieldReq')}
              control={control}
              fieldName={FieldNames.endDate}
              label={t('calendar:endDate')}
              iconColor="black"
              isExcludePastDate={false}
              showSpecificRangeDates={{
                from: workOrderData.actualStartDate && formatDate(workOrderData.actualStartDate),
                to: workOrderData.actualEndDate && formatDate(workOrderData.actualEndDate),
              }}
            />
          </DatePickerContainer>
        </Box>
      ) : (
        <>
          <CardTitle>
            {assignmentType === AssignmentCardType.AssignSupervisor
              ? t('assignJob:selectSupervisor')
              : t('assignJob:selectAssociateBelow')}
            :
          </CardTitle>
          <SearchFieldContainer
            sx={{
              boxShadow: '0px 10px 20px 6px rgba(121, 87, 0, 0.10)',
              marginBottom: '2em',
            }}>
            <SearchInput
              type="text"
              placeholder={
                assignmentType === AssignmentCardType.AssignSupervisor
                  ? t('assignJob:searchSupervisor')
                  : t('assignJob:searchAssociate')
              }
              onChange={(event) => {
                handleSearchInput(
                  event,
                  assignmentType === AssignmentCardType.AssignSupervisor ? 'supervisor' : 'associate'
                );
              }}
            />
            <img src={Icons.SearchIcon} alt={t('altTexts:search')} style={{ width: '1.5rem', height: '1.5rem' }} />
          </SearchFieldContainer>
          {isRecommended ? <CardTitle>{t('assignJob:recommended')}</CardTitle> : null}
          <CardContainerWrapper>
            {isTeamDataLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
            ) : assignmentType === AssignmentCardType.AssignSupervisor ? (
              teamData?.length > 0 ? (
                renderTeamContent(teamData)
              ) : (
                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                  {t('assignJob:noResultsFound')}
                </Typography>
              )
            ) : associateTeamData?.length > 0 ? (
              renderTeamContent(associateTeamData)
            ) : (
              <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                {t('assignJob:noResultsFound')}
              </Typography>
            )}
          </CardContainerWrapper>
        </>
      )}
      {teamCardNumber === 1 ? (
        <div style={{ display: 'flex' }}>
          <Button
            // disabled={disableNextButton()}
            sx={{ ...cancelButton, cursor: 'pointer', maxWidth: '100%', marginTop: '2em', width: '100%' }}
            onClick={() => onClose(assignmentType)}>
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>{t('assignJob:cancel')}</Typography>
          </Button>
          <Button
            // disabled={disableNextButton()}
            sx={{ ...createJobButton, maxWidth: '100%', marginTop: '2em', width: '100%' }}
            onClick={handleConfirmButton}>
            {isConfirmButtonLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
            ) : (
              <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
                {t('assignJob:confirm')}
              </Typography>
            )}
          </Button>
        </div>
      ) : (
        <Button
          disabled={disableNextButton()}
          sx={{ ...createJobButton, opacity: disableNextButton() ? '0.5' : '1', maxWidth: '100%', marginTop: '2em' }}
          onClick={handleNextButton}>
          {isDataSaving ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {assignmentType === AssignmentCardType.AssignSupervisor
                ? t('assignJob:assignSupervisors')
                : t('assignJob:next')}
            </Typography>
          )}
        </Button>
      )}
      <ModifyPopUp
        open={isModalOpen}
        handleClose={handleCloseModal}
        handleOnCloseJob={() => handleDeleteAssociate(deleteMember)}
        loading={isLoading}
        heading={
          assignmentType === AssignmentCardType.AssignSupervisor
            ? t('assignJob:removeSupervisor')
            : t('assignJob:removeAssociate')
        }
        text={
          assignmentType === AssignmentCardType.AssignSupervisor
            ? t('assignJob:areYouSureToRemoveSupervisor')
            : t('assignJob:areYouSureToRemoveAssociate')
        }
      />

      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
        styles={snackbarStyle && { width: '100%', margin: 0 }}
      />
    </>
  );
};

export default AssignTeam;
