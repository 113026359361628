/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { Dialog, styled } from '@mui/material';
import theme from '../../../Shared/Themes/theme';
import { StyleConstants } from '../../../Shared/Constants/Style.Constants';

export const buttonStyle = {
  photoAndVideoStyle: {
    width: '43.5rem',
    height: '21.75rem',
    borderRadius: '1.5rem',
  },
  '@media (max-width: 64rem)': { width: '30.5rem', height: '16.75rem' },

  selectStyle: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    borderRadius: '0.875rem',
    height: '2.5rem',
    '& .MuiOutlinedInput-input': {
      '&:focus': {
        borderColor: `${theme.palette.secondary.light} !important`,
      },
    },
  },

  menuItemStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h3?.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '1.375rem',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.customColor7,
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  button: {
    padding: '1rem 1.5rem',
    border: 'none',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '1.25rem',
    minWidth: '9.375rem',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 1rem',
      borderRadius: '1rem',
      minWidth: '7.75rem',
    },
  },
  buttonTextStyle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: StyleConstants.LetterSpacingTight,
    color: theme.palette.common.white,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  secondaryButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '1.25rem',
    textTransform: 'none',
    marginRight: '1.5rem',
    padding: '1.0625rem 2rem',
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
      marginRight: '1rem',
    },
  },

  radioButton: {
    width: '1.25rem',
    height: '1.25rem',
    color: theme.palette.common.black,
    '&.Mui-checked': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.main,
      '& + span:last-child': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },

  radioButtonText: {
    fontFamily: theme.typography.fontFamily,
    marginRight: '0 !important',
    marginLeft: '0',
    gap: '1rem',
    '&.MuiFormControlLabel-root': {
      '&> span:last-child': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h3?.fontSize,
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: '1.375rem',
        letterSpacing: StyleConstants.LetterSpacingTight,
        color: theme.palette.text.primary,
      },
    },
    '@media (max-width: 64rem)': {
      '&.MuiFormControlLabel-root': {
        '&> span:last-child': {
          fontSize: theme.typography.h3?.fontSize,
          lineHeight: '1.5rem',
        },
      },
    },
  },

  executionType: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    '@media (max-width: 64rem)': {
      gridTemplateColumns: 'repeat(2,1fr)',
    },
  },

  serviceScheduleContent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2.625rem',
  },

  displayRowStyle: {
    display: 'flex',
    flexDirection: 'row',
  },

  displayStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
};
export const HeaderContainer = styled('div')(({ flexDirection }: any) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 10px 20px 6px rgba(121, 87, 0, 0.10)',
  padding: '1.5rem',
  marginBottom: '1.5rem',
  width: '100%',
  borderRadius: '1rem',
  display: 'flex',
  flexDirection: flexDirection || 'column',
}));

export const LogoContainer = styled('div')(({ theme }) => ({
  marginRight: '1rem',
  '& img': {
    width: '3.5rem',
    height: '3.5rem',
  },
  [theme.breakpoints.down('laptop')]: {
    marginRight: '0.5rem',
    '& img': {
      width: '3rem',
      height: '3rem',
    },
  },
}));

export const FieldsContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: StyleConstants.JustifyContentBetween,
  gap: '0.5rem',
}));

export const Fields = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: StyleConstants.JustifyContentBetween,
}));

export const DurationText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingDefault,
  color: theme.palette.secondary.contrastText,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h6.fontSize,
    lineHeight: '1.25rem',
    letterSpacing: '0.025rem',
  },
}));

export const MediaContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
}));

export const MediaText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.primary.dark,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
  },
}));

export const DialogWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    padding: '1.5rem',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const CloseIconStyle = styled('span')(() => ({
  cursor: 'pointer',
  position: 'absolute',
  right: '1.5rem',
  top: '-1.125rem',
}));

export const PopupHeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  marginBottom: '1.5rem',
  [theme.breakpoints.down('laptop')]: {},
}));

export const PhotosCountText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.5rem',
  },
}));

export const ChevronStyle = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
}));

/////////Bid Proposal////////
export const TextFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [theme.breakpoints.down('laptop')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
}));

export const BidProposalHeadingText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  marginBottom: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const Divider = styled('div')(() => ({
  borderLeft: `0.0625rem solid ${theme.palette.divider}`,
  margin: '0 1rem',
}));

////Success screen////
export const SuccessWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
    width: '46.5rem',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

export const CongratsContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: theme.palette.secondary.dark,
  borderRadius: '1.5rem 1.5rem 0 0',
}));

export const CongratsText = styled('span')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '2rem',
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '2.5rem',
  color: theme.palette.text.primary,
  marginBottom: '2.5rem',
}));

export const MessageText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  color: theme.palette.text.secondary,
}));

export const ButtonsContainer = styled('div')(() => ({
  padding: '2.5rem 0',
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const DetailsContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  // height: '66px',
}));

export const RevisionRequestContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '1rem 0',
}));

export const RevisionText = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  width: '100%',
  maxWidth: StyleConstants.MaxWidth,
  height: '100%',
  borderRadius: '6px',
  padding: '4px 8px',
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '22px',
  letterSpacing: '0.25px',
  marginRight: '66px',
}));

export const RequestText = styled('div')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '22px',
  letterSpacing: '0.25px',
  paddingTop: '4px',
  display: 'flex',
  justifyContent: StyleConstants.JustifyContentBetween,
  width: '100%',
  color: theme.palette.secondary.contrastText,
}));

export const HorizantalLine = styled('div')(() => ({
  width: '100%',
  border: '0.5px',
  borderColor: theme.palette.divider,
  borderBlockColor: 'unset',
  backgroundColor: theme.palette.divider,
  display: 'block',
  unicodeBidi: 'isolate',
  marginBlockStart: '0.5em',
  marginBlockEnd: '0.5em',
  marginInlineStart: 'auto',
  marginInlineEnd: 'auto',
  overflow: 'hidden',
  borderStyle: 'inset',
  borderWidth: '1px',
}));

export const ViewLogsContainer = styled('span')(() => ({
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.fontWeightBold,
}));

export const Field = styled('div')(({ theme }) => ({
  border: `0.0625rem solid ${theme.palette.secondary.light}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '10px',
  width: '8.5rem',
  height: '2rem',
  padding: '1rem 0.75rem 1rem 0.25rem',
  [theme.breakpoints.down('laptop')]: {
    marginLeft: 0,
    width: '7rem',
    padding: '1rem 0.5rem',
  },
}));

export const TextField = styled('input')(({ theme }) => ({
  borderRadius: '10px',
  width: '100%',
  maxWidth: StyleConstants.MaxWidth,
  textAlign: 'right',
  border: 'none',
  marginLeft: '0.5rem',
  marginRight: '0.25rem',
  outlineStyle: 'none',
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '22px',
  color: theme.palette.text.primary,
  letterSpacing: '0.25px',
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('laptop')]: {
    marginLeft: '0.5rem',
    height: '26px',
  },
}));

export const Percentage = styled('div')(() => ({
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '22px',
  color: theme.palette.text.primary,
  // marginRight: '0.75rem',
  marginRight: '0px',
}));

export const ProfitText = styled('label')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  whiteSpace: 'nowrap',
  marginBottom: '0.25rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
  },
}));

export const ProfitPercentageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: StyleConstants.MaxWidth,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: '2.5rem',
  [theme.breakpoints.down('laptop')]: {
    marginRight: '2rem',
  },
}));

export const FinalQuoteText = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: theme.typography.fontWeightLight,
  fontSize: theme.typography.h1.fontSize,
  lineHeight: '40px',
  textAlign: 'right',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '24px',
    marginBottom: '10px',
  },
}));

export const FinalQuotePriceText = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h1.fontSize,
  lineHeight: '40px',
  textAlign: 'right',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '22px',
  },
}));

export const ErrorMessage = styled('div')(() => ({
  color: 'red',
  fontSize: theme.typography.h5?.fontSize,
}));

export const DeleteWithdrawText = styled('span')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  color: theme.palette.text.secondary,
}));

export const FinalQuoteContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  marginRight: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}));

export const BottomBarButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1.5rem',
}));

export const TableWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: StyleConstants.JustifyContentBetween,
  flexWrap: 'wrap',
}));

export const EstimationTableInput = styled('input')(() => ({
  letterSpacing: 'inherit',
  boxSizing: 'content-box',
  background: 'none',
  height: '1.4375em',
  margin: '0',
  display: 'block',
  minWidth: '0',
  width: '100%',
  padding: '8px 12px',
  font: 'inherit',
  borderRadius: '14px',
  color: theme.palette.text.primary,
  border: `0.0625rem solid ${theme.palette.secondary.light}`,
  ':focus-visible': {
    outlineColor: theme.palette.secondary.light,
  },
}));

export const ReviewContainer = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  marginBottom: '2rem',
  boxShadow: '0px 10px 20px 6px rgba(121, 87, 0, 0.10)',
  [theme.breakpoints.down('mobile')]: {
    padding: '1rem',
  },
}));

export const ReviewText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.primary,
  marginBottom: '0.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
  },
}));

export const DetailText = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.disabled,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
  },
}));

export const BottombarContainer = styled('div')(({ theme }) => ({
  padding: '1.5rem 9.75rem 1.5rem 4.75rem',
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  gap: '1rem',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex: '3',
  [theme.breakpoints.down('laptop')]: {
    padding: '1rem 1.5rem 1rem 6rem',
  },
  [theme.breakpoints.down('mobile')]: {
    padding: '1.5rem',
  },
}));

export const ProposalFields = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3,1fr)',
  gap: '1.5rem',
  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const DetailFields = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ProposalContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: StyleConstants.JustifyContentBetween,
  marginBottom: '1.5rem',
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}));

export const JobVisualContainer = styled('div')(() => ({
  display: 'flex',
  marginTop: '1.5rem',
  flexDirection: 'column',
}));

export const BottomBarFields = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '2.5rem',
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',
  },
}));

export const BottomBarContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  zIndex: '3',
  height: '6.5rem',
  paddingRight: '9.75rem',
  paddingLeft: '9.75rem',
  [theme.breakpoints.down('laptop')]: {
    height: '8.5rem',
    paddingRight: '1.5rem',
    paddingLeft: '6.5rem',
    paddingTop: '1rem',
    paddingBottom: '1.5rem',
    flexWrap: 'wrap',
  },
}));

export const BottomBarProfits = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: StyleConstants.JustifyContentBetween,
  },
}));

export const BottomBarButtonOptions = styled('div')(({ theme }) => ({
  width: '80%',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
  },
}));

export const BottomBarCustomerButtonOptions = styled('div')(() => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}));
