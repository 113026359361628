/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Controller } from 'react-hook-form';
import { Box, IconButton, InputAdornment, TextField, Autocomplete, CircularProgress } from '@mui/material';
import { CurrentLocation, InfoField, buttonStyles } from './AddFacility.Style';

import MasterDataApiService from '../../../../Shared/Services/MasterDataService';

import { Options } from '../../../Jobs/Components/WorkTasks/JobDetailsTasks.styles';
import { Patterns } from '../../../../Shared/Constants';
import { ErrorMsg } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';

import { Icons } from '../../../../Shared/Constants/Icons';
import { FacilityType } from '../../Models/AddFacility.Model';

import theme from '../../../../Shared/Themes/theme';

const FacilityDetailsCard = (props: any): JSX.Element => {
  const [isFacilityTypeOpen, setFacilityTypeOpen] = useState(false);
  const [facilityTypes, setFacilityTypes] = useState<FacilityType[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const { control } = props;

  const location = useLocation();
  const { t } = useTranslation(['addFacility', 'addFacility']);
  const { textField } = buttonStyles;

  const fetchAllFacilityTypes = useCallback(async () => {
    const facilityTypesResponse = await MasterDataApiService.getAllFacilityTypes();
    if (facilityTypesResponse?.metaData) {
      const { metaData } = facilityTypesResponse;
      const allFacilityTypesResponse = await MasterDataApiService.getAllFacilityTypes(metaData.totalCount);
      const filteredData = allFacilityTypesResponse?.data?.map((item: { value: any; keyCode: any }) => ({
        value: item.value,
        keyCode: item.keyCode,
      }));
      setFacilityTypes(filteredData);
    }
  }, []);

  useEffect(() => {
    fetchAllFacilityTypes();
  }, [fetchAllFacilityTypes]);

  const isLocationStatePresent = !!location.state;

  return (
    <>
      <CurrentLocation sx={{ padding: 0 }}>
        <Box>
          <Controller
            name="facilityName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Box sx={{ marginBottom: '2.5rem' }}>
                  <TextField
                    sx={buttonStyles.textField}
                    label={
                      <span>
                        {t('addFacility:facilityName')} <span className="mandatory-field">*</span>
                      </span>
                    }
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </Box>
              );
            }}
            rules={{
              required: `${t('addUsers:nameReq')}`,
              pattern: {
                value: Patterns.WordsBetweenSpace,
                message: t('addUsers:invalidName'),
              },
            }}
          />

          <Controller
            name="suitNumber"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Box sx={{ marginBottom: '2.5rem' }}>
                  <TextField
                    sx={textField}
                    label={t('addFacility:SuitNumber')}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </Box>
              );
            }}
            rules={{
              pattern: {
                value: Patterns.OnlyInteger,
                message: t('addFacility:invalidSuitNo'),
              },
            }}
          />

          <Controller
            name="facilityType"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Box sx={{ marginBottom: '2.5rem' }}>
                  <Autocomplete
                    sx={textField}
                    value={facilityTypes.find((item) => item.keyCode === value) || null}
                    onChange={(e, newValue) => {
                      onChange(newValue?.keyCode || null);
                    }}
                    options={facilityTypes}
                    getOptionLabel={(option: any) => option.value}
                    loading={loadingOptions && facilityTypes.length === 0}
                    loadingText={<CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />}
                    noOptionsText={
                      <InfoField>
                        <Box>
                          <img src={Icons.InfoIcon} style={{ marginRight: '0.5rem' }} />
                        </Box>
                        {t('addFacility:notInList')}
                      </InfoField>
                    }
                    onOpen={() => {
                      setLoadingOptions(true);
                      setFacilityTypeOpen(true);
                    }}
                    onClose={() => {
                      setLoadingOptions(false);
                      setFacilityTypeOpen(false);
                    }}
                    disabled={isLocationStatePresent}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <span>
                            {t('addFacility:facilityType')} <span className="mandatory-field">*</span>
                          </span>
                        }
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: isFocused && (
                            <InputAdornment position="start">
                              <IconButton>
                                <img src={Icons.SearchIcon} alt={t('altTexts:viewDetails')} />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setFacilityTypeOpen(!isFacilityTypeOpen)}
                                style={{ padding: 0 }}>
                                <img
                                  src={isFacilityTypeOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                                  alt={isFacilityTypeOpen ? 'down' : 'up'}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                          placeholder: isFocused ? `${t('addFacility:search')}` : '',
                        }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Options {...props} value={option.keyCode}>
                        {option.value}
                      </Options>
                    )}
                    filterOptions={(options, { inputValue }) =>
                      options.filter((option: any) => option.value.toLowerCase().includes(inputValue.toLowerCase()))
                    }
                    open={isFacilityTypeOpen}
                  />
                  <ErrorMsg>{error && error?.message}</ErrorMsg>
                </Box>
              );
            }}
            rules={{
              required: `${t('addFacility:facilityTypeReq')}`,
            }}
          />
        </Box>
      </CurrentLocation>
    </>
  );
};

export default FacilityDetailsCard;
