/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { CenterAlignment, CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { Container, TitleWrapper } from '../../../../Shared/Components/Common/PageTitle/PageTitle.styles';
import {
  BottombarContainer,
  CongratsContainer,
  CongratsText,
  ReviewContainer,
  ReviewText,
  DetailText,
  buttonStyle,
  ProposalFields,
  DetailFields,
  MediaContainer,
  MediaText,
  Divider,
  ProposalContainer,
  JobVisualContainer,
} from '../../../Vendor/Components/CreateBid.Style';
import { BidApprovalContainer } from './BidApprovalEmail.style';
import { Loader } from '../../../Jobs/Components/JobView/JobsView.styles';

import CustomerApiService from '../../Services/CustomerService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import {
  BidApprovalResponseModel,
  EmailTransactionDetailsModel,
  RESPONSE_CODES,
  TransactionStatus,
  ICommentModel,
} from '../../Models/BidApproval.Model';
import BidApprovalPopup from './BidApprovalPopup/BidApprovalPopup';
import { fetchDataFromS3Bucket, formatDate, getIpAddress, joinByComma } from '../../../../Shared/Utilities/utils';

import { Icons } from '../../../../Shared/Constants/Icons';
// import ErrorImg from '../../../../assets/images/error_caution.svg';
import { isEmpty } from 'lodash';
import { buttonStyles } from '../../../Onboarding/Components/Login/LoginPage.Style';
import { Patterns } from '../../../../Shared/Constants';
import { ErrorMsg } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import MediaPopup from '../../../../Shared/Components/Common/MediaPopUp/MediaPopUp';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import { IDetailsProps } from '../../../Jobs/Models/AddEditOneTimeJob.Model';
import { CommenterType, KeyType } from '../../../../API';
import theme from '../../../../Shared/Themes/theme';

type MediaType = 'photo' | 'video';

const BidApprovalEmail = (): JSX.Element => {
  const { t } = useTranslation(['altTexts']);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailTransactionId = queryParams.get('txId');
  const status = queryParams.get('status');
  const [serviceCategories, setServiceCategories] = useState([]);
  const [summary, setSummary] = useState<BidApprovalResponseModel>();
  const [noteToCustomer, setNoteToCustomer] = useState('');
  const { button, buttonTextStyle } = buttonStyle;
  const [isBidApprovalLoading, setIsBidApprovalLoading] = useState(false);
  const [isApprovingBid, setIsApprovingBid] = useState(false);
  const [isRejectingBid, setIsRejectingBid] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>('');
  const [emailTransactionDetails, setEmailTransactionDetails] = useState<EmailTransactionDetailsModel | object>({});
  const [customMessage, setCustomMessage] = useState('');
  const [customHeaderImg, setCustomHeaderImg] = useState(Icons.ErrorCautionImg);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showCtaButton, setShowCtaButton] = useState(true);
  const [showErrorView, setShowErrorView] = useState(false);
  const [isLogoLoading, setIsLogoLoading] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [isValidLogo, setIsValidLogo] = useState<boolean>(false);
  const [images, setImages] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaType, setMediaType] = useState<MediaType>('photo');
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);
  const { displayStyle, displayRowStyle } = buttonStyle;
  const navigate = useNavigate();

  const handleDialogState = (): void => {
    setIsDialogOpen(false);
    setCustomMessage('');
  };

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      comments: '',
    },
  });

  const checkIfAlreadyApprovedRejected = (emailTransactionDetails: EmailTransactionDetailsModel) => {
    if (emailTransactionDetails.transactionStatus === TransactionStatus.Approved) {
      setCustomMessage('Job is already approved');
      setCustomHeaderImg(Icons.CongratulationsImg);
      setShowErrorView(true);
      setShowCtaButton(false);
      return true;
    }
    if (emailTransactionDetails.transactionStatus === TransactionStatus.Rejected) {
      setCustomMessage('Job is already rejected');
      setCustomHeaderImg(Icons.CongratulationsImg);
      setShowErrorView(true);
      setShowCtaButton(false);
      return true;
    }
  };
  const checkIfImageValid = async (url: string) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = url;
      image.onload = (): void => {
        resolve(true);
      };
      image.onerror = (): void => {
        resolve(false);
      };
    });
  };

  const fetchLogoUrl = async (vendorId: string) => {
    try {
      setIsLogoLoading(true);
      const url = await fetchDataFromS3Bucket(navigate, `vendor/${vendorId}/company-logos/${vendorId}`);
      const isValid = await checkIfImageValid(url);
      if (isValid) {
        setIsValidLogo(true);
        setLogoUrl(url);
      } else {
        setIsValidLogo(false);
        setLogoUrl('');
      }
      return { logoUrl, isValid };
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsLogoLoading(false);
    }
  };

  const rendersVendorLogo = (): JSX.Element | null => {
    if (isLogoLoading) {
      return <CircularProgress size={20} sx={{ color: theme.palette.primary.dark }} />;
    } else if (isValidLogo) {
      return <img src={logoUrl} style={{ height: '5rem', aspectRatio: '16/9' }} alt={t('altTexts:companyLogo')} />;
    }
    return null;
  };

  const isReadonlyProposal = (emailTransactionDetails: EmailTransactionDetailsModel) => {
    if (emailTransactionDetails?.isReadOnly) {
      setShowCtaButton(false);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchBidSummary = async () => {
    try {
      const isSummaryAlreadyLoading = localStorage.getItem('isCustomerApprovalInProgress');
      if (isSummaryAlreadyLoading === 'true') {
        return true;
      }
      setIsBidApprovalLoading(true);
      if (!emailTransactionId) {
        setCustomMessage('Invalid url');
        setCustomHeaderImg(Icons.ErrorCautionImg);
        setShowErrorView(true);
        setShowCtaButton(false);
        throw new Error('no email transaction id found in query params');
      }
      const emailTransactionDetailsResponse = await CustomerApiService.getEmailTransactionDetails(emailTransactionId);
      if (
        !emailTransactionDetailsResponse.data ||
        !Object.values(emailTransactionDetailsResponse.data).some((field) => field !== null)
      ) {
        setCustomMessage('Something went wrong!');
        setCustomHeaderImg(Icons.ErrorCautionImg);
        setShowErrorView(true);
        setShowCtaButton(false);
        throw new Error('no email transaction details found for email transaction');
      }
      isReadonlyProposal(emailTransactionDetailsResponse.data as EmailTransactionDetailsModel);
      if (checkIfAlreadyApprovedRejected(emailTransactionDetailsResponse.data as EmailTransactionDetailsModel)) {
        throw new Error('already approved/rejected');
      }
      const ipAddress = await getIpAddress();
      if (!ipAddress || !Object.keys(ipAddress).length) {
        setCustomMessage('Something went wrong!');
        setCustomHeaderImg(Icons.ErrorCautionImg);
        setShowErrorView(true);
        setShowCtaButton(false);
        throw new Error('Cannot find ip address');
      }
      const response = await CustomerApiService.getBidProposalSummary(
        (emailTransactionDetailsResponse.data as EmailTransactionDetailsModel).bidId
      );
      if (isEmpty(response.data)) {
        setShowCtaButton(false);
        throw new Error('Failed to fetch summary');
      }
      const commentsResponse = await JobCreateApiService.getComments(response.data.bidId);
      if (!commentsResponse.data) {
        throw new Error('failed to fetch notes for customer');
      }
      const filteredComment: ICommentModel = commentsResponse.data.find(
        (comment: ICommentModel) =>
          (comment.keyType as KeyType) === KeyType.JobBid &&
          (comment.commenterType as CommenterType) === CommenterType.DistrictManager
      );
      fetchLogoUrl(response?.data?.vendorId);
      const { data: orgServiceCategoriesResponse } = await MasterDataApiService.getAllServiceCategoryV3(
        response?.data.jobs.customerId
      );
      if (response?.data?.jobId) {
        fetchAllJobAttachments(response?.data?.jobId);
      }

      if (!orgServiceCategoriesResponse?.length) {
        throw new Error('Failed to fetch services');
      }
      const serviceCategoriesList = response.data.jobs.jobServices.map(
        (item: { mdServiceId: string }) => item.mdServiceId
      );
      const filteredServiceCategories = orgServiceCategoriesResponse.reduce(
        (
          accumulator: string[],
          current: {
            taskId: string;
            serviceCategoryId: string;
            serviceCategoryName: string;
            categoryImageUrl: string;
            categoryDescription: string;
            orgId: string;
            mdServiceType: string;
            taskName: string;
            taskImageUrl: string;
            taskDescription: string;
            unit: string;
            mdAreaType: string;
            modifiedBy: string;
            createdBy: string;
          }
        ) => {
          if (
            serviceCategoriesList.includes(current.serviceCategoryId) &&
            !accumulator?.includes(current.serviceCategoryName)
          ) {
            accumulator.push(current.serviceCategoryName);
          }
          return accumulator;
        },
        []
      );

      setServiceCategories(filteredServiceCategories);
      setSummary({
        ...response.data,
      });
      setNoteToCustomer(filteredComment?.comment || '');
      setEmailTransactionDetails(emailTransactionDetailsResponse.data);
      if (emailTransactionId && status === TransactionStatus.Approved) {
        localStorage.setItem('isCustomerApprovalInProgress', 'true');
        handleApproval(
          (emailTransactionDetailsResponse.data as EmailTransactionDetailsModel).emailTransactionId,
          ipAddress
        );
      }
      setIpAddress(ipAddress);
      setIsBidApprovalLoading(false);
    } catch (error) {
      localStorage.setItem('isCustomerApprovalInProgress', 'false');
      console.error(error);
      setIsBidApprovalLoading(false);
    }
  };

  useEffect(() => {
    fetchBidSummary();
  }, []);

  const handleApprovalSubmission = (data: { comments: string }) => {
    handleApproval(
      (emailTransactionDetails as EmailTransactionDetailsModel).emailTransactionId,
      ipAddress,
      data.comments
    );
  };

  const rendersCustomerAddress = () => {
    if (summary?.customerAddress) {
      const splitAddress = summary?.customerAddress.split('|');
      if (splitAddress[0]) {
        return splitAddress[0];
      } else {
        return joinByComma([splitAddress[1], splitAddress[2]]);
      }
    }
  };

  const handleSuccessDialog = (responseCode: string) => {
    if (responseCode === RESPONSE_CODES.BIDS.RECEIVED_APPROVAL) {
      setCustomMessage('Thank you for your approval!');
      setCustomHeaderImg(Icons.CongratulationsImg);
    } else {
      setCustomMessage('Expired');
      setCustomHeaderImg(Icons.ErrorCautionImg);
    }
    setIsDialogOpen(true);
    setIsApprovingBid(false);
    setShowCtaButton(false);
  };

  const handleApproval = async (emailTransactionId: string, ipAddress: string, comments = '') => {
    try {
      setIsApprovingBid(true);
      const response = await CustomerApiService.acceptRejectBidProposal(
        emailTransactionId,
        TransactionStatus.Approved,
        ipAddress,
        comments
      );
      if (comments && summary?.bidId) {
        const commentsInput = {
          createdBy: summary?.jobs.customerId,
          keyType: KeyType.JobBid,
          comment: comments,
          commenterType: CommenterType.Customer,
        };
        const commentsResponse = await JobCreateApiService.addComments(summary?.bidId, commentsInput);
        if (!commentsResponse?.data) {
          throw new Error('failed to add comment');
        }
      }
      if (!response?.data.responseCode) {
        throw new Error('no response found');
      }
      reset({
        comments: '',
      });
      handleSuccessDialog(response?.data.responseCode);
      localStorage.setItem('isCustomerApprovalInProgress', 'false');
    } catch (error) {
      console.error('Error', error);
      setIsApprovingBid(false);
      localStorage.setItem('isCustomerApprovalInProgress', 'false');
    }
  };

  const handleRejectDialog = (responseCode: string) => {
    if (responseCode === RESPONSE_CODES.BIDS.RECEIVED_REJECTION) {
      setCustomMessage('Quote sent for revision. Thank you!');
      setCustomHeaderImg(Icons.CongratulationsImg);
    } else {
      setCustomMessage('Expired');
      setCustomHeaderImg(Icons.ErrorCautionImg);
    }
    setIsDialogOpen(true);
    setIsRejectingBid(false);
    setShowCtaButton(false);
  };

  const handleReject = async (data: { comments: string }) => {
    try {
      setIsRejectingBid(true);
      const response = await CustomerApiService.acceptRejectBidProposal(
        (emailTransactionDetails as EmailTransactionDetailsModel).emailTransactionId,
        TransactionStatus.Rejected,
        ipAddress,
        data.comments
      );
      if (data.comments && summary?.bidId) {
        const commentsInput = {
          createdBy: summary?.bidId,
          keyType: KeyType.JobBid,
          comment: data.comments,
          commenterType: CommenterType.Customer,
        };
        const commentsResponse = await JobCreateApiService.addComments(summary?.bidId, commentsInput);
        if (!commentsResponse?.data) {
          throw new Error('failed to add comment');
        }
      }
      if (!response?.data.responseCode) {
        throw new Error('not response found');
      }
      reset({
        comments: '',
      });
      handleRejectDialog(response?.data.responseCode);
    } catch (error) {
      console.error('Error', error);
      setIsRejectingBid(false);
    }
  };

  const { textField } = buttonStyles;

  const rendersCallToActionButton = () => {
    if (showCtaButton) {
      return (
        <BottombarContainer>
          <Button
            sx={{ ...button, opacity: isApprovingBid || isRejectingBid ? '0.5' : '1' }}
            onClick={handleSubmit(handleApprovalSubmission)}
            disabled={isApprovingBid}>
            {isApprovingBid ? (
              <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
            ) : (
              <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>Approve</Typography>
            )}
          </Button>
          <Button
            sx={{ ...button, opacity: isApprovingBid || isRejectingBid ? '0.5' : '1' }}
            disabled={isRejectingBid}
            onClick={handleSubmit(handleReject)}>
            {isRejectingBid ? (
              <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
            ) : (
              <Typography sx={{ ...buttonTextStyle, textTransform: 'none' }}>Request Revision</Typography>
            )}
          </Button>
        </BottombarContainer>
      );
    }
    return null;
  };

  const rendersErrorView = () => {
    return (
      <CenterAlignment>
        <CongratsContainer sx={{ borderRadius: '1.5rem', marginTop: '80px', width: '35rem', padding: '1rem' }}>
          <Box sx={{ margin: '3rem 0 2.5rem 0' }}>
            <img src={customHeaderImg} />
          </Box>
          <CongratsText sx={{ textAlign: 'center' }}>{customMessage}</CongratsText>
        </CongratsContainer>
      </CenterAlignment>
    );
  };

  const openMediaPopup = (type: MediaType) => {
    const mediaArray = type === 'photo' ? images : videos;
    if (mediaArray.length) {
      setPopupOpen(true);
      setMediaType(type);
    }
  };

  const rendersMainContent = () => {
    return (
      <>
        <Container sx={{ width: '100%' }}>
          <TitleWrapper>
            <img src={Icons.PeazyNameLogo} alt={t('altTexts:peazyLogo')} />
          </TitleWrapper>
        </Container>
        {showErrorView ? (
          <CustomerContainer>{rendersErrorView()}</CustomerContainer>
        ) : (
          <CustomerContainer>
            <ReviewContainer
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1.5rem',
              }}>
              <Box sx={{ marginBottom: '0.5rem' }}>{rendersVendorLogo()}</Box>
              <DetailFields>
                <ReviewText>{summary?.vendorName}</ReviewText>
                <DetailText>{summary?.jobCustodianAddress}</DetailText>
              </DetailFields>
            </ReviewContainer>
            <ReviewContainer sx={buttonStyle.displayStyle}>
              <ProposalContainer>
                <Box>
                  <Box
                    sx={{
                      ...displayStyle,
                      justifyContent: 'space-between',
                    }}>
                    <ReviewText sx={{ fontSize: theme.typography.h1?.fontSize }}>Proposal</ReviewText>
                    <ReviewText>Submitted for approval on {formatDate(summary?.bidSubmissionDate)}</ReviewText>
                  </Box>
                </Box>
                <DetailFields sx={{ width: '14rem' }}>
                  <ReviewText>Proposal for</ReviewText>
                  <DetailText>{summary?.jobs.customerName}</DetailText>
                  <DetailText>{rendersCustomerAddress()}</DetailText>
                </DetailFields>
              </ProposalContainer>
              <ProposalFields
                sx={{
                  marginBottom: '1.5rem',
                }}>
                <DetailFields>
                  <ReviewText>Project</ReviewText>
                  <DetailText>{summary?.bidName}</DetailText>

                  <MediaPopup
                    isOpen={isPopupOpen}
                    onClose={closeMediaPopup}
                    currentMediaIndex={currentMediaIndex}
                    media={mediaType === 'photo' ? images : videos}
                    showPreviousMedia={() => showMedia('previous')}
                    showNextMedia={() => showMedia('next')}
                    numMedia={mediaType === 'photo' ? images?.length : videos?.length}
                    type={mediaType}
                  />
                </DetailFields>
                <DetailFields>
                  <ReviewText>Location</ReviewText>
                  <DetailText>
                    {summary?.jobs.intReferenceId}
                    {''} {summary?.jobs.customerName}
                  </DetailText>
                </DetailFields>
                <DetailFields>
                  <ReviewText>PO#</ReviewText>
                  <DetailText>- - - - - -</DetailText>
                </DetailFields>
              </ProposalFields>
              <ProposalFields>
                <DetailFields>
                  <ReviewText>Created by</ReviewText>
                  <DetailText>{summary?.jobs.createdBy}</DetailText>
                </DetailFields>
                <DetailFields>
                  <ReviewText>Date Range for Completion</ReviewText>
                  <Box sx={{ ...displayRowStyle, gap: '2rem' }}>
                    <DetailText>{formatDate(summary?.jobs.startDate)}</DetailText>
                    <DetailText>{formatDate(summary?.jobs.stopDate)}</DetailText>
                  </Box>
                </DetailFields>
                <DetailFields>
                  <ReviewText>Approve by</ReviewText>
                  <DetailText>{formatDate(summary?.jobs.startDate)}</DetailText>
                </DetailFields>
              </ProposalFields>
              <JobVisualContainer>
                <ReviewText sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  Job Visuals :{' '}
                  <span style={{ fontWeight: theme.typography.fontWeightBold, display: 'flex', marginLeft: '0.25em' }}>
                    {isMediaLoading ? (
                      <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                    ) : (
                      // <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <>
                        <MediaContainer onClick={() => openMediaPopup('photo')}>
                          <img src={Icons.PhotosIcon} alt={t('altTexts:galleryIcon')} />
                          <MediaText>{images?.length} Photos</MediaText>
                        </MediaContainer>
                        <Divider />
                        <MediaContainer onClick={() => openMediaPopup('video')}>
                          <img src={Icons.VideoIcon} alt={t('altTexts:videoIcon')} />
                          <MediaText>{videos?.length} Videos</MediaText>
                        </MediaContainer>
                      </>
                      // </Box>
                    )}
                  </span>
                </ReviewText>
              </JobVisualContainer>
            </ReviewContainer>
            <ReviewContainer sx={buttonStyle.displayStyle}>
              <ReviewText sx={{ fontSize: theme.typography.h2?.fontSize }}>Description</ReviewText>
              {serviceCategories.length ? (
                <DetailText sx={{ marginBottom: '1rem' }}>
                  <ul style={{ listStyleType: 'disc', paddingLeft: '1rem' }}>
                    {serviceCategories.map((category, index) => (
                      <li key={index}>
                        <Typography sx={{ fontWeight: theme.typography.fontWeightBold }}>{category}</Typography>
                      </li>
                    ))}
                  </ul>
                </DetailText>
              ) : null}
              <ReviewText
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '1rem',
                }}>
                Total Price:{' '}
                <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                  ${summary?.finalQuote ? parseFloat(summary?.finalQuote.toString()).toFixed(2) : '0.00'}
                </span>
              </ReviewText>
              {noteToCustomer ? (
                <Box sx={buttonStyle.displayStyle}>
                  <ReviewText sx={{ display: 'flex', gap: '1rem' }}>
                    Notes:<DetailText>{noteToCustomer}</DetailText>
                  </ReviewText>
                </Box>
              ) : null}
              {showCtaButton ? (
                <>
                  <ReviewText sx={{ marginBottom: '1rem' }}>Comments:</ReviewText>
                  <Controller
                    control={control}
                    name={'comments'}
                    disabled={status === TransactionStatus.Approved}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return (
                        <>
                          <TextField
                            multiline
                            sx={{
                              ...textField,
                              width: '100%',
                              '& .MuiOutlinedInput-input': {
                                color: theme.palette.text.primary,
                                fontSize: theme.typography.h5?.fontSize,
                                fontFamily: theme.typography.fontFamily,
                                fontWeight: theme.typography.fontWeightRegular,
                                lineHeight: '1.375rem',
                                letterSpacing: '0.015625rem',
                              },
                            }}
                            disabled={status === TransactionStatus.Approved}
                            onChange={(e) => onChange(e.target.value)}
                            value={value}></TextField>
                          <ErrorMsg>{error && error?.message}</ErrorMsg>
                        </>
                      );
                    }}
                    rules={{
                      pattern: {
                        value: Patterns.Instructions,
                        message: 'Invalid comments provided',
                      },
                      maxLength: {
                        value: 200,
                        message: 'Comments should be less than 200 characters',
                      },
                    }}
                  />
                </>
              ) : null}
            </ReviewContainer>
          </CustomerContainer>
        )}
        {rendersCallToActionButton()}
      </>
    );
  };

  const rendersApprovalContent = () => {
    if (isBidApprovalLoading) {
      return (
        <Loader sx={{ height: '100vh' }}>
          <CircularProgress size={50} sx={{ color: theme.palette.primary.dark }} />
        </Loader>
      );
    }
    return rendersMainContent();
  };

  const showMedia = (direction: 'previous' | 'next') => {
    setCurrentMediaIndex((prevIndex) => {
      const mediaArray = mediaType === 'photo' ? images : videos;
      return direction === 'previous'
        ? (prevIndex - 1 + mediaArray?.length) % mediaArray?.length
        : (prevIndex + 1) % mediaArray?.length;
    });
  };

  const closeMediaPopup = () => {
    setPopupOpen(false);
    setCurrentMediaIndex(0);
  };

  const fetchAllJobAttachments = async (jobId: string) => {
    try {
      setIsMediaLoading(true);
      setImages([]);
      setVideos([]);
      const updatedJobId = jobId;
      const allJobAttachmentInput = { jobIds: [updatedJobId] };
      const attachmentResponse = await JobCreateApiService.getAllJobAttachmentsForBidSummary(allJobAttachmentInput);
      if (isEmpty(attachmentResponse.errors)) {
        const updatedDetails = await Promise.all(
          attachmentResponse.data.map(async (details: IDetailsProps) => {
            const attachmentUrl = await fetchDataFromS3Bucket(navigate, details?.url ?? '');
            const modifiedUrl = attachmentUrl.split('?x-amz-content');
            return {
              ...details,
              url: modifiedUrl[0],
              id: details.attachmentId,
              attachmentId: details.attachmentId,
              responseUrl: details.url,
            };
          })
        );
        const imagesList: any[] = [];
        const videosList: any[] = [];
        updatedDetails.forEach((attachment: any) => {
          if (attachment.mdAttachmentType.includes('image')) {
            imagesList.push(attachment);
          } else {
            videosList.push(attachment);
          }
        });
        setImages([...images, ...imagesList]);
        setVideos([...videos, ...videosList]);
        setIsMediaLoading(false);
      } else {
        setIsMediaLoading(false);
      }
    } catch (e) {
      setIsMediaLoading(false);
      console.error('error', e);
    }
  };

  return (
    <BidApprovalContainer>
      {rendersApprovalContent()}
      <BidApprovalPopup
        open={isDialogOpen}
        onClose={handleDialogState}
        headerImg={customHeaderImg}
        message={customMessage}
      />
    </BidApprovalContainer>
  );
};

export default BidApprovalEmail;
