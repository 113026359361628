/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import GoogleMapReact, { MapOptions } from 'google-map-react';
import { fromLatLng, setKey } from 'react-geocode';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';

import FacilityApiService from '../../Services/FacilityApiService';
import { AddressData, FacilityData, FacilityInput } from '../../../../API';
import { getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';

import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import CurrentLocationCard from './CurrentLocationCard';
import {
  ControlPosition,
  ZoomControlStyle,
  FacilityDetails,
  AddressComponent,
  FacilityApiResponse,
  LatLng,
} from '../../Models/AddFacility.Model';
import { MapLoader } from '../../../../Shared/Components/Common/MapLoader/MapLoader';
import GoogleAutoComplete from './GoogleAutoComplete';
import { AddressLocationState } from '../../../../Shared/Models/Location.Model';

import theme from '../../../../Shared/Themes/theme';

interface CurrentAddress {
  googlePlaceId: string | null;
  formattedAddress: string | null;
  latitude: number | null;
  longitude: number | null;
  addressLine1: string | null;
  addressLine2: string | null;
  country: string | null;
  state: string | null;
  stateCode: string | null;
  city: string | null;
  postalCode: string | null;
}

const AddFacilityPage = (): JSX.Element => {
  const location = useLocation();
  const state = location.state as AddressLocationState;

  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<CurrentAddress | null>(null);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [addressExists, setAddressExists] = useState(state?.addressExists || false);

  const { handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: {
      facilityName: '',
      suitNumber: '',
      facilityType: '',
    },
  });

  const { t } = useTranslation(['addFacility']);

  const { customerId = '' } = getStoredCustomerDetails() as { customerId: string };
  const addressId = state?.address?.addressId ?? '';
  const facilityAddress = state?.address?.addressLine2 || state?.address?.addressLine1;

  const onSubmit = async (facilityDetails: FacilityDetails): Promise<void> => {
    try {
      if (location.state && currentAddress) {
        const facilityPayload = {
          facilityId: state.facilityId,
          customerId: state.customerId,
          buildingName: facilityDetails.facilityName,
          noOfFloors: 0,
          sqFootage: 0,
          contactId: '',
          mdFacilityType: facilityDetails.facilityType,
        } as FacilityData;
        const addressPayload = {
          addressId: addressId,
          customerId: customerId,
          noOfBuildings: 1,
          addressName: '',
          addressLine1: currentAddress.addressLine1,
          addressLine2: currentAddress.addressLine2,
          city: currentAddress.city,
          stateCode: currentAddress.stateCode,
          county: currentAddress.country,
          postalCode: currentAddress.postalCode,
          mdCountryCode: 'US',
          landmark: '',
          googlePlaceId: currentAddress.googlePlaceId,
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
        } as AddressData;

        const updateFacilityRes: FacilityApiResponse = await FacilityApiService.updateFacility(facilityPayload);
        const updateAddressRes: FacilityApiResponse = await FacilityApiService.updateAddress(addressPayload);

        const facilityErrors = updateFacilityRes?.errors ?? [];
        const addressErrors = updateAddressRes?.errors ?? [];

        if (facilityErrors.length === 0 && addressErrors.length === 0) {
          setIsSubmissionSuccess(true);
        } else {
          console.log('Error: failed to update facility or address', facilityErrors, addressErrors);
        }
      } else if (currentAddress) {
        const payload: FacilityInput = {
          customerId: customerId,
          buildingName: facilityDetails.facilityName,
          noOfFloors: 0,
          sqFootage: 0,
          contactId: '',
          mdFacilityType: facilityDetails.facilityType,
          address: {
            customerId: customerId,
            noOfBuildings: 1,
            addressName: '',
            addressLine1: currentAddress.addressLine1,
            addressLine2: currentAddress.addressLine2,
            city: currentAddress.city,
            stateCode: currentAddress.stateCode,
            county: currentAddress.country,
            postalCode: currentAddress.postalCode,
            mdCountryCode: 'US',
            landmark: '',
            googlePlaceId: currentAddress.googlePlaceId,
            latitude: currentLocation.lat,
            longitude: currentLocation.lng,
          },
        } as FacilityInput;

        const facilityResponse: FacilityApiResponse = await FacilityApiService.addFacility(payload);

        if (facilityResponse.errors.length === 0) {
          setIsSubmissionSuccess(true);
        } else {
          console.log('Error: failed to add facility', facilityResponse.errors);
        }
      }
    } catch (e) {
      console.log('Error: failed to add/edit facility', e);
    }
  };

  const createMapOptions = (maps: {
    ControlPosition: ControlPosition;
    ZoomControlStyle: ZoomControlStyle;
  }): MapOptions => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
    };
  };

  const getNameForAddressComponent = (premise: AddressComponent[], type: string): string | null => {
    const foundResult = premise?.length
      ? premise[0].address_components.filter((item) => item.types.includes(type))
      : [];
    return foundResult?.length ? foundResult[0].long_name : null;
  };

  const getShortNameForAddressComponent = (premise: AddressComponent[], type: string): string | null => {
    const foundResult = premise?.length
      ? premise[0].address_components.filter((item) => item.types.includes(type))
      : [];
    return foundResult?.length ? foundResult[0].short_name : null;
  };

  const getAddressComponents = (addressComponentsResults: AddressComponent[]): CurrentAddress => {
    const address: CurrentAddress = {
      formattedAddress: null,
      googlePlaceId: null,
      country: null,
      state: null,
      stateCode: null,
      city: null,
      postalCode: null,
      latitude: null,
      longitude: null,
      addressLine1: '',
      addressLine2: '',
    };
    const premise = addressComponentsResults.filter((addressItem) => addressItem.types.includes('premise'));

    address.formattedAddress = premise.length ? premise[0].formatted_address : null;
    address.googlePlaceId = premise.length ? premise[0].place_id : null;
    address.latitude = premise.length ? premise[0].geometry.location.lat : null;
    address.longitude = premise.length ? premise[0].geometry.location.lng : null;
    address.country = getNameForAddressComponent(premise, 'country') ?? null;
    address.state = getNameForAddressComponent(premise, 'administrative_area_level_1') ?? null;
    address.stateCode = getShortNameForAddressComponent(premise, 'administrative_area_level_1');
    address.city = getNameForAddressComponent(premise, 'locality') ?? null;
    address.postalCode = getNameForAddressComponent(premise, 'postal_code') ?? null;

    const address1 = getNameForAddressComponent(premise, 'street_number') ?? '';
    const address2 = getNameForAddressComponent(premise, 'route') ?? '';
    const address3 = getNameForAddressComponent(premise, 'sublocality_level_2') ?? '';
    const address4 = getNameForAddressComponent(premise, 'sublocality_level_1') ?? '';
    address.addressLine1 = address1 + ' ' + address2;
    address.addressLine2 = address3 + ' ' + address4;

    return address;
  };

  const getSearchAddressComponents = (searchAddressComponentsResults: AddressComponent[]): CurrentAddress => {
    const address: CurrentAddress = {
      formattedAddress: null,
      googlePlaceId: null,
      country: null,
      state: null,
      stateCode: null,
      city: null,
      postalCode: null,
      latitude: null,
      longitude: null,
      addressLine1: '',
      addressLine2: '',
    };

    const premise = searchAddressComponentsResults.filter(
      (addressItem) =>
        addressItem.types.includes('premise') ||
        addressItem.types.includes('establishment') ||
        addressItem.types.includes('street_address')
    );

    address.formattedAddress = premise.length ? premise[0].formatted_address : null;
    address.googlePlaceId = premise.length ? premise[0].place_id : null;
    address.country = getNameForAddressComponent(premise, 'country') ?? null;
    address.state = getNameForAddressComponent(premise, 'administrative_area_level_1') ?? null;
    address.stateCode = getShortNameForAddressComponent(premise, 'administrative_area_level_1');
    address.city = getNameForAddressComponent(premise, 'locality') ?? null;
    address.postalCode = getNameForAddressComponent(premise, 'postal_code') ?? null;
    address.latitude = premise.length ? premise[0].geometry.location.lat : null;
    address.longitude = premise.length ? premise[0].geometry.location.lng : null;

    const address1 = getNameForAddressComponent(premise, 'street_number') ?? '';
    const address2 = getNameForAddressComponent(premise, 'route') ?? '';
    const address3 = getNameForAddressComponent(premise, 'sublocality_level_3') ?? '';
    const address4 = getNameForAddressComponent(premise, 'sublocality_level_2') ?? '';
    address.addressLine1 = address1 + '' + address2;
    address.addressLine2 = address3 + '' + address4;

    return address;
  };

  useEffect(() => {
    setLoading(true);
    setKey('AIzaSyC2t6qfdqtIXTZzPGJkFbFE3YQKTxLo614');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoading(false);
        },
        (error) => {
          console.error('Error getting the current location:', error);
          setLoading(false);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fromLatLng(currentLocation.lat, currentLocation.lng, process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
      .then(({ results }: { results: AddressComponent[] }) => {
        const { lat, lng } = results[0].geometry.location;
        const addressComponents = getAddressComponents(results);
        setCurrentAddress(addressComponents);
        return { lat, lng } as LatLng;
      })
      .catch((error: Error) => {
        console.error('Error fetching address from lat lng:', error);
      });
  }, [currentLocation]);

  return (
    <>
      <PageTitle title={t('addFacility:facilityAddress')} />
      {loading ? (
        <MapLoader />
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string, libraries: ['places'] }}
          defaultCenter={{
            lat: currentLocation.lat,
            lng: currentLocation.lng,
          }}
          defaultZoom={4}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals></GoogleMapReact>
      )}

      <Box sx={{ position: 'absolute', top: '20%', right: '15%' }}>
        <CurrentLocationCard
          control={control}
          setValue={setValue}
          onSubmit={handleSubmit(onSubmit)}
          onClose={() => setIsSubmissionSuccess(false)}
          isSuccess={isSubmissionSuccess}
          currentAddress={addressExists ? facilityAddress : (currentAddress && currentAddress.formattedAddress) || ''}
          loading={loading}
          getValues={getValues}
          SearchField={() => setShowSearchInput(!showSearchInput)}
        />
      </Box>

      {showSearchInput && (
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            left: '5%',
            borderRadius: '1rem',
            background: theme.palette.common.white,
          }}>
          <GoogleAutoComplete
            onLocationChange={(location: LatLng) => {
              if (location && typeof location.lat === 'number' && typeof location.lng === 'number') {
                return fromLatLng(location.lat, location.lng, process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
                  .then(({ results }: { results: AddressComponent[] }) => {
                    const addressComponents = getSearchAddressComponents(results);
                    if (addressExists) {
                      setAddressExists(false);
                    }
                    setCurrentAddress(addressComponents);
                    return results;
                  })
                  .catch((error: Error) => {
                    console.error('Error converting lat lng to address:', error);
                    throw error;
                  });
              }
            }}
            initialCenter={{
              lat: currentLocation.lat,
              lng: currentLocation.lng,
            }}
          />
        </Box>
      )}
    </>
  );
};

export default AddFacilityPage;
