/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useCallback, useEffect, useState } from 'react';
import { CircularProgress, FormControl, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Arrow from '../../../../assets/images/ic_dropdown-up.svg';
import { DropdownIcon, Options, SelectInput } from './Dropdown.styles';
import theme from '../../../Themes/theme';
import { PricingOptions } from '../../../../Shared/Constants/App';

interface Data {
  label: string;
  value: string;
  id: number;
}

interface IDropdownProps {
  dropdownData: Data[];
  defaultValue: string;
  onChange: (value: string) => void;
  isDisable?: boolean;
  isLoading?: boolean;
  width?: string;
  labelName?: string;
  dropDownWidth?: string;
}

/**
 * @returns Dropdown
 */
const Dropdown = ({
  dropdownData,
  defaultValue,
  onChange,
  isDisable,
  isLoading,
  width,
  labelName,
  dropDownWidth,
}: IDropdownProps): JSX.Element => {
  const { t } = useTranslation(['altTexts']);
  const [value, setValue] = useState<PricingOptions | ''>('');
  const [isDataLoading, setIsDataLoading] = useState<boolean | undefined>(false);

  useEffect(() => {
    setValue(defaultValue as PricingOptions);
  }, [defaultValue]);

  const handleChange = useCallback((event: SelectChangeEvent<PricingOptions>) => {
    setValue(event.target.value as PricingOptions);
    onChange(event.target.value as PricingOptions);
  }, []);

  useEffect(() => {
    setIsDataLoading(isLoading);
  }, [isLoading]);

  return (
    <FormControl
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: dropDownWidth ? dropDownWidth : 'auto',
      }}>
      {labelName && (
        <label
          id="select-label"
          style={{
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h3?.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            lineHeight: '1.375rem',
            letterSpacing: '0.009375rem',
            color: theme.palette.text.primary,
          }}>
          {labelName}:
        </label>
      )}
      <Select
        sx={{ width: dropDownWidth ? dropDownWidth : width ? width : 'auto' }}
        disabled={isDisable}
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue as PricingOptions}
        input={<SelectInput />}
        displayEmpty
        IconComponent={(props) => {
          return (
            <DropdownIcon {...props}>
              {isDataLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
              ) : (
                <img src={Arrow} alt={t('altTexts:dropdown')} />
              )}
            </DropdownIcon>
          );
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              '&.MuiPaper-root': {
                borderRadius: '1rem',
              },
            },
          },
        }}
        inputProps={{ 'aria-label': 'Without label' }}>
        {!isDataLoading && dropdownData && dropdownData.length ? null : <Options value="">No records</Options>}
        {dropdownData &&
          dropdownData.map((dataItem) => {
            return (
              <Options key={dataItem.id} value={dataItem.value}>
                {dataItem.label}
              </Options>
            );
          })}
      </Select>
    </FormControl>
  );
};

Dropdown.defaultProps = {
  isDisable: false,
  isLoading: false,
};

export default Dropdown;
