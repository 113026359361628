/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { ReactNode } from 'react';

import {
  FacilityAccessLabelContainer,
  LabelWrapper,
  ValueWrapper,
} from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';

const FacilityAccessLabel = ({ icon, label }: { icon: ReactNode; label: ReactNode }): JSX.Element => {
  return (
    <FacilityAccessLabelContainer>
      <ValueWrapper>{icon}</ValueWrapper>
      <LabelWrapper>{label}</LabelWrapper>
    </FacilityAccessLabelContainer>
  );
};

export default FacilityAccessLabel;
