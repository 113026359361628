/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWrapper, CloseIconStyle, ButtonsContainer } from '../../Styles/Modal.style';
import { Divider } from '@mui/material';

import Button, { ButtonType } from '../../../../../Shared/Components/Common/Buttons/Button';
import { Icons } from '../../../../../Shared/Constants/Icons';
import { buttonStyles } from '../../../Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';

import { CSSObject } from '@emotion/react';

export interface CustomStyles {
  [key: string]: CSSObject;
}

interface ModalProps {
  open: boolean;
  onClose: () => void;
  primaryAction?: () => void;
  secondaryAction?: () => void;
  primaryButtonLoading?: boolean;
  primaryButtonDisabling?: boolean;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  renderHeader: React.ReactNode;
  renderBody: React.ReactNode;
  styles?: CustomStyles;
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  primaryButtonLoading,
  primaryButtonDisabling,
  primaryButtonLabel,
  secondaryButtonLabel,
  primaryAction,
  secondaryAction,
  renderHeader,
  renderBody,
  styles,
}) => {
  const { t } = useTranslation(['altTexts']);
  const { divider } = buttonStyles;
  return (
    <ModalWrapper styles={styles} open={open} onClose={onClose}>
      <CloseIconStyle onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseIconStyle>
      {renderHeader}
      <Divider sx={{ ...divider, margin: '1em 0' }} />
      {renderBody}
      {secondaryAction && primaryAction && (
        <ButtonsContainer>
          {secondaryAction && (
            <Button type={ButtonType.Secondary} label={secondaryButtonLabel} onClick={secondaryAction} />
          )}
          {primaryAction && (
            <Button
              type={ButtonType.Primary}
              disabled={primaryButtonLoading || primaryButtonDisabling}
              isLoading={primaryButtonLoading}
              label={primaryButtonLabel}
              onClick={primaryAction}
            />
          )}
        </ButtonsContainer>
      )}
    </ModalWrapper>
  );
};

export default Modal;
