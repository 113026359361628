/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { DialogContent, DialogActions, Box } from '@mui/material';

import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';

import { Icons } from '../../../../Shared/Constants/Icons';

import { ApprovalText, buttonStyles, LoginDialogWrapper, TaskDetails } from '../TaskDetails/TaskDetailsPage.Style';

interface IApproveDialogProps {
  handleCloseApproveWindow: () => void;
  isApproveWindowOpen: boolean;
  rowDetails?: any;
  isShowApproveAll?: boolean;
  handleApprove: () => void;
  ChildComponent?: React.ReactNode | null;
  title?: string;
  showCancelButton?: boolean;
  loading?: boolean;
  t?: any;
}

const ApproveDialog = ({
  isApproveWindowOpen,
  handleCloseApproveWindow,
  handleApprove,
  isShowApproveAll,
  rowDetails,
  ChildComponent,
  loading,
  t,
}: IApproveDialogProps): JSX.Element => {
  const { closeIconStyle } = buttonStyles;

  return (
    <LoginDialogWrapper open={isApproveWindowOpen} onClose={handleCloseApproveWindow}>
      <Box sx={closeIconStyle} onClick={handleCloseApproveWindow}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </Box>
      <Box
        sx={{
          padding: '0.81rem',
        }}>
        <DialogContent>
          {ChildComponent ? (
            ChildComponent
          ) : (
            <ApprovalText id="alert-dialog-description">
              {isShowApproveAll ? (
                `${t('dashboard:approveRows')}`
              ) : (
                <>
                  `${t('dashboard:approve')}`{' '}
                  {rowDetails.taskName ? <TaskDetails>{rowDetails.taskName}</TaskDetails> : null}
                </>
              )}
            </ApprovalText>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1.5rem',
              width: '100%',
              justifyContent: 'flex-end',
            }}>
            <Button
              type={ButtonType.Secondary}
              label={t('delete:yes')}
              onClick={handleApprove}
              isLoading={loading}></Button>
            <Button type={ButtonType.Primary} label={t('delete:no')} onClick={handleCloseApproveWindow}></Button>
          </Box>
        </DialogActions>
      </Box>
    </LoginDialogWrapper>
  );
};

ApproveDialog.defaultProps = {
  rowDetails: '',
  isShowApproveAll: false,
};

export default withTranslation(['dashboard', 'delete', 'altTexts'])(ApproveDialog);
