/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import { SetStateAction, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { TextField, InputAdornment, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { Auth } from 'aws-amplify';

import { ResetPasswordProps } from './Models/Login.Model';
import SnackbarMessage from './SnackbarMessage';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';

import { Icons } from '../../../../Shared/Constants/Icons';

import {
  EmailContainer,
  buttonStyles,
  PasswordConditionCheck,
  ConditionCheckHeading,
  ConditionContainer,
  OptionsContainer,
  ConditionCheckTextContainer,
  ConditionCheckText,
  ForgotPasswordText,
} from './LoginPage.Style';
import { InputWrapper, ErrorMsg } from './styles';

function ResetPassword(props: ResetPasswordProps): JSX.Element {
  const { t } = useTranslation(['signUp', 'forgotPassword']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setConfirmNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOTPVerificationInProgress, setIsOTPVerificationInProgress] = useState<boolean>(false);
  const [password, setPassword] = useState('');

  const errorMessage = t('forgotPassword:errorOccurred');
  const { textField } = buttonStyles;

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      newPassword: '',
      confirmPassword: '',
      oneTimeVerificationCode: 0,
    },
  });

  const handleNewPasswordVisibility = (): void => {
    setShowNewPassword(!showNewPassword);
  };
  const handleConfirmPasswordVisibility = (): void => {
    setConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleOtpChange = (otpValue: SetStateAction<string>): void => {
    setOtp(otpValue);
  };

  const [passwordStrength, setPasswordStrength] = useState({
    minLength: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasLowerCase: false,
    hasUpperCase: false,
  });

  const checkPasswordStrength = (value: string): void => {
    const passwordStrength = {
      minLength: value.length >= 8,
      hasNumber: /\d/.test(value),
      hasSpecialChar: /[!@#$%^&*]/.test(value),
      hasLowerCase: /[a-z]/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
    };
    setPasswordStrength(passwordStrength);
  };

  const passwordConditions = [
    {
      label: `${t('signUp:minimumCharacters')}`,
      strength: passwordStrength.minLength,
    },
    {
      label: `${t('signUp:numbers')}`,
      strength: passwordStrength.hasNumber,
    },
    {
      label: `${t('signUp:specialCharacters')}`,
      strength: passwordStrength.hasSpecialChar,
    },
    {
      label: `${t('signUp:lowerCaseLetters')}`,
      strength: passwordStrength.hasLowerCase,
    },
    {
      label: `${t('signUp:upperCaseLetters')}`,
      strength: passwordStrength.hasUpperCase,
    },
  ];

  const resendOtp = (): void => {
    try {
      setLoading(true);
      setIsOTPVerificationInProgress(true);
      if (props.email) {
        Auth.forgotPassword(props.email)
          .then(() => {
            setSnackbarMessage(t('forgotPassword:otpSentSuccessfully'));
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            return null;
          })
          .catch(() => {
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          });
      } else {
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (e) {
      console.error('Error in resendOtp:', e);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setIsOTPVerificationInProgress(false);
    }
  };

  const onSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      setIsOTPVerificationInProgress(true);
      await Auth.forgotPasswordSubmit(props.email, otp, password);
      setSnackbarMessage(t('forgotPassword:passwordResetSuccessful'));
      setSnackbarOpen(true);
      setSnackbarSeverity('success');
      props.redirect(true, 'email');
      props.redirect(false, 'resetPassword');
    } catch (error) {
      setSnackbarMessage(t('forgotPassword:EnterCorrectOtp'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false);
    } finally {
      setIsOTPVerificationInProgress(false);
    }
  };

  return (
    <form>
      <>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1.5rem' }}>
          <EmailContainer>
            <Controller
              control={control}
              name="newPassword"
              render={({ field: { onChange }, fieldState: { error } }) => (
                <InputWrapper sx={{ width: '100%', margin: 'auto' }}>
                  <TextField
                    sx={{ ...textField }}
                    id="outlined-required"
                    label={t('forgotPassword:newPassword')}
                    value={password}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setPassword(e.target.value);
                      checkPasswordStrength(e.target.value);
                    }}
                    type={showNewPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showNewPassword ? (
                            <img
                              src={Icons.VisibilityOnIcon}
                              alt="Hide Password"
                              height={100}
                              width={20}
                              onClick={handleNewPasswordVisibility}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : (
                            <img
                              src={Icons.VisibilityOffIcon}
                              alt="Show Password"
                              height={100}
                              width={20}
                              onClick={handleNewPasswordVisibility}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMsg>{error && error.message}</ErrorMsg>
                </InputWrapper>
              )}
              rules={{
                required: t('forgotPassword:passwordRequired'),
              }}
            />
          </EmailContainer>
          <EmailContainer>
            <Controller
              control={control}
              name="confirmPassword"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <InputWrapper sx={{ width: '100%', margin: 'auto' }}>
                  <TextField
                    sx={{ ...textField }}
                    id="outlined-required"
                    label={t('forgotPassword:confirmPassword')}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setConfirmPassword(e.target.value);
                    }}
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showConfirmNewPassword ? (
                            <img
                              src={Icons.VisibilityOnIcon}
                              alt={t('forgotPassword:hidePassword')}
                              height={100}
                              width={20}
                              onClick={handleConfirmPasswordVisibility}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : (
                            <img
                              src={Icons.VisibilityOffIcon}
                              alt={t('forgotPassword:showPassword')}
                              height={100}
                              width={20}
                              onClick={handleConfirmPasswordVisibility}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMsg>
                    {error
                      ? error?.message
                      : password !== confirmPassword &&
                        confirmPassword.length >= 1 &&
                        t('forgotPassword:passwordNotMatching')}
                  </ErrorMsg>
                </InputWrapper>
              )}
              rules={{
                required: t('forgotPassword:passwordRequired'),
              }}
            />
          </EmailContainer>
        </Box>
        <PasswordConditionCheck>
          <Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <ConditionCheckHeading>{t('signUp:passwordContains')}</ConditionCheckHeading>
            </Box>
          </Box>
          <ConditionContainer>
            <OptionsContainer>
              {passwordConditions.map((condition, index) => (
                <ConditionCheckTextContainer key={index}>
                  {condition.strength ? (
                    <img src={Icons.ConditionCheckCorrectIcon} style={{ width: '1rem', height: '1rem' }} />
                  ) : (
                    <img src={Icons.ConditionCheckIcon} style={{ width: '1rem', height: '1rem' }} />
                  )}
                  <ConditionCheckText>{condition.label}</ConditionCheckText>
                </ConditionCheckTextContainer>
              ))}
            </OptionsContainer>
          </ConditionContainer>
        </PasswordConditionCheck>
        <EmailContainer
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}>
          <ForgotPasswordText>{t('forgotPassword:enterConfirmationCode')}</ForgotPasswordText>
          <Controller
            control={control}
            name="oneTimeVerificationCode"
            render={({ fieldState: { error } }) => (
              <InputWrapper
                sx={{
                  width: '100%',
                  margin: 'auto',
                  '&> div': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                }}>
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  inputType="number"
                  renderSeparator={<span>&nbsp;&nbsp;</span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      type="tel"
                      style={{
                        width: '3.125rem',
                        height: '3.125rem',
                        borderRadius: '0.25rem',
                        border: '0.0625rem solid gray',
                        textAlign: 'center',
                        outline: 'none',
                      }}
                    />
                  )}
                />
                <ErrorMsg>{error && error.message}</ErrorMsg>
              </InputWrapper>
            )}
            rules={{
              required: t('forgotPassword:otpRequired'),
            }}
          />
          <ConditionCheckHeading onClick={resendOtp} sx={{ cursor: 'pointer' }}>
            {t('forgotPassword:resendConfirmationCode')}
          </ConditionCheckHeading>
        </EmailContainer>
        <Button
          type={ButtonType.Primary}
          width="100%"
          disabled={
            password !== confirmPassword ||
            !passwordStrength.minLength ||
            !passwordStrength.hasNumber ||
            !passwordStrength.hasSpecialChar ||
            !passwordStrength.hasLowerCase ||
            !passwordStrength.hasUpperCase ||
            otp.length !== 6
          }
          label={isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : t('forgotPassword:resetPassword')}
          onClick={handleSubmit(onSubmit)}
          isLoading={isOTPVerificationInProgress}
        />
        <SnackbarMessage
          open={snackbarOpen}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
          successMessage={snackbarMessage}
        />
      </>
    </form>
  );
}

export default ResetPassword;
