/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useSelector } from 'react-redux';

import { RouteType } from '../../../../routes/config';
import LeftNavBarItem from './LeftNavBarItem';
import { RootState } from '../../../Store/store';

type Props = {
  item: RouteType;
  isSideBarVisible: boolean;
};

const LeftNavBarItemCollapse = ({ item, isSideBarVisible }: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(false);

  const { appState } = useSelector((state: RootState) => state.appState);

  const renderLeftNavBarItem = useCallback(
    (route: RouteType, key: number) => {
      if (route.child) {
        return <LeftNavBarItemCollapse item={route} key={key} isSideBarVisible={isSideBarVisible} />;
      }

      return <LeftNavBarItem item={route} key={key} isSideBarVisible={isSideBarVisible} />;
    },
    [isSideBarVisible]
  );

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true);
    }
  }, [appState, item]);

  return (
    <>
      {item.sidebarProps ? (
        <>
          <ListItemButton
            onClick={() => setOpen(!isOpen)}
            sx={{
              '&: hover': {
                backgroundColor: 'lightblue',
              },
              paddingY: '0.75rem',
              paddingX: '1.5rem',
            }}>
            {item.sidebarProps.icon && (
              <ListItemIcon
                sx={{
                  color: appState === item.state ? 'lightblue' : 'black',
                }}>
                {item.sidebarProps.icon}
              </ListItemIcon>
            )}
            <ListItemText disableTypography primary={<Typography>{item.sidebarProps.displayText}</Typography>} />
            {isOpen ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </ListItemButton>
          <Collapse in={isOpen} timeout="auto">
            <List>
              {item.child?.map((route, index) => (route.sidebarProps ? renderLeftNavBarItem(route, index) : undefined))}
            </List>
          </Collapse>
        </>
      ) : undefined}
    </>
  );
};

export default LeftNavBarItemCollapse;
