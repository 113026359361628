/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
export const formatAxisValue = (tickItem: number): string => {
  const formatter = Intl.NumberFormat('en', {
    notation: 'compact',
  });
  return formatter.format(tickItem);
};
