/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { buttonStyles, SearchForVendorsContainer } from './AddEditOneTimeJob.Style';
import {
  Button,
  Typography,
  Box,
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputProps,
  Autocomplete,
} from '@mui/material';
import { debounce } from 'lodash';

import { Icons } from '../../../../Shared/Constants/Icons';
// import ClearIcon from '../../../../assets/images/ic_clear.svg';

import JobCreateApiService from '../../Services/JobCreateService';
import { dataStatus } from '../../../../API';
import { IVendor } from '../../Models/AddEditOneTimeJob.Model';
import theme from '../../../../Shared/Themes/theme';

interface ISearchVendorProps {
  selectedVendors: IVendor[];
  isMulti: boolean;
  onVendorSelect: (v: IVendor[]) => void;
  fieldName: string;
  inPopup?: boolean;
}

interface OptionStyle {
  opacity: string;
  pointerEvents: 'none' | 'all';
}

const SearchVendor = (props: ISearchVendorProps): JSX.Element => {
  const { t } = useTranslation(['oneTimeJob']);
  const { selectedVendors, onVendorSelect, isMulti, fieldName } = props;
  const [vendorsList, setVendorsList] = useState<IVendor[]>([]);
  const [, setSearchedTerm] = useState('');
  const [chosenVendors, setChosenVendors] = useState<IVendor[]>(selectedVendors);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteIcons, setShowDeleteIcons] = useState(Array(chosenVendors.length).fill(true));
  const [isAddVendorDisabled, setIsAddVendorDisabled] = useState(false);
  const [labels, setLabels] = useState<string[]>(['Select Vendor']);
  const [isOptionSelected, setIsOptionSelected] = useState<boolean[]>([false]);

  const { textField, addButton, addButtonText } = buttonStyles;

  const fetchVendors = useCallback(
    async (searchTerm: string) => {
      setIsLoading(true);
      if (searchTerm.trim()) {
        try {
          const searchedVendorsResponse = await JobCreateApiService.getAllVendorsOnARK(searchTerm, dataStatus.A, {});
          if (searchedVendorsResponse?.data.length) {
            const modifiedResponse = searchedVendorsResponse?.data.map(
              (item: { vendorId: string; vendorCompanyName: any; logoUrl: any; email: string }) => {
                if (
                  chosenVendors.some((alreadyAddedVendor: IVendor) => alreadyAddedVendor.vendorId === item.vendorId)
                ) {
                  return { vendorName: item.vendorCompanyName, vendorId: item.vendorId, email: item.email };
                }
                return { vendorName: item.vendorCompanyName, vendorId: item.vendorId, email: item.email };
              }
            );
            setVendorsList(modifiedResponse);
          }
        } catch (e) {
          console.error('Error', e);
          setVendorsList([]);
        }
      } else {
        setVendorsList([]);
      }
      setIsLoading(false);
    },
    [chosenVendors]
  );

  const addVendor = (): void => {
    setChosenVendors([...chosenVendors, { vendorId: '', vendorName: '', index: chosenVendors.length, email: '' }]);
    setShowDeleteIcons([...showDeleteIcons, true]);
    setLabels([...labels, 'Select Vendor']);
    setIsOptionSelected([...isOptionSelected, false]);
  };

  const handleDeleteVendor = (index: number): void => {
    const filteredVendorsList = chosenVendors.filter((vendorItem: IVendor) => vendorItem.index !== index);
    setChosenVendors(filteredVendorsList);
    const newLabels = [...labels];
    newLabels.splice(index, 1);
    setLabels(newLabels);
    const newFlags = [...isOptionSelected];
    newFlags.splice(index, 1);
    setIsOptionSelected(newFlags);
  };

  const handleSearchIconClick = (): void => {
    console.log('search icon click');
  };

  const handleClearInput = (): void => {
    //setVendorsList([]);
  };

  const getOptionStyle = (option: IVendor): OptionStyle => {
    const isVendorSelected = chosenVendors.some((vendor) => vendor.vendorName === option.vendorName);
    return {
      opacity: isVendorSelected ? '0.7' : '1',
      pointerEvents: isVendorSelected ? 'none' : 'all',
    };
  };

  const handleSearchVendorOnChange = (event: any, newValue: any, index: number): void => {
    const newShowDeleteIcons = [...showDeleteIcons];
    newShowDeleteIcons[index] = !!newValue;
    setShowDeleteIcons(newShowDeleteIcons);

    const modifiedVendors = chosenVendors.map((vendorItem: IVendor) => {
      if (vendorItem.index === index) {
        return {
          index,
          vendorId: newValue?.vendorId as string,
          vendorName: newValue?.vendorName as string,
          email: newValue?.email as string,
        };
      }
      return vendorItem;
    });
    setChosenVendors(modifiedVendors);
    onVendorSelect(modifiedVendors);

    const newFlags = [...isOptionSelected];
    newFlags[index] = true;
    setIsOptionSelected(newFlags);
  };

  useEffect(() => {
    setChosenVendors(selectedVendors);
  }, [selectedVendors]);

  const debounceFetchVendors = useCallback(debounce(fetchVendors, 1500), [fetchVendors]);

  useEffect(() => {
    const emptyVendors = chosenVendors.filter((vendorItem) => vendorItem.vendorId === '');
    if (emptyVendors.length) {
      setIsAddVendorDisabled(true);
    } else {
      setIsAddVendorDisabled(false);
    }
  }, [chosenVendors]);

  useEffect(() => {
    const updatedLabels = [...labels];
    updatedLabels[labels.length - 1] = isOptionSelected[labels.length - 1]
      ? `${t('oneTimeJob:selectedVendor')}`
      : `${t('oneTimeJob:selectVendor')}`;
    setLabels(updatedLabels);
  }, [isOptionSelected]);

  return (
    <>
      <SearchForVendorsContainer
        sx={{
          [theme.breakpoints.down('laptop')]: {
            display: 'grid',
            gridTemplateColumns: props?.inPopup ? 'auto' : 'repeat(2,1fr)',
          },
        }}>
        {chosenVendors.map((vendorItem, index) => (
          <Box key={index} sx={{ ...textField, marginBottom: '2rem', position: 'relative' }} onFocus={handleClearInput}>
            <Autocomplete
              options={vendorsList}
              getOptionLabel={(option: IVendor) => option.vendorName}
              onChange={(event, newValue) => handleSearchVendorOnChange(event, newValue, index)}
              renderInput={(params) => (
                <TextField
                  name={fieldName}
                  sx={{ ...textField }}
                  {...params}
                  label={
                    <span>
                      {labels[index]} <span className="mandatory-field">*</span>
                    </span>
                  }
                  onChange={async (event) => {
                    setSearchedTerm(event.target.value);
                    const newFlags = [...isOptionSelected];
                    newFlags[index] = false;
                    setIsOptionSelected(newFlags);
                    debounceFetchVendors(event.target.value);
                  }}
                  InputProps={{
                    ...(params.InputProps as InputProps),
                    startAdornment: params.inputProps?.value ? (
                      <InputAdornment position="start">
                        <img
                          src={Icons.SearchIcon}
                          alt={t('altTexts:search')}
                          style={{
                            cursor: 'pointer',
                          }}
                        />
                      </InputAdornment>
                    ) : null,
                    endAdornment: (
                      <InputAdornment position="end">
                        {isLoading ? (
                          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                        ) : index > 0 && showDeleteIcons[index] ? (
                          <IconButton
                            onClick={() => {
                              handleDeleteVendor(index);
                            }}>
                            <img
                              src={Icons.DeleteIcon}
                              alt={t('altTexts:delete')}
                              style={{
                                cursor: 'pointer',
                              }}
                            />
                          </IconButton>
                        ) : params.inputProps?.value ? (
                          <></>
                        ) : (
                          <img
                            src={Icons.SearchIcon}
                            alt={t('altTexts:search')}
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={handleSearchIconClick}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option: IVendor, state) => (
                <div>
                  <li
                    {...props}
                    className={`option ${state.selected ? 'selectedOption' : ''}`}
                    style={{ ...getOptionStyle(option) }}>
                    <div>
                      {option.vendorName}
                      <img src={Icons.PlusIcon} className="plusIcon" />
                    </div>
                  </li>
                  {option !== vendorsList[vendorsList.length - 1] && <hr className="optionDivider" />}
                </div>
              )}
            />
          </Box>
        ))}
        {isMulti && (
          <Button
            variant="outlined"
            sx={{ ...addButton, opacity: isAddVendorDisabled ? 0.5 : 1, height: '3.5rem' }}
            disabled={isAddVendorDisabled}
            onClick={addVendor}>
            <img src={Icons.AddIcon} alt={t('altTexts:addIcon')} />
            <Typography sx={{ ...addButtonText, textTransform: 'none' }}>{t('oneTimeJob:addAnotherVendor')}</Typography>
          </Button>
        )}
      </SearchForVendorsContainer>
    </>
  );
};

export default SearchVendor;
