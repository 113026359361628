/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useTranslation } from 'react-i18next';
import { IVendorJobAwardData } from '../../Models/Marketplace.model';
import { JobSize, fetchDataFromS3Bucket, formatDate } from '../../../../Shared/Utilities/utils';
import { ImageField, LogoContainer, NameField } from '../JobAward/Popup.Styles';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { JobType, UpdateWorkOrderInput, WorkOrderStatus } from '../../../../API';
import { Icons } from '../../../../Shared/Constants/Icons';
import { buttonStyles } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';
import { useCallback, useEffect, useState } from 'react';
import { AwardJobCard, Label } from './Marketplace.Style';
import { useNavigate } from 'react-router-dom';

interface IVendorJobAward {
  data: IVendorJobAwardData;
  isPrimaryButtonLoading: boolean;
  viewDetailsLoading: boolean;
  loadingCardId: string;
  handleViewDetails: (jobId: string, customerId: string, workOrderId: string) => void;
  handleStart: (
    payload: UpdateWorkOrderInput,
    jobName: string,
    facilityId: string,
    customerId: string,
    jobId: string
  ) => void;
}

const VendorJobAward = ({
  data,
  handleStart,
  isPrimaryButtonLoading,
  viewDetailsLoading,
  loadingCardId,
  handleViewDetails,
}: IVendorJobAward): JSX.Element => {
  const { t } = useTranslation(['vendor', 'assignJob', 'dashboard', 'tableHeader', 'altTexts']);

  const {
    vendorCompanyName,
    customerCompanyName,
    customerFacilityName,
    jobName,
    customerFacilityAddress,
    jobStartDate,
    jobEndDate,
    totalAmount,
    workVolume,
    workOrderId,
    workOrderStatus,
    facilityId,
    vendorId,
    customerId,
    jobType,
    jobId,
  } = data;
  const navigate = useNavigate();
  const [facilityLogoUrl, setFacilityLogoUrl] = useState<string>('');
  const [vendorLogoUrl, setVendorLogoUrl] = useState<string>('');

  const { divider, cancelButton, buttonText, createJobButton } = buttonStyles;

  const getJobTypeText = (jobType: JobType) => {
    if (jobType === JobType.OneTime) {
      return t('assignJob:oneTimeJob');
    }
    return t('assignJob:priority');
  };

  const returnWorkVolume = (workVolume: JobSize) => {
    if (workVolume === JobSize.Small) return t('assignJob:small');
    if (workVolume === JobSize.Medium) return t('assignJob:medium');
    return t('assignJob:large');
  };

  const checkIfImageValid = async (url: string) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = url;
      image.onload = () => {
        resolve(true);
      };
      image.onerror = () => {
        resolve(false);
      };
    });
  };

  const fetchFacilityIcon = useCallback(async () => {
    const customerLogoResponse = await fetchDataFromS3Bucket(
      navigate,
      `customer/${customerId}/company-logos/${customerId}`
    );
    const isFacilityValid = await checkIfImageValid(customerLogoResponse);
    if (isFacilityValid) {
      setFacilityLogoUrl(customerLogoResponse);
    } else {
      setFacilityLogoUrl('');
    }
    const vendorLogoResponse = await fetchDataFromS3Bucket(navigate, `customer/${vendorId}/company-logos/${vendorId}`);
    const isVendorValid = await checkIfImageValid(vendorLogoResponse);
    if (isVendorValid) {
      setVendorLogoUrl(vendorLogoResponse);
    } else {
      setVendorLogoUrl('');
    }
  }, []);

  const getAcronym = (name: string) => {
    if (name) {
      return name
        .split(' ')
        .slice(0, 2)
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
    }
    return name;
  };

  useEffect(() => {
    fetchFacilityIcon();
  }, []);

  return (
    <AwardJobCard>
      <div
        style={{
          backgroundColor: theme.palette.secondary.customColor7,
          padding: '1em', //'3em 7em',
          borderTopLeftRadius: '1em',
          borderTopRightRadius: '1em',
        }}>
        <Typography
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h1?.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            margin: '1em',
          }}>
          {t('dashboard:jobAwarded')}!
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}>
          <LogoContainer sx={{ backgroundColor: theme.palette.common.white, borderColor: 'green' }}>
            {facilityLogoUrl ? (
              <img
                src={facilityLogoUrl}
                style={{
                  height: '3.5rem',
                  width: '3.5rem',
                  background: theme.palette.secondary.customColor7,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: theme.typography.h5?.fontSize,
                  fontWeight: theme.typography.fontWeightBold,
                }}
                alt={t('altTexts:companyLogo')}
              />
            ) : (
              <ImageField>{getAcronym(customerCompanyName)}</ImageField>
            )}
          </LogoContainer>
          <Box
            sx={{
              '& img': {
                width: '3rem',
                height: '3rem',
                borderRadius: '50%',
              },
              zIndex: 3,
              position: 'absolute',
            }}>
            <img src={Icons.AwardedIcon} />
          </Box>

          <LogoContainer sx={{ backgroundColor: theme.palette.common.white, borderColor: 'green' }}>
            {vendorLogoUrl ? (
              <img
                src={vendorLogoUrl}
                style={{
                  height: '3.5rem',
                  width: '3.5rem',
                  background: theme.palette.secondary.customColor7,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: theme.typography.h5?.fontSize,
                  fontWeight: theme.typography.fontWeightBold,
                }}
                alt={t('altTexts:companyLogo')}
              />
            ) : (
              <ImageField>{getAcronym(vendorCompanyName)}</ImageField>
            )}
          </LogoContainer>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '2.625rem',
            gap: '1.5rem',
          }}>
          <NameField sx={{ width: '50%' }}>{customerCompanyName ?? customerFacilityName}</NameField>
          <NameField sx={{ width: '50%' }}>{vendorCompanyName}</NameField>
        </Box>
      </div>
      <div style={{ margin: '1em' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
            }}>
            {jobName}
          </Typography>
          <Typography
            sx={{
              fontSize: theme.typography.h5?.fontSize,
            }}>
            {customerFacilityAddress}
          </Typography>
        </div>

        <Divider sx={divider} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography
              sx={{
                fontSize: theme.typography.h5?.fontSize,
                color: theme.palette.text.disabled,
              }}>
              {(jobType as JobType) === JobType.Recurring ? t('assignJob:startDate') : t('assignJob:jobDate')}
            </Typography>
            <Label>{formatDate(jobStartDate, 'MMM D, YYYY')}</Label>
          </div>
          <div>
            <Typography
              sx={{
                fontSize: theme.typography.h5?.fontSize,
                color: theme.palette.text.disabled,
              }}>
              {(jobType as JobType) === JobType.Recurring ? t('assignJob:endDate') : t('assignJob:jobType')}
            </Typography>
            <Label>
              {(jobType as JobType) === JobType.Recurring
                ? formatDate(jobEndDate, 'MMM D, YYYY')
                : getJobTypeText(jobType as JobType)}
            </Label>
          </div>
          <div>
            <Typography
              sx={{
                fontSize: theme.typography.h5?.fontSize,
                color: theme.palette.text.disabled,
              }}>
              {t('assignJob:workVolume')}
            </Typography>
            <Label>{returnWorkVolume(workVolume as JobSize)}</Label>
          </div>
        </div>
        <Divider sx={divider} />

        <div
          style={{
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h1?.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
          }}>{`$ ${totalAmount.toFixed(2)}`}</div>
      </div>
      <div style={{ display: 'flex', padding: '16px', alignSelf: 'end' }}>
        <Button
          sx={{ ...cancelButton, cursor: 'pointer', maxWidth: '100%', width: '100%' }}
          onClick={() => handleViewDetails(jobId, customerId, workOrderId)}>
          {loadingCardId === workOrderId && viewDetailsLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>
              {t('assignJob:viewDetails')}
            </Typography>
          )}
        </Button>
        <Button
          disabled={isPrimaryButtonLoading && loadingCardId === workOrderId}
          sx={{
            ...createJobButton,
            opacity: isPrimaryButtonLoading && loadingCardId === workOrderId ? 0.5 : 1,
            maxWidth: '100%',
            width: '100%',
          }}
          onClick={() =>
            handleStart({ workOrderId, status: WorkOrderStatus.Accepted }, jobName, customerId, jobId, facilityId)
          }>
          {loadingCardId === workOrderId && isPrimaryButtonLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {workOrderStatus === WorkOrderStatus.Created ? t('assignJob:letsGetStarted') : t('assignJob:continue')}
            </Typography>
          )}
        </Button>
      </div>
    </AwardJobCard>
  );
};

export default VendorJobAward;
