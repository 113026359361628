/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { isEmpty } from 'lodash';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobRow, IJobs } from '../../../Jobs/Models/JobsView.Model';

import { facilityTypes, getJobsTab } from '../../Models/constants';
import {
  JobDetails,
  UserRoles,
  getStoredCustomerDetails,
  removeJobLocalData,
} from '../../../../Shared/Utilities/utils';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import CreateBidServices from '../../Services/CreateBidServices';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import { CurrencySymbol, JobStatuses, JobTypes } from '../../../../Shared/Constants/App';
import theme from '../../../../Shared/Themes/theme';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';

import VendorApiService from '../../Services/VendorService';
import { formatDateCell, getFacilityName, getWorkOrderStatus } from '../../Utilities/Jobs.Utils';
import WorkOrdersApiService from '../../../Jobs/Services/WorkOrdersService';
import { JobBidEstimation, JobService, ServiceCategoryV3, UserRolesAndScopes, WorkOrderStatus } from '../../../../API';
import { ActionsColumn, Linking } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import { Icons } from '../../../../Shared/Constants/Icons';
import JobCreateApiService from '../../../Jobs/Services/JobCreateService';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import WhiteTooltip from '../../../../Shared/Components/Common/CommonStyle/Tooltip.Style';

const OngoingJobsTab = (): JSX.Element => {
  const [tabSelected, setTabSelected] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jobs, setJobs] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const [sortBy, setSortBy] = useState('buildingName');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>();
  const [newSortOrder, setNewSortOrder] = useState('');

  const { t } = useTranslation(['oneTimeJob', 'dashboard', 'tableHeader', 'altText', 'vendor']);
  const location = useLocation();
  const itemsPerPage = 10;
  const jobsTab = getJobsTab();
  const pathVariables = location?.pathname.split('/');
  const tabIndex = jobsTab.findIndex((tab) => tab.route === pathVariables[pathVariables.length - 1]);

  const renderCell = (renderedCellValue: React.ReactNode): string | number => {
    // If renderedCellValue is a string or number, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    if (renderedCellValue && Array.isArray(renderedCellValue)) {
      return renderedCellValue[0]?.finalQuote || renderedCellValue[0]?.finalQuote === 0
        ? `${CurrencySymbol.Dollar}${Number(renderedCellValue[0]?.finalQuote).toFixed(2)}`
        : '-';
    }
    return '-';
  };

  const renderJobName = (row: any): JSX.Element => {
    const { jobId, status } = row.original as unknown as IJobRow;
    const route = status === 'Inprogress' ? '/job-detail' : '/onetimejobdetails';
    return (
      <ActionsColumn>
        <Linking
          to={{
            pathname: route,
            search: `?id=${jobId}`,
          }}
          state={{
            facilityItem: jobs[row.index]?.jobDetails,
            facility: {
              ...jobs[row.index]?.facility,
              address: jobs[row.index]?.address,
              facilityType: jobs[row.index]?.facilityType,
            },
            job: jobs[row.index],
          }}
          key={3}>
          <div style={{ color: theme.palette.primary.dark }}>{row.original.jobName}</div>
        </Linking>
      </ActionsColumn>
    );
  };

  const renderActionsCell = (row: any): JSX.Element => {
    const { facilityId, workOrderId, customerId, jobId, executionType } = row.original as unknown as IJobRow;
    return (
      <ActionsColumn>
        {row.original.status === WorkOrderStatus.Accepted || row.original.status === JobStatuses.Upcoming ? (
          <Linking
            to={{
              pathname: '/onetimejobdetails',
              search: `?id=${row.original.jobId}`,
            }}
            state={{
              facilityItem: jobs[row.index]?.jobDetails,
              facility: {
                ...jobs[row.index]?.facility,
                address: jobs[row.index]?.address,
                facilityType: jobs[row.index]?.facilityType,
              },
              job: jobs[row.index],
            }}
            key={2}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <WhiteTooltip title={t('homePage:viewDetails')}>
                <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        ) : (
          <Linking
            to="/job-detail/overview"
            state={{
              facilityItem: {
                facilityId,
                workOrderId,
                facilityName: jobs[row.index]?.address?.addressName,
                addressLine2: jobs[row.index]?.address?.addressLine2
                  ? jobs[row.index]?.address?.addressLine2
                  : jobs[row.index]?.address?.addressLine3,
              },
              jobData: jobs[row.index],
              customerId,
              jobId,
              executionType,
            }}
            key={3}>
            <IconButton
              size="large"
              sx={{
                '&:hover': {
                  background: theme.palette.primary.main,
                },
              }}>
              <WhiteTooltip title={t('homePage:viewDetails')}>
                <img src={Icons.EyeIcon} alt={t('altTexts:viewDetails')} />
              </WhiteTooltip>
            </IconButton>
          </Linking>
        )}
      </ActionsColumn>
    );
  };

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('tableHeader:jobName'),
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 180,
        Cell: ({ row }) => renderJobName(row),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'location',
        header: t('tableHeader:location'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 180,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'jobType',
        header: t('tableHeader:jobType'),
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 170,
        Cell: ({ renderedCellValue }) => {
          const jobType = renderedCellValue as keyof typeof JobTypes;
          const formattedJobType = JobTypes[jobType] || renderedCellValue;
          return renderCell(formattedJobType);
        },
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'status',
        header: t('tableHeader:jobStatus'),
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 170,
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        enableColumnActions: false,
        sortingFn: 'alphanumericCaseSensitive',
        enableHiding: false,
      },
      {
        accessorKey: 'createdOn',
        header: t('tableHeader:createdOn'),
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        enableColumnFilter: false,
        size: 100,
        filterFn: (row, _columnIds, filterValue) => {
          const formattedValue = moment(row.getValue('createdOn')).format('MM / DD / yyyy');
          return formattedValue.toLowerCase().includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => formatDateCell(cell),
        enableColumnActions: false,
        enableHiding: false,
      },
      {
        header: t('tableHeader:actions'),
        enableColumnActions: false,
        enablePinning: false,
        size: 90,
        Cell: ({ row }) => renderActionsCell(row),
      },
    ],
    [jobs]
  );

  const handleChange = (value: number): void => {
    setCurrentPage(value);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchData = useCallback(async (pageNumber?: number, sortBy?: any, sortOrder?: any) => {
    try {
      setIsLoading(true);

      const { customerId }: { customerId: string } = getStoredCustomerDetails() || '';

      if (sortOrder) {
        sortOrder = 'desc';
      } else {
        if (sortBy) sortOrder = 'asc';
      }
      const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
      const userDetails = await AuthenticationService.getUserByCognitoUserId(loggedInUser?.username);
      const userScopeResponse = await JobCreateApiService.getUserScopes(userDetails?.data?.userId);
      let jobCreatorRoleName = '';
      const userScopeData = userScopeResponse?.data ?? [];
      if (!isEmpty(userScopeData)) {
        userScopeData?.forEach((userScope: UserRolesAndScopes) => {
          if (!isEmpty(userScope?.roles || []) && userScope?.roles?.includes('AccountHolder' as any)) {
            jobCreatorRoleName = UserRoles.AccountHolder;
          } else if (
            !isEmpty(userScope?.roles || []) &&
            (userScope?.roles?.includes('AccountHolder' as any) || userScope?.roles?.includes('Admin' as any))
          ) {
            jobCreatorRoleName = UserRoles.Admin;
          }
        });
      }

      if (isEmpty(jobCreatorRoleName) && !isEmpty(userScopeData) && !isEmpty(userScopeData[0]?.roles)) {
        // If user role name neither AccountHolder nor Admin
        jobCreatorRoleName = userScopeData[0]?.roles[0]?.roleName || '';
      }
      const allOngoingInProgressJobsResponse = await WorkOrdersApiService.getAllWorkOrders({
        vendorId: customerId,
        status: `${WorkOrderStatus.Created},${WorkOrderStatus.Accepted},${WorkOrderStatus.InProgress}`,
        jobCreatedBy: userDetails?.data?.userId,
        jobCreatorRoleName,
        limit: 10,
        pageNumber: pageNumber,
        sortBy,
        sortOrder,
      });

      setTotalCount(allOngoingInProgressJobsResponse.metadata?.totalCount);

      const mModifiedData = await Promise.all(
        allOngoingInProgressJobsResponse?.data.map(async (ongoingJobItem: any) => {
          const facilityResponse = await FacilityApiService.facility(
            ongoingJobItem?.facilities?.[0]?.facilityId,
            ongoingJobItem?.customerId
          );
          const mBidEstimations = await CreateBidServices.allJobBidEstimation(
            ongoingJobItem?.jobId,
            ongoingJobItem?.bidId ?? ''
          );
          const serviceCategories = await getServiceCategories(ongoingJobItem.customerId);

          mBidEstimations.data = mBidEstimations.data ?? [];

          const jobStatus = getWorkOrderStatus(ongoingJobItem.status);
          const mModifiedJob: any = { ...ongoingJobItem };
          if (ongoingJobItem?.bidId) {
            const bidSummaryResponse = await VendorApiService.getBidSummary(ongoingJobItem?.bidId);

            if (bidSummaryResponse?.data && bidSummaryResponse?.errors.length === 0) {
              mModifiedJob.bidSummaryResponse = bidSummaryResponse.data;
            }
          }
          const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, ongoingJobItem?.jobId);

          mModifiedJob.executionType =
            jobSummaryResponse?.data?.jobVendors?.[0]?.mdVendorType === 'SubVendor'
              ? JobDetails.SubVendor
              : JobDetails.Self;
          mModifiedJob.id = ongoingJobItem.jobId;
          mModifiedJob.jobId = ongoingJobItem.jobId;
          mModifiedJob.location =
            facilityResponse?.data?.address?.addressLine2 || facilityResponse?.data?.address?.addressName;
          mModifiedJob.facilityType = getFacilityName(facilityTypes, ongoingJobItem.facility?.mdFacilityType);
          mModifiedJob.createdOn = ongoingJobItem.createdOn;
          mModifiedJob.percentageMatch = String(ongoingJobItem.matchPercentage) + '%';
          mModifiedJob.jobName = ongoingJobItem.name;
          mModifiedJob.buildingName = facilityResponse.data?.buildingName;
          mModifiedJob.address = facilityResponse.data?.address;
          mModifiedJob.startDate = ongoingJobItem.startDate;
          mModifiedJob.stopDate = ongoingJobItem.stopDate;
          mModifiedJob.lastActivity = null;
          mModifiedJob.jobStatus = jobStatus;
          mModifiedJob.status = jobStatus;
          mModifiedJob.facilityId = ongoingJobItem.facilities[0]?.facilityId;
          mModifiedJob.customerId = ongoingJobItem.customerId;
          mModifiedJob.revisionRequests = [];
          mModifiedJob.jobFrequencies = jobSummaryResponse?.data?.jobFrequencies;
          mModifiedJob.jobServices = mBidEstimations?.data?.length
            ? mBidEstimations.data.map((bidEstimationItem: JobBidEstimation) => {
                const selectedService: any = serviceCategories.find(
                  (serviceCategoryItem: ServiceCategoryV3) =>
                    serviceCategoryItem.serviceCategoryId === bidEstimationItem.mdCategoryId
                );
                return {
                  service: selectedService?.serviceCategoryName ?? '',
                  id: 0,
                  quantity: bidEstimationItem.quantity,
                  unit: bidEstimationItem.mdUnits,
                  mdServiceId: bidEstimationItem.mdCategoryId,
                  numOfHours: bidEstimationItem.quantity,
                  ratePerHour: bidEstimationItem.rate,
                  totalJobEstimation: bidEstimationItem.totalCost,
                  totalServices: 1,
                  serviceMeasure: bidEstimationItem.mdMeasureType,
                  estimationId: bidEstimationItem.estimationId,
                };
              })
            : ongoingJobItem?.jobServices?.map((jobServiceItem: JobService) => {
                const selectedService: any = serviceCategories.find(
                  (serviceCategoryItem: ServiceCategoryV3) =>
                    serviceCategoryItem.serviceCategoryId === jobServiceItem.mdServiceId
                );
                return {
                  service: selectedService?.serviceCategoryName ?? '',
                  id: 0,
                  quantity: 0,
                  unit: jobServiceItem.mdServiceUnits,
                  mdServiceId: jobServiceItem.mdServiceId,
                  numOfHours: 0,
                  ratePerHour: 0,
                  totalJobEstimation: 0,
                  totalServices: 1,
                  serviceMeasure: jobServiceItem.serviceMeasure,
                };
              });
          mModifiedJob.jobDetails = { ...mModifiedJob, ...ongoingJobItem };
          return mModifiedJob;
        })
      );
      setJobs(mModifiedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  }, []);

  const getServiceCategories = async (customerId: string) => {
    try {
      const masterData = await MasterDataApiService.getAllServiceCategoryV3(customerId);
      return masterData.data ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  useEffect(() => {
    setTabSelected(tabIndex);
  }, [location.pathname]);

  useEffect(() => {
    handleChange(currentPage);
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    if (location?.state?.jobName) {
      setSnackbarMessage(`${location?.state?.jobName} Job successfully created.`);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }

    fetchData();
  }, [tabSelected]);

  const myCustomSortingFn = () => {
    setSortBy('DESC');
  };

  const handleSort = async (sortingObj: any) => {
    setSorting(sortingObj);
    try {
      if (sortingObj.length) {
        setNewSortOrder(sortingObj[0].id);
        setNewSort(sortingObj[0].desc);
        await fetchData(currentPage, sortingObj[0].id, sortingObj[0].desc);
      } else {
        await fetchData(currentPage);
      }
    } catch (error) {
      console.error('Error fetching facility data:', error);
    }
  };

  useEffect(() => {
    localStorage.removeItem(AsyncStorageKeys.viewJob);
    localStorage.removeItem(AsyncStorageKeys.currentJob);
    localStorage.removeItem(AsyncStorageKeys.uploadQuote);
    removeJobLocalData();
  }, []);

  return (
    <>
      <>
        <DataGrid
          columns={columns}
          data={jobs}
          enableRowSelect={false}
          enableColumnPinning={false}
          sortingObj={sorting}
          sort={handleSort}
          loader={isLoading}
          sortingFns={{
            myCustomSortingFn: myCustomSortingFn,
          }}
          errorMessageTitle={t('noData:noDataYet')}
          errorMessageDescription={t('noData:comeBackLater')}
        />
        {!isLoading && totalCount > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
              <StyledPagination
                count={Math.ceil(totalCount / itemsPerPage)}
                page={currentPage}
                onChange={(event, value) => {
                  handleChange(value);
                  if (newSortOrder) {
                    fetchData(value, newSortOrder, newSort);
                  } else {
                    fetchData(value, '', '');
                  }
                }}
                color="primary"
              />
            </Stack>
            <Typography>{`Total items: ${totalCount}`}</Typography>
          </Box>
        )}
      </>
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default OngoingJobsTab;
