/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';
import { t } from 'i18next';

import { ILoginItem } from './Models/Login.Model';
import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';

const CustomCarousel: React.FC = (): JSX.Element => {
  const items: ILoginItem[] = [
    {
      image: Icons.JanitorialImg,
      padding: '3rem 6rem 4rem 5.5rem',
    },
    {
      image: Icons.FurnitureImg,
      padding: '3.5rem 6rem 4rem 5.0625rem',
    },
    {
      image: Icons.FacadeImg,
      padding: '0.875rem 2.253125rem 4rem 2.196875rem',
    },
    {
      image: Icons.ExteriorImg,
      padding: '5.4375rem 1.809375rem 4rem 0rem',
    },
  ];

  const activeIndicatorStyles = {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.dark,
    width: '1.5rem',
    height: '0.625rem',
    borderRadius: '1rem',
  };
  const inactiveIndicatorStyles = {
    color: theme.palette.secondary.customColor9,
    marginRight: '0.25rem',
  };

  return (
    <Carousel
      height="calc(100vh - 9.625rem)"
      indicatorIconButtonProps={{ style: inactiveIndicatorStyles }}
      navButtonsAlwaysInvisible={true}
      activeIndicatorIconButtonProps={{
        style: activeIndicatorStyles,
      }}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

interface ItemComponentProps {
  item: ILoginItem;
}

const Item: React.FC<ItemComponentProps> = ({ item }): JSX.Element => {
  const itemStyles: React.CSSProperties = {
    background: theme.palette.secondary.main,
    textAlign: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: 'none',
    position: 'absolute',
    borderRadius: '2rem',
    flex: 1,
  };

  const imageStyles: React.CSSProperties = {
    marginBottom: 0,
    padding: item.padding,
  };

  return (
    <Paper style={itemStyles}>
      <img src={item.image} alt={t('altTexts:carouselImages')} style={imageStyles} />
    </Paper>
  );
};

export default CustomCarousel;
