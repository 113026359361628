/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import { buttonStyles, ButtonContainer } from './TaskDetailsPage.Style';
import { useAuth } from '../../../../Configuration/AuthContext';
import theme from '../../../../Shared/Themes/theme';

const ApproveOrRejectCardButtons = ({ handleApproveButtonClick, isSelfPerformJob, t }) => {
  const { approveButton, buttonText } = buttonStyles;
  const { isApproveLoading } = useAuth();
  const buttonData = [
    {
      label: `${t('dashboard:approveButtonText')}`,
      styles: approveButton,
      loaderColor: theme.palette.common.white,
      onClick: handleApproveButtonClick,
      loading: isApproveLoading,
    },
  ];

  return (
    <ButtonContainer>
      {buttonData.map((dataItem, index) => {
        return (
          <Box key={index}>
            <Button
              sx={{
                ...dataItem.styles,
                visibility:
                  isSelfPerformJob && dataItem.label === t('dashboard:rejectButtonText') ? 'hidden' : 'visible',
              }}
              onClick={dataItem.onClick}>
              <Typography sx={buttonText} color={dataItem.loaderColor}>
                {dataItem.loading ? (
                  <CircularProgress size={30} sx={{ color: dataItem.loaderColor }} />
                ) : (
                  dataItem.label
                )}
              </Typography>
            </Button>
          </Box>
        );
      })}
    </ButtonContainer>
  );
};

export default withTranslation(['dashboard'])(ApproveOrRejectCardButtons);
