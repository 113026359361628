/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CircularProgress } from '@mui/material';

import { Wrapper, HeaderWrapper, Title, SelectInput, Options, DropDown, GraphLoader } from './GraphContainer.styles';
import BarGraph from '../Graphs/BarGraph/BarGraph';
import LineGraph from '../Graphs/LineGraph/LineGraph';
import { GraphType } from '../Graphs/models';

import { Icons } from '../../../Constants/Icons';
import { NoDataFound } from '../NoDataFound/NoDataFound';
import theme from '../../../Themes/theme';

export interface IData {
  name: string;
  amount: number;
  date: string[];
}

interface IGraphData {
  title: string;
  graphView?: string;
  data: IData[];
  graphColor: string;
}

interface ISimpleBarGraphProps {
  graphData: IGraphData;
  type: string;
  loading: boolean;
  id: string;
  onDateRangeSelect: (s: string, id: string) => void;
}

/**
 * Difference between GraphContainer and GraphContainerV2 is only the data filtering based on date
 * @param props
 * @returns GraphContainerV2
 */
const GraphContainerV2 = (props: ISimpleBarGraphProps): JSX.Element => {
  const { t } = useTranslation(['altTexts']);
  const {
    graphData: { data, title, graphColor },
    type,
    loading,
    onDateRangeSelect,
    id,
  } = props;
  const [value, setValue] = useState('7D');
  const [isValueOpen, setValueOpen] = useState(false);

  const dropdownData = [
    { id: 1, label: 'Last 7 days', value: '7D' },
    { id: 2, label: 'Last 15 days', value: '15D' },
    { id: 3, label: 'Last 30 days', value: '30D' },
    { id: 3, label: 'Last 45 days', value: '45D' },
    { id: 4, label: 'Last 3 months', value: '3M' },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    onDateRangeSelect(event.target.value, id);
  };

  const toggleValue = () => {
    setValueOpen(!isValueOpen);
  };

  const renderGraph = () => {
    if ((type as GraphType) === GraphType.BarGraph) {
      return (
        <BarGraph
          graphData={data}
          graphView={props.graphData?.graphView ?? 'vertical'}
          barColor={graphColor}
          yAxisLabel={title}
        />
      );
    } else if ((type as GraphType) === GraphType.LineGraph) {
      return <LineGraph graphData={data} LineColor={graphColor} />;
    }
    return null;
  };

  return (
    <>
      {Object.keys(props.graphData)?.length || loading ? (
        <Wrapper>
          <HeaderWrapper>
            <Title>{title}</Title>
            <DropDown>
              <div onClick={toggleValue}>
                <Select
                  value={value}
                  onChange={handleChange}
                  defaultValue={value}
                  input={<SelectInput />}
                  inputProps={{ 'aria-label': 'Without label' }}
                  IconComponent={() => (
                    <img
                      src={Icons.DropdownIcon}
                      alt={t('altTexts:dropdown')}
                      style={{
                        transform: isValueOpen ? 'rotate(180deg)' : 'none',
                        position: 'absolute',
                        right: '0.75rem',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                  open={isValueOpen}
                  onClose={toggleValue}
                  onOpen={toggleValue}
                  MenuProps={{
                    PaperProps: { style: { boxShadow: '0px 16px 20px 0px rgba(58, 42, 0, 0.12)' } },
                  }}>
                  {dropdownData.map((dataItem) => (
                    <Options key={dataItem.id} value={dataItem.value}>
                      {dataItem.label}
                    </Options>
                  ))}
                </Select>
              </div>
            </DropDown>
          </HeaderWrapper>
          {loading ? (
            <GraphLoader>
              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
            </GraphLoader>
          ) : data?.length ? (
            renderGraph()
          ) : (
            <NoDataFound />
          )}
        </Wrapper>
      ) : null}
    </>
  );
};

GraphContainerV2.defaultProps = {};

export default GraphContainerV2;
