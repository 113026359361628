/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, TextField } from '@mui/material';

import { UserForUserManagement } from '../../../../API';
import CustomerApiService from '../../Services/CustomerService';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import { getRoleName, getStoredCustomerDetails } from '../../../../Shared/Utilities/utils';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';

import theme from '../../../../Shared/Themes/theme';

import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { BottomBarContainer, buttonStyles } from '../../../Jobs/Components/AddEditOneTimeJob/AddEditOneTimeJob.Style';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { Loader } from '../../../Jobs/Components/JobView/JobsView.styles';
import {
  AccessOptions,
  ITypes,
  IAddAccessFacilityInfoForm,
  ICustomerTeam,
} from '../../../Vendor/Models/ViewFacilityDetails.model';
import { Patterns } from '../../../../Shared/Constants';

const AddAccessFacilityInfo = (): JSX.Element => {
  const location = useLocation();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IAddAccessFacilityInfoForm>({
    defaultValues: {
      access: location?.state?.accessInfo || '',
      personOfContact: location?.state?.accessContactPerson || '',
      parkingAt: location?.state?.otherInformation || '',
      codeForAccess: location.state.accessCode,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDataSaving, setIsDataSaving] = useState(false);
  const [isDataUpdating, setIsDataUpdating] = useState(false);
  const [isHideUpdateButton, setIsHideUpdateButton] = useState(true);
  const [customerTeams, setCustomerTeams] = useState<ICustomerTeam[]>([]);

  const [locationAddress, setLocationAddress] = useState<string>('');

  const { textField } = buttonStyles;

  console.log('location20', location);

  const navigate = useNavigate();

  const accessOptions: ITypes[] = [
    { label: AccessOptions.LockboxWithKey, value: AccessOptions.LockboxWithKey },
    { label: AccessOptions.KeyCard, value: AccessOptions.KeyCard },
    { label: AccessOptions.AccessPin, value: AccessOptions.AccessPin },
    { label: AccessOptions.ContactPerson, value: AccessOptions.ContactPerson },
  ];

  const { t } = useTranslation(['companyDetails']);

  const isFormEmpty = !watch('access') || !watch('personOfContact') || !watch('parkingAt');
  const selectedAccess = watch('access');

  useEffect(() => {
    if (location?.state?.address?.addressLine2) {
      setLocationAddress(location.state.address.addressLine2);
    }
    if (location?.state?.accessInfo) {
      setValue('access', location.state.accessInfo || '');
      setValue('codeForAccess', location.state.accessCode || '');
      setValue('personOfContact', location.state.accessContactPerson || '');
      setValue('parkingAt', location.state.otherInformation || '');
    } else {
      setIsHideUpdateButton(false);
    }
  }, [location.state, setValue]);

  const onSubmit = async (data: IAddAccessFacilityInfoForm): Promise<void> => {
    const selectedPersonOfContact = customerTeams.find((member) => member.userId === data.personOfContact);

    try {
      setIsDataSaving(true);
      const addFacilityAccess = {
        accessCode: data.codeForAccess,
        accessContactPerson: selectedPersonOfContact?.userId || '',
        roleName: selectedPersonOfContact?.roleName || '',
        otherInformation: data.parkingAt,
        accessContactDetails: selectedPersonOfContact?.phoneNumber || '',
        accessInfo: data.access,
        accessCodeEncrypted: false,
      };
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const facilityAccessRes = await CustomerApiService.saveFacilityAccessDetails(
        customerId || '1234',
        location?.state?.facilityId,
        addFacilityAccess
      );
      if (facilityAccessRes?.data) {
        navigate('/company/facilities');
      }
      setIsDataSaving(false);
    } catch (e) {
      setIsDataSaving(false);
    }
  };

  const onUpdateSubmit = async (data: IAddAccessFacilityInfoForm): Promise<void> => {
    const selectedPersonOfContact = customerTeams.find((member) => member?.userId === data.personOfContact);
    try {
      setIsDataUpdating(true);
      const updateFacility = {
        accessCode: data?.codeForAccess,
        accessContactPerson: selectedPersonOfContact?.userId || '',
        roleName: selectedPersonOfContact?.roleName || '',
        otherInformation: data?.parkingAt,
        accessInfo: data.access,
        facilityAccessDetailId: location?.state?.facilityAccessDetailId,
        accessContactDetails: selectedPersonOfContact?.phoneNumber || '',
      };
      if (
        (data.access as AccessOptions) === AccessOptions.KeyCard ||
        (data.access as AccessOptions) === AccessOptions.ContactPerson
      ) {
        updateFacility.accessCode = '';
      }
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const facilityAccessRes = await CustomerApiService.updateFacilityAccess(
        customerId || '1234',
        location?.state?.facilityId,
        updateFacility
      );
      if (facilityAccessRes?.errors.length === 0) {
        navigate('/company/facilities');
      } else {
        console.error('Errors updating facility access:', facilityAccessRes.errors);
      }
      setIsDataUpdating(false);
    } catch (e) {
      setIsDataUpdating(false);
    }
  };

  const fetchUsers = useCallback(async () => {
    try {
      const { customerId = '' } = getStoredCustomerDetails() || {};

      if (customerId) {
        setIsLoading(true);
        const teamsResponse = await OrganizationService.getAllUsers(customerId);

        const usersResponse = await Promise.all(
          teamsResponse?.data.map(async (dataItem: UserForUserManagement) => {
            let userRoleInfo;
            if (dataItem?.roles) {
              userRoleInfo = dataItem?.roles[0] || {};
            }
            return {
              userId: dataItem.userId,
              name: dataItem.username,
              roleName: getRoleName(userRoleInfo?.roleName ?? ''),
            };
          })
        );
        setCustomerTeams(usersResponse);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <>
      <PageTitle title={t('companyDetails:modifyFacilityAccess')} />
      <CustomerContainer>
        {isLoading ? (
          <Loader>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : (
          <>
            <Box>
              <form
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '2rem',
                  margin: '3rem',
                }}>
                <TextField
                  sx={{ ...textField }}
                  label={
                    <span>
                      {t('companyDetails:location')} <span className="mandatory-field">*</span>
                    </span>
                  }
                  value={locationAddress}
                  disabled
                />

                <Controller
                  name="access"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={textField}
                      {...field}
                      select
                      label={
                        <span>
                          {t('companyDetails:access')} <span className="mandatory-field">*</span>
                        </span>
                      }>
                      {accessOptions.map((option) => (
                        <Options key={option.value} value={option.value}>
                          {option.label}
                        </Options>
                      ))}
                    </TextField>
                  )}
                />

                {(selectedAccess as AccessOptions) === AccessOptions.AccessPin && (
                  <Controller
                    name="codeForAccess"
                    control={control}
                    rules={{
                      pattern: {
                        value: Patterns.TextOnly,
                        message: `${t('companyDetails:pleaseEnterValidCodeForAccess')}`,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={textField}
                        label={
                          <span>
                            {t('companyDetails:facilityAccessCode')} <span className="mandatory-field">*</span>
                          </span>
                        }
                        value={field.value}
                        onChange={(e) => setValue('codeForAccess', e.target.value)}
                        error={!!errors.codeForAccess}
                        helperText={errors.codeForAccess ? errors.codeForAccess.message : ''}
                      />
                    )}
                  />
                )}

                <Controller
                  name="personOfContact"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={textField}
                      {...field}
                      select
                      label={
                        <span>
                          {t('companyDetails:personToContact')} <span className="mandatory-field">*</span>
                        </span>
                      }>
                      {customerTeams.map((user) => (
                        <Options key={user.userId} value={user.userId}>
                          {user.name} | {user.roleName}
                        </Options>
                      ))}
                    </TextField>
                  )}
                />

                <Controller
                  name="parkingAt"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={textField}
                      label={
                        <span>
                          {t('companyDetails:parkingAt')} <span className="mandatory-field">*</span>
                        </span>
                      }
                      value={field.value}
                      onChange={(e) => setValue('parkingAt', e.target.value)}
                    />
                  )}
                />
              </form>
            </Box>
            <BottomBarContainer>
              {isHideUpdateButton ? (
                <Button
                  isLoading={isDataUpdating}
                  label={t('companyDetails:updateAccessDetails')}
                  onClick={handleSubmit(onUpdateSubmit)}
                  type={ButtonType.Primary}
                  disabled={isFormEmpty || isDataUpdating}
                />
              ) : (
                <Button
                  isLoading={isDataSaving}
                  label={t('companyDetails:saveAccessDetails')}
                  onClick={handleSubmit(onSubmit)}
                  type={ButtonType.Primary}
                  disabled={isFormEmpty || isDataSaving}
                />
              )}
            </BottomBarContainer>
          </>
        )}
      </CustomerContainer>
    </>
  );
};

export default AddAccessFacilityInfo;
