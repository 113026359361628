/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import SimpleDatePicker from '../../../../Shared/Components/Common/SimpleDatePicker/SimpleDatePicker';
import { DateField, DateFieldWrapper, ErrorMsg } from './TasksReview.styles';
import Calendar from '../../../../assets/images/calendar.svg';

interface IDateSelectionProps {
  control: Control<any>;
  requiredText: string;
  fieldName: any;
  label: string;
}

const DateSelection = ({ control, requiredText, fieldName, label }: IDateSelectionProps): JSX.Element => {
  const { t } = useTranslation(['altText']);
  return (
    <>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <DateFieldWrapper>
              <SimpleDatePicker
                isResponsive
                defaultValue={value}
                onChange={onChange}
                CustomComponent={
                  <DateField
                    label={label}
                    variant="outlined"
                    helperText=""
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={Calendar} alt={t('altTexts:calendar')} />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
              <ErrorMsg>{error && error?.message}</ErrorMsg>
            </DateFieldWrapper>
          );
        }}
        rules={{
          required: requiredText,
        }}
      />
    </>
  );
};

export default DateSelection;
