/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useEffect, useState } from 'react';
import {
  ButtonWrapper,
  CloseDialogBoxIcon,
  CommentTag,
  CommentTitle,
  CommentsContainer,
  Fields,
  LoaderTag,
  MediaContainer,
  MediaText,
  RejectionTimeHeader,
  RejectionTimeTag,
  ReworkTaskDetailsWrapper,
  TaskCompletedByTag,
  customStyles,
  Divider,
  AttachmnetsHeader,
} from './ActionItems.Style';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../../Shared/Constants/Icons';
import theme from '../../../../Shared/Themes/theme';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { fetchDataFromS3Bucket, getDaySuffix } from '../../../../Shared/Utilities/utils';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ApproverType, mdAttachmentType } from '../../../../API';
import moment from 'moment';
import MediaPopup from '../../../../Shared/Components/Common/MediaPopUp/MediaPopUp';
import { MediaType, MediaDirection } from '../../../../Shared/Constants/App';

interface ReworkTaskDetails {
  openReworkTaskDetails: boolean;
  handleReworkTaskDetailsClose: () => void;
  taskData: any;
  handleScheduleTask: (taskId: string) => void;
}

const enum CommnetRoles {
  CustomersComments = 'customersComments',
  SupervisorsComments = 'supervisorsComments',
  AssociatesComments = 'associatesComments',
  PrimeVendorsComments = 'primeVendorsComments',
  VendorsComments = 'vendorsComments',
}

function getFormattedReworkrequestedDate(dateString: string): string {
  const date = moment(dateString);
  const day = date.date();
  const daySuffix = getDaySuffix(day);
  return `${day}${daySuffix} ${date.format('MMMM')}, ${date.format('YYYY')}`;
}

function formatCustomDate(dateString: string): string {
  const date = moment(dateString);
  const formattedTime = date.format('h:mm a');
  const day = date.date();
  const daySuffix = getDaySuffix(day);
  const formattedDate = `${date.format('MMM')} ${day}${daySuffix}, ${date.format('YY')}`;

  return `${formattedTime} ${formattedDate}`;
}

const ReworkTaskDetails = ({
  openReworkTaskDetails,
  handleReworkTaskDetailsClose,
  taskData,
  handleScheduleTask,
}: ReworkTaskDetails): JSX.Element => {
  const { t } = useTranslation(['altTexts', 'taskRejectionAndRework', 'dashboard']);
  const navigate = useNavigate();
  const { reworkDetailsScheduleButton, buttonText } = customStyles;
  const [isAttachmentsLoading, setIsAttachmentsLoading] = useState<boolean>(false);
  const [taskComments, setTaskComments] = useState<any>([]);
  const [images, setImages] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [mediaType, setMediaType] = useState<MediaType>(MediaType.Photo);

  console.log('rework task details', taskData);

  const handleDialogClose = (event: React.SyntheticEvent, reason?: string): void => {
    if (reason && reason === 'backdropClick') return;
    handleReworkTaskDetailsClose();
  };

  const getAttachmentDetails = async (attachments: any): Promise<void> => {
    setIsAttachmentsLoading(true);
    const attachmentsList = await Promise.all(
      attachments.map(async (attachment: any) => {
        if (!attachment.url.includes('https')) {
          attachment.url = await fetchDataFromS3Bucket(navigate, attachment?.url ?? '');
        }
        return attachment;
      })
    );
    const imagesList: any[] = [];
    const videosList: any[] = [];
    attachmentsList.forEach((attachment: any) => {
      if (
        attachment.mdAttachmentType.includes(mdAttachmentType.Photo) ||
        attachment.mdAttachmentType.includes(mdAttachmentType.Image)
      ) {
        imagesList.push(attachment);
      } else {
        videosList.push(attachment);
      }
    });
    setImages(imagesList);
    setVideos(videosList);
    setIsAttachmentsLoading(false);
  };

  const getRoleBasedOnApproverTyppe = (
    approverType: ApproverType
  ):
    | CommnetRoles.CustomersComments
    | CommnetRoles.SupervisorsComments
    | CommnetRoles.AssociatesComments
    | CommnetRoles.PrimeVendorsComments => {
    if (approverType === ApproverType.Customer) {
      return CommnetRoles.CustomersComments;
    } else if (approverType === ApproverType.Supervisor) {
      return CommnetRoles.SupervisorsComments;
    } else if (approverType === ApproverType.PrimeVendor) {
      return CommnetRoles.PrimeVendorsComments;
    } else if (approverType === ApproverType.Vendor) {
      return CommnetRoles.SupervisorsComments;
    }
    return CommnetRoles.AssociatesComments;
  };

  const openMediaPopup = (type: MediaType): void => {
    const mediaArray = type === MediaType.Photo ? images : videos;
    if (mediaArray.length) {
      setPopupOpen(true);
      setMediaType(type);
    }
  };

  const closeMediaPopup = (): void => {
    setPopupOpen(false);
    setCurrentMediaIndex(0);
  };

  const showMedia = (direction: MediaDirection): void => {
    setCurrentMediaIndex((prevIndex) => {
      const mediaArray = mediaType === MediaType.Photo ? images : videos;
      return direction === MediaDirection.Previous
        ? (prevIndex - 1 + mediaArray?.length) % mediaArray?.length
        : (prevIndex + 1) % mediaArray?.length;
    });
  };

  useEffect(() => {
    if (taskData.approvalSaga && !isEmpty(taskData.approvalSaga)) {
      const commentsList = taskData.approvalSaga.filter(
        (data: any) => data.comment && !isEmpty(data.comment) && data.taskId === taskData.taskId
      );
      if (commentsList && !isEmpty(commentsList)) {
        setTaskComments([...commentsList]);
      }
    }
    const attachments = taskData.taskAttachments;
    if (attachments.length) {
      getAttachmentDetails(attachments);
    }
  }, []);

  return (
    <>
      <ReworkTaskDetailsWrapper open={openReworkTaskDetails} onClose={handleDialogClose}>
        <CloseDialogBoxIcon onClick={handleReworkTaskDetailsClose}>
          <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
        </CloseDialogBoxIcon>
        <RejectionTimeHeader>
          <RejectionTimeTag>
            {t('taskRejectionAndRework:taskRejected')} - {formatCustomDate(taskData.modifiedOn)}
          </RejectionTimeTag>
        </RejectionTimeHeader>
        {isAttachmentsLoading ? (
          <LoaderTag>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </LoaderTag>
        ) : (
          <div style={{ padding: '2rem' }}>
            <TaskCompletedByTag>
              {t('taskRejectionAndRework:taskWasCompletedBy')}{' '}
              <span style={{ fontWeight: theme.typography.fontWeightBold }}>{taskData.associate}</span>,{' '}
              {t('taskRejectionAndRework:followingAreTheDetailsAndComments')}
            </TaskCompletedByTag>
            <Fields>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachmnetsHeader>{t('taskRejectionAndRework:proofs')}:</AttachmnetsHeader>
                {isAttachmentsLoading ? (
                  <LoaderTag>
                    <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                  </LoaderTag>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <MediaContainer onClick={() => openMediaPopup(MediaType.Photo)}>
                      <img src={Icons.PhotosIcon} alt={t('altTexts:galleryIcon')} />
                      <MediaText>
                        {images?.length} {images?.length <= 1 ? t('dashboard:photo') : t('dashboard:photos')}
                      </MediaText>
                    </MediaContainer>
                    <Divider />
                    <MediaContainer onClick={() => openMediaPopup(MediaType.Video)}>
                      <img src={Icons.VideoIcon} alt={t('altTexts:videoIcon')} />
                      <MediaText>
                        {videos?.length} {videos?.length <= 1 ? t('dashboard:video') : t('dashboard:videos')}
                      </MediaText>
                    </MediaContainer>
                  </Box>
                )}
              </Box>
            </Fields>
            {!isEmpty(taskComments) && (
              <CommentsContainer>
                {taskComments.map((commentData: any, index: number) => {
                  return (
                    <div key={index}>
                      <CommentTitle>
                        {t(`taskRejectionAndRework:${getRoleBasedOnApproverTyppe(commentData.approverType)}`)}
                      </CommentTitle>
                      <CommentTag>{commentData.comment}</CommentTag>
                    </div>
                  );
                })}
              </CommentsContainer>
            )}
            <CommentTitle>{t('taskRejectionAndRework:reworkRequestedOn')}</CommentTitle>
            <CommentTag>{getFormattedReworkrequestedDate(taskData.suggestedReworkDate)}</CommentTag>
          </div>
        )}
        <ButtonWrapper sx={{ padding: '2rem', width: '100%', paddingTop: '0' }}>
          <Button
            sx={{
              ...reworkDetailsScheduleButton,
              maxWidth: '100%',
              width: '100%',
              opacity: isAttachmentsLoading ? 0.5 : 1,
            }}
            onClick={() => handleScheduleTask(taskData?.taskId)}
            disabled={isAttachmentsLoading}>
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {t('taskRejectionAndRework:scheduleRework')}
            </Typography>
          </Button>
        </ButtonWrapper>
      </ReworkTaskDetailsWrapper>
      <MediaPopup
        isOpen={isPopupOpen}
        onClose={closeMediaPopup}
        currentMediaIndex={currentMediaIndex}
        media={mediaType === MediaType.Photo ? images : videos}
        showPreviousMedia={() => showMedia(MediaDirection.Previous)}
        showNextMedia={() => showMedia(MediaDirection.Next)}
        numMedia={mediaType === MediaType.Photo ? images?.length : videos?.length}
        type={mediaType}
      />
    </>
  );
};

export default ReworkTaskDetails;
