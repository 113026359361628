/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { UserService } from '../../Services/UserService';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { Box, CircularProgress, Stack } from '@mui/material';
import { t } from 'i18next';
import { Loader } from '../../../Jobs/Components/JobView/JobsView.styles';
import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import theme from '../../../../Shared/Themes/theme';
import { IJobOverview } from '../../../Jobs/Components/JobsListView/JobsListView';
import { CompanyUserViewInterface } from '../../Models/Company.Model';
import { TaskData } from '../../../Jobs/Components/WorkTasks/WorkTasks';
import { IJobs } from '../../../Jobs/Components/JobView/JobsView';
import { IBidList } from '../../../Jobs/Components/JobDetails/OneTimeJobDetails';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { Icons } from '../../../../Shared/Constants/Icons';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';

const User = (): JSX.Element => {
  const { state } = useLocation();
  const [user] = useState({ ...state.rowInfo });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const totalCount: any = 0;
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const sorting: MRT_SortingState = [];
  const [roleScopes, setRoleScopes] = useState<any>([]);

  const fetchUserRoleScopes = useCallback(async () => {
    try {
      setLoad(true);
      setIsLoading(true);
      const { userId, roles } = user || {};
      const roleId = Array.isArray(roles) && roles[0] ? roles[0].roleId : '';
      const { data } = await UserService.getUserRoleScopes(userId, roleId);
      setRoleScopes([...data]);
      setIsLoading(false);
      setLoad(false);
    } catch (e) {
      console.error('Error', e);
    }
  }, []);

  const renderCell = (renderedCellValue: React.ReactNode): string | number => {
    // If renderedCellValue is a string, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    return '-';
  };

  useEffect(() => {
    fetchUserRoleScopes();
  }, []);

  const myCustomSortingFn = (): void => {
    setSortBy('DESC');
  };

  // const handleSort = () => {};
  //sorting is not implementing yet

  const handleChange = (event: any, value: number): void => {
    setCurrentPage(value);
    fetchUserRoleScopes();
  };

  const commonColumnValues = {
    enableSorting: true,
    enableColumnFilter: true,
    size: 234,
    manualSorting: true,
    state: { sortBy },
    onSortingChange: setSortBy,
    enableColumnActions: true,
    sortingFn: 'alphanumericCaseSensitive',
  };

  const userColoums = useMemo<
    MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | CompanyUserViewInterface | IProfileCardProps>[]
  >(
    () => [
      {
        accessorKey: 'userId',
        header: t('customer:userId'),
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        ...commonColumnValues,
      },
      {
        accessorKey: 'organizationId',
        header: t('customer:organizationId'),
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        ...commonColumnValues,
      },
      {
        accessorKey: 'roleId',
        header: t('customer:roleId'),
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        ...commonColumnValues,
      },
      {
        accessorKey: 'customerId',
        header: t('customer:customerId'),
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        ...commonColumnValues,
      },
      {
        accessorKey: 'mdCountryCode',
        header: t('customer:mdCountryCode'),
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        ...commonColumnValues,
      },
      {
        accessorKey: 'regionId',
        header: t('customer:regionId'),
        Cell: ({ renderedCellValue }) => renderCell(renderedCellValue),
        ...commonColumnValues,
      },
    ],
    []
  );

  return (
    <>
      <PageTitle title={user?.username} />
      <CustomerContainer>
        <Box
          sx={{
            display: 'flex',
            paddingBottom: '1.5rem',
            justifyContent: 'end',
          }}>
          <Link to="/addUserScopes" style={{ textDecoration: 'none' }}>
            <Button
              icon={<img src={Icons.PlusWhiteIcon} alt={t('altTexts:addIcon')} />}
              type={ButtonType.Primary}
              label={t('customer:addUserScopes')}
            />
          </Link>
        </Box>
        {load ? (
          <Loader>
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          </Loader>
        ) : (
          <>
            <DataGrid
              columns={userColoums}
              data={roleScopes}
              enableRowSelect={false}
              enableColumnPinning={false}
              loader={isLoading}
              sortingObj={sorting}
              // sort={handleSort}
              sortingFns={{
                myCustomSortingFn: myCustomSortingFn,
              }}
            />
            {!isLoading && totalCount > 10 && (
              <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                <StyledPagination
                  count={Math.ceil(totalCount / itemsPerPage)}
                  page={currentPage}
                  onChange={(event, value) => {
                    handleChange(event, value);
                  }}
                  color="primary"
                />
              </Stack>
            )}
          </>
        )}
      </CustomerContainer>
    </>
  );
};

export default User;
