/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import { MRT_ColumnDef } from 'material-react-table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDescription,
  AccordionInfo,
  AccordionWrapper,
  ButtonWrapper,
  ProgressBar,
  ServiceContainer,
  ServiceDuration,
  ServiceShift,
  ServicesStatusWrapper,
  ServicesTitle,
  ServiceTitleDetails,
  TaskStatusTag,
  ServicesTitleWrapper,
  At,
} from './Accordion.styles';
import { TaskData } from '../../../../Modules/Jobs/Components/WorkTasks/WorkTasks';
import { IServices, Task } from '../../../Models/WorkTask.model';
import DataGrid from '../DataGrid/DataGrid';
import { ProgressBarContainer } from '../../../../Modules/Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { renderImg } from '../../../../Modules/Jobs/Components/JobDetailsOverView/JobDetailsOverview';
import { IJobOverview } from '../../../../Modules/Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../../Modules/Jobs/Components/JobView/JobsView';
import Button, { ButtonType } from '../Buttons/Button';
import { useAuth } from '../../../../Configuration/AuthContext';
import { Icons } from '../../../Constants/Icons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IBidList } from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../../Modules/Customer/Components/ViewFacilityDetails/ViewFacilityDetails';

import theme from '../../../Themes/theme';
import { Box } from '@mui/material';
import { TaskStatus } from '../../../../API';
import { getAuthData } from '../../../Utilities/utils';
import { UserType } from '../../../Constants/App';
import { ShiftTypes } from '../../../../Modules/Jobs/Models/JobDetailsOverview.Model';

interface IDataItem {
  id: number;
  jobId?: string;
  isShowButton?: boolean;
  selectedRowCount?: number;
  taskCompleted: number;
  // taskTotal: number;
  tableData: TaskData[];
  serviceName: string;
  shiftTiming?: string;
  shiftType?: string;
  Jobname?: string;
  facilityName?: string;
  iconUrl?: any;
  categoryTitle?: any;
  totalTasks: number;
  totalCompletedTasks?: any;
  options?: any;
}

export enum ScreenName {
  IssuesRaised = 'IssuesRaised',
  TasksReviewed = 'TaskReviewed',
  JobDetails = 'JobDetails',
}

interface IAccordionProps {
  vendorTypes?: [];
  accordionObj: IDataItem;
  handleAccordion: (id: number) => (event: React.SyntheticEvent, expanded: boolean) => void;
  columns: MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[];
  onChangeRow: (rowSelection: object, data: TaskData[], service: any) => void;
  expanded: number | boolean;
  screenName: ScreenName;
  serviceList: IServices[];
  handleApproveAll?: () => void;
  handleRejectAll?: () => void;
  dailyTaskData?: any;
  handleCustomerApproval?: (data: Task[]) => void;
  sendingForApproval?: boolean;
  isOtjJob?: boolean;
}

/**
 * @returns Accordion
 */
const Accordion = ({
  vendorTypes,
  accordionObj,
  handleAccordion,
  columns,
  onChangeRow,
  expanded,
  screenName,
  serviceList,
  handleApproveAll,
  handleRejectAll,
  handleCustomerApproval,
  sendingForApproval = false,
  isOtjJob,
}: IAccordionProps): JSX.Element => {
  const {
    id,
    Jobname = '',
    facilityName = '',
    iconUrl,
    shiftTiming,
    shiftType,
    selectedRowCount,
    categoryTitle: serviceName,
    totalTasks: taskTotal,
    taskCompleted,
    options: tableData,
  } = accordionObj;
  const { role, isDistrictManager } = getAuthData();

  const customerApprovalPendingTasks = tableData.filter(
    (data: Task) => (data.status as TaskStatus) === TaskStatus.ApprovedBySupervisor
  );

  const { isApproveLoading, isRejectLoading } = useAuth();

  const progress = (parseFloat(taskCompleted.toString()) / parseFloat(taskTotal.toString())) * 100;
  let statusText = '';
  if (screenName === ScreenName.IssuesRaised) {
    statusText = 'issues resolved';
  } else if (screenName === ScreenName.JobDetails) {
    statusText = 'tasks';
  } else if (screenName === ScreenName.TasksReviewed) {
    statusText = 'tasks reviewed';
  }
  const expandStatus = serviceList.findIndex((data) => data.id === id);
  const { Secondary, Primary } = ButtonType;
  const recordsLength = tableData?.length || 0;

  const { t } = useTranslation(['dashboard']);

  const WhiteTooltip = withStyles({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      borderRadius: '1rem',
      fontSize: theme.typography.h4?.fontSize,
      boxShadow: '0px 10px 20px 6px rgba(0, 107, 107, 0.10)',
      padding: ' 0.5rem 1rem',
    },
  })(Tooltip);

  return (
    <AccordionWrapper expanded={expanded === expandStatus + 1} onChange={handleAccordion(expandStatus + 1)}>
      <AccordionDescription expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <img src={iconUrl || Icons.BuildingImg} width="56" height="56" />
        <ServiceContainer>
          <ServicesStatusWrapper>
            <ServiceTitleDetails>
              {screenName === ScreenName.JobDetails ? <ServicesTitle>{serviceName}</ServicesTitle> : null}
              {screenName === ScreenName.IssuesRaised || screenName === ScreenName.TasksReviewed ? (
                <ServicesTitleWrapper>
                  <ServicesTitle>
                    {`${Jobname} `}
                    <At>at</At>
                    {` ${facilityName}`}
                  </ServicesTitle>
                </ServicesTitleWrapper>
              ) : null}
              {shiftTiming && shiftType ? (
                <>
                  <ServiceDuration>{shiftTiming}</ServiceDuration>
                  <ServiceShift>
                    {renderImg(shiftType as ShiftTypes)}
                    {shiftType}
                  </ServiceShift>
                </>
              ) : null}
            </ServiceTitleDetails>
            <TaskStatusTag sx={{ display: 'flex', gap: '0.5rem' }}>
              {taskCompleted}/{taskTotal} {statusText}
              <WhiteTooltip title={t('dashboard:totalTasksApprovedByCustomer')}>
                <InfoOutlinedIcon />
              </WhiteTooltip>
            </TaskStatusTag>
          </ServicesStatusWrapper>
          <ProgressBarContainer>
            <ProgressBar progressPercent={progress}></ProgressBar>
          </ProgressBarContainer>
        </ServiceContainer>
      </AccordionDescription>
      <AccordionInfo>
        <DataGrid
          vendorTypes={vendorTypes}
          expanded={expanded}
          columns={columns}
          data={tableData}
          onRowChange={(rowSelection: any) => onChangeRow(rowSelection, tableData, accordionObj)}
        />
        {customerApprovalPendingTasks.length > 0 && role !== UserType.Customer && !isDistrictManager && (
          <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type={Primary}
              label={
                isOtjJob
                  ? t('dashboard:sendWorkTasksForPrimeVendorApproval')
                  : `${t('dashboard:send')} (${customerApprovalPendingTasks.length}) ${t('dashboard:workTasksForCustomerApproval')}`
              }
              onClick={() => handleCustomerApproval && handleCustomerApproval(customerApprovalPendingTasks)}
              isLoading={sendingForApproval}
              disabled={sendingForApproval}
              width="25rem"
            />
          </Box>
        )}
        {selectedRowCount ? (
          <ButtonWrapper>
            <Button
              type={Secondary}
              label={selectedRowCount !== recordsLength ? `Reject (${selectedRowCount})` : 'Reject All'}
              onClick={handleRejectAll}
              width="160px"
              isLoading={isRejectLoading}
            />
            <Button
              type={Primary}
              label={selectedRowCount !== recordsLength ? `Approve (${selectedRowCount})` : 'Approve All'}
              onClick={handleApproveAll}
              width="160px"
              isLoading={isApproveLoading}
            />
          </ButtonWrapper>
        ) : null}
      </AccordionInfo>
    </AccordionWrapper>
  );
};

export default Accordion;
