/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { TabSection, TabTitle } from '../../../Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { TabsWrapper } from '../../../Jobs/Components/JobView/JobsView.styles';

import { getAuthData } from '../../../../Shared/Utilities/utils';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { UserType } from '../../../../Shared/Constants/App';
import Facilities from '../Facilities/Facilities';
import UsersList from '../Users/UsersList';
import { TabPanelProps } from '../../Models/Company.Model';

function a11yProps(index: number): {
  id: string;
  'aria-controls': string;
} {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps & { style?: React.CSSProperties }): JSX.Element {
  const { children, value, index, style, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}>
      {value === index && children}
    </div>
  );
}

const CompanyView = (): JSX.Element => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const snackbarMessage = '';
  const snackbarSeverity: 'success' | 'error' = 'success';

  const { t } = useTranslation(['Dashboard', 'tableHeader', 'homePage', 'altTexts', 'calendar']);
  const [tabSelected, setTabSelected] = useState(location?.pathname.includes('users') ? 1 : 0);
  // const [currentTab, setCurrentTab] = useState<string>();
  const userRole = getAuthData()?.role;
  const handleChangeTabs = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
    updateCurrentTab();
    const gotToTab = newValue === 0 && userRole === UserType.Customer ? 'facilities' : 'users';
    window.history.pushState(null, 'Peazy', `/company/${gotToTab}`);
  }, []);

  useEffect(() => {
    updateCurrentTab();
  }, []);

  const updateCurrentTab = (): void => {
    const { role } = getAuthData() || {};
    if (role === UserType.Customer) {
      // setCurrentTab(tabIndex === 0 ? 'Facility' : tabIndex === 1 ? 'User' : '');
    } else {
      // setCurrentTab(tabIndex === 0 ? 'User' : '');
      setTabSelected(1);
    }
  };

  const renderCell = (renderedCellValue: React.ReactNode): string | number => {
    // If renderedCellValue is a string, return it. Otherwise, return a fallback '-'
    if (typeof renderedCellValue === 'string' || typeof renderedCellValue === 'number') {
      return renderedCellValue ? renderedCellValue : '-';
    }
    return '-';
  };

  return (
    <>
      <PageTitle title={t('dashboard:company')} />
      <CustomerContainer>
        <TabSection sx={{ height: '100%' }}>
          <TabsWrapper>
            <Tabs value={tabSelected} onChange={handleChangeTabs} aria-label="basic tabs">
              {userRole === UserType.Customer && <TabTitle label={t('dashboard:facilities')} {...a11yProps(0)} />}
              <TabTitle label={t('dashboard:users')} {...a11yProps(1)} />
            </Tabs>
          </TabsWrapper>
          <TabPanel value={tabSelected} index={0} style={{ height: '100%' }}>
            <Facilities renderCell={renderCell} />
          </TabPanel>
          <TabPanel value={tabSelected} index={1} style={{ height: '100%' }}>
            <UsersList renderCell={renderCell} />
          </TabPanel>
        </TabSection>
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </CustomerContainer>
    </>
  );
};

export default CompanyView;
