/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React from 'react';
import {
  ActionItemCard,
  ButtonWrapper,
  CustomerRejectedTag,
  RejectionCardHeader,
  customStyles,
} from './ActionItems.Style';
import Grid from '@material-ui/core/Grid';
import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Typography } from '@mui/material';
import moment from 'moment';
import { getDaySuffix } from '../../../../Shared/Utilities/utils';

interface CustomerRejectedCard {
  taskData: any;
  handleViewDetails: (taskId: string) => void;
  handleScheduleTask: (taskId: string) => void;
}

function getFormattedDate(date: Date): string {
  const day = date.getDate();
  const daySuffix = getDaySuffix(day);
  return `${moment(date).format('MMM')} ${day}${daySuffix}, ${moment(date).format('YYYY')}`;
}

const CustomerRejectedCard = ({
  taskData,
  handleViewDetails,
  handleScheduleTask,
}: CustomerRejectedCard): JSX.Element => {
  const { t } = useTranslation(['altTexts', 'taskRejectionAndRework']);
  const { divider, viewDetailsButton, scheduleReworkButton, buttonText, cardItem, iconSizes } = customStyles;

  return (
    <ActionItemCard>
      <RejectionCardHeader>
        <div>{getFormattedDate(new Date())}</div>
        <CustomerRejectedTag sx={{ color: theme.palette.error.main }}>
          <img src={Icons.CustomerRejectedIcon} alt={t('taskRejectionAndRework:customerRejected')} />
          {t('taskRejectionAndRework:customerRejected')}
        </CustomerRejectedTag>
      </RejectionCardHeader>
      <Grid container style={{ padding: '16px', width: '100%' }}>
        <Grid item xs={12} spacing={2} style={{ ...cardItem, marginBottom: '1rem' }}>
          <img src={Icons.TaskIcon} alt={t('altTexts:taskNumber')} style={{ ...iconSizes }} />
          {t('taskRejectionAndRework:taskNumber')} {taskData.taskNumber ?? '-'}
        </Grid>

        <Grid item xs={6} spacing={2} style={{ ...cardItem }}>
          <img src={Icons.TaskIcon} alt={t('altTexts:taskName')} style={{ ...iconSizes }} />
          {taskData.taskName}
        </Grid>
        <Grid item xs={6} spacing={2} style={{ ...cardItem }}>
          <img src={Icons.LocationIcon} alt={t('altTexts:floor')} /> {t('taskRejectionAndRework:floor')}{' '}
          {taskData.floor ? taskData.floor : 0}
        </Grid>
      </Grid>
      <Divider sx={{ ...divider }} />
      <Grid container style={{ padding: '16px', width: '100%' }}>
        <Grid item xs={6} spacing={2} style={{ ...cardItem, marginBottom: '1rem' }}>
          <img src={Icons.FacilityIcon} alt={t('altTexts:facility')} />
          {taskData.facilityName}
        </Grid>
        <Grid item xs={6} spacing={2} style={{ ...cardItem, marginBottom: '1rem' }}>
          <img src={Icons.LocationIcon} alt={t('altTexts:address')} />
          {taskData.facilityAddress}
        </Grid>
        <Grid item xs={12} spacing={2} style={{ ...cardItem }}>
          <img src={Icons.UserManagementIcon} alt={t('altTexts:associate')} /> &nbsp;{taskData.associate} (
          {t('taskRejectionAndRework:completedByAssociate')})
        </Grid>
      </Grid>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'end', width: '100%' }}>
        <Divider sx={{ ...divider }} />
        <ButtonWrapper sx={{ padding: '16px', width: '100%' }}>
          <Button
            sx={{
              ...viewDetailsButton,
              cursor: 'pointer',
              maxWidth: '100%',
              width: '100%',
            }}
            onClick={() => handleViewDetails(taskData?.taskId)}>
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>
              {t('taskRejectionAndRework:viewDetails')}
            </Typography>
          </Button>
          <Button
            sx={{
              ...scheduleReworkButton,
              maxWidth: '100%',
              width: '100%',
            }}
            onClick={() => handleScheduleTask(taskData?.taskId)}>
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {t('taskRejectionAndRework:scheduleRework')}
            </Typography>
          </Button>
        </ButtonWrapper>
      </div>
    </ActionItemCard>
  );
};

export default CustomerRejectedCard;
