/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { BottomBar, EnterDetailsText } from './AddUser.Style';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import { getStoredCustomerDetails, getAuthData } from '../../../../Shared/Utilities/utils';
import { OrganizationService } from '../../../../Shared/Services/OrganizationService';
import { UserType } from '../../../../Shared/Constants/App';
import { CreateUser, UserForUserManagement } from '../../../../API';
import { AuthenticationService } from '../../../../Shared/Services/AuthenticationService';
import VendorApiService from '../../../../Shared/Services/VendorService';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import AddUserFormRenderer from './AddUserFormRenderer';
import SuccessMessagePopup from '../../../../Shared/Components/Common/SuccessMessagePopup/SuccessMessagePopup';

interface IRoleItem {
  label: string;
  value: string;
}

const AddUserPage = (): JSX.Element => {
  const { t } = useTranslation(['addUsers', 'vendor', 'addFacility']);

  const { handleSubmit, control, getValues, setError, watch } = useForm({
    defaultValues: {
      name: '',
      email: '',
      roleId: '',
      phoneNumber: '',
    },
  });
  const [roleOptions, setRoleOptions] = useState<IRoleItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);

  const { role, isDistrictManager, userId } = getAuthData();
  const navigate = useNavigate();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSubmit = async (details: any) => {
    setIsLoading(true);
    try {
      let teamsResponse = { data: [], errors: [] };
      if (role === UserType.Vendor) {
        const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);
        teamsResponse = isDistrictManager
          ? await VendorApiService.getVendorTeam(userDetails?.data?.userId)
          : await OrganizationService.getAllUsers(details.vendorId);
      } else if (role === UserType.Customer) {
        teamsResponse = await OrganizationService.getAllUsers(details.customerId);
      }
      const userDetails = await AuthenticationService.getUserByCognitoUserId(userId);

      if (teamsResponse?.data && isEmpty(teamsResponse.errors)) {
        const isUserAlreadyAdded = teamsResponse?.data?.some(
          (user: UserForUserManagement) =>
            (user?.email && user?.email?.toLowerCase() === details?.email?.toLowerCase()) ||
            (user?.phoneNumber && user?.phoneNumber === details?.phoneNumber?.replace('+', ''))
        );
        if (isUserAlreadyAdded) {
          setSnackbarMessage(t('addUsers:userAlreadyExist'));
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
          return true;
        }
      }
      const emailAndPhoneNumber: { email?: string; phoneNumber?: string } = {
        email: details?.email ?? '',
        phoneNumber: details?.phoneNumber?.replace('+', '') ?? '',
      };
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const userInput: CreateUser = {
        ...emailAndPhoneNumber,
        username: details.name ?? '',
        email: details.email ?? '',
        phoneNumber: details.phoneNumber ?? '',
        roleId: details.roleId ?? '',
        organizationId: customerId,
        ...(role === UserType.Vendor ? { managerId: userDetails?.data?.userId } : {}),
      };
      const createUserResponse = await OrganizationService.createUser(userInput);
      if (!isEmpty(createUserResponse.errors)) {
        setSnackbarMessage(t('vendor:errorOccurred'));
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
      } else {
        setIsLoading(false);
        setIsSuccessPopupVisible(true);
      }
    } catch (e) {
      setIsLoading(false);
      setSnackbarMessage(t('vendor:errorOccurred'));
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
    }
  };

  const onCloseSuccessPopup = (): void => {
    setIsSuccessPopupVisible(false);
    navigate('/company/users');
  };

  const onPrimaryButtonClick = (): void => {
    navigate('/company/users');
  };

  const getRoles = useCallback(async () => {
    try {
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const organizationRolesResponse = await OrganizationService.getOrganizationRoles(customerId);
      const rolesOptions = organizationRolesResponse.data[0]?.roles.map((roleItem: { roleName: any; roleId: any }) => {
        return {
          label: roleItem.roleName,
          value: roleItem.roleId,
        };
      });
      setRoleOptions(rolesOptions);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <PageTitle title={t('addUsers:addUsers')} />
      <CustomerContainer>
        <Box sx={{ marginBottom: '2.5rem' }}>
          <EnterDetailsText>{t('addUsers:addUser')}</EnterDetailsText>
        </Box>
        <AddUserFormRenderer roles={roleOptions} control={control} setError={setError} />
      </CustomerContainer>
      <BottomBar>
        <Button
          type={ButtonType.Primary}
          label={t('addUsers:AddSendInvite')}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          disabled={!watch('name') || !watch('roleId')}
        />
      </BottomBar>
      <SuccessMessagePopup
        open={isSuccessPopupVisible}
        onClose={onCloseSuccessPopup}
        message={`${getValues('name')} ${t('addUsers:hasBeenAdded')}`}
        primaryButtonLabel={t('addFacility:goToMyCompany')}
        primaryAction={onPrimaryButtonClick}
      />
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default AddUserPage;
