/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import CreateBidServices from '../../../Vendor/Services/CreateBidServices';

import { Icons } from '../../../../Shared/Constants/Icons';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';

import {
  buttonStyles,
  OneTimeJobContainer,
  JobNameContainer,
  ServiceScheduleContainer,
  DateFieldWrapper,
  DatePickerContainer,
  ErrorMsg,
  VendorDetailsContainer,
  BottomBarContainer,
  JobNameWrapper,
  ServiceFieldWrapper,
  ServiceScheduleRangeText,
  ServiceScheduleDateRangeText,
  PhotosAndVideosContainer,
} from './AddEditOneTimeJob.Style';
import {
  Button,
  Typography,
  Divider,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  getAuthDetails,
  getStoredCustomerDetails,
  hours,
  isDateSameOrAfter,
  getTagValues,
  fetchDataFromS3Bucket,
  uploadDocumentToS3Bucket,
  removeJobLocalData,
  isDateSameOrBefore,
  isDateBefore,
  isDateAfter,
  getAuthData,
  joinByComma,
  getDefaultFormValue,
  getDefaultFacilitiesList,
  getDefaultServiceValue,
  formatDate,
} from '../../../../Shared/Utilities/utils';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import JobCreateApiService from '../../Services/JobCreateService';
import {
  BidStatus,
  CreateJobInput,
  CreateOrUpdateJobAttachmentInput,
  DraftStatus,
  DraftStep,
  Family,
  JobType,
  MdDueType,
  YesNoType,
} from '../../../../API';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { Controller, useForm } from 'react-hook-form';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import moment from 'moment';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';
import { Patterns } from '../../../../Shared/Constants';
import './AddEditOneTimeJob.css';
import SearchVendor from './SearchVendor';

import { FileContextType, UserType, MasterData, JobStatus } from '../../../../Shared/Constants/App';
import PhotosAndVideos from './PhotosAndVideos';
import {
  FieldNames,
  IDetailsProps,
  IFacilitiesItem,
  ISelectedFacility,
  IServiceCategoryItem,
  IServiceItem,
  ITag,
  ITagsProps,
  IVendor,
  RecommendedJobsResponse,
} from '../../Models/AddEditOneTimeJob.Model';
import {
  getNumberOfDaysLeft,
  getServiceDuration,
  getServiceVolume,
  getTotalServiceArea,
} from '../../Utilities/AddEditOneTimeJob.Util';
import theme from '../../../../Shared/Themes/theme';
import MultiSelectDropdownWithCheckboxes from '../../../../Shared/Components/Common/MultiSelectDropdownWithCheckboxes/MultiSelectDropdownWithCheckboxes';
import FacilityAutocomplete from './FacilityAutoComplete';
import AsyncStorageKeys from '../../../../Shared/Constants/StorageKeys';
import { BoxShadow } from '../../../../Shared/Constants/Style.Constants';

const selectedFacility = localStorage.getItem('selectedFacility');
const attachments = localStorage.getItem('jobAttachments');
const customersList = localStorage.getItem('customersList');

const getDefaultSelectedFacility = () => {
  return selectedFacility ? JSON.parse(selectedFacility) : [];
};

const getDefaultJobAttachments = () => {
  return attachments ? JSON.parse(attachments) : [];
};

const getDefaultCustomersList = () => {
  return customersList ? JSON.parse(customersList) : [];
};

const getDefaultCustomerId = () => {
  return getStoredCustomerDetails() || '';
};

const AddEditOneTimeJob = () => {
  const { t } = useTranslation(['oneTimeJob', 'calendar']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const {
    headingText,
    textField,
    textFieldService,
    serviceAndAreaSpecification,
    addButton,
    addButtonText,
    divider,
    saveExitButton,
    buttonText,
    createJobButton,
    serviceScheduleContent,
    radioButton,
    radioButtonText,
    vendorDetailsContent,
    executionType,
    addAnotherService,
  } = buttonStyles;

  const location = useLocation();
  const customerSelected = localStorage.getItem('selectedCustomer');

  const jobRecord = location.state?.jobRecord;

  const getDefaultStartTimeValue = () => {
    const storedStartTime = localStorage.getItem('startTime');

    if (storedStartTime) {
      return storedStartTime;
    }

    const jobFrequencies = location.state?.jobRecord?.jobFrequencies || [];

    return jobFrequencies.length ? jobFrequencies[0].fromTime : '';
  };

  const getDefaultEndTimeValue = () => {
    const storedEndTime = localStorage.getItem('endTime');

    if (storedEndTime) {
      return storedEndTime;
    }

    const jobFrequencies = location.state?.jobRecord?.jobFrequencies || [];

    return jobFrequencies.length ? jobFrequencies[0].toTime : '';
  };

  const getDefaultInstructionsValue = () => {
    const storedInstructions = localStorage.getItem('instructions');

    if (storedInstructions) {
      return storedInstructions;
    }

    const jobRecord = location.state?.jobRecord;
    return jobRecord?.specialInstructions || '';
  };

  const getSelectedCustomer = () => {
    if (customerSelected) {
      return JSON.parse(customerSelected);
    }

    const jobRecord = location.state?.jobRecord;
    const selectedCustomerId = jobRecord?.customerId;

    return customers.find((customer) => customer.customerId === selectedCustomerId) || {};
  };

  const getDefaultJobId = () => {
    return location.state?.jobRecord?.jobId || '';
  };

  const getDefaultJobDetails = () => {
    return location.state || {};
  };

  const [facilityOptions, setFacilityOptions] = useState<any[]>([]);
  const [serviceOptions, setServiceOptions] = useState<IServiceCategoryItem[]>([]);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [facilities, setFacilities] = useState<IFacilitiesItem[]>(getDefaultFacilitiesList());
  const [selectedFacilities, setSelectedFacilities] = useState<any[]>(getDefaultSelectedFacility());
  const [bidDueDateRange, setBidDueDateRange] = useState(0);

  const [service, setService] = useState<IServiceItem[]>(getDefaultServiceValue());
  const [selectedVendor, setSelectedVendor] = useState('All');
  const [isJobCreationInProgress, setJobCreationProgressStatus] = useState(false);
  const { handleSubmit, control, setError, setValue, getValues, watch } = useForm({
    defaultValues: {
      startDate: getDefaultFormValue(FieldNames.startDate),
      startTime: getDefaultStartTimeValue(),
      endDate: getDefaultFormValue(FieldNames.endDate),
      endTime: getDefaultEndTimeValue(),
      jobName: getDefaultFormValue(FieldNames.jobName),
      instructions: getDefaultInstructionsValue(),
      // bidDueDate: getDefaultFormValue(FieldNames.bidDueDate),
      searchedVendors: getDefaultFormValue(FieldNames.searchedVendors),
    },
  });
  const [charactersLeft, setCharactersLeft] = useState(50);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isFacilityLoading, setFacilityLoading] = useState(false);
  const [isServiceLoading, setIsServiceLoading] = useState<boolean>(false);
  const [isDataSaving, setIsDataSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSelectOpen, setSelectOpen] = useState(new Array(service.length).fill(false));
  const [isStartTimeSelectOpen, setStartTimeSelectOpen] = useState(false);
  const [isEndTimeSelectOpen, setEndTimeSelectOpen] = useState(false);
  const [selectedOption, setExecutionType] = useState('self');
  const [subVendorOption, setSubVendorOption] = useState('estimateWithinPeazy');
  const [isCustomerOpen, setCustomerOpen] = useState(false);
  const [jobAttachments, setJobAttachments] = useState<any[]>(getDefaultJobAttachments());
  const [uploadedFileTags, setUploadedFileTags] = useState<any[]>([]);

  const [previouslyUploadedAttachments, setPreviouslyUploadedAttachments] = useState<
    CreateOrUpdateJobAttachmentInput[]
  >([]);
  const [modifiedPreviousAttachments, setModifiedPreviousAttachments] = useState<string[]>([]);
  const customers: any[] = getDefaultCustomersList();
  const [selectedCustomer, setSelectedCustomer] = useState<any>(getSelectedCustomer());
  const { customerId }: { customerId: string } = getDefaultCustomerId();
  const [jobId, setJobId] = useState<string>(getDefaultJobId());
  const { isJobCreation = true, mediaFileIds = [] } = getDefaultJobDetails();
  const [isSearchVendorsSelected, setSearchVendorsSelected] = useState(false);
  const [searchedVendors, setSearchedVendors] = useState<IVendor[]>([
    { vendorId: '', vendorName: '', index: 0, email: '' },
  ]);
  const [isPriorityJob, setIsPriorityJob] = useState(false);
  const [hoursList, setHoursList] = useState<any[]>([]);
  const [serviceTasks, setServiceTasks] = useState<any>({});

  const watchFields = watch();
  const { role, userId } = getAuthData();

  const handleExecutionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExecutionType(event.target.value);
  };

  const handleSubVendorOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubVendorOption(event.target.value);
    if (event.target.value === 'estimateOutsidePeazy') {
      setSelectedVendor('SearchVendors');
      setSearchedVendors([{ vendorId: '', vendorName: '', index: 0, email: '' }]);
    } else {
      if (isSearchVendorsSelected) {
        setSelectedVendor('SearchVendors');
      } else {
        setSelectedVendor('All');
      }
    }
  };

  const handlePriorityChange = () => {
    if (isPriorityJob) {
      setValue(FieldNames.startDate, null);
      setValue(FieldNames.endDate, null);
    } else {
      const currentDate = new Date();
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + 1);
      setValue(FieldNames.startDate, currentDate.toISOString());
      setValue(FieldNames.endDate, nextDate.toISOString());
    }
    setIsPriorityJob(!isPriorityJob);
  };

  const setJobDates = (startDate: any, jobDueDates: any) => {
    setValue(FieldNames.endDate, jobDueDates?.length ? jobDueDates?.[0]?.dueDate : null);
    setValue(FieldNames.startDate, startDate ? startDate : null);
    if (location?.state?.jobRecord?.jobFrequencies?.length) {
      setValue(FieldNames.startTime, location.state.jobRecord.jobFrequencies?.[0]?.fromTime);
      setValue(FieldNames.endTime, location.state.jobRecord.jobFrequencies?.[0]?.toTime);
    }
  };

  const getServices = async (jobId: string) => {
    const { customerId = '' } = getStoredCustomerDetails() || {};
    const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, jobId);
    if (jobSummaryResponse?.data) {
      if (jobSummaryResponse.data?.jobServices?.length) {
        const selectedServiceMdServiceIds: string[] = [];
        const services = jobSummaryResponse.data?.jobServices?.map((dataItem: any) => {
          const selectedService = serviceOptions.find((serviceItem) => serviceItem.categoryId === dataItem.mdServiceId);
          selectedServiceMdServiceIds.push(dataItem.mdServiceId);
          const selectedTasks = dataItem?.tasks
            ?.map((task: { taskId: string; taskName: string }) => task.taskId)
            .join(', ');
          return {
            service: selectedService?.categoryName,
            id: uuidv4(),
            quantity: dataItem.serviceMeasure,
            unit: dataItem.mdServiceUnits,
            mdServiceId: dataItem.mdServiceId,
            tasks: selectedTasks ? selectedTasks : '',
            tasksError: '',
          };
        });
        localStorage.setItem('jobServices', JSON.stringify(services));
        setService([...services]);
        setSelectedServices([...selectedServiceMdServiceIds]);
      }
      const { jobType, startDate, jobDueDates } = jobSummaryResponse?.data || {};
      if (jobType === JobType.OneTime) {
        setJobDates(startDate, jobDueDates);
      }
    } else {
      console.error('jobsummaryV2 query : ', jobSummaryResponse.errors);
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchRecords = async () => {
    const {
      jobName,
      facilityId,
      executionType,
      startDate,
      stopDate,
      estimationType,
      jobId,
      jobDetails,
      customerId,
      jobServices,
      jobAttachments,
    } = location.state?.jobRecord || {};
    const updatedFacilityId = facilityId ?? '';
    if (updatedFacilityId) {
      await handleCustomerChange(customerId);
      const selectedFacility: ISelectedFacility = facilityOptions.find(
        (facilityItem) => facilityItem?.facilityId === updatedFacilityId
      );
      const modifiedSelectedFacility = [
        {
          facilityName:
            role === UserType.Customer
              ? selectedFacility?.buildingName
              : `${selectedFacility?.jobNumber} - ${selectedFacility?.jobDescription}`,
          id: updatedFacilityId,
          facilityId: updatedFacilityId,
          mdFacilityType: selectedFacility?.mdFacilityType ?? '',
          facilityError: '',
          facilityAddress: `${selectedFacility?.addressName || ''} | ${joinByComma([
            selectedFacility?.addressLine1,
            selectedFacility?.addressLine2,
            selectedFacility?.addressLine3,
            selectedFacility?.city,
            selectedFacility?.county,
            selectedFacility?.postalCode,
          ])}`,
        },
      ];
      setFacilities(modifiedSelectedFacility);
      setSelectedFacilities(modifiedSelectedFacility);
      const selectedService = serviceOptions?.find(
        (serviceItem) => serviceItem.categoryId === jobServices?.[0]?.mdServiceId
      );
      setService([
        {
          service: selectedService?.categoryId ?? '',
          id: selectedService?.categoryId ?? '0',
          quantity: jobServices?.[0]?.serviceMeasure,
          unit: selectedService?.unit ?? '',
          mdServiceId: selectedService?.categoryId ?? '',
          nameError: '',
          asError: '',
          name: selectedService?.categoryName ?? '',
          tasks: jobServices?.[0]?.tasks ?? '',
          tasksError: '',
        },
      ]);
      setExecutionType(executionType);
      setSubVendorOption(estimationType);
      await fetchData();
      await getServices(jobId);
      const attachments: any[] = [];
      await Promise.allSettled(
        jobAttachments.map(async (details: any) => {
          const attachmentUrl = await fetchDataFromS3Bucket(navigate, details?.url ?? '');
          attachments.push({
            ...details,
            url: attachmentUrl,
            id: details.attachmentId,
            attachmentId: details.attachmentId,
            responseUrl: details.url,
          });
        })
      );
      setJobAttachments(attachments);
      // setUploadedFileTags(jobDetails.attachments.tags);
    }

    setValue(FieldNames.jobName, jobName && jobName !== 'untitled' ? jobName : '');
    setValue(FieldNames.startDate, startDate);
    setValue(FieldNames.endDate, stopDate);
    setValue(FieldNames.startTime, jobDetails?.startTime);
    setValue(FieldNames.endTime, jobDetails?.endTime);
  };

  // const handleAddFacilities = () => {
  //   const clonedFacilities = [...facilities];
  //   const prevIndex = facilities?.length - 1;
  //   const { facilityName } = clonedFacilities[prevIndex];
  //   if (facilityName === '') {
  //     clonedFacilities[prevIndex].facilityError = 'Facility name required';
  //     setFacilities(clonedFacilities);
  //   } else {
  //     setFacilities([
  //       ...facilities,
  //       { facilityName: '', id: uuidv4(), facilityId: '', mdFacilityType: '', facilityError: '', facilityAddress: '' },
  //     ]);
  //   }
  // };

  const handleSelectedFacilities = (value: string, prevSelectedFacility: any, selectedFacility: ISelectedFacility) => {
    setSelectedFacilities([selectedFacility]);
  };

  const handleFacilitiesSelectChange = useCallback(
    (selectedFacilitiesId: string, value: string, prevSelectedFacility: any) => {
      const selectedFacility: ISelectedFacility = facilityOptions.find(
        (facilityItem) => facilityItem?.facilityId === value
      );
      const modifiedFacilitiesOptions = facilities?.map((facilitiesItem) => {
        if (facilitiesItem?.id === selectedFacilitiesId) {
          let facilityName = '';
          if (role === UserType.Customer) {
            facilityName = selectedFacility?.buildingName;
          } else {
            facilityName = `${selectedFacility?.jobNumber} - ${selectedFacility?.jobDescription}`;
          }
          facilitiesItem.facilityName = facilityName ?? '';
          facilitiesItem.facilityId = value;
          facilitiesItem.mdFacilityType = selectedFacility?.mdFacilityType ?? '';
          facilitiesItem.facilityError = '';
          facilitiesItem.facilityAddress = `${selectedFacility?.addressName || ''} | ${joinByComma([
            selectedFacility?.addressLine1,
            selectedFacility?.addressLine2,
            selectedFacility?.addressLine3,
            selectedFacility?.city,
            selectedFacility?.county,
            selectedFacility?.postalCode,
          ])}`;
        }
        return facilitiesItem;
      });
      //if (!selectedFacilities.includes(value)) {
      handleSelectedFacilities(value, prevSelectedFacility, selectedFacility);
      //}
      localStorage.setItem('selectedFacility', JSON.stringify(modifiedFacilitiesOptions));
      setFacilities(modifiedFacilitiesOptions);
    },
    [facilities, facilityOptions]
  );

  const handleDeleteFacility = useCallback(
    (selectedFacility: any) => {
      const updatedFacilities = facilities.filter((facilityItem) => facilityItem.id !== selectedFacility.id);
      setFacilities(updatedFacilities);

      const filterFacilities = selectedFacilities.filter((item) => item.facilityId !== selectedFacility?.facilityId);
      setSelectedFacilities([...filterFacilities]);
    },
    [facilities]
  );

  const handleAddService = () => {
    const clonedServices = [...service];
    const prevIndex = service?.length - 1;
    const { service: serviceName } = clonedServices[prevIndex];
    const { tasks: selectedTasks } = clonedServices[prevIndex];
    if (serviceName === '') {
      if (serviceName === '') {
        clonedServices[prevIndex].nameError = 'Service is required';
        localStorage.setItem('jobServices', JSON.stringify(clonedServices));
        setService(clonedServices);
      }
    } else if (selectedTasks === '') {
      clonedServices[prevIndex].tasksError = 'Please select atleast one task';
      localStorage.setItem('jobServices', JSON.stringify(clonedServices));
      setService(clonedServices);
    } else {
      const newService = {
        service: '',
        id: uuidv4(),
        quantity: '',
        unit: '',
        mdServiceId: '',
        nameError: '',
        asError: '',
        name: '',
        tasks: '',
        tasksError: '',
      };
      localStorage.setItem('jobServices', JSON.stringify([...service, { ...newService }]));
      setService([...service, { ...newService }]);
    }
  };

  const handleDeleteService = useCallback(
    (serviceItem: any) => {
      const updatedServices = service.filter((optionItem) => optionItem.id !== serviceItem.id);
      localStorage.setItem('jobServices', JSON.stringify(updatedServices));
      setService(updatedServices);

      const filterServices = selectedServices.filter((id) => id !== serviceItem.mdServiceId);
      setSelectedServices([...filterServices]);
    },
    [service]
  );

  const handleServiceAreaChange = useCallback(
    (selectedServiceId: string, value: string) => {
      const modifiedServiceOptions = service.map((serviceItem) => {
        if (serviceItem.id === selectedServiceId) {
          if (/^\d+$/.test(value) || value === '') {
            serviceItem.quantity = value;
            serviceItem.asError = '';
          } else {
            serviceItem.asError = 'Please enter a valid number.';
          }
        }
        return serviceItem;
      });
      localStorage.setItem('jobServices', JSON.stringify(modifiedServiceOptions));
      setService(modifiedServiceOptions);
    },
    [service]
  );

  const handleServiceSelectChange = useCallback(
    (selectedServiceId: string, value: string, prevSelectedService: any) => {
      const selectedService = serviceOptions.find((serviceItem) => serviceItem.categoryId === value);
      const modifiedServiceOptions = service.map((serviceItem) => {
        if (serviceItem.id === selectedServiceId) {
          serviceItem.service = value ?? '';
          serviceItem.mdServiceId = selectedService?.categoryId ?? '';
          serviceItem.unit = selectedService?.unit ?? '';
          serviceItem.nameError = '';
          serviceItem.name = selectedService?.categoryName;
        }
        return serviceItem;
      });

      if (!selectedServices.includes(selectedService?.categoryId)) {
        setSelectedServices((prevState) => {
          if (!prevState.includes(selectedService?.categoryId)) {
            const filterNonEmptyService = service.filter((item) => item.service !== '');
            if (selectedServices?.length >= filterNonEmptyService?.length) {
              const filterPrevState = [...prevState].filter((serviceId) => serviceId !== prevSelectedService);
              return [...filterPrevState, selectedService?.categoryId];
            }
            return [...prevState, selectedService?.categoryId];
          }
          return [];
        });
      }
      localStorage.setItem('jobServices', JSON.stringify(modifiedServiceOptions));
      setService(modifiedServiceOptions);
    },
    [service, serviceOptions]
  );

  const handleVendorDetailsCheckboxChange = (e: any) => {
    setSelectedVendor(e.target.value);
    setSearchVendorsSelected(e.target.value === 'SearchVendors');
  };

  const fetchData = useCallback(async () => {
    try {
      const { customerId = '' } = getStoredCustomerDetails() || {};
      setFacilityLoading(true);
      setIsServiceLoading(true);
      const loggedInUser = await MasterDataApiService.getCurrentUserInfo();
      const userType = loggedInUser?.attributes?.['custom:Role'];

      if (userType === UserType.Customer) {
        const allFacilitiesResponse = await FacilityApiService.getAllFacilities(customerId);
        if (allFacilitiesResponse?.data) {
          setFacilityOptions(allFacilitiesResponse?.data);
        } else {
          setFacilityOptions([]);
        }
      }
      setFacilityLoading(false);
      let selectedCustomerId = customerId;
      if (role === UserType.Vendor) {
        selectedCustomerId = selectedCustomer.customerId ?? '';
      }
      const allServiceCategoryV2Response = await MasterDataApiService.getAllServiceCategoryV3(selectedCustomerId);
      if (allServiceCategoryV2Response.data) {
        const modifiedCategories = allServiceCategoryV2Response.data.map((categoryItem: any) => {
          return {
            categoryId: categoryItem.serviceCategoryId,
            categoryName: categoryItem.serviceCategoryName,
            unit: categoryItem.unit,
          };
        });
        const uniqueCategories: IServiceCategoryItem[] = [
          ...new Map(modifiedCategories.map((item: any) => [item.categoryId, item])).values(),
        ] as IServiceCategoryItem[];
        uniqueCategories.sort((a: any, b: any) => a.categoryName.localeCompare(b.categoryName));
        if (role === UserType.Customer) {
          setServiceOptions(uniqueCategories);
          fetchTaskDetails(allServiceCategoryV2Response);
        }
      } else {
        setServiceOptions([]);
      }

      setIsServiceLoading(false);
    } catch (e) {
      console.log('error', e);
    }
  }, []);

  const fetchTaskDetails = (allServiceCategoryV2Response: any) => {
    const uniqueTaskData = allServiceCategoryV2Response.data.reduce((acc: any, task: any) => {
      const { serviceCategoryId } = task;
      if (!acc[serviceCategoryId]) {
        acc[serviceCategoryId] = [];
      }
      acc[serviceCategoryId].push(task);
      return acc;
    }, {});
    setServiceTasks(uniqueTaskData);
  };

  const handleSelectedTasks = (selectedTasks: string[], selectedServiceId: string) => {
    const modifiedServiceOptions = service.map((serviceItem: IServiceItem) => {
      if (serviceItem.mdServiceId === selectedServiceId) {
        serviceItem.tasks = isEmpty(selectedTasks) ? '' : selectedTasks.join(', ');
        serviceItem.tasksError = '';
      }
      return serviceItem;
    });
    localStorage.setItem('jobServices', JSON.stringify(modifiedServiceOptions));
    setService(modifiedServiceOptions);
  };

  const updateFrequency = useCallback(
    async (customerId: string, jobId: string) => {
      const servicesPayload = service.map((serviceItem) => {
        const { quantity, mdServiceId, unit } = serviceItem;
        return {
          serviceMeasure: quantity ? parseFloat(quantity) : 0,
          mdServiceId: mdServiceId,
          mdServiceUnits: unit,
        };
      });
      const payload = {
        mdShiftType: 'Day',
        mdScheduleType: 'Daily',
        mdServiceIds: servicesPayload.map((jobService: any) => jobService.mdServiceId),
        scheduleDays: '',
        fromTime: getValues('startTime'),
        toTime: getValues('endTime'),
      };
      return await JobCreateApiService.addJobFrequency(customerId, jobId, [payload]);
    },
    [service]
  );

  const updateServices = useCallback(
    async (customerId: string, jobId: string) => {
      const servicesPayload = service.map((serviceItem) => {
        const { quantity, mdServiceId, unit, tasks } = serviceItem;
        const tasksforSelectedService = serviceTasks[mdServiceId];
        const selectedTaskIds = tasks.split(', ');
        const selectedTasksForService = tasksforSelectedService?.filter((task: any) =>
          selectedTaskIds.includes(task.taskId)
        );
        const formattedTasks = selectedTasksForService?.map((task: any) => ({
          taskId: task.taskId,
          taskName: task.taskName,
        }));
        return {
          serviceMeasure: quantity ? parseFloat(quantity) : 0,
          mdServiceId: mdServiceId,
          mdServiceUnits: unit,
          actualServiceMeasure: unit,
          actualMdServiceUnits: quantity ? parseFloat(quantity) : 0,
          tasks: formattedTasks,
        };
      });
      return await JobCreateApiService.addJobServices(customerId, jobId, servicesPayload);
    },
    [service]
  );

  const updateFacilityServiceSpecification = useCallback(
    async (customerId: string, facilityId: string) => {
      const servicesPayload = service.map((serviceItem) => {
        const { quantity, unit } = serviceItem;

        return {
          floorNumber: '1',
          mdServiceId: serviceItem.mdServiceId,
          serviceQuantity: quantity ? parseFloat(quantity) : 0,
          mdServiceUnitType: unit,
        };
      });
      return await FacilityApiService.addFacilityServiceSpecification(customerId, facilityId, servicesPayload);
    },
    [service]
  );

  const updateFacilities = useCallback(
    async (customerId: string, jobId: string) => {
      const facilitiesPayload = await Promise.all(
        facilities.map(async (facilityItem) => {
          const facilityResponse = await FacilityApiService.facility(facilityItem?.facilityId, customerId);
          return {
            facilityId: facilityItem?.facilityId,
            mdFacilityType: facilityItem.mdFacilityType,
            facilityAddress: facilityItem.facilityAddress,
            latitude: facilityResponse.data.address.latitude,
            longitude: facilityResponse.data.address.longitude,
          };
        })
      );
      return await JobCreateApiService.addJobFacility(customerId, jobId, facilitiesPayload);
    },
    [facilities]
  );

  const resetJobData = () => {
    setValue(FieldNames.endDate, null);
    setValue(FieldNames.startDate, null);
    setValue(FieldNames.startTime, '');
    setValue(FieldNames.endTime, '');
    setValue(FieldNames.jobName, '');
    setValue(FieldNames.instructions, '');
    setService([
      {
        service: '',
        id: uuidv4(),
        quantity: '',
        unit: '',
        mdServiceId: '',
        nameError: '',
        asError: '',
        name: '',
        tasks: '',
        tasksError: '',
      },
    ]);
    setSelectedServices([]);
    setFacilities([
      {
        facilityName: '',
        id: uuidv4(),
        facilityId: '',
        mdFacilityType: '',
        facilityError: '',
        facilityAddress: '',
      },
    ]);
    setSelectedCustomer({});
    setServiceOptions([]);
  };

  const getCustomerSelectedVendorsPayload = (vendors: IVendor[]) => {
    if (selectedVendor === 'All') {
      return vendors?.map(() => ({
        mdVendorType: 'All',
        vendorId: '',
      }));
    } else {
      return vendors?.map((vendorItem: IVendor) => ({
        mdVendorType: 'SearchVendors',
        vendorId: vendorItem.vendorId,
      }));
    }
  };

  const getVendorSelectedVendorsPayload = (vendors: IVendor[]) => {
    if (selectedOption === 'subVendor') {
      if (selectedVendor === 'All') {
        return [{ vendorId: '', mdVendorType: selectedVendor }];
      } else if (selectedVendor === 'SearchVendors') {
        let vendorType: string;
        if (subVendorOption === 'estimateOutsidePeazy') {
          vendorType = 'SubVendor';
        } else {
          vendorType = 'SearchVendors';
        }
        return vendors.map((vendorItem: IVendor) => ({
          mdVendorType: vendorType,
          vendorId: vendorItem.vendorId,
        }));
      }
    } else {
      return vendors.map((vendorItem: IVendor) => ({
        mdVendorType: 'SelfPerform',
        vendorId: vendorItem.vendorId,
      }));
    }
  };

  const updateVendors = useCallback(
    async (customerId: string, jobId: string, vendors: IVendor[]) => {
      let vendorsPayload: any = [];
      if (role === UserType.Customer) {
        vendorsPayload = getCustomerSelectedVendorsPayload(vendors);
      } else {
        vendorsPayload = getVendorSelectedVendorsPayload(vendors);
      }
      return await JobCreateApiService.addJobVendors(customerId, jobId, vendorsPayload);
    },
    [selectedVendor, selectedOption]
  );

  const handleCreateJob = async (startDate: string, endDate: string) => {
    try {
      setJobCreationProgressStatus(true);
      const customerDetails = await Promise.resolve(getStoredCustomerDetails());
      let customerId = '';
      if (role === UserType.Customer) {
        ({ customerId } = customerDetails || {});
      } else {
        customerId = selectedCustomer.customerId ?? '';
      }
      let subJobId;

      const jobId = await getMainJobId(startDate, endDate, customerId);

      const { updateServiceFrequencyResponse, updateFacilityResponse, updateServiceResponse, ...rest } =
        await handleJobCreation(startDate, endDate, jobId, customerId);
      subJobId = rest.subJobId;
      const updateAttachmentsResponse = await saveAttachments(customerId, jobId);
      await handleJobDueDates(customerId, jobId, startDate);
      await handleJobUpdateVendors(customerId, jobId, customerDetails);
      const updateJobResponse = await JobCreateApiService.updateJob(customerId, jobId, {
        draftStatus: DraftStatus.C,
        draftStep: DraftStep.JobSummary,
        specialInstructions: getValues('instructions'),
        startDate: startDate ?? null,
        stopDate: endDate ?? null,
      });
      if (selectedOption === 'subVendor' && subVendorOption === 'estimateWithinPeazy') {
        subJobId = await handleSubContractedJob(customerDetails, jobId);
      }
      const updateJobBidResponse = await handleOPenJobForBid(customerId, jobId, subJobId);
      setJobCreationProgressStatus(false);
      return updateFacilityResponse.data &&
        updateServiceResponse.data &&
        updateServiceFrequencyResponse.data &&
        updateJobResponse.data &&
        updateJobBidResponse.data &&
        (!updateAttachmentsResponse || updateAttachmentsResponse.data)
        ? true
        : false;
    } catch (error) {
      setJobCreationProgressStatus(false);
      console.log(error);
      return false;
    }
  };

  const getMainJobId = async (startDate: string, endDate: string, customerId: string) => {
    const jobsInput: CreateJobInput = {
      jobName: getValues('jobName') || 'untitled',
      jobType: !isPriorityJob ? JobType.OneTime : JobType.Emergency,
      startDate: startDate ?? null,
      stopDate: endDate ?? null,
      intReferenceId: selectedFacilities[0]?.jobNumber ?? '',
    };
    const currentJob = localStorage.getItem(AsyncStorageKeys.currentJob);
    if (currentJob) {
      const parsedJob = JSON.parse(currentJob);
      return parsedJob.jobId;
    } else {
      const createJobResponse = await createNewJob(customerId, jobsInput);
      return createJobResponse.data?.jobId;
    }
  };

  // const getServiceCategories = async (customerId: string) => {
  //   try {
  //     const masterData = await MasterDataApiService.getAllServiceCategoryV3(customerId);
  //     return masterData.data ?? [];
  //   } catch (e) {
  //     console.log(e);
  //     return [];
  //   }
  // };

  const handleJobCreation = async (startDate: string, endDate: string, jobId: string, customerId: string) => {
    const updatedJob: any = {};
    let modifiedServices: any = [];
    let updateServiceFrequencyResponse = { data: null };
    let updateFacilityResponse = { data: null };
    let updateServiceResponse = { data: null };
    // let updateFacilityServiceSpecificationResponse = { data: null };
    let subJobId: string = '';
    // const serviceCategories = await getServiceCategories(customerId);
    // const serviceSpecificationServicePayload: CreateFacilityServiceSpecificationInput[] = (selectedServices || []).map(
    //   (bidEstimationItem: any, index: number) => {
    //     const selectedService: any = serviceCategories.find(
    //       (serviceCategoryItem: ServiceCategoryV3) => serviceCategoryItem?.serviceCategoryId === bidEstimationItem
    //     );
    //     return {
    //       mdServiceId: bidEstimationItem?.mdServiceId,
    //       serviceQuantity: Number(bidEstimationItem.value),
    //       mdServiceUnitType: bidEstimationItem.unit,
    //     };
    //   }
    // );

    if (role === UserType.Vendor) {
      const facilityResponse = await FacilityApiService.facility(selectedFacilities[0]?.facilityId, customerId);
      const servicesSelected = service.filter((serviceOptionItem) =>
        selectedServices.includes(serviceOptionItem.mdServiceId)
      );
      const selectedJobId = location.state?.jobRecord ? location.state?.jobRecord.jobId : jobId;
      modifiedServices = servicesSelected.map((serviceItem) => {
        return {
          jobId: selectedJobId,
          mdServiceId: serviceItem.mdServiceId,
          serviceMeasure: serviceItem.unit, //serviceItem.quantity,
          mdServiceUnits: serviceItem.quantity, //serviceItem.unit,
          name: serviceItem.name,
        };
      });

      updatedJob.executionType = selectedOption;
      updatedJob.estimationType = subVendorOption;
      updatedJob.jobId = jobId;
      updatedJob.jobNumber = selectedFacilities[0].jobNumber;
      updatedJob.location = facilityResponse?.data?.address?.addressName;
      updatedJob.createdOn = moment();
      const area = getTotalServiceArea(modifiedServices);
      updatedJob.area = String(area) + ' sqft';
      updatedJob.duration = getServiceDuration(startDate, endDate);
      updatedJob.volume = getServiceVolume(area);
      updatedJob.noOfDaysLeft = getNumberOfDaysLeft([]);
      updatedJob.percentageMatch = String(100) + '%';
      updatedJob.jobName = getValues('jobName');
      updatedJob.address = facilityResponse?.data?.address ?? '';
      updatedJob.customerId = customerId;
      updatedJob.startDate = startDate;
      updatedJob.stopDate = endDate;
      updatedJob.startTime = getValues('startTime');
      updatedJob.endTime = getValues('endTime');
      updatedJob.lastActivity = null;
      updatedJob.jobFacilities = [{ facilityId: selectedFacilities[0].facilityId }];
      updatedJob.jobServices = modifiedServices;
      updatedJob.revisionRequests = [];
      updatedJob.jobAttachments = jobAttachments;

      if (selectedOption === 'subVendor') {
        updatedJob.specialInstructions = getValues('instructions');
      }
      // order of these calls is important please don't change it updateServices should be called before updateFrequency or else updateFrequency will fail
      updateFacilityResponse = await updateFacilities(customerId, jobId);
      updateServiceResponse = await updateServices(customerId, jobId);
      updateServiceFrequencyResponse = await updateFrequency(customerId, jobId);

      if (selectedOption === 'subVendor' && subVendorOption === 'estimateOutsidePeazy') {
        const subJobResponse = await JobCreateApiService.createSubJob({
          parentJobId: jobId,
        });
        if (subJobResponse.data) {
          // order of these calls is important please don't change it updateServices should be called before updateFrequency or else updateFrequency will fail
          await updateServices(customerId, subJobResponse.data?.jobId);
          await updateVendors(customerId, subJobResponse.data?.jobId, searchedVendors);
          updatedJob.subJobId = subJobResponse.data?.jobId;
          subJobId = subJobResponse.data?.jobId;
        }
        updatedJob.vendorDetails = {
          instructions: getValues('instructions'),
          vendorId: searchedVendors,
        };
        updatedJob.subJobServices = modifiedServices;
      }
      localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify(updatedJob));
    }

    if (role === UserType.Customer) {
      // order of these calls is important please don't change it updateServices should be called before updateFrequency or else updateFrequency will fail
      updateFacilityResponse = await updateFacilities(customerId, jobId);
      updateServiceResponse = await updateServices(customerId, jobId);
      await updateFacilityServiceSpecification(customerId, selectedFacilities[0].facilityId);

      updateServiceFrequencyResponse = await updateFrequency(customerId, jobId);
    }
    return { updateServiceFrequencyResponse, updateFacilityResponse, updateServiceResponse, subJobId };
  };

  const handleSubContractedJob = async (customerDetails: any, jobId: string) => {
    // let subJobId: string;
    const subJobResponse = await JobCreateApiService.createSubJob({
      parentJobId: jobId,
    });
    if (subJobResponse.data) {
      // subJobId = subJobResponse.data?.jobId;
    }
    await CreateBidServices.addJobBid(jobId, customerDetails?.customerId, {
      bidCreatedFromSample: '',
      bidCreationDate: '',
      bidEstimationType: 'Easy',
      dataStatus: BidStatus.A,
      bidVersionCreatedDate: '',
      submittedBy: '',
      bidTemplate: '',
      bidVersion: 0,
      bidSubmissionDate: '',
      bidName: getValues('jobName') ?? '',
      finalQuote: 0,
      profitMargin: 0,
      totalCost: 0,
      isFlatMargin: YesNoType.N,
    });
    return subJobResponse.data?.jobId;
  };
  const handleJobDueDates = async (customerId: string, jobId: string, startDate: string) => {
    await JobCreateApiService.addJobDueDates(customerId, jobId, getDueDates(startDate));
  };

  const handleJobUpdateVendors = async (customerId: string, jobId: string, customerDetails: any) => {
    const vendorsList =
      subVendorOption === 'estimateWithinPeazy' && selectedVendor === 'SearchVendors' && searchedVendors[0].vendorId
        ? searchedVendors
        : [
            {
              index: 1,
              vendorId: customerDetails.customerId,
              vendorName: customerDetails.organizationName,
              email: '',
            },
          ];

    await updateVendors(customerId, jobId, vendorsList);
  };

  const handleOPenJobForBid = async (customerId: string, jobId: string, subJobId: string) => {
    let openJobForBidPayload;
    if (role === UserType.Customer) {
      const { name, email, phone_number } = getAuthDetails();
      openJobForBidPayload = {
        customerEmail: email ?? phone_number,
        customerName: name,
      };
    } else {
      openJobForBidPayload = {
        // This is a temporary fix, as discussed with Shivansh as we aren't getting email from backend and email is a mandatory field
        customerEmail: selectedCustomer.email.length ? selectedCustomer.email : '',
        customerName: selectedCustomer.legalName,
      };
    }

    const updateJobBidResponse = await JobCreateApiService.openJobForBid(customerId, jobId, openJobForBidPayload);
    if (
      updateJobBidResponse.data &&
      updateJobBidResponse.errors.length === 0 &&
      selectedOption === 'subVendor' &&
      subJobId
    ) {
      await JobCreateApiService.openJobForBid(
        searchedVendors[0].vendorId ? searchedVendors[0].vendorId : customerId,
        subJobId,
        openJobForBidPayload
      );
    }
    return updateJobBidResponse;
  };

  const navigate = useNavigate();

  const createNewJob = async (customerId: string, jobsInput: CreateJobInput) => {
    return await JobCreateApiService.createJob(customerId, jobsInput);
  };

  const getDueDates = useCallback(
    (startDate: any) => {
      const dueDates = [];
      if (isPriorityJob || startDate !== null) {
        dueDates.push({
          mdDueType: MdDueType.Bid,
          dueDescription: '',
          dueDate: startDate,
        });
        dueDates.push({
          mdDueType: MdDueType.Question,
          dueDescription: '',
          dueDate: startDate,
        });
        dueDates.push({
          mdDueType: MdDueType.Approval,
          dueDescription: '',
          dueDate: startDate,
        });
      }
      return dueDates;
    },
    [isPriorityJob]
  );

  const getUpdatedStartDate = (details: any) => {
    const { startDate, endDate } = details;
    let updatedstartDate;
    if (startDate) {
      updatedstartDate = startDate;
      if (endDate && !isDateSameOrAfter(updatedstartDate, endDate)) {
        setError(FieldNames.endDate, { type: 'validate', message: 'End date cannot be before start date' });
        const endDate = document.getElementById('endDate');
        endDate?.scrollIntoView();
        return { isValid: false, endDate: '' };
      }
      return { isValid: true, startDate: updatedstartDate };
    }
    return { isValid: true, startDate: '' };
  };

  // const getJobDates = (details: any) => {
  //   const { specificDate } = details;
  //   let startDate, stopDate;
  //   if (!isPriorityJob) {
  //     startDate = specificDate ? specificDate : null;
  //     stopDate = specificDate ? moment(specificDate).add(1, 'day').toISOString() : null;
  //   } else {
  //     startDate = moment().toISOString();
  //     stopDate = moment().add(1, 'day').toISOString();
  //   }
  //   return { startDate, stopDate };
  // };

  const updateJobDetails = useCallback(
    async (details: any, customerId: string, jobId: string) => {
      const { endDate, startDate, jobName, instructions } = details;
      const payload: any = {
        jobName,
        specialInstructions: instructions,
        jobType: isPriorityJob ? JobType.Emergency : JobType.OneTime,
      };
      // payload.startDate = dates.startDate ?? null;
      // payload.stopDate = dates.stopDate ?? null;
      payload.startDate = startDate ?? null;
      payload.stopDate = endDate ?? null;
      const updateJobResponse = await JobCreateApiService.updateJob(customerId, jobId, payload);
      const addJobDueDatesResponse = await JobCreateApiService.addJobDueDates(
        customerId,
        jobId,
        getDueDates(startDate)
      );
      if (updateJobResponse.data) {
        return addJobDueDatesResponse;
      }
      return updateJobResponse;
    },
    [isPriorityJob]
  );

  const createDraft = async (customerId: string, jobId: string, status: any, draftStep: any) => {
    return await JobCreateApiService.updateJob(customerId, jobId, {
      draftStatus: status,
      draftStep: draftStep,
    });
  };

  const getDraftUpdateStatus = useCallback(
    async (details: any, customerId: string, jobId: string) => {
      const { endDate, startDate } = details;
      const updatedJobResponse = await updateJobDetails(details, customerId, jobId);
      const updatedFacilityResponse = await updateFacilities(customerId, jobId);
      const updatedServiceResponse = await updateServices(customerId, jobId);
      if (getValues(FieldNames.startTime) || getValues(FieldNames.endTime)) await updateFrequency(customerId, jobId);
      let updatedAttachmentsResponse;
      if (jobAttachments.length) {
        updatedAttachmentsResponse = await saveAttachments(customerId, jobId);
      }
      const [draftJobResponse, draftFacilityResponse, draftJServiceResponse, draftAttachmentsResponse] =
        await handleCreateDraft(
          updatedJobResponse,
          updatedFacilityResponse,
          updatedServiceResponse,
          updatedAttachmentsResponse,
          startDate,
          endDate,
          jobId
        );

      return !!(
        draftJobResponse?.data ||
        draftFacilityResponse?.data ||
        draftJServiceResponse?.data ||
        (draftAttachmentsResponse && draftAttachmentsResponse?.data)
      );
    },
    [service, isPriorityJob, jobAttachments]
  );

  const getDraftJobResponse = async (startDate: string, endDate: string, jobId: string) => {
    if ((endDate && startDate) || isPriorityJob) {
      return await createDraft(customerId, jobId, DraftStatus.Y, DraftStep.DurationAndDetails);
    } else {
      return await createDraft(customerId, jobId, DraftStatus.Y, DraftStep.Emergency);
    }
  };

  const handleCreateDraft = async (
    updatedJobResponse: any,
    updatedFacilityResponse: any,
    updatedServiceResponse: any,
    updatedAttachmentsResponse: any,
    startDate: string,
    endDate: string,
    jobId: string
  ) => {
    let draftJobResponse, draftFacilityResponse, draftJServiceResponse, draftAttachmentsResponse;
    if (updatedJobResponse?.data || updatedJobResponse?.errors?.length === 0) {
      draftJobResponse = await getDraftJobResponse(startDate, endDate, jobId);
    } else {
      if (updatedFacilityResponse?.data || updatedFacilityResponse.errors?.length === 0) {
        draftFacilityResponse = await createDraft(customerId, jobId, DraftStatus.C, DraftStep.SelectFacility);
      } else if (updatedServiceResponse?.data || updatedServiceResponse.errors?.length === 0) {
        draftJServiceResponse = await createDraft(customerId, jobId, DraftStatus.C, DraftStep.AddServices);
      } else console.error('updatedServiceResponse  : ', updatedServiceResponse?.errors);
    }
    if (
      updatedFacilityResponse &&
      (updatedAttachmentsResponse?.data || updatedAttachmentsResponse?.errors?.length === 0)
    ) {
      draftAttachmentsResponse = await createDraft(customerId, jobId, DraftStatus.C, DraftStep.ServicePhotosAndVideos);
    }
    return [draftJobResponse, draftFacilityResponse, draftJServiceResponse, draftAttachmentsResponse];
  };

  const getJobId = async (customerId: string) => {
    let updatedJobId = '';
    const storedJob = localStorage.getItem(AsyncStorageKeys.currentJob);
    if (location.state?.jobRecord) {
      const { jobId } = location.state?.jobRecord || {};
      updatedJobId = jobId;
    } else if (storedJob) {
      const parsedJob = JSON.parse(storedJob);
      updatedJobId = parsedJob.jobId;
    } else {
      const jobDetails = await createNewJob(customerId, {
        jobType: !isPriorityJob ? JobType.OneTime : JobType.Emergency,
        intReferenceId: selectedFacilities[0].jobNumber,
      });
      if (jobDetails?.data) {
        updatedJobId = jobDetails?.data?.jobId;
        localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify({ jobId: updatedJobId }));
      } else {
        console.error('addjob mutation error : ', jobDetails.errors);
      }
    }
    return updatedJobId;
  };

  const handleAttachmentDetails = (data: any) => {
    localStorage.setItem('jobAttachments', JSON.stringify(data));
    setJobAttachments(data);
    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    });
  };

  const handleUploadedFileTags = (data: any) => {
    const modifiedSelectedTags = [...uploadedFileTags];
    const foundIndex = modifiedSelectedTags.findIndex((item) => item.id === data.id);
    if (foundIndex !== -1) {
      // If the object is found, update its 'tags' property and filter out duplicates
      modifiedSelectedTags[foundIndex].tags = [
        ...new Set([...(modifiedSelectedTags[foundIndex].tags ?? []), ...(data.tags ?? [])]),
      ];
    } else {
      // If not found, add a new object to the array with filtered values
      const filteredTags = [...new Set(data.tags ?? [])];
      modifiedSelectedTags.push({
        id: data.id,
        tags: filteredTags,
      });
    }
    if (!isEmpty(data?.id)) {
      setModifiedPreviousAttachments((previousIds: string[]) => {
        const updatedAttachmentIds = [...previousIds];
        if (!updatedAttachmentIds.includes(data?.id)) {
          updatedAttachmentIds.push(data?.id);
        }
        return updatedAttachmentIds;
      });
    }
    setUploadedFileTags([...modifiedSelectedTags]);
  };

  const handleInstructionsChange = (id: string, comments: string) => {
    let modifiedAttachmentId: any = '';
    const updatedInstructions = jobAttachments.map((item: IDetailsProps) => {
      if (item?.id === id) {
        if (!isEmpty(item?.attachmentId)) {
          modifiedAttachmentId = item?.attachmentId;
        }
        return {
          ...item,
          comments,
        };
      }
      return {
        ...item,
      };
    });
    if (!isEmpty(modifiedAttachmentId)) {
      setModifiedPreviousAttachments((previousIds: string[]) => {
        const updatedAttachmentIds = [...previousIds];
        if (!updatedAttachmentIds.includes(modifiedAttachmentId)) {
          updatedAttachmentIds.push(modifiedAttachmentId);
        }
        return updatedAttachmentIds;
      });
    }
    localStorage.setItem('jobAttachments', JSON.stringify(updatedInstructions));
    setJobAttachments(updatedInstructions);
  };

  const handleDeleteSelectedTag = (id: string, tag: string, item: any) => {
    const modifiedSelectedTags = [...uploadedFileTags];
    const foundIndex = modifiedSelectedTags.findIndex((item) => item.id === id);
    if (foundIndex !== -1) {
      // If the object is found, remove the specified value from its 'values' property
      const valuesArray = modifiedSelectedTags[foundIndex].tags;
      const indexOfValueToRemove = valuesArray.indexOf(tag);
      if (indexOfValueToRemove !== -1) {
        valuesArray.splice(indexOfValueToRemove, 1);
      }
    }
    setUploadedFileTags(modifiedSelectedTags);
    if (!isEmpty(item?.attachmentId)) {
      setModifiedPreviousAttachments((previousIds: string[]) => {
        const updatedAttachmentIds = [...previousIds];
        if (!updatedAttachmentIds.includes(item?.attachmentId)) {
          updatedAttachmentIds.push(item?.attachmentId);
        }
        return updatedAttachmentIds;
      });
    }
  };

  const handleDeleteAttachment = async (index: number) => {
    const item = jobAttachments[index];
    if (!isEmpty(item?.attachmentId)) {
      try {
        const deleteAttachmentResponse = await JobCreateApiService.deleteJobAttachments(jobId, [item?.attachmentId]);
        if (!isEmpty(deleteAttachmentResponse.errors)) {
          console.log('deleteAttachmentResponse.errors', deleteAttachmentResponse.errors);
        } else if (!isEmpty(deleteAttachmentResponse.data)) {
          console.log('deleted successfully!');
          fetchAllJobAttachments();
        }
      } catch (e) {
        console.log('error', e);
      }
      return;
    }
    const filteredDetails = jobAttachments.filter((data) => data.id !== item.id);
    localStorage.setItem('jobAttachments', JSON.stringify(filteredDetails));
    setJobAttachments(filteredDetails);
  };

  // filtering the attachments based on tag id
  const filterAttachments = useCallback(
    (selectedTagDetails: ITagsProps[], updatedDetails: IDetailsProps[]) => {
      const currentServiceTagDetails = selectedTagDetails.filter((dataItem: ITagsProps) =>
        mediaFileIds.includes(dataItem.id)
      );
      const filteredService: IDetailsProps[] = [];
      currentServiceTagDetails.forEach((dataItem: ITagsProps) => {
        updatedDetails.forEach((attachment: IDetailsProps) => {
          if (dataItem.id === attachment.id) {
            filteredService.push(attachment);
          }
        });
      });
      localStorage.setItem('jobAttachments', JSON.stringify(filteredService));
      setJobAttachments(filteredService);
      setUploadedFileTags(currentServiceTagDetails);
    },
    [mediaFileIds]
  );

  const storeAttachmentsToS3Cloud = async (attachments = [], jobId: string, isUpdate = false) => {
    try {
      const fetchAllTags = await JobCreateApiService.getTags(Family.JobAttachments, jobId, customerId);
      const uploadDocumentData = await Promise.all(
        attachments.map(async (data: IDetailsProps) => {
          let storeTagValues = '';
          const storeTags = uploadedFileTags.find((tagDetail) => tagDetail.id === data.id);
          if (storeTags?.tags) {
            const getAllTheTags = storeTags?.tags?.map((tag: string) => {
              return fetchAllTags.data.find((allTag: ITag) => allTag?.value === tag);
            });
            storeTagValues = getAllTheTags
              .map((item: ITag, index: number) => `${item?.type}_${index + 1} = ${item?.value?.replace(/&/g, 'and')}`)
              .join(' & ');
          }
          const attachmentDetails = previouslyUploadedAttachments?.find(
            (attachment) => attachment?.attachmentId === data?.attachmentId
          );
          const uploadDocumentResponse = await uploadDocumentToS3Bucket(
            navigate,
            isUpdate
              ? attachmentDetails?.url
              : `customer/${customerId}/job-attachments/${jobId}/${data.id}${
                  data && data.fileExtension ? `.${data.fileExtension}` : ''
                }`,
            data?.url ?? '',
            {
              level: 'public',
              tagging: storeTagValues,
            },
            data.fileExtension
          );
          return uploadDocumentResponse?.file?.key || '';
        })
      );
      return uploadDocumentData.filter((item) => item !== '');
    } catch (error) {
      console.log('error', error);
      return [];
    }
  };

  const saveAttachments = async (customerId: string, jobId: string) => {
    try {
      let attachmentsResponse: any;
      if (jobAttachments.length) {
        const facilityIds = selectedFacilities[0].facilityId;
        // const fetchAllTags = await JobCreateApiService.getTags(Family.JobAttachments);
        // if (!isEmpty(fetchAllTags?.errors)) {
        //   console.log('fetchAllTags?.errors', fetchAllTags?.errors);
        //   return;
        // }
        const newAttachments: any = jobAttachments?.filter((attachment: IDetailsProps) =>
          isEmpty(attachment.attachmentId)
        );
        if (!isEmpty(modifiedPreviousAttachments)) {
          attachmentsResponse = await handleModifiedAttachments(facilityIds, jobId);
        }
        if (!isEmpty(newAttachments)) {
          attachmentsResponse = await handleNewAttachments(facilityIds, newAttachments, jobId);
        }
      }
      return attachmentsResponse;
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleModifiedAttachments = async (facilityIds: string, jobId: string) => {
    if (!isEmpty(modifiedPreviousAttachments)) {
      const updatedAttachments: any = jobAttachments?.filter((attachment: any) =>
        modifiedPreviousAttachments.includes(attachment?.attachmentId)
      );
      const savedS3BucketKeys = await storeAttachmentsToS3Cloud(updatedAttachments, jobId, true);
      const s3BucketAttachmentKeys = await Promise.all(savedS3BucketKeys);
      if (s3BucketAttachmentKeys.length) {
        const updateAttachmentPayload: CreateOrUpdateJobAttachmentInput[] = updatedAttachments?.map(
          (item: IDetailsProps, index: number) => {
            const finalTag = '';
            const updateAttachment: any = {
              mdAttachmentType: item.mdAttachmentType,
              fileName: item?.fileName + '.' + item?.fileExtension,
              fileExtension: item?.fileExtension,
              url: s3BucketAttachmentKeys[index] ?? '',
              comments: item.comments,
              mdFileContext: 'JobAttachment',
              tags: finalTag ? finalTag : '',
            };
            return updateAttachment;
          }
        );
        const updateAttachmentResponse = await JobCreateApiService.updateJobAttachment(
          jobId,
          facilityIds,
          updateAttachmentPayload
        );

        if (!isEmpty(updateAttachmentResponse.errors)) {
          console.log('updateAttachmentResponse.errors[0]?.message', updateAttachmentResponse.errors[0]?.message);
        }
        return updateAttachmentResponse;
      }
    }
  };

  const handleNewAttachments = async (facilityIds: string, newAttachments: any, jobId: string) => {
    if (!isEmpty(newAttachments)) {
      const savedS3BucketKeys = await storeAttachmentsToS3Cloud(newAttachments, jobId, false);
      const s3BucketAttachmentKeys = await Promise.all(savedS3BucketKeys);
      if (s3BucketAttachmentKeys.length) {
        const createAttachmentPayload: CreateOrUpdateJobAttachmentInput[] = newAttachments?.map(
          (item: IDetailsProps, index: number) => {
            const finalTag = '';
            return {
              mdAttachmentType: item?.mdAttachmentType,
              attachmentId: item?.attachmentId,
              fileName: item?.fileName + '.' + item?.fileExtension,
              fileExtension: item?.fileExtension,
              url: s3BucketAttachmentKeys[index],
              comments: item?.comments,
              mdFileContext: FileContextType.JobAttachment,
              ...(finalTag ? { tags: finalTag } : {}),
            };
          }
        );
        const createAttachmentResponse = await JobCreateApiService.createJobAttachment(
          jobId,
          facilityIds,
          createAttachmentPayload
        );
        if (!isEmpty(createAttachmentResponse.errors)) {
          console.log('createAttachmentResponse.errors', createAttachmentResponse.errors[0].message);
        }
        return createAttachmentResponse;
      }
    } else {
      return {};
    }
  };

  const fetchAllJobAttachments = async () => {
    try {
      // const customerDetails = await Promise.resolve(getStoredCustomerDetails());
      // let customerId;
      // if (role === UserType.Customer) {
      //   ({ customerId } = customerDetails || {});
      // } else {
      //   customerId = selectedCustomer.customerId;
      // }
      const updatedJobId = jobId;
      const allJobAttachmentInput = { jobIds: [updatedJobId] };
      const attachmentResponse = await JobCreateApiService.getAllJobAttachments(allJobAttachmentInput);
      if (isEmpty(attachmentResponse.errors)) {
        const updatedDetails = await Promise.all(
          attachmentResponse.data.map(async (details: IDetailsProps) => {
            const attachmentUrl = await fetchDataFromS3Bucket(navigate, details?.url ?? '');
            return {
              ...details,
              url: attachmentUrl,
              id: details.attachmentId,
              attachmentId: details.attachmentId,
              responseUrl: details.url,
            };
          })
        );
        const selectedTagDetails = updatedDetails.map((item) => {
          return {
            id: item.attachmentId,
            tags: getTagValues(item.tags),
          };
        });
        setPreviouslyUploadedAttachments(attachmentResponse?.data ?? []);
        if (isJobCreation) {
          filterAttachments(selectedTagDetails, updatedDetails);
        } else {
          localStorage.setItem('jobAttachments', JSON.stringify(updatedDetails));
          setJobAttachments(updatedDetails);
          setUploadedFileTags(selectedTagDetails);
        }
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const onSave = async (details: any) => {
    setIsDataSaving(true);
    try {
      const customerDetails = await Promise.resolve(getStoredCustomerDetails());
      let customerId = '';
      if (role === UserType.Customer) {
        ({ customerId } = customerDetails || {});
      } else {
        customerId = selectedCustomer.customerId;
      }
      const updatedJobId = await getJobId(customerId);
      setJobId(updatedJobId);

      const { startDate } = getUpdatedStartDate(details);
      const updatedDetails = { ...details, specificDate: startDate };
      const isSaved = await getDraftUpdateStatus(updatedDetails, customerId, updatedJobId);

      setJobCreationProgressStatus(false);
      setIsDataSaving(false);
      if (isSaved) {
        removeJobLocalData();
        resetJobData();
        navigate(-1);
      }
    } catch (error) {
      setIsDataSaving(false);
      console.error('An error occurred:', error);
    }
  };

  const handleJobTimingsValidations = (startTime: string, endTime: string) => {
    if (startTime || endTime) {
      if (!startTime) {
        setSnackbarMessage(`Start Time Cannot Be Empty`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return false;
      }
      if (!endTime) {
        setSnackbarMessage(`End Time Cannot Be Empty`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return false;
      }
      if (startTime && endTime && startTime === endTime) {
        setSnackbarMessage(`Start Time and End Time cannot be same.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return false;
      }
      // if (startTime && endTime && startTime !== endTime) {
      //   const splitStartTime = startTime.split(':');
      //   const splitEndTime = endTime.split(':');
      //   if (
      //     parseFloat(splitStartTime[0]) > parseFloat(splitEndTime[0]) ||
      //     (parseFloat(splitStartTime[0]) === parseFloat(splitEndTime[0]) && parseFloat(splitStartTime[1]) > parseFloat(splitEndTime[0]))
      //   ) {
      //     setSnackbarMessage(`End Time should be greater than Start Time`);
      //     setSnackbarSeverity('error');
      //     setSnackbarOpen(true);
      //     return false;
      //   }
      // }
    }
  };

  const fieldValidation = (details: any) => {
    const { startDate, endDate, jobName, startTime, endTime } = details;
    if (jobName === '') {
      setError(FieldNames.jobName, { type: 'validate', message: 'Job name is required' });
      return false;
    }
    handleJobTimingsValidations(startTime, endTime);
    if (!isPriorityJob) {
      let isDatesValid = true;
      if (endDate === null) {
        setError(FieldNames.endDate, { type: 'validate', message: 'End date field is required' });
        isDatesValid = false;
      }
      if (startDate === null) {
        setError(FieldNames.startDate, { type: 'validate', message: 'Start date field is required' });
        isDatesValid = false;
      }

      return isDatesValid;
    }
    // if (selectedOption === 'subVendor' && subVendorOption === 'estimateWithinPeazy' && bidDueDateRange < 0) {
    //   return false;
    // }
    return true;
  };

  const handleNavigationAfterJobCreation = () => {
    if (role === UserType.Customer) {
      navigate('/jobs-View/onetime');
    } else if (role === UserType.Vendor) {
      if (selectedOption === 'subVendor' && subVendorOption === 'estimateWithinPeazy') {
        navigate('/vendor-jobs/sub-contractor-jobs');
      } else {
        navigate('/create-bid');
      }
    }
  };

  const onSubmit = async (details: any) => {
    if (!location.state?.jobRecord) {
      localStorage.removeItem(AsyncStorageKeys.currentJob);
    }
    const { startDate, endDate, jobName } = details;
    if (fieldValidation(details)) {
      try {
        if (isDateSameOrAfter(startDate, endDate)) {
          const jobCreationResponse = await handleCreateJob(startDate, endDate);
          if (jobCreationResponse) {
            removeJobLocalData();
            resetJobData();
            handleNavigationAfterJobCreation();
          } else {
            setSnackbarMessage(`An error occurred while creating the job.`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
        } else {
          setError(FieldNames.endDate, { type: 'validate', message: 'End date cannot be before start date' });
          const endDate = document.getElementById('endDate');
          endDate?.scrollIntoView();
        }
      } catch (error) {
        console.log('An error occurred during submission', error);
        setSnackbarMessage(`An error occurred while creating ${jobName}. Please try again later.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const onVendorSelect = (vendors: IVendor[]) => {
    setSearchedVendors(vendors);
  };

  const handleCustomerChange = useCallback(
    async (value: string) => {
      try {
        setFacilityLoading(true);
        setIsServiceLoading(true);
        const mSelectedCustomer: any = customers.find((customer: any) => customer.customerId === value);
        const customerDetails = await Promise.resolve(getStoredCustomerDetails());
        const recommendedJobs = (await JobCreateApiService.getRegionalJobs(
          customerDetails.customerId,
          userId
        )) as RecommendedJobsResponse;
        const filteredFacilityOptions = recommendedJobs.data.filter(
          (option) => option.customerId === mSelectedCustomer.customerId
        );
        if (role === UserType.Vendor) localStorage.setItem('selectedCustomer', JSON.stringify(mSelectedCustomer));
        setSelectedCustomer(mSelectedCustomer);
        // setFacilityOptions([...recommendedJobs.data]);
        setFacilityOptions([...filteredFacilityOptions]);
        let selectedCustomerId = customerId;
        if (role === UserType.Vendor) {
          selectedCustomerId = mSelectedCustomer.customerId ?? '';
          setIsServiceLoading(true);
          const allServiceCategoryV2Response = await MasterDataApiService.getAllServiceCategoryV3(selectedCustomerId);
          if (allServiceCategoryV2Response.data) {
            const modifiedCategories = allServiceCategoryV2Response.data.map((categoryItem: any) => {
              return {
                categoryId: categoryItem.serviceCategoryId,
                categoryName: categoryItem.serviceCategoryName,
                unit: categoryItem.unit,
              };
            });
            const uniqueCategories: IServiceCategoryItem[] = [
              ...new Map(modifiedCategories.map((item: any) => [item.categoryId, item])).values(),
            ] as IServiceCategoryItem[];
            uniqueCategories.sort((a: any, b: any) => a.categoryName.localeCompare(b.categoryName));
            setServiceOptions(uniqueCategories);
            fetchTaskDetails(allServiceCategoryV2Response);
            setIsServiceLoading(false);
          } else {
            setServiceOptions([]);
            setIsServiceLoading(false);
          }
        }

        setCustomerOpen(false);
        setFacilityLoading(false);
      } catch (error) {
        setFacilityLoading(false);
        console.log('error', error);
      }
    },
    [customers]
  );

  const toggleSelectOpen = (index: number) => {
    const updatedIsSelectOpen = [...isSelectOpen];
    updatedIsSelectOpen[index] = !updatedIsSelectOpen[index];
    setSelectOpen(updatedIsSelectOpen);
  };

  const handleCustomersList = async () => {
    try {
      if (customerSelected) {
        const customerDetails = await Promise.resolve(getStoredCustomerDetails());
        const recommendedJobs = (await JobCreateApiService.getRegionalJobs(
          customerDetails.customerId,
          userId
        )) as RecommendedJobsResponse;
        const filteredFacilityOptions = recommendedJobs.data.filter(
          (option) => option.customerId === selectedCustomer.customerId
        );
        setFacilityOptions([...filteredFacilityOptions]);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getTimeFormat = (time: string) => {
    const hour24 = time.split(':');
    const minutes: string = time.split(':')[1];
    const AmOrPm = +hour24[0] >= 12 ? 'PM' : 'AM';
    const hours = +hour24[0] % 12 || 12;
    return `${hours}:${minutes} ${AmOrPm}`;
  };

  useEffect(() => {
    removeJobLocalData();
  }, [location.pathname]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const clonedServices = [...service];
    const clonedFacility = [...facilities];
    const { jobName, startDate, endDate, startTime, endTime } = getValues();
    if (endTime && !startTime) {
      setValue(FieldNames.endTime, '');
      localStorage.removeItem(FieldNames.endTime);
      setSnackbarMessage(`Please select Start Time.`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    if (
      clonedFacility[0]?.facilityId &&
      clonedFacility[0]?.facilityId !== '' &&
      clonedServices[0].mdServiceId !== '' &&
      clonedServices[0].tasks !== '' &&
      jobName &&
      startDate &&
      endDate
    ) {
      if (
        (isSearchVendorsSelected || (selectedOption === 'subVendor' && selectedVendor === 'SearchVendors')) &&
        searchedVendors.length === 1 &&
        !searchedVendors[0].vendorId
      ) {
        setIsDisabled(true);
      } else if (selectedOption === 'subVendor' && subVendorOption === 'estimateWithinPeazy' && bidDueDateRange < 0) {
        setIsDisabled(true);
      } else if (role === UserType.Customer && bidDueDateRange < 0) {
        setIsDisabled(true);
      } else if (!clonedServices.every((service) => service.mdServiceId !== '' && service.tasks !== '')) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [service, facilities, watchFields]);

  useEffect(() => {
    localStorage.removeItem(AsyncStorageKeys.currentJob);
    if (location.state?.jobRecord) {
      fetchRecords();
      localStorage.setItem(AsyncStorageKeys.currentJob, JSON.stringify({ ...location.state?.jobRecord }));
    }
    setHoursList(
      hours.map((hour) => {
        return {
          label: getTimeFormat(hour),
          value: hour,
        };
      })
    );
    handleCustomersList();
    fetchData();
    if (!isJobCreation) {
      fetchAllJobAttachments();
    }
  }, []);

  // const validateBidDueDate = (
  //   updatedBidDueDate: string | null,
  //   updatedStartDate: string | null,
  //   updatedEndDate: string | null
  // ) => {
  //   if (updatedBidDueDate && updatedStartDate && updatedEndDate) {
  //     if (updatedStartDate === updatedEndDate && updatedStartDate === updatedBidDueDate) {
  //       // setError(FieldNames.bidDueDate, { type: 'validate', message: '' });
  //     } else if (updatedStartDate === updatedEndDate && isDateAfter(updatedBidDueDate, updatedStartDate)) {
  //       // setError(FieldNames.bidDueDate, {
  //       //   type: 'validate',
  //       //   message: `${t('oneTimeJob:dueDateShouldNotAfterStartAndEndDate')}`,
  //       // });
  //       // setValue(FieldNames.bidDueDate, null);
  //       // localStorage.removeItem(FieldNames.bidDueDate);
  //     } else if (!isDateSameOrBefore(updatedStartDate, updatedBidDueDate)) {
  //       // setError(FieldNames.bidDueDate, {
  //       //   type: 'validate',
  //       //   message: `${t('oneTimeJob:dueDateShouldNotAfterStartDate')}`,
  //       // });
  //       // setValue(FieldNames.bidDueDate, null);
  //       // localStorage.removeItem(FieldNames.bidDueDate);
  //     } else if (!isDateBefore(updatedBidDueDate, updatedEndDate)) {
  //       // setError(FieldNames.bidDueDate, {
  //       //   type: 'validate',
  //       //   message: `${t('oneTimeJob:dueDateShouldNotSameOrAfterEndDate')}`,
  //       // });
  //       // setValue(FieldNames.bidDueDate, null);
  //       localStorage.removeItem(FieldNames.bidDueDate);
  //     } else {
  //       // setError(FieldNames.bidDueDate, { type: 'validate', message: '' });
  //     }
  //   }
  // };

  const validateEndDate = (
    updatedBidDueDate: string | null,
    updatedStartDate: string | null,
    updatedEndDate: string | null
  ) => {
    if (updatedEndDate && updatedStartDate && updatedBidDueDate) {
      if (updatedEndDate === updatedStartDate && updatedEndDate === updatedBidDueDate) {
        setError(FieldNames.endDate, { type: 'validate', message: '' });
      } else if (isDateBefore(updatedEndDate, updatedStartDate)) {
        setError(FieldNames.endDate, {
          type: 'validate',
          message: `${t('oneTimeJob:endDateShouldNotBeforeStartDate')}`,
        });
        setValue(FieldNames.endDate, null);
        localStorage.removeItem(FieldNames.endDate);
      } else if (isDateSameOrBefore(updatedBidDueDate, updatedEndDate)) {
        setError(FieldNames.endDate, {
          type: 'validate',
          message: `${t('oneTimeJob:endDateShouldNotSameOrBeforeBidDueDate')}`,
        });
        setValue(FieldNames.endDate, null);
        localStorage.removeItem(FieldNames.endDate);
      } else {
        setError(FieldNames.endDate, { type: 'validate', message: '' });
      }
    }
  };

  const validateStartDate = (
    updatedBidDueDate: string | null,
    updatedStartDate: string | null,
    updatedEndDate: string | null
  ) => {
    if (updatedStartDate && updatedBidDueDate && updatedEndDate) {
      if (updatedStartDate === updatedBidDueDate && updatedStartDate === updatedEndDate) {
        setError(FieldNames.startDate, { type: 'validate', message: '' });
      } else if (isDateAfter(updatedStartDate, updatedEndDate)) {
        setError(FieldNames.startDate, {
          type: 'validate',
          message: `${t('oneTimeJob:startDateShouldNotAfterEndDate')}`,
        });
        setValue(FieldNames.startDate, null);
        localStorage.removeItem(FieldNames.startDate);
      } else if (isDateBefore(updatedStartDate, updatedBidDueDate)) {
        setError(FieldNames.startDate, {
          type: 'validate',
          message: `${t('oneTimeJob:startDateShouldNotBeforeBidDueDate')}`,
        });
        setValue(FieldNames.startDate, null);
        localStorage.removeItem(FieldNames.startDate);
      } else {
        setError(FieldNames.startDate, { type: 'validate', message: '' });
      }
    }
  };

  // useEffect(() => {
  //   const { startDate, endDate } = getValues();
  //   const updatedStartDate = startDate ? new Date(moment(startDate).format('MM-DD-YYYY')).toISOString() : null;
  //   const updatedEndDate = endDate ? new Date(moment(endDate).format('MM-DD-YYYY')).toISOString() : null;
  //   // const updatedBidDueDate = bidDueDate ? new Date(moment(bidDueDate).format('MM-DD-YYYY')).toISOString() : null;
  //   if (updatedStartDate && !updatedEndDate) {
  //     if (!isDateSameOrBefore(updatedStartDate, updatedBidDueDate)) {
  //       setError(FieldNames.bidDueDate, {
  //         type: 'validate',
  //         message: `${t('oneTimeJob:dueDateShouldNotAfterStartDate')}`,
  //       });
  //       setValue(FieldNames.bidDueDate, null);
  //       localStorage.removeItem(FieldNames.bidDueDate);
  //     } else {
  //       setError(FieldNames.bidDueDate, { type: 'validate', message: '' });
  //     }
  //   }
  //   if (updatedBidDueDate && !updatedStartDate && updatedEndDate) {
  //     if (!isDateBefore(updatedBidDueDate, updatedEndDate)) {
  //       setError(FieldNames.bidDueDate, {
  //         type: 'validate',
  //         message: `${t('oneTimeJob:dueDateShouldNotSameOrAfterEndDate')}`,
  //       });
  //       setValue(FieldNames.bidDueDate, null);
  //       localStorage.removeItem(FieldNames.bidDueDate);
  //     } else {
  //       setError(FieldNames.bidDueDate, { type: 'validate', message: '' });
  //     }
  //   }
  //   validateBidDueDate(updatedBidDueDate, updatedStartDate, updatedEndDate);
  // }, [getValues(FieldNames.bidDueDate)]);

  useEffect(() => {
    const { startDate, endDate } = getValues();
    const updatedStartDate = startDate ? new Date(formatDate(startDate)).toISOString() : null;
    const updatedEndDate = endDate ? new Date(formatDate(endDate)).toISOString() : null;
    const updatedBidDueDate = updatedStartDate;
    if (updatedEndDate && updatedStartDate) {
      if (isDateBefore(updatedEndDate, updatedStartDate)) {
        setError(FieldNames.endDate, {
          type: 'validate',
          message: `${t('oneTimeJob:endDateShouldNotBeforeStartDate')}`,
        });
        setValue(FieldNames.endDate, null);
        localStorage.removeItem(FieldNames.endDate);
      } else {
        setError(FieldNames.endDate, { type: 'validate', message: '' });
      }
    }
    if (updatedEndDate && updatedBidDueDate && !updatedStartDate) {
      if (isDateSameOrAfter(updatedEndDate, updatedBidDueDate)) {
        setError(FieldNames.endDate, {
          type: 'validate',
          message: `${t('oneTimeJob:endDateShouldNotSameOrBeforeBidDueDate')}`,
        });
        setValue(FieldNames.endDate, null);
        localStorage.removeItem(FieldNames.endDate);
      } else {
        setError(FieldNames.endDate, { type: 'validate', message: '' });
      }
    }
    validateEndDate(updatedBidDueDate, updatedStartDate, updatedEndDate);
  }, [getValues(FieldNames.endDate)]);

  useEffect(() => {
    const { startDate, endDate } = getValues();
    const updatedStartDate = startDate ? startDate : null;
    const updatedEndDate = endDate ? endDate : null;
    const updatedBidDueDate = startDate ? startDate : null;
    if (updatedStartDate && updatedEndDate && !updatedBidDueDate) {
      if (isDateAfter(updatedStartDate, updatedEndDate)) {
        setError(FieldNames.startDate, {
          type: 'validate',
          message: `${t('oneTimeJob:startDateShouldNotAfterEndDate')}`,
        });
        setValue(FieldNames.startDate, null);
        localStorage.removeItem(FieldNames.startDate);
      } else {
        setError(FieldNames.startDate, { type: 'validate', message: '' });
      }
    }
    if (updatedStartDate && !updatedEndDate && updatedBidDueDate) {
      if (isDateBefore(updatedStartDate, updatedBidDueDate)) {
        setError(FieldNames.startDate, {
          type: 'validate',
          message: `${t('oneTimeJob:startDateShouldNotBeforeBidDueDate')}`,
        });
        setValue(FieldNames.startDate, null);
        localStorage.removeItem(FieldNames.startDate);
      } else {
        setError(FieldNames.startDate, { type: 'validate', message: '' });
      }
    }
    validateStartDate(updatedBidDueDate, updatedStartDate, updatedEndDate);
  }, [getValues(FieldNames.startDate)]);

  useEffect(() => {
    const fetchBidDueDate = async () => {
      const bidDueDateRangeResponse = await MasterDataApiService.getMasterDataCategories(MasterData.BID_DUE_DAYS);
      const { customerId = '' } = getStoredCustomerDetails() || {};
      const response = await JobCreateApiService.jobSummary(customerId, jobId);

      if (!isEmpty(response.data)) {
        setBidDueDateRange(bidDueDateRangeResponse?.data[0]?.value ?? 0);
        // setValue(FieldNames.bidDueDate, jobDueDates[0]?.dueDate || '');
      }
    };
    fetchBidDueDate();
  }, []);

  const renderBottomBarContainer = () => {
    return (
      <BottomBarContainer>
        <Button
          disabled={isDisabled}
          sx={{ ...saveExitButton, opacity: isDisabled ? '0.5' : '1', cursor: 'pointer' }}
          onClick={handleSubmit(onSave)}>
          {isDataSaving ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>
              {t('oneTimeJob:saveAndExit')}
            </Typography>
          )}
        </Button>
        <Button
          disabled={isDisabled}
          sx={{ ...createJobButton, opacity: isDisabled ? '0.5' : '1' }}
          onClick={handleSubmit(onSubmit)}>
          {isJobCreationInProgress ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {location.state?.jobRecord ? `${t('oneTimeJob:modifyJob')}` : `${t('oneTimeJob:createJob')}`}
            </Typography>
          )}
        </Button>
      </BottomBarContainer>
    );
  };

  const renderSelectTasksField = (mdServiceId: string, selectedService: IServiceItem) => {
    return (
      <>
        {!isEmpty(selectedServices) && selectedServices.includes(mdServiceId) && (
          <MultiSelectDropdownWithCheckboxes
            label={t('oneTimeJob:selectTasks')}
            isMandatory={true}
            dropdownValues={serviceTasks[mdServiceId]}
            defaultValues={selectedService?.tasks ? selectedService.tasks.split(', ') : []}
            error={selectedService?.tasksError}
            onSelect={(data: string[]) => handleSelectedTasks(data, mdServiceId)}
            showSelectAll={role === UserType.Customer}
          />
        )}
      </>
    );
  };

  const renderAddAnotherServiceButton = () => {
    return (
      <>
        {role === UserType.Customer && (
          <Box
            sx={{
              ...addAnotherService,
              opacity: `${serviceOptions?.length === service?.length ? 0.3 : 1}`,
              pointerEvents: `${serviceOptions?.length === service?.length ? 'none' : 'auto'}`,
            }}>
            <Button variant="outlined" sx={addButton} onClick={handleAddService}>
              <img src={Icons.AddIcon} alt={t('altTexts:addIcon')} />
              <Typography sx={{ ...addButtonText, textTransform: 'none' }}>
                {t('oneTimeJob:addAnotherService')}
              </Typography>
            </Button>
          </Box>
        )}
      </>
    );
  };

  const ErrorMsgWrapper = ({ error }: any) => {
    return <div>{error}</div>;
  };

  return (
    <>
      <PageTitle
        title={
          location.state?.jobRecord ? `${t('oneTimeJob:modifyOneTimeJob')}` : `${t('oneTimeJob:createOneTimeJob')}`
        }
      />
      <CustomerContainer>
        <OneTimeJobContainer>
          <Typography sx={headingText}>{t('oneTimeJob:jobDetails')}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}>
            <JobNameContainer>
              <Controller
                control={control}
                name={FieldNames.jobName}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return (
                    <JobNameWrapper>
                      <TextField
                        sx={textField}
                        id="outlined-required"
                        label={
                          <span>
                            {t('oneTimeJob:jobName')}
                            <span className="mandatory-field">*</span>
                          </span>
                        }
                        value={value}
                        onChange={(e) => {
                          if (e.target.value.length <= 50) {
                            onChange(e.target.value);
                            setCharactersLeft(50 - e.target.value.length);
                            localStorage.setItem('jobName', e.target.value);
                          }
                        }}
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={() => {
                          setIsInputFocused(false);
                          setError(FieldNames.jobName, { type: 'validate', message: '' });
                        }}
                        disabled={jobRecord?.jobStatus === JobStatus.Awarded}
                      />
                      {!(charactersLeft === 50 || charactersLeft === 0) && isInputFocused && (
                        <div
                          style={{
                            color: theme.palette.secondary.contrastText,
                            fontSize: theme.typography.h5?.fontSize,
                          }}>
                          {charactersLeft} {t('oneTimeJob:charactersLeft')}
                        </div>
                      )}
                      <ErrorMsg sx={{ paddingLeft: 0 }}>{error && error?.message}</ErrorMsg>
                    </JobNameWrapper>
                  );
                }}
                rules={{
                  // required: 'Job name is required',
                  pattern: {
                    value: Patterns.TitleWithDigitsAndSpecialCharacters,
                    message: `${t('oneTimeJob:invalidJobName')}`,
                  },
                  maxLength: {
                    value: 50,
                    message: `${t('oneTimeJob:jobNameLimit')}`,
                  },
                }}
              />
            </JobNameContainer>

            {role === UserType.Vendor && (
              <Box>
                <TextField
                  select
                  sx={{ ...textField, marginBottom: '2rem' }}
                  label={
                    <span>
                      {t('oneTimeJob:customer')} <span className="mandatory-field">*</span>
                    </span>
                  }
                  value={selectedCustomer.customerId}
                  onChange={(event) => handleCustomerChange(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setCustomerOpen(!isCustomerOpen)}
                          style={{
                            padding: 0,
                          }}>
                          <img
                            src={isCustomerOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                            alt={isCustomerOpen ? 'down' : 'up'}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  SelectProps={{
                    open: isCustomerOpen,
                    IconComponent: () => null,
                    onOpen: () => setCustomerOpen(true),
                    onClose: () => setCustomerOpen(false),
                    MenuProps: {
                      PaperProps: {
                        style: {
                          boxShadow: BoxShadow.BoxShadow,
                        },
                      },
                    },
                  }}
                  disabled={jobRecord?.jobStatus === JobStatus.Awarded}>
                  {customers.map((customer: any, index: number) => (
                    <Options key={index} value={customer.customerId}>
                      {customer.legalName}
                    </Options>
                  ))}
                </TextField>
              </Box>
            )}

            {facilities.map((facilityItem: { id: React.Key | null | undefined; facilityError: any }, index: any) => {
              return (
                <Box key={facilityItem.id}>
                  <>
                    <FacilityAutocomplete
                      facilityItem={facilityItem}
                      index={index}
                      facilityOptions={facilityOptions}
                      role={role}
                      handleFacilitiesSelectChange={handleFacilitiesSelectChange}
                      isFacilityLoading={isFacilityLoading}
                      jobRecord={jobRecord}
                      selectedFacilities={selectedFacilities}
                      t={t}
                      theme={theme}
                      handleDeleteFacility={handleDeleteFacility}
                    />
                    <ErrorMsgWrapper error={facilityItem.facilityError} />
                  </>
                </Box>
              );
            })}
          </Box>

          <Divider sx={divider} />
          <Box>
            <Typography sx={headingText}>{t('oneTimeJob:serviceRequired')}</Typography>
            {service.map((serviceItem, index) => {
              const prevSelectedService = serviceItem.service;

              return (
                <Box key={serviceItem.id} sx={{ ...serviceAndAreaSpecification, flexWrap: 'wrap', rowGap: '2rem' }}>
                  <ServiceFieldWrapper>
                    <TextField
                      select
                      sx={{
                        pointerEvents: isServiceLoading ? 'none' : 'all',
                        ...textFieldService,
                      }}
                      label={
                        <span>
                          {t('oneTimeJob:selectService')} <span className="mandatory-field">*</span>
                        </span>
                      }
                      value={serviceItem.mdServiceId}
                      onChange={(e) => handleServiceSelectChange(serviceItem.id, e.target.value, prevSelectedService)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {index === 0 && isServiceLoading ? (
                              <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
                            ) : null}
                          </InputAdornment>
                        ),
                      }}
                      SelectProps={{
                        IconComponent: () =>
                          !isServiceLoading ? (
                            <button
                              onClick={() => toggleSelectOpen(index)}
                              style={{
                                border: 'none',
                                background: 'transparent',
                                cursor: 'pointer',
                                padding: 0,
                              }}>
                              <img
                                src={isSelectOpen[index] ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                                alt={isSelectOpen[index] ? 'down' : 'up'}
                                onClick={() => toggleSelectOpen(index)}
                              />
                            </button>
                          ) : null,
                        open: isSelectOpen[index],
                        onOpen: () => toggleSelectOpen(index),
                        onClose: () => toggleSelectOpen(index),
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                          PaperProps: {
                            style: {
                              boxShadow: BoxShadow.BoxShadow,
                            },
                          },
                        },
                      }}
                      disabled={jobRecord?.jobStatus === JobStatus.Awarded}>
                      {serviceOptions.map((item: any) => {
                        const alreadySelected = selectedServices.includes(item.categoryId);

                        return (
                          <Options
                            style={{
                              opacity: `${alreadySelected ? 0.3 : ''}`,
                              pointerEvents: `${alreadySelected ? 'none' : 'auto'}`,
                            }}
                            key={item.categoryId}
                            value={item.categoryId}>
                            {item.categoryName}
                          </Options>
                        );
                      })}
                    </TextField>
                    <ErrorMsgWrapper>{serviceItem.nameError}</ErrorMsgWrapper>
                  </ServiceFieldWrapper>
                  <ServiceFieldWrapper>
                    <TextField
                      sx={textFieldService}
                      label={t('oneTimeJob:areaSpecification')}
                      value={serviceItem.quantity}
                      onChange={(e) => handleServiceAreaChange(serviceItem.id, e.target.value)}
                      disabled={jobRecord?.jobStatus === JobStatus.Awarded}
                    />
                    <ErrorMsgWrapper>{serviceItem.asError}</ErrorMsgWrapper>
                  </ServiceFieldWrapper>

                  {renderSelectTasksField(serviceItem.mdServiceId, serviceItem)}
                  {index > 0 && (
                    <Button
                      onClick={() => handleDeleteService(serviceItem)}
                      sx={{
                        backgroundColor: 'transparent',
                        padding: 0,
                        margin: 0,
                        minWidth: 0,
                      }}>
                      <img src={Icons.DeleteIcon} alt={t('altTexts:delete')} />
                    </Button>
                  )}
                </Box>
              );
            })}

            {/* <Box
              sx={{
                ...addAnotherService,
                opacity: `${serviceOptions?.length === service?.length ? 0.3 : 1}`,
                pointerEvents: `${serviceOptions?.length === service?.length ? 'none' : 'auto'}`,
              }}>
              <Button variant="outlined" sx={addButton} onClick={handleAddService}>
                <img src={AddIcon} alt={t('altTexts:addIcon')} />
                <Typography sx={{ ...addButtonText, textTransform: 'none' }}>{t('oneTimeJob:addAnotherService')}</Typography>
              </Button>
            </Box> */}
            {renderAddAnotherServiceButton()}
            <Divider sx={divider} />
          </Box>
          <ServiceScheduleContainer>
            <Typography sx={headingText}>{t('oneTimeJob:serviceSchedule')}</Typography>
            <Box
              sx={{
                ...serviceScheduleContent,
              }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {isPriorityJob ? (
                  <img
                    src={Icons.CheckedIcon}
                    alt={t('altTexts:checked')}
                    onClick={handlePriorityChange}
                    style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                  />
                ) : (
                  <img
                    src={Icons.Unchecked}
                    alt={t('altTexts:unchecked')}
                    onClick={handlePriorityChange}
                    style={{ cursor: 'pointer' }}
                  />
                )}
                <Typography
                  sx={{
                    ...radioButtonText,
                    marginLeft: '0.5rem',
                  }}>
                  {t('oneTimeJob:needService')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box sx={{ flex: 1, marginRight: '1.5rem' }}>
                <ServiceScheduleDateRangeText> {t('oneTimeJob:dateRange')} </ServiceScheduleDateRangeText>
                <DatePickerContainer>
                  <DateSelection
                    requiredText={t('oneTimeJob:startDateFieldReq')}
                    control={control}
                    fieldName={FieldNames.startDate}
                    label={t('calendar:startDate')}
                    iconColor="black"
                    isExcludePastDate={false}
                  />
                  <DateSelection
                    requiredText={t('oneTimeJob:endDateFieldReq')}
                    control={control}
                    fieldName={FieldNames.endDate}
                    label={t('calendar:endDate')}
                    iconColor="black"
                    isExcludePastDate={false}
                  />
                </DatePickerContainer>
              </Box>
              <Box sx={{ flex: 1 }}>
                <ServiceScheduleRangeText>{t('oneTimeJob:timeRange')}</ServiceScheduleRangeText>
                <DatePickerContainer>
                  <Controller
                    control={control}
                    name={FieldNames.startTime}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return (
                        <DateFieldWrapper>
                          <TextField
                            select
                            sx={textField}
                            label={t('calendar:startTime')}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                              localStorage.setItem('startTime', e.target.value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setStartTimeSelectOpen(!isStartTimeSelectOpen)}
                                    style={{
                                      padding: 0,
                                    }}>
                                    <img
                                      src={isStartTimeSelectOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                                      alt={isStartTimeSelectOpen ? 'down' : 'up'}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            SelectProps={{
                              open: isStartTimeSelectOpen,
                              IconComponent: () => null,
                              onOpen: () => setStartTimeSelectOpen(true),
                              onClose: () => setStartTimeSelectOpen(false),
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    boxShadow: BoxShadow.BoxShadow,
                                  },
                                },
                              },
                            }}>
                            {hoursList.map((item) => (
                              <Options key={'startTime' + item.value} value={item.value}>
                                {item.label}
                              </Options>
                            ))}
                          </TextField>

                          <ErrorMsg>{error && error?.message}</ErrorMsg>
                        </DateFieldWrapper>
                      );
                    }}
                  />

                  <Controller
                    control={control}
                    name={FieldNames.endTime}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return (
                        <DateFieldWrapper>
                          <TextField
                            select
                            sx={textField}
                            label={t('calendar:endTime')}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                              localStorage.setItem('endTime', e.target.value);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setEndTimeSelectOpen(!isEndTimeSelectOpen)}
                                    style={{
                                      padding: 0,
                                    }}>
                                    <img
                                      src={isEndTimeSelectOpen ? Icons.DropdownDownIcon : Icons.DropdownUpIcon}
                                      alt={isEndTimeSelectOpen ? 'down' : 'up'}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            SelectProps={{
                              open: isEndTimeSelectOpen,
                              IconComponent: () => null,
                              onOpen: () => setEndTimeSelectOpen(true),
                              onClose: () => setEndTimeSelectOpen(false),
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    boxShadow: BoxShadow.BoxShadow,
                                  },
                                },
                              },
                            }}>
                            {hoursList.map((item) => (
                              <Options key={'endTime' + item.value} value={item.value}>
                                {item.label}
                              </Options>
                            ))}
                          </TextField>

                          <ErrorMsg>{error && error?.message}</ErrorMsg>
                        </DateFieldWrapper>
                      );
                    }}
                  />
                </DatePickerContainer>
              </Box>
            </Box>
            <Divider sx={buttonStyles.divider} />
          </ServiceScheduleContainer>
          {/* ///////////Newly added checkboxes//////// */}
          {role === UserType.Vendor && (
            <ServiceScheduleContainer>
              <Typography sx={headingText}>{t('oneTimeJob:executionType')}</Typography>
              <Box
                sx={{
                  ...serviceScheduleContent,
                }}>
                <FormControl
                  component="fieldset"
                  sx={{ width: '100%' }}
                  disabled={jobRecord?.jobStatus === JobStatus.Awarded}>
                  <RadioGroup row value={selectedOption} onChange={handleExecutionChange} sx={executionType}>
                    <Box>
                      <FormControlLabel
                        value="self"
                        control={
                          <Radio
                            sx={{
                              ...radioButton,
                            }}
                          />
                        }
                        label={t('oneTimeJob:selfPerform')}
                        sx={{
                          ...radioButtonText,
                        }}
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="subVendor"
                        control={
                          <Radio
                            sx={{
                              ...radioButton,
                            }}
                          />
                        }
                        label={t('oneTimeJob:subContractor')}
                        sx={{
                          ...radioButtonText,
                        }}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Divider sx={divider} />
            </ServiceScheduleContainer>
          )}
          {role === UserType.Vendor && selectedOption === 'subVendor' && (
            <ServiceScheduleContainer>
              <Typography sx={headingText}>{t('oneTimeJob:estimationType')}</Typography>
              <Box
                sx={{
                  ...serviceScheduleContent,
                }}>
                {selectedOption === 'subVendor' && (
                  <FormControl
                    component="fieldset"
                    sx={{ width: '100%' }}
                    disabled={jobRecord?.jobStatus === JobStatus.Awarded}>
                    <RadioGroup row value={subVendorOption} onChange={handleSubVendorOptionChange} sx={executionType}>
                      <Box>
                        <FormControlLabel
                          value="estimateWithinPeazy"
                          control={
                            <Radio
                              sx={{
                                ...radioButton,
                              }}
                            />
                          }
                          label="Put Out For Bid Within Peazy"
                          sx={{
                            ...radioButtonText,
                          }}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="estimateOutsidePeazy"
                          control={
                            <Radio
                              sx={{
                                ...radioButton,
                              }}
                            />
                          }
                          label={t('oneTimeJob:estimateFromVendor')}
                          sx={{
                            ...radioButtonText,
                          }}
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                )}
              </Box>
            </ServiceScheduleContainer>
          )}
          {(selectedOption === 'subVendor' || role === UserType.Customer) && (
            <VendorDetailsContainer>
              {role !== UserType.Customer && <Divider sx={buttonStyles.divider} />}
              <Typography sx={{ ...headingText }}>{t('oneTimeJob:vendorDetails')}</Typography>
              {(subVendorOption === 'estimateWithinPeazy' || role === UserType.Customer) && (
                <Box
                  sx={{
                    ...vendorDetailsContent,
                    marginBottom: '2rem',
                  }}>
                  <FormControl component="fieldset" sx={{ width: '100%' }}>
                    <RadioGroup
                      sx={executionType}
                      row
                      aria-label="vendor-details"
                      name="vendor-details-radio-button"
                      value={selectedVendor}
                      onChange={handleVendorDetailsCheckboxChange}>
                      <Box>
                        <FormControlLabel
                          value="All"
                          control={
                            <Radio
                              sx={{
                                ...radioButton,
                              }}
                            />
                          }
                          label={t('oneTimeJob:availableVendors')}
                          sx={{
                            ...radioButtonText,
                          }}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          value="SearchVendors"
                          control={
                            <Radio
                              sx={{
                                ...radioButton,
                              }}
                            />
                          }
                          label={t('oneTimeJob:searchVendors')}
                          sx={{
                            ...radioButtonText,
                          }}
                        />
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}
              {(isSearchVendorsSelected || subVendorOption === 'estimateOutsidePeazy') && (
                <>
                  {jobRecord?.jobStatus === JobStatus.Awarded ? (
                    <Box sx={{ pointerEvents: 'none', opacity: 0.5 }}>
                      <SearchVendor
                        fieldName={FieldNames.searchedVendors}
                        selectedVendors={searchedVendors}
                        onVendorSelect={onVendorSelect}
                        isMulti={subVendorOption !== 'estimateOutsidePeazy' || role === UserType.Customer}
                      />
                    </Box>
                  ) : (
                    <SearchVendor
                      fieldName={FieldNames.searchedVendors}
                      selectedVendors={searchedVendors}
                      onVendorSelect={onVendorSelect}
                      isMulti={subVendorOption !== 'estimateOutsidePeazy' || role === UserType.Customer}
                    />
                  )}
                </>
              )}
              {(role === UserType.Customer ||
                (selectedOption === 'subVendor' && subVendorOption === 'estimateWithinPeazy')) && (
                <Controller
                  control={control}
                  name={'instructions'}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <JobNameWrapper sx={{ marginBottom: '2.5rem' }}>
                        <TextField
                          multiline
                          sx={{
                            ...textField,
                            width: '100%',
                            zIndex: '0',
                            [theme.breakpoints.down('laptop')]: {
                              width: '100%',
                            },
                          }}
                          id="outlined-required"
                          label={t('oneTimeJob:instructionsForVendor')}
                          inputProps={{
                            maxLength: 100,
                          }}
                          value={value}
                          onChange={onChange}
                        />
                        <ErrorMsg>{error && error?.message}</ErrorMsg>
                      </JobNameWrapper>
                    );
                  }}
                  rules={{
                    pattern: {
                      value: Patterns.TitleWithDigitsAndSpecialCharacters,
                      message: 'Invalid Instructions provided.',
                    },
                  }}
                />
              )}
              {subVendorOption === 'estimateWithinPeazy' && (
                <DatePickerContainer>
                  {bidDueDateRange > 0 && (
                    <DateSelection
                      requiredText="Bid due date field is required"
                      control={control}
                      fieldName={FieldNames.bidDueDate}
                      label={t('vendor:bidDueDate')}
                      iconColor="black"
                      isExcludePastDate={false}
                    />
                  )}
                </DatePickerContainer>
              )}
              <Divider sx={{ ...divider, marginTop: '2.625rem' }} />
            </VendorDetailsContainer>
          )}
          <PhotosAndVideosContainer>
            <Box
              sx={{
                opacity: jobRecord?.jobStatus === JobStatus.Awarded ? 0.5 : 1,
                pointerEvents: jobRecord?.jobStatus === JobStatus.Awarded ? 'none' : 'auto',
              }}>
              <PhotosAndVideos
                customerId={customerId}
                jobId={jobId}
                details={jobAttachments}
                uploadedFileTags={uploadedFileTags}
                handleAttachmentDetails={handleAttachmentDetails}
                handleUploadedFileTags={handleUploadedFileTags}
                handleDeleteAttachment={handleDeleteAttachment}
                handleDeleteSelectedTag={handleDeleteSelectedTag}
                handleInstructionsChange={handleInstructionsChange}
              />
            </Box>
          </PhotosAndVideosContainer>
        </OneTimeJobContainer>
        {renderBottomBarContainer()}
        <SnackbarMessage
          open={snackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        />
      </CustomerContainer>
    </>
  );
};

export default AddEditOneTimeJob;
