/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React from 'react';
import { ConversationContainer, LogoWrapper, ReplyTag } from './ConversationsPage.Style';
import theme from '../../../../Shared/Themes/theme';
import { getAcronymName } from '../../../../Shared/Utilities/utils';
import { useTranslation } from 'react-i18next';

interface MessageCard {
  conversation: any;
  handleReplyOnClick: (conversationId: string) => void;
  enableReply: boolean;
}

const MessageCard = ({ conversation, handleReplyOnClick, enableReply }: MessageCard): JSX.Element => {
  const { t } = useTranslation(['conversations']);

  return (
    <ConversationContainer>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <LogoWrapper sx={{ backgroundColor: theme.palette.primary.main, borderRadius: '50%' }}>
          <div>{getAcronymName(conversation.messages[0]?.userName as string).toUpperCase()}</div>
        </LogoWrapper>
        <div>{conversation.messages[0]?.userName}</div>
      </div>
      <div style={{ margin: '1rem 0 0 3.75rem' }}>
        <div style={{ whiteSpace: 'pre-wrap' }}>{conversation?.messages[0]?.message}</div>
        <div style={{ marginTop: '1rem' }}>
          {conversation.messages.length > 1 ? (
            <ReplyTag onClick={() => handleReplyOnClick(conversation.conversationId)}>
              {conversation.messages.length - 1}{' '}
              {conversation.messages.length - 1 === 1 ? `${t('conversations:reply')}` : `${t('conversations:replies')}`}
            </ReplyTag>
          ) : (
            <>
              {enableReply && (
                <ReplyTag onClick={() => handleReplyOnClick(conversation.conversationId)}>
                  {t('conversations:reply')}
                </ReplyTag>
              )}
            </>
          )}
        </div>
      </div>
    </ConversationContainer>
  );
};

export default MessageCard;
