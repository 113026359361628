/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useTranslation } from 'react-i18next';

import Button, { ButtonType } from '../Buttons/Button';
import { ErrorDesc, ErrorTitle, ErrorTitleWrapper, ErrorWrapper } from './ErrorAndNoData.styles';

import { Icons } from '../../../Constants/Icons';

const ErrorMessage = (): JSX.Element => {
  const { t } = useTranslation(['noData', 'altTexts']);
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <ErrorWrapper>
      <img src={Icons.ErrorCautionImg} alt={t('altTexts:error')} />
      <ErrorTitleWrapper>
        <ErrorTitle>{t('noData:errorLoadingData')}</ErrorTitle>
        <ErrorDesc>{t('noData:tryAgain')}</ErrorDesc>
        <Button width="19.6rem" type={ButtonType.Primary} label="Reload" onClick={refreshPage} />
      </ErrorTitleWrapper>
    </ErrorWrapper>
  );
};

const ErrorText = (): JSX.Element => {
  const { t } = useTranslation(['noData']);
  return (
    <ErrorWrapper>
      <ErrorTitleWrapper>
        <ErrorTitle>{t('noData:errorLoadingData')}</ErrorTitle>
        <ErrorDesc>{t('noData:tryAgain')}</ErrorDesc>
      </ErrorTitleWrapper>
    </ErrorWrapper>
  );
};

export { ErrorMessage, ErrorText };
