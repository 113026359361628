/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import React, { useEffect } from 'react';
import {
  ButtonWrapper,
  CardSubTitle,
  CardTitle,
  CloseDialogBoxIcon,
  DatePickerWrapper,
  DropdownWrapper,
  NoteText,
  RejectAndReworkCardWrapper,
  buttonStyles,
} from './TaskRejectionAndRework.Style';
import { Icons } from '../../../../Shared/Constants/Icons';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { IProofsProps, UserType } from '../../../../Shared/Constants/App';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { useForm, useWatch } from 'react-hook-form';
import { getAuthData } from '../../../../Shared/Utilities/utils';
import { isEmpty } from 'lodash';
import { TaskScheduleType, WorkDayDetails } from '../../../../API';
import moment from 'moment';

interface RequestOrScheduleRework {
  openRequestRework: boolean;
  handleRequestReworkClose: () => void;
  handleRequestRework: () => void;
  reworkShift: TaskScheduleType | null;
  setReworkShift: (shiftdate: TaskScheduleType | null) => void;
  isCurrentShiftAvailable: boolean;
  nextAvailableWorkDay: WorkDayDetails | null;
  tomorrowDate: any;
  workOrderEndDate: any;
  reworkScheduleDate: any;
  setReworkScheduleDate: (date: any) => void;
  isDataLoading: boolean;
  proofs: IProofsProps[];
}

function formatDate(dateString: string): string {
  // Parse the input date string using moment
  const date = moment(dateString);

  // Format the date to the desired string format (DD MMM, YYYY)
  return date.format('DD MMM, YYYY');
}

const RequestOrScheduleRework = ({
  openRequestRework,
  handleRequestReworkClose,
  handleRequestRework,
  reworkShift,
  setReworkShift,
  isCurrentShiftAvailable,
  nextAvailableWorkDay,
  tomorrowDate,
  workOrderEndDate,
  reworkScheduleDate,
  setReworkScheduleDate,
  isDataLoading,
  proofs,
}: RequestOrScheduleRework): JSX.Element => {
  const { t } = useTranslation(['altTexts', 'logout', 'taskRejectionAndRework']);
  const { requestReworkButton, cancelButton, buttonText, radioButton, radioButtonText } = buttonStyles;
  const { role } = getAuthData();
  const { control } = useForm({
    defaultValues: {
      customShiftDate: reworkScheduleDate ? reworkScheduleDate : tomorrowDate.toISOString(),
    },
  });

  const customShiftDate = useWatch({
    control,
    name: 'customShiftDate',
  });

  const handleDialogClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    handleRequestReworkClose();
  };

  const isWorkOrderEndDateValid = () => {
    return workOrderEndDate ? new Date(workOrderEndDate) > new Date(tomorrowDate) : false;
  };

  const getTitleBasedOnRole = () => {
    return role === UserType.Customer
      ? t('taskRejectionAndRework:requestToRescheduleRework')
      : t('taskRejectionAndRework:scheduleReworkTask');
  };

  const getSubTitleBasedonRole = () => {
    return role === UserType.Customer
      ? t('taskRejectionAndRework:requestForReworkInTheFollowingShift')
      : t('taskRejectionAndRework:reworkTaskInTheFollowingShift');
  };

  const getCurrentShiftLabel = () => {
    return (
      `${t('taskRejectionAndRework:reworkTaskInCurrentShift')}` +
      (!isCurrentShiftAvailable ? ` (${t('taskRejectionAndRework:unavailable')})` : '')
    );
  };

  const getNextShiftLabel = () => {
    return `${t('taskRejectionAndRework:reworkTaskInNextShift')} (${nextAvailableWorkDay && nextAvailableWorkDay.workDate ? formatDate(nextAvailableWorkDay.workDate) : t('taskRejectionAndRework:unavailable')})`;
  };

  useEffect(() => {
    setReworkScheduleDate(customShiftDate);
  }, [customShiftDate]);

  return (
    <RejectAndReworkCardWrapper open={openRequestRework} onClose={handleDialogClose}>
      <CloseDialogBoxIcon onClick={handleRequestReworkClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </CloseDialogBoxIcon>
      <CardTitle>{getTitleBasedOnRole()}</CardTitle>
      <CardSubTitle>{getSubTitleBasedonRole()}</CardSubTitle>
      <FormControl component="fieldset">
        <RadioGroup
          row
          value={reworkShift}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setReworkShift(event.target.value as TaskScheduleType);
            if ((event.target.value as TaskScheduleType) === TaskScheduleType.CurrentShift) {
              setReworkScheduleDate(new Date().toISOString());
            } else if ((event.target.value as TaskScheduleType) === TaskScheduleType.NextWorkDay) {
              setReworkScheduleDate(nextAvailableWorkDay?.workDate);
            } else {
              setReworkScheduleDate(customShiftDate);
            }
          }}
          sx={{ flexDirection: 'column', gap: '1rem' }}>
          <Box
            sx={{
              opacity: !isCurrentShiftAvailable ? 0.5 : 1,
              pointerEvents: !isCurrentShiftAvailable || isDataLoading ? 'none' : 'all',
            }}>
            <FormControlLabel
              value={TaskScheduleType.CurrentShift}
              control={<Radio sx={{ ...radioButton }} />}
              label={getCurrentShiftLabel()}
              sx={{ ...radioButtonText }}
            />
          </Box>
          <Box
            sx={{
              opacity: !nextAvailableWorkDay ? 0.5 : 1,
              pointerEvents: !nextAvailableWorkDay || isDataLoading ? 'none' : 'all',
            }}>
            <FormControlLabel
              value={TaskScheduleType.NextWorkDay}
              control={<Radio sx={{ ...radioButton }} />}
              label={getNextShiftLabel()}
              sx={{ ...radioButtonText }}
            />
          </Box>
          <Box sx={{ display: 'flex', gap: '2rem', pointerEvents: isDataLoading ? 'none' : 'auto' }}>
            <FormControlLabel
              value={TaskScheduleType.SpecificDay}
              control={<Radio sx={{ ...radioButton }} />}
              label={t('taskRejectionAndRework:selectSpecificDateToCompleteTask')}
              sx={{ ...radioButtonText }}
            />
            {reworkShift === TaskScheduleType.SpecificDay && (
              <DropdownWrapper>
                <DatePickerWrapper
                  sx={{
                    maxWidth: '13rem',
                    '& .MuiOutlinedInput-root': {
                      '& .MuiInputBase-input': {
                        padding: '0.5625rem 0 0.5625rem 1rem',
                        color: theme.palette.primary.dark,
                      },
                    },
                    '& .react-datepicker-popper': {
                      zIndex: 3,
                      transform: 'translate(50%, -50%) !important',
                    },
                    pointerEvents: isDataLoading ? 'none' : 'auto',
                  }}>
                  <DateSelection
                    requiredText="Date is mandatory"
                    control={control}
                    fieldName={'customShiftDate'}
                    label={t('taskRejectionAndRework:selectDate')}
                    isExcludePastDate={true}
                    showSpecificRangeDates={{
                      from: tomorrowDate,
                      to: isWorkOrderEndDateValid() ? workOrderEndDate : undefined,
                    }}
                    textFieldStyles={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                          borderWidth: 1,
                          borderColor: theme.palette.primary.dark,
                          borderRadius: '1rem',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: theme.palette.primary.dark,
                        '&.Mui-focused': {
                          color: theme.palette.primary.dark,
                        },
                      },
                    }}
                  />
                </DatePickerWrapper>
              </DropdownWrapper>
            )}
          </Box>
        </RadioGroup>
      </FormControl>
      <ButtonWrapper>
        <Button
          sx={{
            ...cancelButton,
            cursor: 'pointer',
            maxWidth: '100%',
            marginTop: '2em',
            width: '100%',
            opacity: isDataLoading ? 0.5 : 1,
          }}
          disabled={isDataLoading}
          onClick={handleRequestReworkClose}>
          <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>
            {t('taskRejectionAndRework:cancel')}
          </Typography>
        </Button>
        <Button
          sx={{
            ...requestReworkButton,
            maxWidth: '100%',
            marginTop: '2em',
            width: '100%',
            opacity: isDataLoading ? 0.5 : 1,
          }}
          disabled={isDataLoading}
          onClick={handleRequestRework}>
          {isDataLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
          ) : (
            <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
              {(role as UserType) === UserType.Customer
                ? t('taskRejectionAndRework:requestRework')
                : t('taskRejectionAndRework:scheduleRework')}
            </Typography>
          )}
        </Button>
      </ButtonWrapper>
      {!isEmpty(proofs) && isDataLoading && (
        <NoteText>
          {t('taskRejectionAndRework:note')}: {t('taskRejectionAndRework:uploadingProofsMightTakeTime')}
        </NoteText>
      )}
    </RejectAndReworkCardWrapper>
  );
};

export default RequestOrScheduleRework;
