/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { API } from 'aws-amplify';

import {
  AddWorkOrderTeamMutationVariables,
  BooleanType,
  CreateScheduleAssignments,
  // AssignTaskInput,
  // AssignTeamInput,
  CreateTaskApprovalInput,
  CreateTaskAttachmentInput,
  PreShiftChecklistTemplateInput,
  RescheduleWorkDayInput,
  ShiftDetailsInput,
  UpdateScheduleAssignments,
  UpdateShiftDetailsInput,
  // ShiftDetailsInput,
  // TasksQueryVariables,
  UpdateTaskAttachmentInput,
  // UpdateTaskAttachmentInput,
  UpdateTaskInput,
  UpdateTaskScheduleInput,
  UpdateWorkOrderInput,
  UserManagers,
  WorkOrderStatus,
  // UpdateTaskScheduleInput,
  // UpdateWorkOrderInput,
  // WorkOrderStatus,
} from '../../../API';
import {
  addPreShiftChecklistTemplate,
  addScheduleAssignments,
  addShiftDetails,
  // addJobAcceptance,
  // addShiftDetails,
  addTaskApproval,
  addTaskAttachment,
  addWorkOrderTeam,
  createPreShiftChecklistTemplate,
  deleteScheduleAssignments,
  rescheduleWorkDays,
  updateMultipleTask,
  updatePreShiftChecklistTemplate,
  updateScheduleAssignments,
  updateShiftDetails,
  updateTask,
  updateTaskAttachment,
  updateTaskSchedule,
  updateWorkOrderStatus,
  // updateTaskAssignment,
  // updateTaskAttachment,
  // updateTaskSchedule,
  // updateWorkOrderStatus,
} from '../../../graphql/mutations';
import {
  // allPunchInOutByWorkDay,
  allShiftDetails,
  allUpcomingJobsByVendorId,
  workOrderTaskAttachment,
  workOrderTeam,
  allPreShiftChecklistTemplate,
  latestPunchInOutByWorkDay,
  tasksV2,
  allJobShiftDetailsByCustomerWorkDay,
  allPunchInOutByWorkDay,
  findWorkDay,
  scheduleAssignments,
  preShiftChecklistTemplates,
  scheduleMasterServices,
  schedulesByDate,
} from '../../../graphql/queries';
import { IGraphQLError, IGraphQLResponse } from '../../../Shared/Models';
import { CustomHeaders, AWSAuthMode, numberOfTasksPerBatch } from '../../../Shared/Constants/App';
import { workOrderMock } from '../Components/IssuesRaised/MockData';
import CustomerApiService from '../../Customer/Services/CustomerService';
import { chunk, isEmpty } from 'lodash';
import { ITaskParameters } from '../../../Shared/Components/Common/Graphs/models';
import { formattingDate } from '../../../Shared/Utilities/utils';

interface IGetAllJobIds {
  customerId?: string;
  isJobAccepted?: boolean;
  jobCreatedBy?: string;
  jobCreatorRoleName?: string;
  jobEndDate?: string;
  jobStartDate?: string;
  status?: string;
  userId?: string;
  vendorId?: string;
  workOrderId?: string;
  jobId?: string;
  pageNumber?: number;
  limit?: number;
  facilityId?: string;
  sortBy?: string;
  sortOrder?: string;
  searchText?: string;
}

interface IAllPunchInOutInput {
  userId?: string;
  workDayId?: string;
}

interface IGetAllWorkOrderTeams {
  fromDate?: string;
  toDate?: string;
  workDayId?: string;
  workOrderId: string;
  woScheduleMasterId: string;
  taskId?: string;
  punchInGraceMinutes?: number;
  punchOutGraceMinutes?: number;
}

export default class WorkOrdersApiService {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  static async getAllWorkOrders(ids: IGetAllJobIds, isMocked = false) {
    if (isMocked) {
      return { data: workOrderMock, errors: [] };
    }

    const variables: IGetAllJobIds = {};
    const {
      customerId,
      status,
      vendorId,
      workOrderId,
      userId,
      jobStartDate,
      jobEndDate,
      jobId,
      pageNumber,
      limit,
      sortBy,
      sortOrder,
      facilityId,
      jobCreatorRoleName,
      jobCreatedBy,
    } = ids;

    if (customerId) variables.customerId = customerId;
    if (status) variables.status = status;
    if (vendorId) variables.vendorId = vendorId;
    if (workOrderId) variables.workOrderId = workOrderId;
    if (userId) variables.userId = userId;
    if (ids.isJobAccepted || ids.isJobAccepted === false) variables.isJobAccepted = ids.isJobAccepted;
    if (jobStartDate) variables.jobStartDate = jobStartDate;
    if (jobEndDate) variables.jobEndDate = jobEndDate;
    if (jobId) variables.jobId = jobId;
    if (pageNumber) variables.pageNumber = pageNumber;
    if (limit) variables.limit = limit;
    if (sortBy) variables.sortBy = sortBy;
    if (sortOrder) variables.sortOrder = sortOrder;
    if (facilityId) variables.facilityId = facilityId;
    if (jobCreatorRoleName) variables.jobCreatorRoleName = ids.jobCreatorRoleName;
    if (jobCreatedBy) variables.jobCreatedBy = ids.jobCreatedBy;

    variables.pageNumber = ids?.pageNumber ?? 1;
    variables.limit = ids?.limit ?? 10;
    // variables.searchText = '@Test Associate on the job flow 1';

    try {
      const allJobsByVendorIdResponse: IGraphQLResponse = await API.graphql(
        {
          query: allUpcomingJobsByVendorId,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );

      return {
        data: allJobsByVendorIdResponse.data.allUpcomingJobsByVendorId.data ?? [],
        metadata: allJobsByVendorIdResponse.data?.allUpcomingJobsByVendorId?.metadata ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getAllJobs() {
    try {
      const allJobsResponse: IGraphQLResponse = await API.graphql(
        {
          query: allUpcomingJobsByVendorId,
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: allJobsResponse.data?.allUpcomingJobsByVendorId ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async updateWorkOrderStatus(updateWorkOrderInput: UpdateWorkOrderInput) {
    try {
      const updatedWorkOrderResponse: IGraphQLResponse = await API.graphql(
        {
          query: updateWorkOrderStatus,
          variables: { updateWorkOrderInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: updatedWorkOrderResponse.data?.updateWorkOrderStatus ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  // static async getWorkOrderFacilityAccess(workOrderId: string, workOrderFacilityAccessId: string) {
  //   try {
  //     const workOrderFacilityResponse = await API.graphql({
  //       query: workOrderFacilityAccess,
  //       variables: { workOrderId, workOrderFacilityAccessId },
  //       authMode: AWSAuthMode.COGNITO,
  //     }, CustomHeaders);
  //     return { data: workOrderFacilityResponse.data?.workOrderFacilityAccess ?? [], errors: [] };
  //   } catch (e) {
  //     return { data: [], errors: e.errors };
  //   }
  // }

  static async getallShiftDetails(workOrderId: string) {
    try {
      const allShiftDetailsResponse: IGraphQLResponse = await API.graphql(
        {
          query: allShiftDetails,
          variables: { workOrderId },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: allShiftDetailsResponse.data?.allShiftDetails ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async addShiftDetails(workOrderId: string, createShiftDetailsInput: ShiftDetailsInput[]) {
    try {
      const shiftDetailsResponse: IGraphQLResponse = await API.graphql(
        {
          query: addShiftDetails,
          variables: { workOrderId, createShiftDetailsInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: shiftDetailsResponse.data?.addShiftDetails ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getLatestPunchRecords(workDayId: string, userId?: string, showRemovedAssociatePunches?: string) {
    try {
      const latestPunchInOutByWorkDayResponse: IGraphQLResponse = await API.graphql(
        {
          query: latestPunchInOutByWorkDay,
          variables: { workDayId, userId, showRemovedAssociatePunches },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return {
        data: latestPunchInOutByWorkDayResponse.data?.latestPunchInOutByWorkDay ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: null, errors: e.errors };
    }
  }

  // static async updateTaskSchedule(updateTaskScheduleInput: UpdateTaskScheduleInput[]) {
  //   try {
  //     const updateTaskScheduleResponse = await API.graphql({
  //       query: updateTaskSchedule,
  //       variables: { updateTaskScheduleInput },
  //       authMode: AWSAuthMode.COGNITO,
  //     }, CustomHeaders);
  //     return { data: updateTaskScheduleResponse?.data?.updateTaskSchedule ?? [], errors: [] };
  //   } catch (e) {
  //     return { data: [], errors: e.errors };
  //   }
  // }

  // static async getAllUpcomingJobsForAssociate(
  //   fromDate: string,
  //   toDate?: string,
  //   pageSize?: number,
  //   status?: WorkOrderStatus
  // ) {
  //   const variables: IGetAllUpcomingTasks = {};
  //   if (fromDate) variables.fromDate = fromDate;
  //   if (toDate) variables.toDate = toDate;
  //   if (pageSize) variables.pageSize = pageSize;
  //   if (status) variables.status = status;
  //   try {
  //     const allUpcomingJobsForAssociateResponse = await API.graphql({
  //       query: allUpcomingJobsForAssociate,
  //       variables,
  //       authMode: AWSAuthMode.COGNITO,
  //     }, CustomHeaders);
  //     return { data: allUpcomingJobsForAssociateResponse.data?.allUpcomingJobsForAssociate ?? [], errors: [] };
  //   } catch (e) {
  //     return { data: [], errors: e.errors };
  //   }
  // }

  // static async updateTaskAssignment(taskAssignmentInput: AssignTaskInput) {
  //   try {
  //     const updateTaskAssignmentResponse = await API.graphql({
  //       query: updateTaskAssignment,
  //       variables: { taskAssignmentInput },
  //       authMode: AWSAuthMode.COGNITO,
  //     }, CustomHeaders);
  //     return { data: updateTaskAssignmentResponse.data?.updateTaskAssignment ?? [], errors: [] };
  //   } catch (e) {
  //     return { data: [], errors: e.errors };
  //   }
  // }

  static async getAllPunchInOutByWorkDay(punchInOutInput: IAllPunchInOutInput) {
    try {
      const variables: IAllPunchInOutInput = {};
      if (punchInOutInput.userId) variables.userId = punchInOutInput.userId;
      if (punchInOutInput.workDayId) variables.workDayId = punchInOutInput.workDayId;
      const allPunchInOutByWorkDayResponse: IGraphQLResponse = await API.graphql(
        {
          query: allPunchInOutByWorkDay,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return {
        data: allPunchInOutByWorkDayResponse.data?.allPunchInOutByWorkDay ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  // static async getLatestPunchInOutByWorkDay(userId: string, workDayId?: string, mocked?: boolean) {
  //   try {
  //     const latestPunchInOutByWorkDayResponse = await API.graphql({
  //       query: latestPunchInOutByWorkDay,
  //       variables: { workDayId, userId },
  //       authMode: AWSAuthMode.COGNITO,
  //     }, CustomHeaders);
  //     return {
  //       data: mocked
  //         ? latestPunchInOutByWorkDayMockedData.data.latestPunchInOutByWorkDay
  //         : latestPunchInOutByWorkDayResponse.data?.latestPunchInOutByWorkDay ?? [],
  //       errors: [],
  //     };
  //   } catch (e) {
  //     return mocked
  //       ? { data: latestPunchInOutByWorkDayMockedData.data.latestPunchInOutByWorkDay, errors: [] }
  //       : { data: [], errors: e.errors };
  //   }
  // }

  // static async updateJobAcceptance(teamId: string, workOrderId?: string, assignTeamInput?: AssignTeamInput) {
  //   try {
  //     const addJobAcceptanceResponse = await API.graphql({
  //       query: addJobAcceptance,
  //       variables: { teamId, workOrderId, assignTeamInput },
  //       authMode: AWSAuthMode.COGNITO,
  //     }, CustomHeaders);
  //     return { data: addJobAcceptanceResponse?.data?.addJobAcceptance ?? [], errors: [] };
  //   } catch (e) {
  //     return { data: [], errors: e.errors };
  //   }
  // }
  // static async getTasks({
  //   workOrderId,
  //   Status,
  //   facilityId,
  //   associateId,
  //   serviceId,
  //   fromDate,
  //   toDate,
  //   workDate,
  // }: TasksQueryVariables) {
  //   const variables: TasksQueryVariables = {};
  //   if (workOrderId) variables.workOrderId = workOrderId;
  //   if (Status) variables.Status = Status;
  //   if (facilityId) variables.facilityId = facilityId;
  //   if (associateId) variables.associateId = associateId;
  //   if (serviceId) variables.serviceId = serviceId;
  //   if (fromDate) variables.fromDate = fromDate;
  //   if (toDate) variables.toDate = toDate;
  //   if (workDate) variables.workDate = workDate;
  //   try {
  //     const tasksResponse = await API.graphql(
  //       {
  //         query: tasks,
  //         variables,
  //         authMode: AWSAuthMode.COGNITO,
  //       },
  //       CustomHeaders
  //     );
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     return { data: tasksResponse.data?.tasks ?? [], errors: [] };
  //   } catch (e: any) {
  //     return { data: [], errors: e.errors };
  //   }
  // }

  static async getTasksV2(taskDetails: ITaskParameters, isMocked: boolean) {
    try {
      const variables: ITaskParameters = {};
      if (taskDetails.workOrderId) variables.workOrderId = taskDetails.workOrderId;
      if (taskDetails.fromDate) variables.fromDate = taskDetails.fromDate;
      if (taskDetails.toDate) variables.toDate = taskDetails.toDate;
      if (taskDetails.Status) variables.Status = taskDetails.Status;
      if (taskDetails.status) variables.status = taskDetails.status;
      if (taskDetails.associateId) variables.associateId = taskDetails.associateId;
      if (taskDetails.workDayId) variables.workDayId = taskDetails.workDayId;
      const associateTasksV2: IGraphQLResponse = await API.graphql({
        query: tasksV2,
        variables,
        authMode: AWSAuthMode.COGNITO,
      });
      const associateTasks = associateTasksV2.data.tasksV2 ?? [];
      return { data: isMocked ? [] : associateTasks, errors: [] };
    } catch (e: unknown) {
      const apiError: IGraphQLError = e as IGraphQLError;
      return { data: [], errors: apiError.errors };
    }
  }

  static async addTaskApproval(taskId: string, createTaskApproval: CreateTaskApprovalInput) {
    try {
      const addTaskApprovalResponse: IGraphQLResponse = await API.graphql(
        {
          query: addTaskApproval,
          variables: { taskId, createTaskApproval },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: addTaskApprovalResponse.data?.addTaskApproval ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getWorkOrderTaskAttachment(taskId: string) {
    try {
      const workOrderTaskAttachmentResponse: IGraphQLResponse = await API.graphql(
        {
          query: workOrderTaskAttachment,
          variables: { taskId },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: workOrderTaskAttachmentResponse.data?.workOrderTaskAttachment ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async updateTasks(taskId: string, updateTaskInput: UpdateTaskInput) {
    try {
      const associateupdatedTasks: IGraphQLResponse = await API.graphql(
        {
          query: updateTask,
          variables: { taskId, updateTaskInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: associateupdatedTasks.data.updateTask ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getVendorTeam(
    managerId: string,
    userId?: string,
    isAssociate?: BooleanType,
    searchString?: string,
    pageNumber?: number,
    limit?: number,
    isPaginationRequire = false // This flag can use if pagination is required for this query.
  ) {
    try {
      const managersTeamsResponse = await CustomerApiService.getUserManagers(
        managerId,
        userId || '',
        isAssociate,
        searchString,
        pageNumber,
        limit,
        isPaginationRequire
      );

      const modifiedUsersData =
        managersTeamsResponse?.data?.map((userData: UserManagers) => {
          return {
            ...userData?.user,
            username: userData?.user?.userName,
            userId: userData?.userId,
          };
        }) || [];

      const usersData = modifiedUsersData;
      const usersError = managersTeamsResponse.errors;
      if (!isEmpty(usersError)) {
        return { data: [], errors: usersError, metadata: {} };
      }
      return { data: usersData || [], metadata: managersTeamsResponse?.metadata ?? {}, errors: [] };
    } catch (e: unknown) {
      const apiError: IGraphQLError = e as IGraphQLError;
      return { data: [], errors: apiError.errors, metadata: {} };
    }
  }

  static async getWorkOrderTeam(workOrderId: string, fromDate?: string, toDate?: string) {
    try {
      const variables: any = {};
      if (workOrderId) variables.workOrderId = workOrderId;
      if (fromDate) variables.fromDate = fromDate;
      if (toDate) variables.toDate = toDate;
      const workOrderTeamResponse: IGraphQLResponse = await API.graphql(
        {
          query: workOrderTeam,
          variables: { workOrderId, fromDate, toDate },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: workOrderTeamResponse.data?.workOrderTeam ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  static async getWorkOrderTeams({
    workOrderId,
    woScheduleMasterId,
    workDayId,
    taskId,
    fromDate,
    toDate,
    punchInGraceMinutes,
    punchOutGraceMinutes,
  }: IGetAllWorkOrderTeams) {
    const variables: IGetAllWorkOrderTeams = { workOrderId, woScheduleMasterId };
    if (workDayId) variables.workDayId = workDayId;
    if (fromDate) variables.fromDate = fromDate;
    if (toDate) variables.toDate = toDate;
    if (taskId) variables.taskId = taskId;
    if (punchInGraceMinutes) variables.punchInGraceMinutes = punchInGraceMinutes;
    if (punchOutGraceMinutes) variables.punchOutGraceMinutes = punchOutGraceMinutes;
    try {
      const workOrderTeamResponse: IGraphQLResponse = await API.graphql({
        query: scheduleAssignments,
        variables,
        authMode: AWSAuthMode.COGNITO,
      });
      let modifiedWorkOrderTeamResponse: any[] = workOrderTeamResponse.data?.scheduleAssignments ?? [];

      if (!isEmpty(modifiedWorkOrderTeamResponse)) {
        modifiedWorkOrderTeamResponse =
          modifiedWorkOrderTeamResponse?.map((eachWorkOrderTeam) => {
            const EachWorkOrderTeamAssociateStartDate = eachWorkOrderTeam?.associateStartDate;
            const EachWorkOrderTeamAssociateEndDate = eachWorkOrderTeam?.associateEndDate;
            return {
              ...eachWorkOrderTeam,
              associateStartDate: formattingDate(EachWorkOrderTeamAssociateStartDate),
              associateEndDate: formattingDate(EachWorkOrderTeamAssociateEndDate),
            };
          }) ?? [];
      }
      return { data: modifiedWorkOrderTeamResponse || [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async updateWorkOrderTeam({ workOrderId, createWorkOrderTeamInput }: AddWorkOrderTeamMutationVariables) {
    try {
      const addWorkOrderTeamResponse: IGraphQLResponse = await API.graphql(
        {
          query: addWorkOrderTeam,
          variables: { workOrderId, createWorkOrderTeamInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: addWorkOrderTeamResponse.data?.addWorkOrderTeam ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async addTeamAssignmentWorkOrderTeam({
    workOrderId,
    createScheduleAssignmentsInput,
    woScheduleMasterId,
  }: {
    workOrderId: string;
    woScheduleMasterId: string;
    createScheduleAssignmentsInput: CreateScheduleAssignments[];
  }) {
    try {
      const addTeamAssignmentWorkOrderTeamResponse: IGraphQLResponse = await API.graphql({
        query: addScheduleAssignments,
        variables: { workOrderId, createScheduleAssignmentsInput, woScheduleMasterId },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: addTeamAssignmentWorkOrderTeamResponse?.data?.addScheduleAssignments ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async updateTeamAssignmentWorkOrderTeam({
    workOrderId,
    updateScheduleAssignmentsInput,
  }: {
    workOrderId: string;
    updateScheduleAssignmentsInput: UpdateScheduleAssignments[];
  }) {
    try {
      const updateTeamAssignmentWorkOrderTeamResponse: IGraphQLResponse = await API.graphql({
        query: updateScheduleAssignments,
        variables: { workOrderId, updateScheduleAssignmentsInput },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: updateTeamAssignmentWorkOrderTeamResponse?.data?.updateScheduleAssignments ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async deleteTeamAssignmentWorkOrderTeam({
    workOrderId,
    userIds,
    woScheduleMasterId,
  }: {
    workOrderId: string;
    woScheduleMasterId: string;
    userIds: string[];
  }) {
    try {
      const deleteTeamAssignmentWorkOrderTeamResponse: IGraphQLResponse = await API.graphql({
        query: deleteScheduleAssignments,
        variables: { workOrderId, userIds, woScheduleMasterId },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: deleteTeamAssignmentWorkOrderTeamResponse?.data?.deleteScheduleAssignments ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async updateShiftDetails(updateShiftDetailsInput: UpdateShiftDetailsInput[]) {
    try {
      const updateShiftDetailsResponse: IGraphQLResponse = await API.graphql({
        query: updateShiftDetails,
        variables: { updateShiftDetailsInput },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: updateShiftDetailsResponse?.data?.updateShiftDetails ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async updateTaskSchedule(updateTaskScheduleInput: UpdateTaskScheduleInput[]) {
    try {
      const totalTasksBatch = chunk(updateTaskScheduleInput, 8);

      const allTaskUpdateResponse = await Promise.allSettled(
        totalTasksBatch.map((taskBatchInput: UpdateTaskScheduleInput[]) => {
          return API.graphql(
            {
              query: updateTaskSchedule,
              variables: { updateTaskScheduleInput: taskBatchInput },
              authMode: AWSAuthMode.COGNITO,
            },
            CustomHeaders
          );
        })
      );

      let data: UpdateTaskScheduleInput[] = [];
      allTaskUpdateResponse.forEach((updateResponse: any) => {
        data = [...data, ...(updateResponse?.value?.data?.updateTaskSchedule ?? [])];
      });
      return { data, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async getAllPreShiftChecklistTemplate(workOrderId: string) {
    try {
      const allPreShiftChecklistTemplateResponse: IGraphQLResponse = await API.graphql(
        {
          query: allPreShiftChecklistTemplate,
          variables: { workOrderId },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: allPreShiftChecklistTemplateResponse.data?.allPreShiftChecklistTemplate ?? [], errors: [] };
    } catch (e: any) {
      console.error('Error', e);
      return { data: [], errors: e.errors };
    }
  }

  static async addPreShiftChecklistTemplate(
    workOrderId: string,
    createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput //[]
  ) {
    try {
      const addPreShiftChecklistResponse: IGraphQLResponse = await API.graphql(
        {
          query: addPreShiftChecklistTemplate,
          variables: { workOrderId, createPreShiftChecklistTemplateInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: addPreShiftChecklistResponse.data?.addPreShiftChecklistTemplate ?? [], errors: [] };
    } catch (e: any) {
      console.error('Error', e);
      return { data: [], errors: e.errors };
    }
  }

  static async updatePreShiftChecklistTemplate(
    preShiftChecklistTemplateId: string,
    createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput //[]
  ) {
    try {
      const addPreShiftChecklistResponse: IGraphQLResponse = await API.graphql(
        {
          query: updatePreShiftChecklistTemplate,
          variables: { preShiftChecklistTemplateId, createPreShiftChecklistTemplateInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: addPreShiftChecklistResponse.data?.updatePreShiftChecklistTemplate ?? [], errors: [] };
    } catch (e: any) {
      console.error('Error', e);
      return { data: [], errors: e.errors };
    }
  }

  static async addTaskAttachments(taskId: string, createTaskAttachment: CreateTaskAttachmentInput[]) {
    try {
      const addTaskAttachmentsResponse: IGraphQLResponse = await API.graphql(
        {
          query: addTaskAttachment,
          variables: { taskId, createTaskAttachment },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: addTaskAttachmentsResponse.data.addTaskAttachment ?? [], errors: [] };
    } catch (e: unknown) {
      const apiError: IGraphQLError = e as IGraphQLError;
      return { data: [], errors: apiError.errors };
    }
  }

  static async updateTaskAttachment(taskId: string, updateTaskAttachmentInput: UpdateTaskAttachmentInput[]) {
    try {
      const updateTaskAttachmentResponse: IGraphQLResponse = await API.graphql(
        {
          query: updateTaskAttachment,
          variables: { taskId, updateTaskAttachmentInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: updateTaskAttachmentResponse.data?.updateTaskAttachment ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async updateMultipleTasksAtOnce(updateTaskInput: UpdateTaskInput[]) {
    try {
      let updateAllTasksResponse: { data: any[]; errors: IGraphQLError[] } = { data: [], errors: [] };

      if (updateTaskInput?.length > numberOfTasksPerBatch) {
        const totalTasksBatch = chunk(updateTaskInput, numberOfTasksPerBatch);
        const allTaskUpdateResponse = await Promise.allSettled(
          totalTasksBatch.map(async (taskBatchInput: UpdateTaskInput[]) => {
            return (await API.graphql(
              {
                query: updateMultipleTask,
                variables: { updateTaskInput: taskBatchInput },
                authMode: AWSAuthMode.COGNITO,
              },
              CustomHeaders
            )) as IGraphQLResponse;
          })
        );
        let data: UpdateTaskInput[] = [];
        allTaskUpdateResponse.forEach((updateResponse) => {
          if (updateResponse.status === 'fulfilled') {
            data = [...data, ...(updateResponse?.value?.data?.updateMultipleTask ?? [])];
          }
        });
        updateAllTasksResponse = { data, errors: [] };
      } else {
        const singleBatchResponse: IGraphQLResponse = (await API.graphql(
          {
            query: updateMultipleTask,
            variables: { updateTaskInput },
            authMode: AWSAuthMode.COGNITO,
          },
          CustomHeaders
        )) as IGraphQLResponse;
        updateAllTasksResponse = {
          data: singleBatchResponse.data.updateMultipleTask ?? [],
          errors: [],
        };
      }
      return {
        data: updateAllTasksResponse.data,
        errors: updateAllTasksResponse.errors ?? [],
      };
    } catch (e: unknown) {
      const apiError: IGraphQLError = e as IGraphQLError;
      return { data: [], errors: apiError.errors };
    }
  }

  static async allJobShiftDetailsByCustomerWorkDay(
    workDate: string,
    customerId: string,
    workOrderStatus?: WorkOrderStatus,
    facilityId?: string,
    isJobAccepted?: string
  ) {
    const variables: {
      customerId: string;
      facilityId?: string;
      isJobAccepted?: string;
      workDate: string;
      workOrderStatus?: WorkOrderStatus;
    } = { workDate, customerId };

    if (facilityId) {
      variables.facilityId = facilityId;
    }
    if (isJobAccepted) {
      variables.isJobAccepted = isJobAccepted;
    }
    if (workOrderStatus) {
      variables.workOrderStatus = workOrderStatus;
    }
    try {
      const allJobShiftDetailsByCustomerWorkDayResponse: IGraphQLResponse = await API.graphql(
        {
          query: allJobShiftDetailsByCustomerWorkDay,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return {
        data: allJobShiftDetailsByCustomerWorkDayResponse.data?.allJobShiftDetailsByCustomerWorkDay ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async rescheduleWorkDays(workOrderId: string, rescheduleWorkDayInput: RescheduleWorkDayInput[]) {
    try {
      const rescheduleWorkDaysResponse: IGraphQLResponse = await API.graphql(
        {
          query: rescheduleWorkDays,
          variables: { workOrderId, rescheduleWorkDayInput },
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: rescheduleWorkDaysResponse.data?.rescheduleWorkDays ?? {}, errors: [] };
    } catch (e: any) {
      return { data: {}, errors: e.errors };
    }
  }

  static async findWorkDay(workOrderId: string, actualWorkDate: string, workDate?: string) {
    try {
      const variables: { workOrderId: string; actualWorkDate: string; workDate?: string } = {
        workOrderId,
        actualWorkDate,
      };
      if (workDate) {
        variables.workDate = workDate;
      }

      const findWorkDayResponse: IGraphQLResponse = await API.graphql(
        {
          query: findWorkDay,
          variables,
          authMode: AWSAuthMode.COGNITO,
        },
        CustomHeaders
      );
      return { data: findWorkDayResponse.data?.findWorkDay ?? {}, errors: [] };
    } catch (e: any) {
      return { data: {}, errors: e.errors };
    }
  }

  static async getLatestPunchInOutByWorkDay(workDayId: string, userId?: string, showRemovedAssociatePunches = false) {
    try {
      const latestPunchInOutByWorkDayResponse: IGraphQLResponse = await API.graphql({
        query: latestPunchInOutByWorkDay,
        variables: { workDayId, userId, showRemovedAssociatePunches },
        authMode: AWSAuthMode.COGNITO,
      });
      return {
        data: latestPunchInOutByWorkDayResponse.data?.latestPunchInOutByWorkDay ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async createPreShiftChecklistTemplate(createPreShiftCheckListItemsPayload: {
    workOrderId: string;
    woScheduleMasterId: string;
    createPreShiftChecklistTemplateInput: PreShiftChecklistTemplateInput;
  }) {
    const { workOrderId, createPreShiftChecklistTemplateInput, woScheduleMasterId } =
      createPreShiftCheckListItemsPayload;
    try {
      const createPreShiftChecklistTemplateResponse: IGraphQLResponse = await API.graphql({
        query: createPreShiftChecklistTemplate,
        variables: { workOrderId, createPreShiftChecklistTemplateInput, woScheduleMasterId },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: createPreShiftChecklistTemplateResponse?.data?.createPreShiftChecklistTemplate ?? [], errors: [] };
    } catch (e: any) {
      console.error('Error', e);
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async getAllPreShiftCheckListTemplates(payload: { workOrderId: string; woScheduleMasterId: string }) {
    const { workOrderId, woScheduleMasterId } = payload;
    try {
      const allPreShiftChecklistTemplatesResponse: IGraphQLResponse = await API.graphql({
        query: preShiftChecklistTemplates,
        variables: { workOrderId, woScheduleMasterId },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: allPreShiftChecklistTemplatesResponse?.data?.preShiftChecklistTemplates ?? [], errors: [] };
    } catch (e: any) {
      console.error('Error', e);
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async getScheduleMasterServices(payload: { woScheduleMasterId: string }) {
    const { woScheduleMasterId } = payload;
    try {
      const scheduleMasterServicesResponse: IGraphQLResponse = await API.graphql({
        query: scheduleMasterServices,
        variables: { woScheduleMasterId },
        authMode: AWSAuthMode.COGNITO,
      });
      return { data: scheduleMasterServicesResponse?.data?.scheduleMasterServices ?? [], errors: [] };
    } catch (e: any) {
      return { data: [], errors: e?.errors ?? [] };
    }
  }

  static async getSchedulesByDate(workOrderId: string, scheduleDate: string) {
    try {
      const schedulesByDateResponse: IGraphQLResponse = await API.graphql({
        query: schedulesByDate,
        variables: { workOrderId, scheduleDate },
        authMode: AWSAuthMode.COGNITO,
      });
      console.log('schedulesByDateResponse', schedulesByDateResponse);
      return {
        data: schedulesByDateResponse?.data?.schedulesByDate ?? [],
        errors: [],
      };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}
