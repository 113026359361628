/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { useForm, useWatch } from 'react-hook-form';

import DataGrid from '../../../../Shared/Components/Common/DataGrid/DataGrid';
import { ReadMore } from '../../../../Shared/Components/Common/DataGrid/DataGrid.styles';
import PageTitle from '../../../../Shared/Components/Common/PageTitle/PageTitle';
import { CustomerContainer } from '../../../../Shared/Components/Layout/styles';
import { getStoredCustomerDetails, getAuthDetails, JobDetails, getAuthData } from '../../../../Shared/Utilities/utils';
import { TaskData } from '../WorkTasks/WorkTasks';
import { JobsTitle } from '../IssuesRaised/JobsIssuesRaised.styles';
import { IJobs } from '../JobView/JobsView';
import { TitleWrapper, TableWrapper } from './JobsListView.styles';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { DatePickerWrapper } from '../../../../Shared/Components/Common/GoogleMap/GoogleMapChips.Style';
import AnalyticsApiService, { UserType } from '../../../../Shared/Services/AnalyticsService';

import theme from '../../../../Shared/Themes/theme';

import { IBidList } from '../JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../Customer/Components/ViewFacilityDetails/ViewFacilityDetails';
import moment from 'moment';
import JobCreateApiService from '../../Services/JobCreateService';
import { StyledPagination } from '../../../../Shared/Components/Common/CommonStyle/Pagination.Style';
import { useLocation } from 'react-router-dom';

export interface IJobOverview {
  facility: string;
  facilityId: string;
  jobName: string;
  approvedByCustomer?: string | number;
  totalTasks?: number;
  status: string;
  totalCompleted: string;
  totalInProgress: string;
  totalApprovedBySupervisor: string;
  totalApprovedByCustomer: string;
  jobId?: string;
  sortingFn?: any;
  executionType?: string;
  customerId?: string;
}

export type FilterItem = {
  id: string;
  value: string;
};

export type StatusItem = {
  label: string;
  value: string;
};

export type JobTypeItem = {
  label: string;
  value: string;
};

const JobsListView = (): JSX.Element => {
  const { t } = useTranslation(['Dashboard', 'homePage', 'calendar']);
  const [jobs, setJobs] = useState<IJobOverview[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [newSort, setNewSort] = useState<boolean>(false);
  const [newSortOrder, setNewSortOrder] = useState('');
  const [columnFilters, setColumnFilters] = useState<{ id: string; value: string }[]>([
    { id: 'workDayStatus', value: 'All' },
    { id: 'jobType', value: 'All' },
  ]);
  const location = useLocation();
  const selectedDate = location.state?.selectedDate;
  const itemsPerPage = 10;
  const shiftStatuses: StatusItem[] = [
    { label: 'All', value: '' },
    { label: 'Scheduled', value: 'A' },
    { label: 'In Progress', value: 'P' },
    { label: 'Completed', value: 'S' },
    { label: 'Missed', value: 'N' },
    { label: 'Incomplete', value: 'I' },
  ];
  const jobTypes: JobTypeItem[] = [
    { label: 'All', value: '' },
    { label: 'Recurring', value: 'Recurring' },
    { label: 'One time', value: 'OneTime' },
    { label: 'Emergency', value: 'Emergency' },
  ];

  const storedAuthDetails = getAuthDetails();
  const { isDistrictManager } = getAuthData() || {};

  const { control } = useForm({
    defaultValues: {
      specificDate: selectedDate ?? new Date().toISOString(),
    },
  });

  const specificDate = useWatch({
    control,
    name: 'specificDate',
  });

  const handlePaginationChange = (event: any, value: number) => {
    handleChange(event, value);
    fetchData(moment(specificDate).toDate(), value, newSortOrder, newSort, columnFilters);
  };

  const getSortOrderString = (sortBy?: any, sortOrder?: any) => {
    let sortOrderString = sortOrder;
    if (sortOrder) {
      sortOrderString = 'desc';
    } else {
      if (sortBy) sortOrderString = 'asc';
    }
    return sortOrderString;
  };

  const fetchData = async (
    date: Date,
    currentPage: any,
    sortBy?: any,
    sortOrder?: any,
    columnFilters: FilterItem[] = []
  ) => {
    try {
      setIsLoading(true);
      const { customerId = '', userId } = getStoredCustomerDetails() || {};
      const sortOrderString = getSortOrderString(sortBy, sortOrder);

      const queryParams = columnFilters
        .map((filter) => {
          const statusMatch = shiftStatuses.find((s) => s.label.toLowerCase() === filter.value.toLowerCase());
          const value = statusMatch ? statusMatch.value : filter.value;
          return `${encodeURIComponent(filter.id)}=${encodeURIComponent(value)}`;
        })
        .join('&');

      const allIdsResponse = await AnalyticsApiService.paginatedTaskSummary(
        customerId,
        date,
        storedAuthDetails['custom:Role'] === 'Customer' ? UserType.Customer : UserType.Vendor,
        userId,
        currentPage,
        sortBy,
        sortOrderString,
        queryParams
      );

      if (allIdsResponse?.errors?.length) {
        // Handle errors if needed
        // setIsError(true);
      } else if (allIdsResponse?.data?.data?.length > 0) {
        const modifiedJobs = await processJobsData(allIdsResponse.data.data, customerId);
        setJobs(modifiedJobs);
        setTotalCount(allIdsResponse.data.metadata.totalCount);
      } else {
        setJobs([]);
        setTotalCount(allIdsResponse.data.metadata.totalCount);
      }
    } catch (e) {
      console.log('Error', e);
      // Handle error state
    } finally {
      setIsLoading(false);
    }
  };

  const processJobsData = async (jobsData: any[], customerId: string) => {
    return await Promise.all(
      jobsData.map(async (item: any) => {
        const modifiedJob: any = { ...item };
        const jobSummaryResponse = await JobCreateApiService.jobSummaryV2(customerId, item.jobId);
        modifiedJob.customerId = jobSummaryResponse?.data?.customerId;
        modifiedJob.executionType =
          jobSummaryResponse?.data?.jobVendors?.[0]?.mdVendorType === 'SubVendor'
            ? JobDetails.SubVendor
            : JobDetails.Self;
        return modifiedJob;
      })
    );
  };

  const handleChange = (event: any, value: number): void => {
    setCurrentPage(value);
  };

  const filterJobs = (filtersObj: FilterItem[]): void => {
    setColumnFilters(filtersObj);
    if (filtersObj?.length) {
      fetchData(moment(specificDate).toDate(), currentPage, newSortOrder, newSort, filtersObj);
    } else {
      fetchData(moment(specificDate).toDate(), currentPage, newSortOrder, newSort, []);
    }
  };

  const sortingJobs = (sortingObj: any): void => {
    setSorting(sortingObj);
    if (sortingObj.length) {
      setNewSortOrder(sortingObj[0].id);
      setNewSort(sortingObj[0].desc);
      fetchData(moment(specificDate).toDate(), currentPage, sortingObj[0].id, sortingObj[0].desc);
    } else {
      fetchData(moment(specificDate).toDate(), currentPage, '', newSort, columnFilters);
    }
  };

  useEffect(() => {
    fetchData(moment(specificDate).toDate(), 1, '', newSort, columnFilters);
  }, [specificDate]);

  const myCustomSortingFn = (): void => {
    setSortBy('DESC');
  };

  const columns = useMemo<MRT_ColumnDef<IJobOverview | TaskData | IJobs | IBidList | IProfileCardProps>[]>(
    () => [
      {
        accessorKey: 'facilityName',
        header: 'Facility',
        enableSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        onColumnFiltersChange: setColumnFilters,
        enableColumnFilter: true,
        size: 300,
        Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : '-'),
        enableHiding: false,
        enableColumnActions: true,
      },
      {
        accessorKey: 'workOrderName',
        header: 'Job name',
        enableSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        onColumnFiltersChange: setColumnFilters,
        enableColumnFilter: true,
        size: 112,
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableColumnActions: true,
      },
      {
        accessorKey: 'jobType',
        header: 'Job type',
        enableSorting: true,
        state: { sortBy },
        onSortingChange: setSortBy,
        filterSelectOptions: jobTypes.map((jobTypeItem) => jobTypeItem.label),
        enableColumnFilter: true,
        size: 112,
        Cell: ({ renderedCellValue }) => jobTypes.find((jt) => jt.value === renderedCellValue)?.label ?? '-',
        enableColumnActions: true,
        filterVariant: 'select',
      },
      {
        accessorKey: 'workDayStatus',
        header: 'Job status',
        enableSorting: true,
        state: { sortBy, columnFilters },
        onSortingChange: setSortBy,
        onColumnFiltersChange: setColumnFilters,
        filterSelectOptions: shiftStatuses.map((shiftStatusItem) => shiftStatusItem.label),
        enableColumnFilter: true,
        enableColumnActions: true,
        size: 162,
        filterVariant: 'select',
      },
      {
        header: 'Tasks done',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ cell }) => {
          const { totalTasks, approvedByCustomer } = cell.row.original;

          return `${approvedByCustomer}/${totalTasks}`;
        },
        enableColumnActions: false,
      },
      {
        header: 'Inspection score',
        enableSorting: false,
        enableColumnFilter: false,
        size: 112,
        Cell: ({ cell }) => {
          const { totalTasks, approvedByCustomer } = cell.row.original;
          const perc =
            approvedByCustomer && totalTasks
              ? `${((Number(approvedByCustomer) / Number(totalTasks)) * 100).toFixed(0)}%`
              : '0.00%';
          return `${perc}`;
        },
        enableColumnActions: false,
      },
      {
        header: 'Actions',
        enableColumnActions: false,
        enablePinning: false,
        size: 162,
        Cell: ({ cell }) => {
          return (
            <ReadMore
              to="/job-detail/worktasks"
              state={{
                facilityItem: cell.row.original,
                jobId: getParentJobId(cell.row.original),
                executionType: cell.row.original?.executionType,
                customerId: cell.row.original?.customerId,
                selectedDate: specificDate,
              }}>
              {t('dashboard:viewDetails')}
            </ReadMore>
          );
        },
      },
    ],
    [specificDate]
  );

  const getParentJobId = (data: any) => {
    if (isDistrictManager) {
      return data.parentJobId ?? data.jobId;
    } else {
      return data.jobId;
    }
  };

  return (
    <>
      <PageTitle title={t('homePage:listView')} />
      <CustomerContainer>
        <TitleWrapper>
          <JobsTitle>{t('homePage:jobOverView')}</JobsTitle>
          <DatePickerWrapper
            sx={{
              maxWidth: '13rem',
              '& .MuiOutlinedInput-root': {
                '& .MuiInputBase-input': {
                  padding: '0.5625rem 0 0.5625rem 1rem',
                  fontSize: theme.typography.h4?.fontSize,
                  lineHeight: '1.5rem',
                  color: theme.palette.primary.dark,
                },
              },
            }}>
            <DateSelection
              requiredText="Date is mandatory"
              control={control}
              fieldName={'specificDate'}
              label={t('calendar:selectDate')}
              isExcludePastDate={false}
              isExcludeFutureDate={false}
              textFieldStyles={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset,&:hover fieldset,&.Mui-focused fieldset': {
                    borderWidth: 1,
                    borderColor: theme.palette.primary.dark,
                    borderRadius: '1rem',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.primary.dark,
                  '&.Mui-focused': {
                    color: theme.palette.primary.dark,
                  },
                },
              }}
            />
          </DatePickerWrapper>
        </TitleWrapper>
        <TableWrapper>
          <>
            <DataGrid
              columns={columns}
              data={jobs}
              enableRowSelect={false}
              enableColumnPinning={false}
              loader={isLoading}
              sortingObj={sorting}
              sort={sortingJobs}
              filter={filterJobs}
              errorMessageTitle={''}
              errorMessageDescription={t('homePage:shiftNotFound')}
              sortingFns={{
                myCustomSortingFn: myCustomSortingFn,
              }}
            />
            <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
              {!isLoading && totalCount > 10 && (
                <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                  <StyledPagination
                    count={Math.ceil(totalCount / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    color="primary"
                  />
                </Stack>
              )}
              {!isLoading && (
                <Stack spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
                  <Typography>{`Total items: ${totalCount}`}</Typography>
                </Stack>
              )}
            </Box>
          </>
        </TableWrapper>
      </CustomerContainer>
    </>
  );
};

export default JobsListView;
