/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import awsConfig from '../../Configuration/environment-config';
import { formatDateToYYMMDDFormat } from '../Utilities/utils';

export enum AWSAuthMode {
  API_KEY = 'API_KEY',
  COGNITO = 'AMAZON_COGNITO_USER_POOLS',
}

export enum UserType {
  Customer = 'Customer',
  Vendor = 'Vendor',
}

export interface IGraphQLResponse {
  data?: any;
  errors?: any;
}

enum Message {
  NoEndPoint = 'Endpoint not found',
}

export default class AnalyticsApiService {
  static async taskSummary(id: string, workDate: string | number | Date, userType: UserType, userId: string) {
    try {
      const taskSummary = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'taskSummary'
      );

      if (!taskSummary?.endpoint) {
        return { data: [], errors: [{ message: Message.NoEndPoint }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const startDateYMDFormat = formatDateToYYMMDDFormat(workDate);
      const queryParams =
        userType === UserType.Customer
          ? `?workDate=${startDateYMDFormat}&customerId=${id}&userId=${userId}`
          : `?workDate=${startDateYMDFormat}&vendorId=${id}&userId=${userId}`;
      const endpointWithParams = `${taskSummary.endpoint}${queryParams}`;

      const taskSummaryResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await taskSummaryResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async paginatedTaskSummary(
    id: string,
    workDate: string | number | Date,
    userType: UserType,
    userId: string,
    pageIndex: number,
    sortBy?: string,
    sortOrder?: string,
    filterQueryParams?: string
  ) {
    try {
      const paginatedTaskSummary = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'paginatedTaskSummary'
      );

      if (!paginatedTaskSummary?.endpoint) {
        return { data: [], errors: [{ message: Message.NoEndPoint }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const startDateYMDFormat = formatDateToYYMMDDFormat(workDate);

      // eslint-disable-next-line sonarjs/no-nested-template-literals
      const queryParams = `?workDate=${startDateYMDFormat}&${userType === UserType.Customer ? `customerId=${id}` : `vendorId=${id}`}&userId=${userId}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortOrder=${sortOrder}${filterQueryParams ? '&' + filterQueryParams : ''}`;

      const endpointWithParams = `${paginatedTaskSummary.endpoint}${queryParams}`;

      const taskSummaryResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await taskSummaryResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async oneTimeJobs(
    id: string,
    fromDate: string | number | Date,
    toDate: string | number | Date,
    userType: UserType,
    userId: string
  ) {
    try {
      const oneTimeJobs = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'oneTimeJobs'
      );

      if (!oneTimeJobs?.endpoint) {
        return { data: [], errors: [{ message: Message.NoEndPoint }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const fromDateYMDFormat = formatDateToYYMMDDFormat(fromDate);
      const toDateYMDFormat = formatDateToYYMMDDFormat(toDate);
      const queryParams =
        userType === UserType.Customer
          ? `?fromDate=${fromDateYMDFormat}&toDate=${toDateYMDFormat}&customerId=${id}&userId=${userId}`
          : `?fromDate=${fromDateYMDFormat}&toDate=${toDateYMDFormat}&vendorId=${id}&userId=${userId}`;
      const endpointWithParams = `${oneTimeJobs.endpoint}${queryParams}`;

      const oneTimeJobsResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await oneTimeJobsResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async noShowFacilities(
    id: string,
    fromDate: string | number | Date,
    toDate: string | number | Date,
    userType: UserType,
    userId: string
  ) {
    try {
      const noShowFacilities = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'noShowFacilities'
      );

      if (!noShowFacilities?.endpoint) {
        return { data: [], errors: [{ message: Message.NoEndPoint }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const fromDateYMDFormat = formatDateToYYMMDDFormat(fromDate);
      const toDateYMDFormat = formatDateToYYMMDDFormat(toDate);
      const queryParams =
        userType === UserType.Customer
          ? `?fromDate=${fromDateYMDFormat}&toDate=${toDateYMDFormat}&customerId=${id}&userId=${userId}`
          : `?fromDate=${fromDateYMDFormat}&toDate=${toDateYMDFormat}&vendorId=${id}&userId=${userId}`;
      const endpointWithParams = `${noShowFacilities.endpoint}${queryParams}`;

      const noShowFacilitiesResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await noShowFacilitiesResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async taskPerformance() {
    try {
      const taskPerformance = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'taskPerformance'
      );

      if (!taskPerformance?.endpoint) {
        return { data: [], errors: [{ message: Message.NoEndPoint }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const endpointWithParams = `${taskPerformance.endpoint}`;

      const taskPerformanceResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await taskPerformanceResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async inspectionByDays() {
    try {
      const inspectionByDays = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'inspectionByDays'
      );

      if (!inspectionByDays?.endpoint) {
        return { data: [], errors: [{ message: Message.NoEndPoint }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const endpointWithParams = `${inspectionByDays.endpoint}`;

      const inspectionByDaysResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await inspectionByDaysResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async inspectionByLocation() {
    try {
      const inspectionByLocation = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'inspectionByLocation'
      );

      if (!inspectionByLocation?.endpoint) {
        return { data: [], errors: [{ message: Message.NoEndPoint }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const endpointWithParams = `${inspectionByLocation.endpoint}`;

      const inspectionByLocationResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await inspectionByLocationResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }

  static async taskInspection() {
    try {
      const taskInspection = awsConfig.aws_cloud_logic_custom.find(
        (apiEndpoint: { name: string }) => apiEndpoint.name === 'taskInspection'
      );

      if (!taskInspection?.endpoint) {
        return { data: [], errors: [{ message: Message.NoEndPoint }] };
      }

      const requestOptions = {
        method: 'GET',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'X-PZY-Client-Type': 'Web' },
      };

      const endpointWithParams = `${taskInspection.endpoint}`;

      const taskInspectionResponse = await fetch(endpointWithParams, requestOptions);

      const responseJson = await taskInspectionResponse.json();

      return { data: responseJson ?? null, errors: [] };
    } catch (e: any) {
      return { data: [], errors: e.errors };
    }
  }
}
