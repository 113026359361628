/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

const config = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:27884e61-518a-404a-ba37-4514c73b4147',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_jQXzajTnP',
  aws_user_pools_web_client_id: '72omkfuv6vao8jppt30416dko4',
  oauth: {
    domain: 'peazy.auth.us-east-1.amazoncognito.com',
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
    redirectSignIn: 'ark://oauth-landing',
    redirectSignOut: 'ark://',
    responseType: 'token',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE', 'APPLE'],
  aws_cognito_signup_attributes: ['NAME'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 6,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL', 'PHONE_NUMBER'],
  aws_cloud_logic_custom: [
    {
      name: 'getIdsApi',
      endpoint: 'https://api.peazy.com/analytics/customerDashboard/submitQuery',
      region: 'us-east-1',
    },
    {
      name: 'getFacilities',
      endpoint: `https://api.peazy.com/analytics/customerDashboard/getQueryResults`,
      region: 'us-east-1',
    },
    {
      name: 'taskSummary',
      endpoint: ` https://api.peazy.com/analytics/dashboard/taskSummary`,
      region: 'us-east-1',
    },
    {
      name: 'paginatedTaskSummary',
      endpoint: `https://api.peazy.com/analytics/dashboard/taskSummary/paginated`,
      region: 'us-east-1',
    },
    {
      name: 'noShowFacilities',
      endpoint: `https://api.peazy.com/analytics/dashboard/noShowFacilities`,
      region: 'us-east-1',
    },
    {
      name: 'oneTimeJobs',
      endpoint: `https://api.peazy.com/analytics/dashboard/oneTimeJobs`,
      region: 'us-east-1',
    },
    {
      name: 'Jobs',
      endpoint: `https://api.peazy.com/analytics/dashboard/jobs/finder`,
      region: 'us-east-1',
    },
  ],
  aws_appsync_graphqlEndpoint: 'https://sia7g76s3jgxnl6whcl5ycirym.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-xzbjg6pabjathaa5tdxylsfpbq',
  aws_user_files_s3_bucket: 'peazy-app-data',
  aws_user_files_s3_bucket_region: 'us-east-1',
};

export default config;
