/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemButton, ListItemIcon } from '@mui/material';
import { Icons } from '../../../Constants/Icons';
import { Language } from '../../../Constants/App';
import { TextStyle, styles } from './LeftNavBar.styles';

interface ILanguageSwitcherProps {
  isSideBarVisible: boolean;
  theme: any;
  isTablet: boolean;
}

const LanguageSwitcher = ({ isSideBarVisible, theme, isTablet }: ILanguageSwitcherProps): JSX.Element => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.English);
  const { i18n, t } = useTranslation(['homePage']);

  const handleLanguageSwitch = () => {
    const newLanguage = currentLanguage === Language.English ? Language.Spanish : Language.English;
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <ListItemButton
      onClick={handleLanguageSwitch}
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold,
        },
        padding: isTablet ? '0.5rem 0.75rem' : '1rem',
        borderRadius: isTablet ? '0.5rem' : '1rem',
        marginBottom: '0.5rem',
        alignItems: isSideBarVisible ? 'flexStart' : 'center',
        justifyContent: isSideBarVisible ? 'flexStart' : 'center',
        width: '100%',
      }}>
      <ListItemIcon
        sx={{
          ...styles.iconStyle,
        }}>
        <img src={Icons.ShuffleIcon} alt={t('homePage:languageSwitcher')} />
      </ListItemIcon>
      {isSideBarVisible && <TextStyle>{t('homePage:languageSwitcher')}</TextStyle>}
    </ListItemButton>
  );
};

export default LanguageSwitcher;
