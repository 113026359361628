/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { withTranslation } from 'react-i18next';
import React, { useEffect, useState, KeyboardEvent, useCallback } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
} from '@mui/material';
import { buttonStyles, DialogWrapper, TagDropdownText, Tags, TagText, DropdownStyle } from './AddEditOneTimeJob.Style';
// import Button, { ButtonType } from '../../components/common/Buttons/Button';

import { Icons } from '../../../../Shared/Constants/Icons';
// import JobCreateApiService from '../../modules/Jobs/Services/JobCreateService';
// import { Family } from '../../API';
import { isEmpty } from 'lodash';
import JobCreateApiService from '../../Services/JobCreateService';
import { Family } from '../../../../API';
import { Loader } from '../JobView/JobsView.styles';
// import { Loader } from '../jobsView/JobsView.styles';
import theme from '../../../../Shared/Themes/theme';

interface AddTagDialogProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
  jobId: string;
  fileIndex: number;
  updateUploaddFileTags: (index: number, selectedTags: any) => void;
  alreadySelectedTags: any[];
  attachmentId: string;
  t: any;
}

const AddTags: React.FC<AddTagDialogProps> = ({
  open,
  onClose,
  customerId,
  jobId,
  fileIndex,
  updateUploaddFileTags,
  alreadySelectedTags,
  attachmentId,
  t,
}) => {
  const { selectTagsHeading, closeIconStyle, textFieldSearch } = buttonStyles;
  const [allTagsData, setAllTagsData] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showTagsTypeDropdown, setShowTagsTypeDropdown] = useState<any[]>(new Array(allTagsData.length).fill(false));
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { addTagsButton } = buttonStyles;
  // const [originalTagsList, setOriginalTagssList] = useState<string[]>([]);

  useEffect(() => {
    getTagsData();
  }, []);

  useEffect(() => {
    setSelectedTags([...alreadySelectedTags]);
  }, [alreadySelectedTags]);

  const getTagsData = useCallback(
    async (tagsText?: string) => {
      try {
        setIsLoading(true);
        const fetchAllTagsResponse = await JobCreateApiService.getTags(
          Family.JobAttachments,
          // jobId,
          // customerId,
          tagsText
        );
        console.log('fetchAllTags', fetchAllTagsResponse);
        if (isEmpty(fetchAllTagsResponse.errors)) {
          const tagsData: any = [];
          fetchAllTagsResponse.data.forEach((tagObj: any) => {
            const tagIndex = tagsData.findIndex((tag: any) => tag.type === tagObj.type);
            if (tagIndex !== -1) {
              tagsData[tagIndex].options.push({ ...tagObj });
            } else {
              const tag: any = {};
              tag.type = tagObj.type;
              tag.options = [{ ...tagObj }];
              tagsData.push(tag);
            }
          });
          setAllTagsData([...tagsData]);
          // setOriginalTagssList([...tagsData]);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log('error', fetchAllTagsResponse.errors[0]?.message);
        }
      } catch (error) {
        setIsLoading(false);
        console.log('error', error);
      }
    },
    [customerId, jobId]
  );

  const handleTagDropdownClick = (index: number): void => {
    const showDropdownList = [...showTagsTypeDropdown];
    showDropdownList[index] = !showDropdownList[index];
    setShowTagsTypeDropdown([...showDropdownList]);
  };

  const toggleTagSelection = (tag: string): void => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleAddTags = (): void => {
    const tagAttachment = {
      id: attachmentId,
      tags: [...selectedTags],
    };
    updateUploaddFileTags(fileIndex, tagAttachment);
    setSelectedTags([]);
    onClose();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = (): void => {
    getTagsData(searchText);
  };

  return (
    <DialogWrapper open={open} onClose={onClose}>
      <Box sx={closeIconStyle} onClick={onClose}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </Box>
      <Box sx={selectTagsHeading}>{t('oneTimeJob:selectTags')}</Box>
      {isLoading ? (
        <Loader sx={{ marginBottom: '4em' }}>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </Loader>
      ) : (
        <>
          <Box sx={{ marginBottom: '2rem' }}>
            <TextField
              label={t('oneTimeJob:search')}
              sx={textFieldSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                      <img src={Icons.SearchIcon} alt={t('altTexts:search')} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: theme.palette.info.main,
                  borderRadius: '1.25rem',
                },
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box style={{ flex: 1 }}>
              {allTagsData.map((tag, index) => (
                <div key={index}>
                  <DropdownStyle onClick={() => handleTagDropdownClick(index)}>
                    <TagDropdownText>{tag.type}</TagDropdownText>
                    <IconButton onClick={() => handleTagDropdownClick(index)} sx={{ padding: '0 !important' }}>
                      <img
                        src={Icons.DropdownUpIcon}
                        alt={t('altTexts:dropdown')}
                        style={{ transform: showTagsTypeDropdown[index] ? 'rotate(180deg)' : 'rotate(0deg)' }}
                      />
                    </IconButton>
                  </DropdownStyle>
                  {showTagsTypeDropdown[index] && (
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem', cursor: 'pointer' }}>
                      {tag.options.map((option: any, optIndex: number) => (
                        <Tags
                          key={optIndex}
                          onClick={() => toggleTagSelection(option.value)}
                          style={{
                            backgroundColor: selectedTags.includes(option.value)
                              ? theme.palette.secondary.customColor7
                              : theme.palette.common.white,
                          }}>
                          <TagText>{option.value}</TagText>
                          <img
                            src={selectedTags.includes(option.value) ? Icons.CancelIcon : Icons.AddTagIcon}
                            alt={selectedTags.includes(option.value) ? 'cancel' : 'add'}
                          />
                        </Tags>
                      ))}
                    </Box>
                  )}
                  <Divider sx={{ marginBottom: '1.5rem', marginTop: '1em' }} />
                </div>
              ))}
            </Box>
          </Box>
        </>
      )}
      <Box>
        <Button sx={addTagsButton} disabled={isLoading} onClick={handleAddTags}>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.h4?.fontSize,
              fontWeight: theme.typography.fontWeightBold,
              lineHeight: '1.5rem',
              textTransform: 'capitalize',
              color: theme.palette.common.white,
            }}>
            {t('oneTimeJob:addTags')}
          </Typography>
        </Button>
      </Box>
    </DialogWrapper>
  );
};

export default withTranslation(['oneTimeJob'])(AddTags);
