/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Typography, Stack, TextField, Box } from '@mui/material';
import { Auth } from '@aws-amplify/auth';
import CircularProgress from '@mui/material/CircularProgress';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { UserType } from '../../../../Shared/Constants/App';
import { Options } from '../../../../Shared/Components/Common/Dropdown/Dropdown.styles';

import { useAuth } from '../../../../Configuration/AuthContext';
import { buttonStyles, PhoneNoTextfieldContainer, Wrapper } from './LoginPage.Style';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import { CognitoUserStatus } from '../../../../API';
import SnackbarMessage from './SnackbarMessage';
import theme from '../../../../Shared/Themes/theme';

function LoginWithPhoneNo({ t }) {
  const { sendCodeButton, sendCodeButtonText, phoneNoTextfield, facility, OtpTextfield } = buttonStyles;
  const { setUser } = useAuth();
  const [isLoginProgress, setLoginProgress] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [isUserVerified, setIsUserVerified] = useState(true);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState('+1');
  const [countryCodeOptions, setCountryCodeOptions] = useState([]);

  const resendConfirmationCode = useCallback(async () => {
    try {
      if (phoneNumber) {
        if (isUserVerified) {
          const userResponse = await Auth.signIn(`${countryCode}${phoneNumber}`);
          setCurrentUser(userResponse);
        } else {
          await Auth.resendSignUp(`${countryCode}${phoneNumber}`);
        }
        setSnackbarMessage(`${t('messages:codeSend')}`);
        setSnackbarSeverity('success');
        setIsSnackbarOpen(true);
      }
    } catch (e) {
      console.log('Error: an error occurred while resending the verification code.', e);
    }
  }, [phoneNumber]);

  const handleSignIn = useCallback(async () => {
    setLoginProgress(true);
    try {
      const phoneNoWithCode = `${countryCode}${phoneNumber}`;
      const cognitoUser = await MasterDataApiService.checkIfUserExists(phoneNoWithCode.replace('+', ''));
      const isEnabled = cognitoUser.data?.user?.isEnabled ?? true;
      if (isEnabled) {
        if (cognitoUser.data?.user?.status === CognitoUserStatus.CONFIRMED) {
          const userResponse = await Auth.signIn(phoneNoWithCode);
          setCurrentUser(userResponse);
        } else if (cognitoUser.data?.user?.status === CognitoUserStatus.UNCONFIRMED) {
          const userResponse = await Auth.resendSignUp(phoneNoWithCode);
          setCurrentUser(userResponse);
          setIsUserVerified(false);
        } else {
          setSnackbarMessage(`${t('messages:userNotRegistered')}`);
          setSnackbarSeverity('error');
          setIsSnackbarOpen(true);
        }
      }
    } catch (error) {
      setCurrentUser(null);
      console.log('Sign in error:', error);
    } finally {
      setLoginProgress(false);
    }
  }, [phoneNumber]);

  const handleConfirmSignUp = async () => {
    try {
      setLoginProgress(true);
      if (currentUser) {
        const sendCustomChallengeAnswerResponse = await Auth.sendCustomChallengeAnswer(currentUser, otp);
        const userType =
          (sendCustomChallengeAnswerResponse &&
            sendCustomChallengeAnswerResponse?.attributes &&
            sendCustomChallengeAnswerResponse?.attributes['custom:Role']) ||
          '';

        if (sendCustomChallengeAnswerResponse && sendCustomChallengeAnswerResponse?.attributes) {
          if (userType === UserType.Vendor || userType === UserType.Customer) {
            await handleSuccessfulLogin();
          } else {
            showSnackbar(`${t('messages:invalidUser')}`, 'error');
          }
        } else {
          showSnackbar(`${t('messages:invalidOTP')}`, 'error');
        }
      }
    } catch (error) {
      showSnackbar(`${t('messages:invalidOTPinCatch')}`, 'error');
      setLoginProgress(false);
    } finally {
      setLoginProgress(false);
    }
  };

  const handleSuccessfulLogin = async () => {
    showSnackbar('Login successful', 'success');

    const customer = await MasterDataApiService.getOrgDetailsByUserName();
    const loggedInUser = await MasterDataApiService.getCurrentUserInfo();

    if (loggedInUser) {
      setUser?.((prevUser) => ({ ...prevUser, ...loggedInUser }));
    }

    if (customer.data && !customer.error?.length) {
      localStorage.setItem('customerDetails', JSON.stringify(customer.data));
    }

    navigate('/home');
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setIsSnackbarOpen(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (!currentUser) {
      handleSignIn();
    } else {
      handleConfirmSignUp();
    }
  };

  const setCountryCodeFn = (event) => {
    setCountryCode(event.target.value);
  };

  useEffect(() => {
    const options = [
      {
        pin: '+1',
        country: 'USA',
      },
      {
        pin: '+91',
        country: 'India',
      },
    ];
    if (process.env.REACT_APP_ENV === 'production') {
      setCountryCodeOptions([
        {
          pin: '+1',
          country: 'USA',
        },
      ]);
    } else {
      setCountryCodeOptions(options);
    }
  }, []);

  return (
    <form onSubmit={(e) => handleFormSubmit(e)}>
      <Stack
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          width: '100%',
        }}>
        <PhoneNoTextfieldContainer>
          {!currentUser ? (
            <Wrapper>
              <Box
                sx={{
                  ...facility,
                }}>
                <TextField
                  sx={{
                    ...phoneNoTextfield,
                    minWidth: 'auto',
                    width: '7.77rem',
                  }}
                  className="countryCode"
                  label={t('signIn:countryCode')}
                  onChange={(event) => setCountryCodeFn(event)}
                  value={countryCode}
                  defaultValue={'+1'}
                  select
                  SelectProps={{
                    IconComponent: () => null,
                  }}>
                  {countryCodeOptions.map((item) => {
                    return (
                      <Options key={item.country} value={item.pin}>
                        {item.pin} {item.country}
                      </Options>
                    );
                  })}
                </TextField>
              </Box>

              {/* Phone number text field */}
              <TextField
                sx={{
                  ...phoneNoTextfield,
                }}
                id="outlined-required"
                className="phoneNo"
                label={t('signIn:phoneNumber')}
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
              />
            </Wrapper>
          ) : (
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputType="number"
              renderSeparator={<span>&nbsp;&nbsp;</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  type="tel"
                  style={{
                    ...OtpTextfield,
                  }}
                />
              )}
            />
          )}
          {currentUser && (
            <Button
              sx={{ color: theme.palette.primary.dark, alignSelf: 'flex-start' }}
              onClick={resendConfirmationCode}>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontSize: theme.typography.h6.fontSize,
                  fontFamily: theme.typography.fontFamily,
                }}>
                {t('signIn:resendCode')}
              </Typography>
            </Button>
          )}
        </PhoneNoTextfieldContainer>

        <Button
          sx={{
            ...sendCodeButton,
          }}
          type="submit">
          {!isLoginProgress ? (
            <Typography
              sx={{
                ...sendCodeButtonText,
              }}>
              {!currentUser && otp === '' ? `${t('signIn:sendCode')}` : `${t('signIn:confirmCode')}`}
            </Typography>
          ) : (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          )}
        </Button>

        <SnackbarMessage
          open={isSnackbarOpen}
          successMessage={snackbarMessage}
          errorMessage={snackbarMessage}
          severity={snackbarSeverity}
          onClose={() => setIsSnackbarOpen(false)}
        />
      </Stack>
    </form>
  );
}

export default withTranslation(['messages', 'signIn'])(LoginWithPhoneNo);
