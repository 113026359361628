/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import React, { useState } from 'react';
import { BidStatus } from '../../../../API';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import { Icons } from '../../../../Shared/Constants/Icons';
import JobAwardBidApiService from '../../../Jobs/Services/JobAwardBidService';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import { CloseIconStyle } from '../CreateBid.Style';
import { DialogWrapper, NameField, PopupHeadingText, textField } from './Popup.Styles';

interface IAwardJobProps {
  isOpen: boolean;
  vendorName: string;
  vendorId: string;
  bidId: string;
  finalQuote: number;
  rejectionFlag?: boolean;
  onClose: () => void;
}

const BidRequestRevisionPopup: React.FC<IAwardJobProps> = ({
  isOpen,
  onClose,
  vendorName,
  vendorId,
  bidId,
  finalQuote,
  rejectionFlag,
}) => {
  const [reason, setReason] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isBidRejected, setBidRejection] = useState(false);

  const handleClose = (): void => {
    onClose();
  };

  const markRequestRevision = async (): Promise<void> => {
    setBidRejection(true);
    try {
      await JobAwardBidApiService.requestBidRevision(vendorId, bidId, {
        dataStatus: rejectionFlag ? BidStatus.R : BidStatus.V,
        comments: reason,
      });
      setSnackbarMessage(t('dashboard:bidUpdateSuccess'));
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      history.back();
    } catch {
      setSnackbarMessage(t('dashboard:bidUpdateFailed'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setBidRejection(false);
      handleClose();
    }
  };

  return (
    <>
      <DialogWrapper open={isOpen} onClose={handleClose}>
        <CloseIconStyle onClick={handleClose}>
          <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
        </CloseIconStyle>
        <PopupHeadingText>{rejectionFlag ? t('dashboard:rejectBid') : t('dashboard:requestRevision')}</PopupHeadingText>

        <NameField sx={{ justifyContent: 'left' }}>{vendorName}</NameField>

        <Box sx={{ display: 'flex' }}>
          <NameField>{t('vendor:finalQuote')}:</NameField>
          <PopupHeadingText>${isNaN(finalQuote) ? 0 : finalQuote.toFixed(2)}</PopupHeadingText>
        </Box>

        <TextField
          sx={{ ...textField, marginBottom: '1em' }}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          multiline
          maxRows={10}
          label={rejectionFlag ? t('dashboard:reasonForRejection') : t('dashboard:reasonForRevision')}></TextField>

        <Button
          type={ButtonType.Primary}
          width="100%"
          label={t('vendor:confirm')}
          isLoading={isBidRejected}
          onClick={() => {
            markRequestRevision();
          }}
        />
      </DialogWrapper>
      <SnackbarMessage
        open={snackbarOpen}
        successMessage={snackbarMessage}
        errorMessage={snackbarMessage}
        severity={snackbarSeverity}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};

export default BidRequestRevisionPopup;
