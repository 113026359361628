/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

import { TextField } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import Button, { ButtonType } from '../../../../Shared/Components/Common/Buttons/Button';
import { Icons } from '../../../../Shared/Constants/Icons';
import { CloseIconStyle } from '../CreateBid.Style';
import { DialogWrapper, PopupHeadingText, textField } from './Popup.Styles';

interface IAwardJobProps {
  isOpen: boolean;
  onClose: () => void;
  handleOnCloseJob: () => void;
  loader?: boolean;
  reason: any;
  setReason: any;
}

const BidRequestRevisionPopup: React.FC<IAwardJobProps> = ({
  isOpen,
  onClose,
  handleOnCloseJob,
  loader,
  reason,
  setReason,
}) => {
  const handleClose = (): void => {
    onClose();
  };

  return (
    <>
      <DialogWrapper open={isOpen} onClose={handleClose}>
        <CloseIconStyle onClick={handleClose}>
          <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
        </CloseIconStyle>
        <PopupHeadingText>{t('vendor:revisionAfterModification')}</PopupHeadingText>

        <TextField
          sx={{ ...textField, marginBottom: '1em' }}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          multiline
          maxRows={10}
          label={t('vendor:vendorsComment')}></TextField>

        <Button
          type={ButtonType.Primary}
          width="100%"
          label={t('vendor:submitRevision')}
          isLoading={loader}
          disabled={loader}
          onClick={handleOnCloseJob}
        />
      </DialogWrapper>
    </>
  );
};

export default BidRequestRevisionPopup;
