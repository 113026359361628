/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

export const getTotalServiceArea = (services: any[]): number => {
  let totalArea = 0;
  services.forEach((service) => {
    if (service.mdServiceUnits === 'sqft') {
      totalArea += parseFloat(service.serviceMeasure);
    }
  });
  return totalArea;
};

export const getServiceDuration = (startDate: string, stopDate: string): string => {
  const timeDifference = new Date(stopDate).getTime() - new Date(startDate).getTime();
  const numberOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
  if (numberOfDays === 0) {
    return 'One time';
  } else if (numberOfDays === 1) {
    return '1 day';
  } else if (numberOfDays % 7 === 0) {
    return String(numberOfDays / 7) + ' weeks';
  } else if (numberOfDays % 30 === 0 || numberOfDays % 30 === 1) {
    return String(Math.round(numberOfDays / 30)) + ' months';
  } else {
    return String(numberOfDays) + ' days';
  }
};

export const getNumberOfDaysLeft = (
  jobDueDates: { jobId: string; mdDueType: string; dueDescription: string; dueDate: string }[]
): number | string => {
  const bidDueDateObj: any = jobDueDates.find((dateObj) => dateObj.mdDueType === 'Bid');
  if (bidDueDateObj) {
    const timeDifference = new Date(bidDueDateObj?.dueDate).getTime() - new Date().getTime();
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  } else {
    return '-';
  }
};

export const getServiceVolume = (area: number): string | undefined => {
  if (area < 5000) {
    return 'Small';
  } else if (area >= 5000 && area < 10000) {
    return 'Medium';
  } else if (area >= 10000) {
    return 'Large';
  }
};
