/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography, Box, TextField, InputAdornment, LinearProgress } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { Icons } from '../../../../Shared/Constants/Icons';

import {
  buttonStyles,
  SignUpContainer,
  EmailAndPasswordContainer,
  NameAndEmailContainer,
  PasswordContainer,
  ProgressBarContainer,
  StrengthProgressBar,
  StrengthText,
  PasswordConditionCheck,
  ConditionContainer,
  ConditionCheckHeading,
  ConditionCheckTextContainer,
  OptionsContainer,
  ConditionCheckText,
  CheckBoxContainer,
  CheckBoxText,
  SignUpButton,
} from './LoginPage.Style';
import theme from '../../../../Shared/Themes/theme';

function SignUpWithEmail({ t }) {
  const [passwordTouched, setPasswordTouched] = useState(false);

  const { textField, privacyAndTCText, progressBar } = buttonStyles;

  const [isSignUpProgress, setSignUpProgress] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const navigate = useNavigate();
  const [passwordStrength, setPasswordStrength] = useState({
    minLength: false,
    hasNumber: false,
    hasSpecialChar: false,
    hasLowerCase: false,
    hasUpperCase: false,
  });

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = useCallback(async () => {
    setSignUpProgress(true);
    try {
      if (password !== confirmPassword) {
        throw new Error('Passwords do not match.');
      }
      if (!agreeTerms) {
        throw new Error('Please agree to the terms and conditions.');
      }

      const signUpResponse = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          name: name,
        },
      });

      if (signUpResponse) {
        navigate('/login');
      }
    } catch (error) {
      console.log('Error signing up:', error.message);
    }

    setSignUpProgress(false);
  }, [name, email, password, confirmPassword, agreeTerms, navigate]);

  const checkPasswordStrength = (value) => {
    const passwordStrength = {
      minLength: value.length >= 8,
      hasNumber: /\d/.test(value),
      hasSpecialChar: /[!@#$%^&*]/.test(value),
      hasLowerCase: /[a-z]/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
    };
    setPasswordStrength(passwordStrength);
  };

  const passwordConditions = [
    {
      label: `${t('signUp:minimumCharacters')}`,
      strength: passwordStrength.minLength,
    },
    {
      label: `${t('signUp:numbers')}`,
      strength: passwordStrength.hasNumber,
    },
    {
      label: `${t('signUp:specialCharacters')}`,
      strength: passwordStrength.hasSpecialChar,
    },
    {
      label: `${t('signUp:lowerCaseLetters')}`,
      strength: passwordStrength.hasLowerCase,
    },
    {
      label: `${t('signUp:upperCaseLetters')}`,
      strength: passwordStrength.hasUpperCase,
    },
  ];

  const calculatePasswordStrength = () => {
    const strengthCount = Object.values(passwordStrength).filter((strength) => strength).length;
    const strengthPercentage = (strengthCount / Object.keys(passwordStrength).length) * 100;

    if (strengthPercentage < 33.33) {
      return 'Weak';
    } else if (strengthPercentage < 66.66) {
      return 'Fair';
    } else {
      return 'Strong';
    }
  };

  const getStrengthColor = () => {
    const strength = calculatePasswordStrength();

    if (strength === 'Weak') {
      return theme.palette.error.main;
    } else if (strength === 'Fair') {
      return theme.palette.warning.dark;
    } else {
      return theme.palette.success.main;
    }
  };

  return (
    <SignUpContainer>
      <EmailAndPasswordContainer>
        <NameAndEmailContainer>
          <TextField
            sx={{
              ...textField,
            }}
            id="outlined-required"
            label={t('signUp:fullName')}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextField
            sx={{ ...textField }}
            id="outlined-required"
            label={t('signIn:emailAddress')}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </NameAndEmailContainer>

        <ProgressBarContainer>
          <StrengthProgressBar>
            {passwordTouched && (
              <>
                <StrengthText>{calculatePasswordStrength()}</StrengthText>
                <LinearProgress
                  variant="determinate"
                  value={
                    calculatePasswordStrength() === 'Strong'
                      ? 100
                      : calculatePasswordStrength() === 'Fair'
                        ? 66.66
                        : 33.33
                  }
                  sx={{
                    ...progressBar,
                    '& .MuiLinearProgress-barColorPrimary': {
                      backgroundImage: `linear-gradient(to right, ${getStrengthColor()} ${
                        calculatePasswordStrength() === 'Strong'
                          ? 100
                          : calculatePasswordStrength() === 'Fair'
                            ? 66.66
                            : 33.33
                      }%, ${getStrengthColor()} ${
                        calculatePasswordStrength() === 'Strong'
                          ? 100
                          : calculatePasswordStrength() === 'Fair'
                            ? 66.66
                            : 33.33
                      }%)`,
                      boxShadow: 'none',
                      borderRadius: '1rem',
                    },
                  }}
                />
              </>
            )}
          </StrengthProgressBar>
        </ProgressBarContainer>

        <PasswordContainer>
          <TextField
            sx={{
              ...textField,
              width: '100%',
            }}
            id="outlined-required"
            label={t('signUp:setupPassword')}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              checkPasswordStrength(e.target.value);
              setPasswordTouched(true);
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showPassword ? (
                    <img
                      src={Icons.VisibilityOnIcon}
                      alt={t('altTexts:visibilityOnIcon')}
                      onClick={handlePasswordVisibility}
                    />
                  ) : (
                    <img
                      src={Icons.VisibilityOffIcon}
                      alt={t('altTexts:visibilityOffIcon')}
                      onClick={handlePasswordVisibility}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{
              ...textField,
            }}
            id="outlined-required"
            label={t('signUp:confirmPassword')}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showPassword ? (
                    <img
                      src={Icons.VisibilityOnIcon}
                      alt={t('altTexts:visibilityOnIcon')}
                      onClick={handlePasswordVisibility}
                    />
                  ) : (
                    <img
                      src={Icons.VisibilityOffIcon}
                      alt={t('altTexts:visibilityOffIcon')}
                      onClick={handlePasswordVisibility}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </PasswordContainer>
      </EmailAndPasswordContainer>
      <PasswordConditionCheck>
        <Box>
          <Box sx={{ marginBottom: '1rem' }}>
            <ConditionCheckHeading>{t('signUp:passwordContains')}</ConditionCheckHeading>
          </Box>
        </Box>
        <ConditionContainer>
          <OptionsContainer>
            {passwordConditions.map((condition, index) => (
              <ConditionCheckTextContainer key={index}>
                {condition.strength ? (
                  <img src={Icons.ConditionCheckCorrectIcon} style={{ width: '1rem', height: '1rem' }} />
                ) : (
                  <img src={Icons.ConditionCheckIcon} style={{ width: '1rem', height: '1rem' }} />
                )}
                <ConditionCheckText>{condition.label}</ConditionCheckText>
              </ConditionCheckTextContainer>
            ))}
          </OptionsContainer>
        </ConditionContainer>
      </PasswordConditionCheck>
      <CheckBoxContainer>
        <Checkbox
          id="termsAndConditions"
          sx={{
            padding: 0,
            '&.Mui-checked': {
              color: theme.palette.primary.main,
              '& .MuiSvgIcon-root': {
                backgroundColor: theme.palette.common.black,
                borderRadius: '0.375rem',
              },
            },
          }}
          checked={agreeTerms}
          onChange={(e) => setAgreeTerms(e.target.checked)}
        />
        <CheckBoxText>
          {t('signUp:termsAndConditions')}{' '}
          <Typography
            variant="caption"
            component="a"
            sx={{
              ...privacyAndTCText,
            }}>
            {t('signUp:privacyPolicies')}
          </Typography>{' '}
          {t('signUp:and')}{' '}
          <Typography
            variant="caption"
            component="a"
            sx={{
              ...privacyAndTCText,
            }}>
            {t('signUp:t&c')}
          </Typography>
        </CheckBoxText>
      </CheckBoxContainer>
      <SignUpButton variant="contained" color="primary" onClick={handleSignUp} disabled={isSignUpProgress}>
        {isSignUpProgress ? <CircularProgress size={24} sx={{ color: 'white' }} /> : `${t('signUp:signUp')}`}
      </SignUpButton>
    </SignUpContainer>
  );
}

export default withTranslation(['signIn', 'altTexts'])(SignUpWithEmail);
