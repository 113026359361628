/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { keyframes, styled } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import theme from '../../../Themes/theme';
import { StyleConstants } from '../../../Constants/Style.Constants';

interface IProgressProps {
  progressPercent: number;
}

export const AccordionWrapper = styled(Accordion)(() => ({
  boxShadow: '0 0.625rem 1.25rem 0.375rem #7957001A',
  borderRadius: '1rem',
  backgroundColor: theme.palette.common.white,
  marginTop: '1.5rem',
  '&:first-of-type,&:last-of-type': {
    borderRadius: '1rem',
  },
  '&:before': {
    opacity: 0,
  },
  '.Mui-expanded&:last-of-type': {
    marginBottom: '2.5rem',
  },
  '&:last-child': {
    marginBottom: '2.5rem',
  },
  '&.Mui-expanded:first-of-type': {
    marginTop: '1.5rem',
  },
}));

export const AccordionDescription = styled(AccordionSummary)(() => ({
  padding: '1.5rem',
  '&.Mui-expanded': {
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    padding: '0.9375rem 0 0.9375rem 1.5rem',
    transition: 'none',
    color: theme.palette.common.black,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    padding: '0.9375rem 1.5rem 0.9375rem 0',
  },
}));

export const AssociateAccordionDescription = styled(AccordionSummary)(() => ({
  padding: '0.5rem',
  paddingLeft: '1.5rem',
  '&.Mui-expanded': {
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    padding: '0.9375rem 0 0.9375rem 1.5rem',
    transition: 'none',
    color: theme.palette.common.black,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    padding: '0.9375rem 1.5rem 0.9375rem 0',
  },
}));

export const ServiceContainer = styled('div')(() => ({
  flex: 1,
  padding: '0 1rem',
  borderRight: `0.0625rem solid ${theme.palette.divider}`,
}));

export const ServicesStatusWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1rem',
  alignItems: 'center',
}));

export const ServiceTitleDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '1rem',
  [theme.breakpoints.down('laptop')]: {
    rowGap: '0.75rem',
    flexWrap: 'wrap',
  },
}));

export const ServicesTitleWrapper = styled('div')(() => ({
  display: 'flex',
  columnGap: '0.375rem',
}));

export const ServicesTitle = styled('h5')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
  },
}));

export const At = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightLight,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

export const ServiceDuration = styled('h5')(({ theme }) => ({
  borderRadius: '0.375rem',
  backgroundColor: theme.palette.secondary.dark,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  padding: '0.25rem 0.5rem',
}));

export const ServiceShift = styled('h5')(({ theme }) => ({
  borderRadius: '0.5rem',
  backgroundColor: theme.palette.info.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  padding: '0.25rem 0.5rem',
  display: 'flex',
  columnGap: '0.5rem',
}));

export const TaskStatusTag = styled('h4')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

const spin = keyframes`
  from {
    width: 0;
  }
`;

const getBackgroundColor = (progress: number) => {
  let color;
  switch (true) {
    case progress < 50:
      color = theme.palette.primary.main;
      break;
    case progress >= 50 && progress < 100:
      color = theme.palette.warning.dark;
      break;
    case progress === 100:
      color = theme.palette.success.main;
      break;
    default:
      color = theme.palette.error.main;
      break;
  }
  return color;
};

export const ProgressBar = styled('div')(({ progressPercent }: IProgressProps) => ({
  width: `${progressPercent ? progressPercent : 1}%`,
  animation: `${spin} 2s linear`,
  backgroundColor: `${getBackgroundColor(progressPercent)}`,
  height: '0.5rem',
  borderRadius: '1rem',
}));

export const AccordionInfo = styled(AccordionDetails)(() => ({
  padding: '0.5rem 1.5rem 1.5rem',
}));

export const ButtonWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '1.5rem',
  marginTop: '1.5rem',
}));
