/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { Dialog, styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { PaddingValues, StyleConstants } from '../../../../Shared/Constants/Style.Constants';

const wrapperWidth = 1024 + 80;

export const TabsWrapper = styled('div')(() => ({
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  marginBottom: '1.5rem',
}));
export const buttonStyles = {
  rejectButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '1rem',
    padding: PaddingValues.Padding3,
    textTransform: 'none',
    marginBottom: '1rem',
    minWidth: '13.5rem',
    '@media (max-width: 64rem)': {
      padding: '0.8125rem 1.5rem',
      minWidth: '11.25rem',
    },
  },
  approveButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1rem',
    padding: PaddingValues.Padding3,
    textTransform: 'none',
    minWidth: '13.5rem',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '@media (max-width: 64rem)': {
      padding: '0.8125rem 1.5rem',
      minWidth: '11.25rem',
    },
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4?.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.5rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
    },
  },

  closeIconStyle: {
    cursor: 'pointer',
    position: 'absolute',
    right: '1.5rem',
    top: '-1.125rem',
  },

  rejectHeaderText: {
    fontsize: theme.typography.h2?.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.75rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    color: theme.palette.text.primary,
    paddingTop: '1.5rem',
  },
  rejectContentText: {
    fontsize: theme.typography.h4?.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.5rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    color: theme.palette.text.secondary,
  },
  infoCancelButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '1rem',
    minWidth: '7.4375rem',
    padding: PaddingValues.Padding3,
    textTransform: 'none',
    marginRight: '1.5rem',
  },
  infoRejectButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1rem',
    minWidth: '11.25rem',
    padding: PaddingValues.Padding3,
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  logoutButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1rem',
    padding: PaddingValues.Padding3,
    width: '7.5rem',
    height: '3.25rem',
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },

  deleteButton: {
    background: theme.palette.primary.dark,
    borderRadius: '1rem',
    padding: PaddingValues.Padding3,
    width: '7.5rem',
    height: '3.25rem',
    textTransform: 'none',
    marginRight: '1rem',
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },

  nameAndMessage: {
    fontSize: theme.typography.h4?.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    linHeight: '1.5rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    color: theme.palette.text.secondary,
    '@media (max-width: 64rem)': {
      fontSize: '.875rem',
      lineHeight: '1.375rem',
    },
  },

  DateAndTime: {
    fontSize: theme.typography.h5?.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
    color: theme.palette.secondary.contrastText,
    '@media (max-width: 64rem)': {
      fontSize: '.75rem',
      lineHeight: '1rem',
    },
  },

  divider: {
    border: `00.03125rem solid ${theme.palette.secondary.light},`,
    width: '100%',
    marginBottom: '1rem',
  },
  tasKDetailsCardBody: {
    fontSize: theme.typography.h5?.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
    color: theme.palette.secondary.contrastText,
    marginBottom: '1rem',
  },
  tasKDetailsCardMedia: {
    fontSize: theme.typography.h5?.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingDefault,
    color: theme.palette.secondary.contrastText,
  },

  nameAndMessages: {
    fontSize: theme.typography.h4?.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    linHeight: '1.5rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    '@media (max-width: 64rem)': {
      fontSize: '.875rem',
      lineHeight: '1.375rem',
    },
  },

  mediaStyles: {
    width: '15em',
    height: '10em',
    objectFit: 'contain',
    backgroundColor: 'black',
    borderRadius: '1em',
  },
};

export const ChatContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  gap: '1.5rem',
  paddingBottom: '6.5rem',
}));

export const ChatMessageContainer = styled('div')(() => ({
  boxShadow: '0 0.625rem 1.25rem 0.375rem rgba(121, 87, 0, 0.10)',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '1rem',
  padding: '2rem',
}));

export const MessageContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const LogoContainer = styled('div')(({ theme }) => ({
  marginRight: '1rem',
  '& img': {
    width: '3rem',
    height: '3rem',
  },
  [theme.breakpoints.down('laptop')]: {
    marginRight: '0.5rem',
    '& img': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
}));

export const NameAndTimeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '0.375rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '0.5rem',
  },
}));

export const MessageAndImageContainer = styled('div')(({ theme }) => ({
  '& img': {
    width: '15.5',
    height: '8.625rem',
  },
  [theme.breakpoints.down('laptop')]: {
    '& img': {
      width: '9.375rem',
      height: '5.25rem',
    },
  },
}));

export const ApproveRejectContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 0.625rem 1.25rem 0.375rem  rgba(121, 87, 0, 0.10)',
  backgroundColor: 'white',
  borderRadius: '1rem',
  padding: '1.5rem',
  width: '25rem',
}));

export const ApproveRejectButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TaskDetailsBottombar = styled('div')(() => ({
  position: 'fixed',
  width: 'inherit',
  bottom: 0,
  backgroundColor: theme.palette.common.white,
  alignItems: 'center',
  zIndex: '3',
}));

export const TextfieldBottombar = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '1.5rem 9.75rem 1.5rem 4.75rem',
  '& fieldset': {
    borderRadius: '1rem',
    borderColor: theme.palette.secondary.light,
    padding: '1rem',
  },
  '& .MuiInputBase-root': {
    paddingRight: '0.75rem !important',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.secondary.light,
    },
    '&:hover fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.secondary.light,
    },
    '&.Mui-focused fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.secondary.light,
    },
    '& .MuiInputBase-input': {
      paddingRight: '0.75rem !important',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h4.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.375rem',
    letterSpacing: '0.00375rem',
  },
  [theme.breakpoints.down(wrapperWidth)]: {
    padding: '1.5rem',
  },
}));

export const TaskDetails = styled('span')(() => ({
  color: theme.palette.text.secondary,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  display: 'inline-block',
}));

export const ApprovalText = styled('h3')(() => ({
  fontsize: theme.typography.h4?.fontSize,
  fontFamily: theme.typography.fontFamily,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.text.secondary,
}));

export const DialogWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
}));

//Logout Popup style
export const LoginDialogWrapper = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1.5rem',
    overflowY: 'unset',
  },
  '& .MuiDialogTitle-root+.MuiDialogContent-root': {
    paddingTop: '0.5rem',
  },
  '& .MuiPaper-root .MuiDialogActions-root': {
    justifyContent: 'flex-start',
  },
  '& .MuiButtonBase-root': {},
}));

export const ProofHeading = styled('h1')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: '700',
  fontSize: '1rem',
  lineHeight: '24px',
  marginBottom: '4px',
  color: '#292F4D',
  fontStyle: 'normal',
  letterSpacing: StyleConstants.LetterSpacingTight,
}));

export const AttachmentsTitle = styled('div')(() => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h5?.fontSize,
  lineHeight: '24px',
  color: '#292F4D',
  fontStyle: 'normal',
  letterSpacing: StyleConstants.LetterSpacingTight,
  marginBottom: '1rem',
}));

export const AttachmentsContainer = styled('div')(() => ({
  maxHeight: '20rem',
  overflow: 'hidden',
  '&:hover': {
    overflow: 'auto',
  },
}));

export const AttachmentTag = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1em',
  flexWrap: 'wrap',
  marginBottom: '2rem',
}));

export const NoProofsContainer = styled('div')(() => ({
  marginTop: '6%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const NoProofsTag = styled('div')(() => ({
  color: theme.palette.text.disabled,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingDefault,
  padding: '0.5rem 0 3rem 0',
}));
