/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitle, DialogContent, DialogActions, Button, Box, Typography, CircularProgress } from '@mui/material';
import { ApprovalText, buttonStyles, DialogWrapper, TaskDetails } from './TaskDetailsPage.Style';
import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';

interface IApproveDialogProps {
  vendorTypes?: any;
  handleCloseApproveWindow: () => void;
  isApproveWindowOpen: boolean;
  rowDetails?: any;
  isShowApproveAll?: boolean;
  handleApprove: () => void;
  isApproveLoading?: boolean;
}

const ApproveDialog = ({
  isApproveWindowOpen,
  handleCloseApproveWindow,
  handleApprove,
  isShowApproveAll,
  rowDetails,
  isApproveLoading,
}: IApproveDialogProps): JSX.Element => {
  const { t } = useTranslation(['dashboard', 'altTexts']);
  const { closeIconStyle, rejectHeaderText, infoCancelButton, infoRejectButton, buttonText } = buttonStyles;

  return (
    <DialogWrapper open={isApproveWindowOpen} onClose={handleCloseApproveWindow}>
      <Box sx={closeIconStyle} onClick={handleCloseApproveWindow}>
        <img src={Icons.InfoWindowCloseIcon} alt={t('altTexts:closeIcon')} />
      </Box>
      <DialogTitle id="alert-dialog-title" sx={rejectHeaderText}>
        {'Approve work task'}
      </DialogTitle>
      <DialogContent>
        <ApprovalText id="alert-dialog-description">
          {isShowApproveAll ? (
            `${t('dashboard:approveRows')}`
          ) : (
            <>
              {t('dashboard:approve')} {rowDetails.taskName ? <TaskDetails>{rowDetails.taskName}</TaskDetails> : null}
            </>
          )}
        </ApprovalText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0.75rem 1.5rem 1.5rem 6.8125rem',
          }}>
          <Button sx={infoCancelButton} onClick={handleCloseApproveWindow}>
            <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>Cancel</Typography>
          </Button>
          <Button sx={infoRejectButton} onClick={handleApprove}>
            {isApproveLoading ? (
              <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
            ) : (
              <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
                {isShowApproveAll ? `${t('dashboard:approveAll')}` : `${t('dashboard:approveButtonText')}`}
              </Typography>
            )}
          </Button>
        </Box>
      </DialogActions>
    </DialogWrapper>
  );
};

ApproveDialog.defaultProps = {
  rowDetails: '',
  isShowApproveAll: false,
};

export default ApproveDialog;
