/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
const facilityId = 'c0240834-325c-4931-b8dd-eec4d6f233b5';
export const customersList = [
  {
    customerId: '02347d00-843e-404d-9fe5-248063d4eb21',
    name: 'Morgan Stanley & Co',
    facilities: [
      {
        facilityId: 'c0240834-325c-4931-b8dd-eec4d6f233b4',
        buildingName: 'South Jordan, #12560 OTJ - Morgan Stanley #003690',
        address: '680 W 10000 S, South Jordan, UT 84095-3970',
        mdFacilityType: 'financial',
      },
      {
        facilityId: facilityId,
        buildingName: 'Denver, #12565 OTJ - Morgan Stanley #005102',
        address: '1550 Market St, Denver, CO 80202-2054',
        mdFacilityType: 'financial',
      },
      {
        facilityId: facilityId,
        buildingName: 'Peoria, #12566 OTJ - Morgan Stanley #004146',
        address: '401 Main St, Peoria, IL 61602-1267',
        mdFacilityType: 'financial',
      },
    ],
  },
  {
    customerId: '02347d00-843e-404d-9fe5-248063d4eb20',
    name: '3M Company',
    facilities: [
      {
        facilityId: 'c0240834-325c-4931-b8dd-eec4d6f233b4',
        buildingName: 'Oak Hills, #11695 OTJ-3M Oak Hills Facility',
        address: '8981 US Highway 395, Oak Hills, CA 92344-9220',
        mdFacilityType: 'warehouse',
      },
      {
        facilityId: facilityId,
        buildingName: 'Corona, #11696 OTJ-3M Corona Facility',
        address: '18750 Minnesota Rd, Corona, CA 92881-4313',
        mdFacilityType: 'warehouse',
      },
    ],
  },
];
