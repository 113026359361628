/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Divider, CircularProgress, IconButton } from '@mui/material';
import {
  buttonStyles,
  FacilityDetailsContainer,
  DetailsContainer,
  ImageContainer,
  DetailsContentContainer,
  DetailsHeadingContainer,
  HeadingText,
  DetailsAndDate,
  Details,
  Date,
  FacilitiesAndBidOpenTo,
  Match,
  MatchText,
  FacilitiesDescription,
  DescriptionText,
  TimeAndInstructions,
  TitleImgWrapper,
} from './OneTimeJobDetails.Style';
import { MediaContainer, MediaText } from '../../../Vendor/Components/CreateBid.Style';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import FacilityApiService from '../../../Facility/Services/FacilityApiService';
import {
  fetchDataFromS3Bucket,
  formatDate,
  getAuthData,
  getStoredCustomerDetails,
  getTagValues,
  isDateBefore,
  uploadDocumentToS3Bucket,
} from '../../../../Shared/Utilities/utils';
import { CreateOrUpdateJobAttachmentInput, DraftStatus, DraftStep, Facility, Family } from '../../../../API';
import WorkOrdersApiService from '../../Services/WorkOrdersService';
import MasterDataApiService from '../../../../Shared/Services/MasterDataService';
import MediaPopup from '../../../../Shared/Components/Common/MediaPopUp/MediaPopUp';

import { Loader } from '../JobView/JobsView.styles';
import theme from '../../../../Shared/Themes/theme';
import { Icons } from '../../../../Shared/Constants/Icons';
import PhotosIcon from '../../../../assets/images/ic_photos.svg';
import VideoIcon from '../../../../assets/images/ic_video.svg';
import { FileContextType, JobStatus, DateFormat, JobStatuses, UserType } from '../../../../Shared/Constants/App';
import Modal from '../../Utilities/Shared/Components/Modal';
import { DatePickerContainer, PhotosAndVideosContainer } from '../AddEditOneTimeJob/AddEditOneTimeJob.Style';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { useForm } from 'react-hook-form';
import { FieldNames, IDetailsProps, ITag } from '../../Models/AddEditOneTimeJob.Model';
import { isEmpty } from 'lodash';
import JobCreateApiService from '../../Services/JobCreateService';
import SnackbarMessage from '../../../Onboarding/Components/Login/SnackbarMessage';
import GoogleMapPopup from './GoogleMapPopup';
import PhotosAndVideos from '../AddEditOneTimeJob/PhotosAndVideos';

interface IFacilityDetailsProps {
  data: {
    jobDetails: any;
    facility: Facility;
    facilityItem?: any;
    RejectedStatus?: string;
  };
  fetchJobDetails: (jobId: string) => void;
  isModifyJobDetails?: boolean;
}

type MediaType = 'photo' | 'video';

const attachments = localStorage.getItem('jobAttachments');

const getDefaultJobAttachments = () => {
  return attachments ? JSON.parse(attachments) : [];
};

const FacilityDetails = ({ data, fetchJobDetails, isModifyJobDetails }: IFacilityDetailsProps): JSX.Element => {
  const { t } = useTranslation(['dashboard', 'calendar', 'altTexts', 'message']);
  const { role, isDistrictManager } = getAuthData();
  const [services, setServices] = useState<string[]>([]);
  // const [startTime, setStartTime] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mediaType, setMediaType] = useState<MediaType>('photo');
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  const [facilityIcon, setFacilityIcon] = useState<string>('');
  const [addressData, setAddressData] = useState<string>('');
  const [isEditDateModalOpen, setIsEditDateModalOpen] = useState<boolean>(false);
  const [isEditPhotosAndVideosModalOpen, setIsEditPhotosAndVideosModalOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [startDateOfJob, setStartDateOfJob] = useState('');
  const [endDateOfJob, setEndDateOfJob] = useState('');
  const [isAssociatePunchedIn, setIsAssociatePunchedIn] = useState(false);
  const [mapPopupOpen, setMapPopupOpen] = useState(false);
  const [location, setLocation] = useState<{ lat: number | null; long: number | null }>({ lat: null, long: null });
  const [images, setImages] = useState<any[]>([]);
  const [videos, setVideos] = useState<any[]>([]);
  const [uploadedFileTags, setUploadedFileTags] = useState<any[]>([]);
  const [modifiedPreviousAttachments, setModifiedPreviousAttachments] = useState<string[]>([]);
  const [jobNewAttachments, setJobAttachments] = useState<any[]>(getDefaultJobAttachments());
  const [previouslyUploadedAttachments, setPreviouslyUploadedAttachments] = useState<
    CreateOrUpdateJobAttachmentInput[]
  >([]);

  const { mediaText, mediaContainer, divider } = buttonStyles;
  const {
    jobDetails: {
      startDate,
      stopDate,
      jobId,
      jobName,
      jobStatus,
      jobType,
      jobAttachments,
      jobFrequencies,
      jobStartDate,
      jobEndDate,
      subJobId,
      dataStatus,
    },
    facility: { address = null } = {},
    RejectedStatus,
  } = data || {};
  const navigate = useNavigate();
  const { handleSubmit, control, setError, setValue } = useForm({
    defaultValues: {
      startDate: (startDate || jobStartDate) ?? null,
      endDate: (stopDate || jobEndDate) ?? null,
    },
  });

  const handleViewMapClick = () => {
    setLocation({
      lat: address?.latitude ? parseFloat(address.latitude) : null,
      long: address?.longitude ? parseFloat(address.longitude) : null,
    });
    setMapPopupOpen(true);
  };

  const handleClosePopup = () => {
    setMapPopupOpen(false);
  };

  const getTimeFormat = (time: string) => {
    const hour24 = time.split(':');
    const minutes: string = time.split(':')[1];
    const AmOrPm = +hour24[0] >= 12 ? 'pm' : 'am';
    const hours = +hour24[0] % 12 || 12;
    return `${hours}:${minutes} ${AmOrPm}`;
  };

  const getTime = async (jobId: string) => {
    const workorderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId });
    if (workorderResponse?.data?.length) {
      const shiftDetails = await WorkOrdersApiService.getallShiftDetails(workorderResponse?.data?.[0].workOrderId);
      if (shiftDetails?.data) {
        // const { startTime } = shiftDetails?.data[0] || '';
        // const time = startTime ? getTimeFormat(startTime) : '';
        // setStartTime(time);
      } else {
        console.error('shiftDetails query: ', shiftDetails.errors);
      }
    } else {
      console.error('workorder query: ', workorderResponse.errors);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const customerId = getCustomerId();
      const role = getUserRole();
      const finalCustomerId = getFinalCustomerId(customerId, role);

      if (isDistrictManager) {
        await handleDistrictManagerWorkOrders();
      }

      const serviceCategories = await MasterDataApiService.getAllServiceCategoryV3(finalCustomerId);
      await getTime(jobId);
      if (serviceCategories.data) {
        const modifiedCategories = mapServiceCategories(serviceCategories);
        updateServices(modifiedCategories);
      } else {
        console.error('serviceCategoryV2 queries: ', serviceCategories.errors);
      }
    } catch (e) {
      console.error('Error', e);
    } finally {
      setIsLoading(false);
    }
  };

  const getCustomerId = () => {
    const storedCustomerDetails = getStoredCustomerDetails() || {};
    return storedCustomerDetails.customerId || '';
  };

  const getUserRole = () => {
    const authValue = localStorage.getItem('auth');
    const authData = authValue ? JSON.parse(authValue) : {};
    return authData?.attributes?.['custom:Role'] || '';
  };

  const getFinalCustomerId = (customerId: string, role: string) => {
    return role === UserType.Customer.toString() ? customerId : data.jobDetails.customerId;
  };

  const handleDistrictManagerWorkOrders = async () => {
    try {
      const workorderResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId });
      if (workorderResponse?.data && workorderResponse.errors?.length === 0) {
        const workOrderData = workorderResponse.data[0];
        if (workOrderData?.workOrderId && workOrderData?.actualStartDate && workOrderData?.actualEndDate) {
          const workOrderTeamResponse = await WorkOrdersApiService.getWorkOrderTeam(
            workOrderData.workOrderId,
            workOrderData?.actualStartDate,
            workOrderData?.actualEndDate
          );
          handleWorkOrderTeamResponse(workOrderTeamResponse);
        }
      }
    } catch (error) {
      console.error('Error handling district manager work orders:', error);
    }
  };

  const handleWorkOrderTeamResponse = async (workOrderTeamResponse: any) => {
    if (workOrderTeamResponse?.data) {
      const associateDetails = workOrderTeamResponse.data.find((member: any) => !member.isSupervisor);
      const workDayId = associateDetails?.workDayTeam?.workDayId;
      if (workDayId) {
        const latestPunchInOutResponse = await WorkOrdersApiService.getLatestPunchRecords(workDayId);
        handleLatestPunchInOutResponse(latestPunchInOutResponse);
      }
    }
  };

  const handleLatestPunchInOutResponse = (latestPunchInOutResponse: any) => {
    if (!isEmpty(latestPunchInOutResponse?.data) && isEmpty(latestPunchInOutResponse?.data?.errorCode)) {
      setIsAssociatePunchedIn(!!latestPunchInOutResponse?.data?.punchType);
    }
  };

  const mapServiceCategories = (serviceCategories: any) => {
    return serviceCategories.data.map((categoryItem: any) => ({
      categoryId: categoryItem.serviceCategoryId,
      categoryName: categoryItem.serviceCategoryName,
      unit: categoryItem.unit,
    }));
  };

  const updateServices = (modifiedCategories: any) => {
    if (data.jobDetails.jobServices) {
      const services = data.jobDetails.jobServices.map((dataItem: any) => {
        const selectedService = modifiedCategories.find(
          (serviceItem: any) => serviceItem.categoryId === dataItem.mdServiceId
        );
        const categoryName = selectedService?.categoryName || '';
        const unit = selectedService?.unit || '';
        const serviceMeasure = dataItem?.serviceMeasure || '';
        return `${categoryName} - ${serviceMeasure} ${unit}`;
      });
      setServices(services);
    } else {
      setServices([]);
    }
  };

  const { customerId = '' } = getStoredCustomerDetails();

  const getAddress = async () => {
    if (data && data?.facility && data?.facility?.address) {
      const { addressLine2 = '', addressName = '' } = data?.facility?.address ?? '';

      return addressLine2 || addressName;
    } else if (data?.jobDetails?.facilityId) {
      const facilityResponse = await FacilityApiService.facility(data?.jobDetails?.facilityId, customerId);

      const { addressLine2 = '' } = facilityResponse.data.address;

      return addressLine2;
    }
  };

  const fetchJobs = useCallback(async () => {
    try {
      let facilityIcon: any = '';
      const logoUrl = data?.facility?.address?.logoUrl;
      if (data && logoUrl) {
        facilityIcon = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${logoUrl}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
        setFacilityIcon(facilityIcon);
      }

      if (jobAttachments?.length) {
        const attachmentsList = await Promise.all(
          jobAttachments.map(async (attachment: any) => {
            if (!attachment.url.includes('https')) {
              attachment.url = await fetchDataFromS3Bucket(navigate, attachment?.url ?? '');
            }
            return attachment;
          })
        );
        const imagesList: any[] = [];
        const videosList: any[] = [];
        setJobAttachments(attachmentsList);
        attachmentsList.forEach((attachment: any) => {
          if (attachment.mdAttachmentType.includes('image')) {
            imagesList.push(attachment);
          } else {
            videosList.push(attachment);
          }
        });
        setImages([...images, ...imagesList]);
        setVideos([...videos, ...videosList]);
      }
    } catch (e) {
      console.error('Error', e);
      return [];
    }
  }, []);

  const closeMediaPopup = () => {
    setPopupOpen(false);
    setCurrentMediaIndex(0);
  };

  const openMediaPopup = (type: MediaType) => {
    const mediaArray = type === 'photo' ? images : videos;
    if (mediaArray.length) {
      setPopupOpen(true);
      setMediaType(type);
    }
  };

  const showMedia = (direction: 'previous' | 'next') => {
    setCurrentMediaIndex((prevIndex) => {
      const mediaArray = mediaType === 'photo' ? images : videos;
      return direction === 'previous'
        ? (prevIndex - 1 + mediaArray?.length) % mediaArray?.length
        : (prevIndex + 1) % mediaArray?.length;
    });
  };

  const handleOpenEditDateModal = () => {
    setValue(FieldNames.startDate, startDateOfJob);
    setValue(FieldNames.endDate, endDateOfJob);
    setIsEditDateModalOpen(true);
  };

  const handleOpenEditPhotosAndVideosModal = () => {
    setIsEditPhotosAndVideosModalOpen(true);
  };

  const handleWorkOrderUpdate = async (workOrderId: string, startDate: string, endDate: string) => {
    const payload = {
      workOrderId: workOrderId,
      actualStartDate: startDate,
      actualEndDate: endDate,
      jobStartDate: startDate,
      jobEndDate: endDate,
    };
    try {
      const response = await WorkOrdersApiService.updateWorkOrderStatus(payload);
      if (!isEmpty(response?.data) && isEmpty(response?.errors)) {
        setSnackbarMessage(t('message:updatedJobDate'));
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setIsEditDateModalOpen(false);
      } else {
        setSnackbarMessage(response?.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      setSnackbarMessage(t('message:errorWhileUpdatingWorkOrder'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);
      return false;
    }
    return true;
  };

  const handleSubWorkOrderUpdate = async (
    subJobId: string,
    workOrderId: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      const subWorkOrderGetResponse = await WorkOrdersApiService.getAllWorkOrders({
        jobId: subJobId,
      });
      if (isEmpty(subWorkOrderGetResponse.data) || !isEmpty(subWorkOrderGetResponse.errors)) {
        setSnackbarMessage(subWorkOrderGetResponse?.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsLoading(false);
        return false;
      }
      const subWorkOrderId = subWorkOrderGetResponse?.data?.[0]?.workOrderId;
      if (!subWorkOrderId || !workOrderId) {
        setSnackbarMessage(subWorkOrderGetResponse?.errors[0]?.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setIsLoading(false);
        return false;
      }
      return await handleWorkOrderUpdate(subWorkOrderId, startDate, endDate);
    } catch (error) {
      setSnackbarMessage(t('message:errorWhileUpdatingJobDate'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);
      return false;
    }
  };

  const handleChangeDate = async (dateDetails: any) => {
    const { startDate, endDate } = dateDetails;
    setIsLoading(true);
    const formatStartDate = moment(startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(endDate).format('YYYY-MM-DD');

    if (startDate && isDateBefore(endDate, startDate)) {
      handleDateError();
      return;
    }

    try {
      const updateJobDateResponse = await updateJobDates(formatStartDate, formatEndDate);
      if (!updateJobDateResponse) true;
      const workOrderId = await fetchWorkOrderId();

      if (subJobId) {
        const updateSubJobDateResponse = await updateSubJobDates(formatStartDate, formatEndDate);
        if (!updateSubJobDateResponse) return;
        const subWorkOrderUpdateResult = await handleSubWorkOrderUpdate(
          subJobId,
          workOrderId,
          formatStartDate,
          formatEndDate
        );
        if (!subWorkOrderUpdateResult) return;
      }

      if (workOrderId) {
        const workOrderUpdateResult = await handleWorkOrderUpdate(workOrderId, formatStartDate, formatEndDate);
        if (!workOrderUpdateResult) return;
      }
      fetchJobDetails(jobId);
      setStartDateOfJob(moment(formatStartDate).format(DateFormat.StandardDate));
      setEndDateOfJob(moment(formatEndDate).format(DateFormat.StandardDate));
    } catch (error) {
      handleApiError();
    } finally {
      setIsLoading(false);
    }
  };

  const updateJobDates = async (startDate: string, endDate: string) => {
    const updateJobResponse = await JobCreateApiService.updateJob(customerId, jobId, {
      startDate: startDate ?? null,
      stopDate: endDate ?? null,
    });
    if (!isEmpty(updateJobResponse.errors)) {
      return handleApiError();
    }
    return true;
  };

  const fetchWorkOrderId = async () => {
    const workOrderGetResponse = await WorkOrdersApiService.getAllWorkOrders({ jobId: jobId });
    if (!isEmpty(workOrderGetResponse.errors)) {
      return handleApiError();
    }
    return workOrderGetResponse?.data?.[0]?.workOrderId;
  };

  const updateSubJobDates = async (startDate: string, endDate: string) => {
    const updateSubJobResponse = await JobCreateApiService.updateJob(customerId, subJobId, {
      startDate: startDate ?? null,
      stopDate: endDate ?? null,
    });
    if (!isEmpty(updateSubJobResponse.errors)) {
      return handleApiError();
    }
    return true;
  };

  const handleDateError = () => {
    setError(FieldNames.endDate, {
      type: 'validate',
      message: t('message:endDateCannotBeBeforeStartDate'),
    });
    setIsLoading(false);
  };

  const handleApiError = () => {
    setSnackbarMessage(t('message:errorWhileUpdatingJobDate'));
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
    setIsLoading(false);
    return false;
  };

  const handleClose = () => {
    setIsEditDateModalOpen(!isEditDateModalOpen);
    // if (reason && reason === 'backdropClick') return;
  };

  const handlePhotosAndVideosClose = () => {
    localStorage.removeItem('jobAttachments');
    fetchJobs();
    setIsEditPhotosAndVideosModalOpen(!isEditPhotosAndVideosModalOpen);
  };

  useEffect(() => {
    const formattedJobStartDate = formatDate(startDate || jobStartDate);
    const formattedJobEndDates = formatDate(stopDate || jobEndDate);
    setStartDateOfJob(formattedJobStartDate);
    setEndDateOfJob(formattedJobEndDates);
  }, [startDate, stopDate, jobStartDate, jobEndDate]);

  useEffect(() => {
    fetchData();
    fetchJobs();
  }, [data]);

  useEffect(() => {
    const getAddressData = async () => {
      try {
        const address = await getAddress();
        setAddressData(address);
      } catch (error) {
        console.error('Error fetching address data:', error);
      }
    };

    getAddressData();
  }, []);

  const renderEditDateHeaderContent = () => {
    return <HeadingText>{t('assignJob:shiftTimings')}</HeadingText>;
  };

  const renderEditPhotosAndVideosHeaderContent = () => {
    return <HeadingText>{t('oneTimeJob:editPhotosAndVideos')}</HeadingText>;
  };

  const renderEditDateBodyContent = () => {
    return (
      <>
        <Details>{t('assignJob:dateRangeToCompleteJobWithin')}</Details>
        <DatePickerContainer>
          <DateSelection
            requiredText={t('oneTimeJob:startDateFieldReq')}
            control={control}
            isExcludePastDate={false}
            fieldName={FieldNames.startDate}
            label={t('calendar:startDate')}
            iconColor="black"
            disabled={dataStatus === JobStatuses.InProgress}
          />
          <DateSelection
            requiredText={t('oneTimeJob:endDateFieldReq')}
            control={control}
            isExcludePastDate={false}
            fieldName={FieldNames.endDate}
            label={t('calendar:endDate')}
            iconColor="black"
          />
        </DatePickerContainer>
      </>
    );
  };

  const handleUploadedFileTags = (data: any) => {
    const modifiedSelectedTags = [...uploadedFileTags];
    const foundIndex = modifiedSelectedTags.findIndex((item) => item.id === data.id);
    if (foundIndex !== -1) {
      // If the object is found, update its 'tags' property and filter out duplicates
      modifiedSelectedTags[foundIndex].tags = [
        ...new Set([...(modifiedSelectedTags[foundIndex].tags ?? []), ...(data.tags ?? [])]),
      ];
    } else {
      // If not found, add a new object to the array with filtered values
      const filteredTags = [...new Set(data.tags ?? [])];
      modifiedSelectedTags.push({
        id: data.id,
        tags: filteredTags,
      });
    }
    if (!isEmpty(data?.id)) {
      setModifiedPreviousAttachments((previousIds: string[]) => {
        const updatedAttachmentIds = [...previousIds];
        if (!updatedAttachmentIds.includes(data?.id)) {
          updatedAttachmentIds.push(data?.id);
        }
        return updatedAttachmentIds;
      });
    }
    setUploadedFileTags([...modifiedSelectedTags]);
  };
  const handleAttachmentDetails = (data: any) => {
    localStorage.setItem('jobAttachments', JSON.stringify(data));
    setJobAttachments(data);

    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    });
  };

  const handleDeleteAttachment = async (index: number) => {
    const item = jobNewAttachments[index];
    if (!isEmpty(item?.attachmentId)) {
      try {
        const deleteAttachmentResponse = await JobCreateApiService.deleteJobAttachments(jobId, [item?.attachmentId]);
        if (!isEmpty(deleteAttachmentResponse.errors)) {
          console.log('deleteAttachmentResponse.errors', deleteAttachmentResponse.errors);
        } else if (!isEmpty(deleteAttachmentResponse.data)) {
          fetchAllJobAttachments();
        }
      } catch (e) {
        console.log('error', e);
      }
      return;
    }
    const filteredDetails = jobNewAttachments.filter((data: { id: any }) => data.id !== item.id);
    localStorage.setItem('jobAttachments', JSON.stringify(filteredDetails));
    setJobAttachments(filteredDetails);
  };

  const fetchAllJobAttachments = async () => {
    try {
      // const customerDetails = await Promise.resolve(getStoredCustomerDetails());
      // let customerId = '';
      // if (role === UserType.Customer) {
      //   ({ customerId } = customerDetails || {});
      // }
      const updatedJobId = jobId;
      const allJobAttachmentInput = { jobIds: [updatedJobId] };
      const attachmentResponse = await JobCreateApiService.getAllJobAttachments(allJobAttachmentInput);
      if (isEmpty(attachmentResponse.errors)) {
        const updatedDetails = await Promise.all(
          attachmentResponse.data.map(async (details: IDetailsProps) => {
            const attachmentUrl = await fetchDataFromS3Bucket(navigate, details?.url ?? '');
            return {
              ...details,
              url: attachmentUrl,
              id: details.attachmentId,
              attachmentId: details.attachmentId,
              responseUrl: details.url,
            };
          })
        );
        const selectedTagDetails = updatedDetails.map((item) => {
          return {
            id: item.attachmentId,
            tags: getTagValues(item.tags),
          };
        });
        setPreviouslyUploadedAttachments(attachmentResponse?.data ?? []);

        localStorage.setItem('jobAttachments', JSON.stringify(updatedDetails));
        setJobAttachments(updatedDetails);

        setUploadedFileTags(selectedTagDetails);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleDeleteSelectedTag = (id: string, tag: string, item: any) => {
    const modifiedSelectedTags = [...uploadedFileTags];
    const foundIndex = modifiedSelectedTags.findIndex((item) => item.id === id);
    if (foundIndex !== -1) {
      // If the object is found, remove the specified value from its 'values' property
      const valuesArray = modifiedSelectedTags[foundIndex].tags;
      const indexOfValueToRemove = valuesArray.indexOf(tag);
      if (indexOfValueToRemove !== -1) {
        valuesArray.splice(indexOfValueToRemove, 1);
      }
    }
    setUploadedFileTags(modifiedSelectedTags);
    if (!isEmpty(item?.attachmentId)) {
      setModifiedPreviousAttachments((previousIds: string[]) => {
        const updatedAttachmentIds = [...previousIds];
        if (!updatedAttachmentIds.includes(item?.attachmentId)) {
          updatedAttachmentIds.push(item?.attachmentId);
        }
        return updatedAttachmentIds;
      });
    }
  };

  const handleInstructionsChange = (id: string, comments: string) => {
    let modifiedAttachmentId: any = '';
    const updatedInstructions = jobNewAttachments.map((item: IDetailsProps) => {
      if (item?.id === id) {
        if (!isEmpty(item?.attachmentId)) {
          modifiedAttachmentId = item?.attachmentId;
        }
        return {
          ...item,
          comments,
        };
      }
      return {
        ...item,
      };
    });
    if (!isEmpty(modifiedAttachmentId)) {
      setModifiedPreviousAttachments((previousIds: string[]) => {
        const updatedAttachmentIds = [...previousIds];
        if (!updatedAttachmentIds.includes(modifiedAttachmentId)) {
          updatedAttachmentIds.push(modifiedAttachmentId);
        }
        return updatedAttachmentIds;
      });
    }
    localStorage.setItem('jobAttachments', JSON.stringify(updatedInstructions));
    setJobAttachments(updatedInstructions);
  };

  const saveAttachments = async (customerId: string, jobId: string) => {
    try {
      let attachmentsResponse: any;
      if (jobNewAttachments.length) {
        const facilityIds = data?.facility?.facilityId ?? '';

        const newAttachments: any = jobNewAttachments?.filter((attachment: IDetailsProps) =>
          isEmpty(attachment.attachmentId)
        );
        if (!isEmpty(modifiedPreviousAttachments)) {
          attachmentsResponse = await handleModifiedAttachments(facilityIds, jobId);
        }
        if (!isEmpty(newAttachments)) {
          attachmentsResponse = await handleNewAttachments(facilityIds, newAttachments, jobId);
        }
      }
      return attachmentsResponse;
    } catch (e) {
      console.log('error', e);
    }
  };

  const storeAttachmentsToS3Cloud = async (attachments = [], jobId: string, isUpdate = false) => {
    try {
      const fetchAllTags = await JobCreateApiService.getTags(Family.JobAttachments, jobId, customerId);
      const uploadDocumentData = await Promise.all(
        attachments.map(async (data: IDetailsProps) => {
          let storeTagValues = '';
          const storeTags = uploadedFileTags.find((tagDetail) => tagDetail.id === data.id);
          if (storeTags?.tags) {
            const getAllTheTags = storeTags?.tags?.map((tag: string) => {
              return fetchAllTags.data.find((allTag: ITag) => allTag?.value === tag);
            });
            storeTagValues = getAllTheTags
              .map((item: ITag, index: number) => `${item?.type}_${index + 1} = ${item?.value?.replace(/&/g, 'and')}`)
              .join(' & ');
          }
          const attachmentDetails = previouslyUploadedAttachments?.find(
            (attachment) => attachment?.attachmentId === data?.attachmentId
          );
          const uploadDocumentResponse = await uploadDocumentToS3Bucket(
            navigate,
            isUpdate
              ? attachmentDetails?.url
              : `customer/${customerId}/job-attachments/${jobId}/${data.id}${
                  data && data.fileExtension ? `.${data.fileExtension}` : ''
                }`,
            data?.url ?? '',
            {
              level: 'public',
              tagging: storeTagValues,
            },
            data.fileExtension
          );
          return uploadDocumentResponse?.file?.key || '';
        })
      );
      return uploadDocumentData.filter((item) => item !== '');
    } catch (error) {
      console.log('error', error);
      return [];
    }
  };

  const handleModifiedAttachments = async (facilityIds: string, jobId: string) => {
    if (!isEmpty(modifiedPreviousAttachments)) {
      const updatedAttachments: any = jobNewAttachments?.filter((attachment: any) =>
        modifiedPreviousAttachments.includes(attachment?.attachmentId)
      );
      const savedS3BucketKeys = await storeAttachmentsToS3Cloud(updatedAttachments, jobId, true);
      const s3BucketAttachmentKeys = await Promise.all(savedS3BucketKeys);
      if (s3BucketAttachmentKeys.length) {
        const updateAttachmentPayload: CreateOrUpdateJobAttachmentInput[] = updatedAttachments?.map(
          (item: IDetailsProps, index: number) => {
            const finalTag = '';
            const updateAttachment: any = {
              mdAttachmentType: item.mdAttachmentType,
              fileName: item?.fileName + '.' + item?.fileExtension,
              fileExtension: item?.fileExtension,
              url: s3BucketAttachmentKeys[index] ?? '',
              comments: item.comments,
              mdFileContext: 'JobAttachment',
              tags: finalTag ? finalTag : '',
            };
            return updateAttachment;
          }
        );
        const updateAttachmentResponse = await JobCreateApiService.updateJobAttachment(
          jobId,
          facilityIds,
          updateAttachmentPayload
        );

        if (!isEmpty(updateAttachmentResponse.errors)) {
          console.log('updateAttachmentResponse.errors[0]?.message', updateAttachmentResponse.errors[0]?.message);
        }
        return updateAttachmentResponse;
      }
    }
  };

  const handleNewAttachments = async (facilityIds: string, newAttachments: any, jobId: string) => {
    if (!isEmpty(newAttachments)) {
      const savedS3BucketKeys = await storeAttachmentsToS3Cloud(newAttachments, jobId, false);
      const s3BucketAttachmentKeys = await Promise.all(savedS3BucketKeys);
      if (s3BucketAttachmentKeys.length) {
        const createAttachmentPayload: CreateOrUpdateJobAttachmentInput[] = newAttachments?.map(
          (item: IDetailsProps, index: number) => {
            const finalTag = '';
            return {
              mdAttachmentType: item?.mdAttachmentType,
              attachmentId: item?.attachmentId,
              fileName: item?.fileName + '.' + item?.fileExtension,
              fileExtension: item?.fileExtension,
              url: s3BucketAttachmentKeys[index],
              comments: item?.comments,
              mdFileContext: FileContextType.JobAttachment,
              ...(finalTag ? { tags: finalTag } : {}),
            };
          }
        );
        const createAttachmentResponse = await JobCreateApiService.createJobAttachment(
          jobId,
          facilityIds,
          createAttachmentPayload
        );
        if (!isEmpty(createAttachmentResponse.errors)) {
          console.log('createAttachmentResponse.errors', createAttachmentResponse.errors[0].message);
        }
        return createAttachmentResponse;
      }
    } else {
      return {};
    }
  };

  const handleEditPhotos = async () => {
    setIsLoading(true);
    const customerDetails = await Promise.resolve(getStoredCustomerDetails());
    let customerId = '';
    if (role === UserType.Customer) {
      ({ customerId } = customerDetails || {});
    }

    try {
      await saveAttachments(customerId, jobId);
      await JobCreateApiService.updateJob(customerId, jobId, {
        draftStatus: DraftStatus.C,
        draftStep: DraftStep.JobSummary,
      });
      setIsEditPhotosAndVideosModalOpen(false);
      fetchJobDetails(jobId);
      setIsLoading(false);
    } catch (error) {
      handleApiError();
    } finally {
      setIsLoading(false);
    }
  };

  const renderEditPhotosAndVideosBodyContent = () => {
    return (
      <>
        <PhotosAndVideosContainer>
          <Box
            sx={{
              opacity: 1,
              pointerEvents: 'auto',
            }}>
            <PhotosAndVideos
              customerId={customerId}
              jobId={jobId}
              details={jobNewAttachments}
              uploadedFileTags={uploadedFileTags}
              handleAttachmentDetails={handleAttachmentDetails}
              handleUploadedFileTags={handleUploadedFileTags}
              handleDeleteAttachment={handleDeleteAttachment}
              handleDeleteSelectedTag={handleDeleteSelectedTag}
              handleInstructionsChange={handleInstructionsChange}
              isEditPhotosAndVideos={true}
            />
          </Box>
        </PhotosAndVideosContainer>
      </>
    );
  };

  const renderJobDetails = () => {
    return (
      <DetailsContainer>
        <TitleImgWrapper>
          <ImageContainer>
            <img src={facilityIcon || Icons.FacilityDetailsLogo} alt={t('altTexts:facility')} />
          </ImageContainer>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem' }}>
            <DetailsHeadingContainer style={{ paddingLeft: 0, marginBottom: '0.5rem' }}>
              <HeadingText>{t('dashboard:facilityDetails')}</HeadingText>
            </DetailsHeadingContainer>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '0.5rem',
              }}>
              <MediaContainer onClick={() => openMediaPopup('photo')}>
                <img src={PhotosIcon} alt={t('altTexts:galleryIcon')} />
                <MediaText>
                  {images?.length} {images?.length <= 1 ? t('dashboard:photo') : t('dashboard:photos')}
                </MediaText>
              </MediaContainer>
              <Divider />
              <MediaContainer onClick={() => openMediaPopup('video')}>
                <img src={VideoIcon} alt={t('altTexts:videoIcon')} />
                <MediaText>
                  {videos?.length} {videos?.length <= 1 ? t('dashboard:video') : t('dashboard:videos')}
                </MediaText>
              </MediaContainer>
              {isModifyJobDetails && (
                <IconButton onClick={() => handleOpenEditPhotosAndVideosModal()}>
                  <img
                    src={Icons.EditBlack}
                    alt={t('altTexts:edit')}
                    style={{ width: '1.3rem', height: '1.3rem', color: theme.palette.secondary.contrastText }}
                  />
                </IconButton>
              )}
            </Box>
          </div>
        </TitleImgWrapper>
        <DetailsContentContainer>
          <DetailsAndDate>
            <Box sx={{ display: 'flex', gap: '2rem', color: theme.palette.error.main }}>
              <Box>{RejectedStatus}</Box>
              <Details>
                {jobType === 'OneTime'
                  ? `One Time : ${jobName ?? ''}`
                  : jobType === 'Emergency'
                    ? `Priority : ${jobName ?? ''}`
                    : `Recurring : ${jobName ?? ''}`}
              </Details>
            </Box>
            <Date>
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: theme.palette.primary.dark, marginRight: '0.5rem' }} />
              ) : (
                ((isDistrictManager && !isAssociatePunchedIn) ||
                  (role === UserType.Customer && jobStatus !== JobStatus.Awarded)) &&
                dataStatus !== 'Completed' &&
                dataStatus !== JobStatus.Awarded && (
                  <IconButton onClick={() => handleOpenEditDateModal()}>
                    <img
                      src={Icons.EditBlack}
                      alt={t('altTexts:edit')}
                      style={{ width: '1.3rem', height: '1.3rem', color: theme.palette.secondary.contrastText }}
                    />
                  </IconButton>
                )
              )}
              {`${t('calendar:startDate')}: ${startDateOfJob} - ${t('calendar:endDate')}: ${endDateOfJob}`}
            </Date>
            <Modal
              open={isEditDateModalOpen}
              onClose={handleClose}
              primaryButtonLoading={isLoading}
              primaryButtonLabel={t('dashboard:changeDate')}
              secondaryButtonLabel={t('assignJob:cancel')}
              renderHeader={renderEditDateHeaderContent()}
              renderBody={renderEditDateBodyContent()}
              primaryAction={handleSubmit(handleChangeDate)}
              secondaryAction={handleClose}
            />
            {isEditPhotosAndVideosModalOpen && (
              <Modal
                open={isEditPhotosAndVideosModalOpen}
                onClose={handlePhotosAndVideosClose}
                primaryButtonLoading={isLoading}
                primaryButtonLabel={t('oneTimeJob:editPhotosAndVideos')}
                secondaryButtonLabel={t('assignJob:cancel')}
                renderHeader={renderEditPhotosAndVideosHeaderContent()}
                renderBody={renderEditPhotosAndVideosBodyContent()}
                primaryAction={handleSubmit(handleEditPhotos)}
                secondaryAction={handlePhotosAndVideosClose}
              />
            )}
            <SnackbarMessage
              open={snackbarOpen}
              successMessage={snackbarMessage}
              errorMessage={snackbarMessage}
              severity={snackbarSeverity}
              onClose={() => setSnackbarOpen(false)}
            />
          </DetailsAndDate>
        </DetailsContentContainer>
      </DetailsContainer>
    );
  };

  return (
    <FacilityDetailsContainer>
      {renderJobDetails()}
      <Divider sx={divider} />

      <FacilitiesAndBidOpenTo>
        <Match>
          <MatchText>{t('dashboard:facilities')}</MatchText>
        </Match>
        <FacilitiesDescription>
          <DescriptionText>{addressData}</DescriptionText>
          <Box
            onClick={handleViewMapClick}
            sx={{
              ...mediaContainer,
              cursor: 'pointer',
            }}>
            <img src={Icons.GooglemapIcon} alt={t('altTexts:googleMapIcon')} />
            <Typography sx={mediaText}>{t('dashboard:viewMap')}</Typography>
          </Box>
          <GoogleMapPopup
            open={mapPopupOpen}
            onClose={handleClosePopup}
            latitude={location.lat}
            longitude={location.long}
          />
        </FacilitiesDescription>
      </FacilitiesAndBidOpenTo>
      <Divider sx={divider} />
      <TimeAndInstructions>
        <Match>
          <MatchText>{t('dashboard:instructions')}</MatchText>
        </Match>
        <Box>
          <DescriptionText sx={{ whiteSpace: 'pre-wrap' }}>
            {data?.jobDetails?.specialInstructions ?? '-'}
          </DescriptionText>
        </Box>
      </TimeAndInstructions>
      <Divider sx={divider} />
      <MediaPopup
        isOpen={isPopupOpen}
        onClose={closeMediaPopup}
        currentMediaIndex={currentMediaIndex}
        media={mediaType === 'photo' ? images : videos}
        showPreviousMedia={() => showMedia('previous')}
        showNextMedia={() => showMedia('next')}
        numMedia={mediaType === 'photo' ? images?.length : videos?.length}
        type={mediaType}
      />

      {jobFrequencies?.length > 0 &&
        jobFrequencies[0]?.fromTime &&
        jobFrequencies?.length > 0 &&
        jobFrequencies[0]?.toTime && (
          <>
            <TimeAndInstructions>
              <Match>
                <MatchText>{t('dashboard:startAndEndTime')}</MatchText>
              </Match>
              <DescriptionText>
                {getTimeFormat(jobFrequencies?.[0].fromTime)} - {getTimeFormat(jobFrequencies?.[0].toTime)}
              </DescriptionText>
            </TimeAndInstructions>
            <Divider sx={divider} />
          </>
        )}

      {isLoading ? (
        <Loader>
          <CircularProgress size={30} sx={{ color: theme.palette.primary.dark }} />
        </Loader>
      ) : (
        <TimeAndInstructions>
          <Match>
            <MatchText>{t('dashboard:ServiceAndQuantity')}</MatchText>
          </Match>
          <DescriptionText>
            {services?.length ? services?.join(', ')?.toString() : `${t('dashboard:noServicesFound')}`}
          </DescriptionText>
        </TimeAndInstructions>
      )}
    </FacilityDetailsContainer>
  );
};

export default FacilityDetails;
