/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

import LabelComponent from './LabelComponent';
import { fetchFacilityTypes } from './FacilityType';
import { NoDataFound } from '../../../../Shared/Components/Common/NoDataFound/NoDataFound';

import {
  FacilityContainer,
  FacilityDetailsContainer,
  NoDataContainer,
} from '../../../Jobs/Components/JobDetails/OneTimeJobDetails.Style';

const LabelWrapperComponent = (props: any): JSX.Element => {
  const { facilityDetails, selectedServices } = props;
  const [facilityTypeName, setFacilityTypeName] = useState<string>('');

  const servicesLength = selectedServices?.length;

  useEffect(() => {
    if (facilityDetails?.mdFacilityType) {
      const fetchFacilityType = async (): Promise<void> => {
        const typeName = await fetchFacilityTypes(facilityDetails.mdFacilityType as string);
        setFacilityTypeName(typeName);
      };
      fetchFacilityType();
    }
  }, [facilityDetails?.mdFacilityType]);

  const newItems = [
    {
      label: t('viewFacilityDetails:facilityType'),
      value: facilityTypeName,
    },
    {
      label: t('viewFacilityDetails:area'),
      value: facilityDetails?.sqFootage,
    },
    {
      label: t('viewFacilityDetails:address'),
      value: (() => {
        const { address } = facilityDetails;
        if (!address) return '';

        if (address.addressName) {
          return address.addressLine2 !== ',' ? address.addressLine2 : '';
        } else {
          return `${address.addressLine1 ?? ''} ${address.addressLine2 ?? ''} ${address.city ?? ''} ${address.state ?? ''} ${address.postalCode ?? ''} ${address.county ?? ''}`;
        }
      })(),
    },
  ];

  return (
    <FacilityDetailsContainer sx={{ boxShadow: 'none' }}>
      <FacilityContainer>
        {facilityDetails && newItems.map((item, i) => <LabelComponent key={i} label={item.label} value={item.value} />)}
        {servicesLength
          ? selectedServices.map((item: any, i: any) => (
              <LabelComponent
                key={i}
                label={item.categoryName}
                value={`${item.serviceQuantity} ${item.mdServiceUnitType}`}
              />
            ))
          : !facilityDetails && (
              <NoDataContainer>
                <NoDataFound />
              </NoDataContainer>
            )}
      </FacilityContainer>
    </FacilityDetailsContainer>
  );
};

export default LabelWrapperComponent;
