/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { InputBase, MenuItem, styled } from '@mui/material';
import theme from '../../../../Shared/Themes/theme';
import { StyleConstants } from '../../../../Shared/Constants/Style.Constants';

export const Services = styled('h2')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2?.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3?.fontSize,
    lineHeight: '1.375rem',
  },
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: StyleConstants.JustifyContentBetween,
  marginTop: '2rem',
  alignItems: 'center',
  [theme.breakpoints.down('laptop')]: {
    marginTop: '1.5rem',
  },
}));

export const DropdownWrapper = styled('div')(() => ({
  display: 'flex',
  columnGap: '1rem',
}));

export const DropdownIcon = styled('div')(({ theme }) => ({
  '&.MuiSelect-icon': {
    right: '1rem',
    top: 'calc(50% - 0.75rem)',
    [theme.breakpoints.down('laptop')]: {
      right: '0.75rem',
    },
  },
}));

export const Options = styled(MenuItem)(({ theme }) => ({
  borderBottom: `0.0625rem solid ${theme.palette.divider}`,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.375rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
  },
}));

export const SelectInput = styled(InputBase)(({ theme }) => ({
  width: '11.5rem',
  '& .MuiSelect-select': {
    '&:focus': {
      borderRadius: '1rem',
    },
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    borderRadius: '1rem',
    padding: '0.5rem 1rem',
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '1.5rem',
    letterSpacing: StyleConstants.LetterSpacingTight,
    [theme.breakpoints.down('laptop')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
}));

export const ServiceContainer = styled('div')(() => ({
  flex: 1,
  padding: '0 1rem',
  borderRight: `0.0625rem solid ${theme.palette.divider}`,
}));

export const ServicesStatusWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: StyleConstants.JustifyContentBetween,
  marginBottom: '1rem',
}));

export const DatePickerButton = styled('button')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  color: theme.palette.primary.dark,
  backgroundColor: theme.palette.common.white,
  borderRadius: '1rem',
  border: `0.0625rem solid ${theme.palette.primary.dark}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: StyleConstants.JustifyContentBetween,
  padding: '0.5rem',
  minWidth: '10rem',
  width: 'max-content',
  '& img': {
    width: '1.5rem',
    height: '1.5rem',
  },
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h5.fontSize,
  },
}));

export const NoDataTag = styled('div')(() => ({
  color: theme.palette.text.disabled,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5?.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
}));

export const NoPendingTaskContainer = styled('div')(() => ({
  marginTop: '2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
}));

export const buttonStyles = {
  updateShiftDateButton: {
    border: `0.0625rem solid ${theme.palette.primary.dark}`,
    background: theme.palette.common.white,
    borderRadius: '12px',
    textTransform: 'none',
    marginRight: '1.5rem',
    padding: '1.0625rem 2rem',
    cursor: 'pointer',
    maxWidth: 'fit-content',
    width: '100%',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '@media (max-width: 64rem)': {
      padding: '0.75rem 2rem',
      marginRight: '1rem',
    },
  },
  buttonText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h5?.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.5rem',
    letterSpacing: '0.013rem',
    color: theme.palette.primary.dark,
    '@media (max-width: 64rem)': {
      fontSize: theme.typography.h5?.fontSize,
      lineHeight: '1.375rem',
      letterSpacing: '0.0125rem',
    },
  },
};
