/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import { MRT_ColumnDef } from 'material-react-table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDescription,
  AccordionInfo,
  AccordionWrapper,
  ButtonWrapper,
  ProgressBar,
  ServiceContainer,
  ServicesStatusWrapper,
  ServicesTitle,
  ServiceTitleDetails,
  TaskStatusTag,
} from './Accordion.styles';
import { TaskData } from '../../../../Modules/Jobs/Components/WorkTasks/WorkTasks';
import { ISchedules, IServices, Task } from '../../../Models/WorkTask.model';
import DataGrid from '../DataGrid/DataGrid';
import { ProgressBarContainer } from '../../../../Modules/Jobs/Components/JobDetailsOverView/JobDetailsOverview.styles';
import { IJobOverview } from '../../../../Modules/Jobs/Components/JobsListView/JobsListView';
import { IJobs } from '../../../../Modules/Jobs/Components/JobView/JobsView';
import Button, { ButtonType } from '../Buttons/Button';
import { useAuth } from '../../../../Configuration/AuthContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IBidList } from '../../../../Modules/Jobs/Components/JobDetails/OneTimeJobDetails';
import { IProfileCardProps } from '../../../../Modules/Customer/Components/ViewFacilityDetails/ViewFacilityDetails';

import theme from '../../../Themes/theme';
import { Box } from '@mui/material';
import { TaskStatus } from '../../../../API';
import { getAuthData } from '../../../Utilities/utils';
import { UserType } from '../../../Constants/App';

interface IDataItem {
  id: number;
  jobId?: string;
  isShowButton?: boolean;
  selectedRowCount?: number;
  taskCompleted?: number;
  tableData?: TaskData[];
  serviceName?: string;
  shiftTiming?: string;
  shiftType?: string;
  Jobname?: string;
  facilityName?: string;
  iconUrl?: any;
  categoryTitle?: any;
  totalTasks?: number;
  totalCompletedTasks?: any;
  options?: any;
}

interface INewDataItem {
  woScheduleMasterId: string;
  title: string;
  jobId?: string;
  isShowButton?: boolean;
  selectedRowCount?: number;
  taskCompleted?: number;
  tableData?: TaskData[];
  serviceName?: string;
  shiftTiming?: string;
  shiftType?: string;
  Jobname?: string;
  facilityName?: string;
  iconUrl?: any;
  categoryTitle?: any;
  totalTasks?: number;
  totalScheduledTasks?: any;
  totalCompletedTasks?: any;
  options?: any;
  category?: any;
  serviceImage?: any;
}

export enum ScreenName {
  IssuesRaised = 'IssuesRaised',
  TasksReviewed = 'TaskReviewed',
  JobDetails = 'JobDetails',
}

export const WhiteTooltip = withStyles({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderRadius: '1rem',
    fontSize: theme.typography.h4?.fontSize,
    boxShadow: '0px 10px 20px 6px rgba(0, 107, 107, 0.10)',
    padding: ' 0.5rem 1rem',
  },
})(Tooltip);

interface IWorkTasksAccordionProps {
  vendorTypes?: [];
  accordionObj: IDataItem;
  accordionObjNew?: INewDataItem;
  handleAccordion: (id: number) => (event: React.SyntheticEvent, expanded: boolean) => void;
  columns: MRT_ColumnDef<TaskData | IJobOverview | IJobs | IBidList | IProfileCardProps>[];
  onChangeRow: (rowSelection: object, data: TaskData[], service: any) => void;
  expanded: number | boolean;
  screenName: ScreenName;
  serviceList?: IServices[];
  scheduleList?: ISchedules[];
  handleApproveAll?: () => void;
  handleRejectAll?: () => void;
  handleCustomerApproval?: (data: Task[]) => void;
  sendingForApproval?: boolean;
  isOtjJob?: boolean;
  optionsData?: any;
  showUpdateShiftDateButton: () => boolean | '' | null;
  renderUpdateShiftDateButton: (schedule: any) => JSX.Element;
  sectionTitle?: string;
}
/**
 * @returns Accordion
 */
const WorkTasksAccordion = ({
  vendorTypes,
  accordionObjNew,
  accordionObj,
  handleAccordion,
  columns,
  onChangeRow,
  expanded,
  screenName,
  scheduleList,
  handleApproveAll,
  handleRejectAll,
  handleCustomerApproval,
  sendingForApproval = false,
  isOtjJob,
  optionsData,
  showUpdateShiftDateButton,
  renderUpdateShiftDateButton,
  sectionTitle,
}: IWorkTasksAccordionProps) => {
  const selectedRowCount = accordionObj?.selectedRowCount;

  const { totalTasks: taskTotal, taskCompleted } = accordionObjNew as INewDataItem;

  const { woScheduleMasterId = '' } = (accordionObjNew as INewDataItem) ?? {};
  const { role, isDistrictManager } = getAuthData();

  const customerApprovalPendingTasks = optionsData?.filter(
    (data: Task) => (data.status as TaskStatus) === TaskStatus.ApprovedBySupervisor
  );

  const { isApproveLoading, isRejectLoading } = useAuth();
  let progress = 0;
  let taskCompletedFloatValue = 0;
  let taskTotalFloatValue = 0;
  if (taskCompleted) {
    taskCompletedFloatValue = parseFloat(taskCompleted.toString());
  }
  if (taskTotal) {
    taskTotalFloatValue = parseFloat(taskTotal.toString());
  }
  progress = (taskCompletedFloatValue / taskTotalFloatValue) * 100;
  let statusText = '';
  if (screenName === ScreenName.IssuesRaised) {
    statusText = 'issues resolved';
  } else if (screenName === ScreenName.JobDetails) {
    statusText = 'tasks';
  } else if (screenName === ScreenName.TasksReviewed) {
    statusText = 'tasks reviewed';
  }
  const expandStatus = scheduleList ? scheduleList.findIndex((data) => data.id === woScheduleMasterId) : -1;
  const { Secondary, Primary } = ButtonType;
  const recordsLength = optionsData?.length || 0;

  const { t } = useTranslation(['dashboard']);

  const CustomerApprovalActions = ({
    customerApprovalPendingTasks,
    role,
    isDistrictManager,
    isOtjJob,
    handleCustomerApproval,
    sendingForApproval,
    t,
  }: any) =>
    customerApprovalPendingTasks?.length > 0 &&
    role !== UserType.Customer &&
    !isDistrictManager && (
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type={Primary}
          label={
            isOtjJob
              ? t('dashboard:sendWorkTasksForPrimeVendorApproval')
              : `${t('dashboard:send')} (${customerApprovalPendingTasks.length}) ${t('dashboard:workTasksForCustomerApproval')}`
          }
          onClick={() => handleCustomerApproval && handleCustomerApproval(customerApprovalPendingTasks)}
          isLoading={sendingForApproval}
          disabled={sendingForApproval}
          width="25rem"
        />
      </Box>
    );

  const RowSelectionActions = ({
    selectedRowCount,
    recordsLength,
    handleApproveAll,
    handleRejectAll,
    isApproveLoading,
    isRejectLoading,
  }: any) =>
    selectedRowCount ? (
      <ButtonWrapper>
        <Button
          type={Secondary}
          label={selectedRowCount !== recordsLength ? `Reject (${selectedRowCount})` : t('dashboard:rejectAll')}
          onClick={handleRejectAll}
          width="160px"
          isLoading={isRejectLoading}
        />
        <Button
          type={ButtonType.Primary}
          label={selectedRowCount !== recordsLength ? `Approve (${selectedRowCount})` : t('dashboard:approveAll')}
          onClick={handleApproveAll}
          width="160px"
          isLoading={isApproveLoading}
        />
      </ButtonWrapper>
    ) : null;

  return (
    <AccordionWrapper expanded={expanded === expandStatus + 1} onChange={handleAccordion(expandStatus + 1)}>
      <AccordionDescription expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <ServiceContainer>
          <ServicesStatusWrapper>
            <ServiceTitleDetails>
              {screenName === ScreenName.JobDetails ? <ServicesTitle>{sectionTitle}</ServicesTitle> : null}
            </ServiceTitleDetails>
            <TaskStatusTag sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              {showUpdateShiftDateButton() && renderUpdateShiftDateButton(accordionObjNew)}
              {taskCompleted}/{taskTotal} {statusText}
              <WhiteTooltip title={t('dashboard:totalTasksApprovedByCustomer')}>
                <InfoOutlinedIcon />
              </WhiteTooltip>
            </TaskStatusTag>
          </ServicesStatusWrapper>
          <ProgressBarContainer>
            <ProgressBar progressPercent={progress}></ProgressBar>
          </ProgressBarContainer>
        </ServiceContainer>
      </AccordionDescription>
      <AccordionInfo>
        <DataGrid
          vendorTypes={vendorTypes}
          expanded={expanded}
          columns={columns}
          data={optionsData}
          onRowChange={(rowSelection: any) => onChangeRow(rowSelection, optionsData, accordionObj)}
        />
        <CustomerApprovalActions
          customerApprovalPendingTasks={customerApprovalPendingTasks}
          role={role}
          isDistrictManager={isDistrictManager}
          isOtjJob={isOtjJob}
          handleCustomerApproval={handleCustomerApproval}
          sendingForApproval={sendingForApproval}
          t={t}
        />
        <RowSelectionActions
          selectedRowCount={selectedRowCount}
          recordsLength={recordsLength}
          handleApproveAll={handleApproveAll}
          handleRejectAll={handleRejectAll}
          isApproveLoading={isApproveLoading}
          isRejectLoading={isRejectLoading}
        />
      </AccordionInfo>
    </AccordionWrapper>
  );
};

export default WorkTasksAccordion;
