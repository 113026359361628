/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import './GoogleAutoComplete.css';

import { Icons } from '../../../../Shared/Constants/Icons';
import {
  GeocodeResult,
  GoogleAutoCompleteProps,
  LatLng,
  PlacesAutocompleteRenderProps,
  Suggestion,
} from '../../Models/AddFacility.Model';

const GoogleAutoComplete = ({ onLocationChange }: GoogleAutoCompleteProps): JSX.Element | null => {
  const [address, setAddress] = useState<string>('');
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [placesLibraryLoaded, setPlacesLibraryLoaded] = useState(false);

  const { t } = useTranslation(['addFacility']);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.onload = (): void => setPlacesLibraryLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleChange = (address: string): void => {
    setAddress(address);
    setIsInputEmpty(address === '');
  };

  const handleSelect = async (address: string): Promise<void> => {
    setAddress(address);
    setIsInputEmpty(false);
    try {
      const results: GeocodeResult[] = await geocodeByAddress(address);
      const latLng: LatLng = await getLatLng(results[0]);
      onLocationChange(latLng);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const clearSearch = (): void => {
    setAddress('');
    setIsInputEmpty(true);
  };

  if (!placesLibraryLoaded) {
    return null;
  }

  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }: PlacesAutocompleteRenderProps) => (
        <div className="google-auto-complete">
          <div className="search-icon-container">
            <input
              {...getInputProps({
                placeholder: t('addFacility:search'),
                className: 'location-search-input',
              })}
            />
            {isInputEmpty ? (
              <img className="search-icon-img" src={Icons.SearchIcon} />
            ) : (
              <img className="search-icon-img" src={Icons.ClearIcon} onClick={clearSearch} />
            )}
          </div>
          <div className="autocomplete-dropdown-container">
            {loading && <div style={{ paddingLeft: '1rem' }}>{t('addFacility:loading')}</div>}
            {suggestions.map((suggestion: Suggestion) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              return (
                <div
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleAutoComplete;
