/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';
import theme from '../../../../Shared/Themes/theme';
import { StyleConstants } from '../../../../Shared/Constants/Style.Constants';

export const DropdownWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '1.5rem',
  margin: '0 0 1.5rem',
  [theme.breakpoints.down('laptop')]: {
    flexWrap: 'nowrap',
    rowGap: '1.5rem',
  },
}));

export const DateWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '1rem',
  boxShadow: '0 0.625rem 1.25rem 0.375rem #7957001A',
  padding: '1.5rem',
  marginBottom: '2.5rem',
  [theme.breakpoints.down('laptop')]: {
    marginBottom: '2rem',
  },
}));

export const SelectedTitle = styled('h2')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  marginBottom: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1.5rem',
  },
}));

export const DateInputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'row',
    columnGap: '1rem',
  },
}));

export const DateFieldWrapper = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const DateField = styled(TextField)(() => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  '& .MuiOutlinedInput-root': {
    borderRadius: '1rem',
    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.secondary.light,
    },
    '&:hover fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.secondary.light,
    },
    '&.Mui-focused fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.secondary.light,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0.0625rem solid ${theme.palette.secondary.light}`,
      '& legend span': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.h5?.fontSize,
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: '1.375rem',
        letterSpacing: '0.015625rem',
        color: theme.palette.text.secondary,
      },
    },
  },
}));

export const ApplyButton = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  outline: 'none',
  backgroundColor: theme.palette.common.white,
  borderRadius: '1rem',
  border: `0.0625rem solid ${theme.palette.primary.dark}`,
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.5rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  height: '3.5rem',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  [theme.breakpoints.down('laptop')]: {
    padding: '0.8125rem 2rem',
    fontSize: theme.typography.h5.fontSize,
    lineHeight: '1.375rem',
    letterSpacing: '0.0125',
  },
}));

export const ErrorMsg = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '1.375rem',
  letterSpacing: '0.015625rem',
  paddingLeft: '0.625rem',
  whiteSpace: 'nowrap',
}));

export const JobsTitle = styled('h2')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '1.75rem',
  letterSpacing: StyleConstants.LetterSpacingTight,
  marginBottom: '1.875rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: '1.375rem',
  },
}));
