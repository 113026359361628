/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { CircularProgress } from '@mui/material';
import { CustomButton } from './Button.Styles';
import theme from '../../../Themes/theme';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum IconDirection {
  Left = 'left',
  Right = 'right',
}

interface ICustomButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: string | JSX.Element;
  type: ButtonType;
  icon?: JSX.Element;
  iconDirection?: IconDirection;
  width?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

/**
 *
 * @returns Button
 */
const Button = ({
  onClick,
  label,
  type,
  icon,
  iconDirection,
  width,
  isLoading,
  disabled,
}: ICustomButtonProps): JSX.Element => {
  return (
    <CustomButton
      width={width}
      buttonType={type}
      onClick={onClick}
      disabled={disabled}
      style={{ opacity: disabled ? 0.5 : 1 }}>
      {iconDirection === IconDirection.Left && (icon || null)}
      {isLoading ? (
        <CircularProgress
          size={30}
          sx={{ color: ButtonType.Primary === type ? theme.palette.common.white : theme.palette.primary.dark }}
        />
      ) : (
        label
      )}
      {iconDirection === IconDirection.Right && (icon || null)}
    </CustomButton>
  );
};

Button.defaultProps = {
  iconDirection: IconDirection.Left,
  isLoading: false,
  disabled: false,
};

export default Button;
