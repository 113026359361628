/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { LoginOptionsProps } from './Models/Login.Model';

import { Icons } from '../../../../Shared/Constants/Icons';

import {
  LoginWithYourText,
  EmailAndPhoneNoContainer,
  EmailAndPhoneNoText,
  EmailButtonStyles,
  PhoneNumberButtonStyles,
} from './LoginPage.Style';

const LoginOptions: React.FC<LoginOptionsProps> = (props) => {
  const { onClick, t } = props;

  return (
    <>
      <LoginWithYourText>{t('signIn:signInWith')}</LoginWithYourText>
      <EmailAndPhoneNoContainer>
        <Button
          sx={{
            ...EmailButtonStyles,
          }}
          onClick={() => onClick(true, 'email')}>
          <img src={Icons.EmailIcon} alt={t('altTexts:emailLogo')} />
          <EmailAndPhoneNoText>{t('signIn:emailLabel')}</EmailAndPhoneNoText>
        </Button>
        <Button
          sx={{
            ...PhoneNumberButtonStyles,
          }}
          onClick={() => onClick(true, 'phoneNumber')}>
          <img src={Icons.PhoneNoIcon} alt={t('altTexts:phoneNumber')} />
          <EmailAndPhoneNoText>{t('signIn:phoneNumberLabel')}</EmailAndPhoneNoText>
        </Button>
      </EmailAndPhoneNoContainer>
    </>
  );
};

export default withTranslation(['signIn', 'altTexts'])(LoginOptions);
