/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */

/* eslint-disable sonarjs/cognitive-complexity */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Icons } from '../../../../Shared/Constants/Icons';
import { convertToReadableFormat, formatDate, getAcronymName } from '../../../../Shared/Utilities/utils';
import theme from '../../../../Shared/Themes/theme';
import DateSelection from '../../../../Shared/Components/Common/DatePicker/DateSelection';
import { FieldNames } from '../../Models/AddEditOneTimeJob.Model';
import { useForm } from 'react-hook-form';
import { ITeamMemberData } from '../JobDetails/JobAssignmentCard';
import {
  AssignTeamDiv,
  buttonStyles,
  CardContainerWrapper,
  DatePickerContainer,
  LogoWrapper,
  TeamCard,
  TeamContainer,
} from '../JobDetails/OneTimeJobDetails.Style';
import { Role } from '../../../../Shared/Constants/App';
import ModifyPopUp from '../JobDetails/ModifyPopUp';
import { AssociateShiftDateProps } from './SupervisorPunchIn';

interface AddTeam {
  teamMembers: ITeamMemberData[];
  setTeamMembers: (data: ITeamMemberData[]) => void;
  originalTeam: ITeamMemberData[];
  setOriginalTeam: (data: ITeamMemberData[]) => void;
  selectedTeamMembers: ITeamMemberData[];
  setSelectedTeamMembers: (data: ITeamMemberData[]) => void;
  isDataLoading: boolean;
  onClose: (type: Role) => void;
  assignmentType: Role;
  workOrderData: any;
  handleAddTeamMember: (startDate: string, endDate: string) => void;
  isButtonLoading: boolean;
  deleteTeamMember: boolean;
  editTeamMember: boolean;
  handleDeleteTeamMember: (deleteMember: ITeamMemberData | undefined) => void;
  isDeleteModelOpen: boolean;
  setDeleteModalOpen: (data: boolean) => void;
  isDeleteButtonLoading: boolean;
  handleCloseDeleteModal: () => void;
  editAssociateShiftDate?: {
    associateStartDate: string;
    associateEndDate: string;
    userId: string;
  };
  setEditAssociateShiftDate: ({ associateStartDate, associateEndDate, userId }: AssociateShiftDateProps) => void;
}

export const checkAlreadySelected = (data: ITeamMemberData[], memberId: string): boolean => {
  return data.some((prevMember) => prevMember.id === memberId);
};

const AddTeamMember = ({
  teamMembers,
  setTeamMembers,
  originalTeam,
  setOriginalTeam,
  selectedTeamMembers,
  setSelectedTeamMembers,
  isDataLoading,
  onClose,
  assignmentType,
  workOrderData,
  handleAddTeamMember,
  isButtonLoading,
  deleteTeamMember,
  editTeamMember,
  handleDeleteTeamMember,
  isDeleteButtonLoading,
  isDeleteModelOpen,
  setDeleteModalOpen,
  handleCloseDeleteModal,
  setEditAssociateShiftDate,
  editAssociateShiftDate,
}: AddTeam) => {
  const [showTeamCard, setShowTeamCard] = useState(false);
  const [isAssociateButtonDisabled, setIsAssociateButtonDisabled] = useState(true);
  const [isSupervisorButtonDisabled, setIsSupervisorButtonDisabled] = useState(true);
  const [removedTeamMember, setRemovedTeamMember] = useState<ITeamMemberData>();

  const { t } = useTranslation(['assignJob', 'oneTimeJob', 'calendar']);
  const { buttonText, createJobButton, cancelButton } = buttonStyles;
  const { control, getValues, reset } = useForm({
    defaultValues: {
      startDate: formatDate(workOrderData.actualStartDate),
      endDate: formatDate(workOrderData.actualEndDate),
    },
  });

  const handleOpenModal = (member: ITeamMemberData) => {
    setRemovedTeamMember(member);
    setDeleteModalOpen(true);
  };

  const handleSelectMember = (member: any) => {
    if (!member?.isAlreadyChecked) {
      handleSelectTeamMember(member?.id);
    }
  };

  const handleSelectTeamMember = (memberId: string) => {
    const teamMembersData = [...teamMembers];
    const originalTeamMembersData = [...originalTeam];
    const selectedMember = teamMembersData.find((member: ITeamMemberData) => member.id === memberId);
    if (selectedMember) {
      selectedMember.isChecked = !selectedMember?.isChecked;
      const selectedMemberindex = teamMembersData.findIndex((member: ITeamMemberData) => member.id === memberId);
      const originalSelectedMemberindex = originalTeamMembersData.findIndex(
        (member: ITeamMemberData) => member.id === memberId
      );

      if (selectedMemberindex !== -1) {
        teamMembersData[selectedMemberindex] = selectedMember;
        setTeamMembers(teamMembersData);
      }
      if (originalSelectedMemberindex !== -1) {
        originalTeamMembersData[originalSelectedMemberindex] = selectedMember;
        setOriginalTeam(originalTeamMembersData);
      }
      const selectedTeamList = originalTeamMembersData.filter((member: ITeamMemberData) => member.isChecked);
      setSelectedTeamMembers(selectedTeamList);
    }
  };

  const handleNextButton = () => {
    if (assignmentType === Role.Associate) {
      setShowTeamCard(true);
      setEditAssociateShiftDate({
        userId: '',
        associateStartDate: '',
        associateEndDate: '',
      });
    } else {
      const startDate = getValues(FieldNames.startDate);
      const endDate = getValues(FieldNames.endDate);
      handleAddTeamMember(startDate, endDate);
    }
  };

  const renderTeamContent = (teamMembers: ITeamMemberData[]) => {
    return (
      <>
        {teamMembers?.length > 0 && (
          <AssignTeamDiv>
            {teamMembers?.map((member: ITeamMemberData) => {
              return (
                <TeamCard key={member.id} onClick={() => handleSelectMember(member)}>
                  <div style={{ display: 'flex' }}>
                    <LogoWrapper>
                      <div>{getAcronymName(member.name as string).toUpperCase()}</div>
                    </LogoWrapper>
                    <TeamContainer>
                      <div className="Title">{member.name}</div>
                      <div className="Position">{convertToReadableFormat(member.role)}</div>
                    </TeamContainer>
                  </div>
                  <div>
                    <Box>
                      {member.isAlreadyChecked ? (
                        <div style={{ display: 'inline-flex', alignItems: 'center', gap: '0.5rem' }}>
                          {editTeamMember && (
                            <img
                              src={Icons.EditBlackIcon}
                              alt={t('altTexts:edit')}
                              style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                              onClick={() => {
                                setShowTeamCard(true);
                                setEditAssociateShiftDate({
                                  userId: member?.id ?? '',
                                  associateStartDate: member?.associateStartDate ?? '',
                                  associateEndDate: member?.associateEndDate ?? '',
                                });
                              }}
                            />
                          )}
                          {deleteTeamMember && (
                            <img
                              src={Icons.DeleteIcon}
                              alt={t('altTexts:delete')}
                              style={{ cursor: 'pointer', height: '1.5rem', width: '1.5rem' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenModal(member);
                              }}
                            />
                          )}
                        </div>
                      ) : member.isChecked ? (
                        <img
                          src={Icons.CheckedIcon}
                          alt={t('altTexts:checked')}
                          style={{
                            opacity: member?.isAlreadyChecked ? '0.5' : '1',
                            height: '1.5rem',
                            width: '1.5rem',
                          }}
                        />
                      ) : (
                        <img src={Icons.Unchecked} alt={t('altTexts:unchecked')} style={{ cursor: 'pointer' }} />
                      )}
                    </Box>
                  </div>
                </TeamCard>
              );
            })}
          </AssignTeamDiv>
        )}
      </>
    );
  };

  const disableAssignButton = () => {
    if (assignmentType === Role.Associate) {
      return isAssociateButtonDisabled || isDataLoading;
    } else {
      return isSupervisorButtonDisabled || isDataLoading;
    }
  };

  const disablehandleButton = () => {
    const startDate = getValues(FieldNames.startDate);
    const endDate = getValues(FieldNames.endDate);
    return !(startDate && endDate);
  };

  useEffect(() => {
    disableAssignButton();
  }, [showTeamCard, selectedTeamMembers]);

  useEffect(() => {
    const disablingAssociateButton = selectedTeamMembers.every((associate) => {
      return !(!associate.isAlreadyChecked && associate.isChecked);
    });
    setIsAssociateButtonDisabled(disablingAssociateButton);
  }, [selectedTeamMembers]);

  useEffect(() => {
    const disablingSupervisorButton = selectedTeamMembers.every((supervisor) => {
      return !(!supervisor.isAlreadyChecked && supervisor.isChecked);
    });
    setIsSupervisorButtonDisabled(disablingSupervisorButton);
  }, [selectedTeamMembers]);

  useEffect(() => {
    if (editAssociateShiftDate?.associateStartDate || editAssociateShiftDate?.associateEndDate) {
      reset({
        startDate: editAssociateShiftDate?.associateStartDate
          ? editAssociateShiftDate.associateStartDate
          : formatDate(workOrderData.actualStartDate),
        endDate: editAssociateShiftDate?.associateEndDate
          ? editAssociateShiftDate.associateEndDate
          : formatDate(workOrderData.actualEndDate),
      });
    }
  }, [editAssociateShiftDate, reset, workOrderData]);

  return (
    <>
      {!showTeamCard ? (
        <CardContainerWrapper>
          {isDataLoading ? (
            <CircularProgress size={30} sx={{ color: theme.palette.primary.dark, marginLeft: '45%' }} />
          ) : teamMembers.length > 0 ? (
            renderTeamContent(teamMembers)
          ) : (
            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>{t('assignJob:noResultsFound')}</Typography>
          )}
        </CardContainerWrapper>
      ) : (
        <Box sx={{ display: 'flex' }}>
          <DatePickerContainer>
            <DateSelection
              requiredText={t('oneTimeJob:startDateFieldReq')}
              control={control}
              fieldName={FieldNames.startDate}
              label={t('calendar:startDate')}
              iconColor="black"
              isExcludePastDate={false}
              showSpecificRangeDates={{
                from: workOrderData.actualStartDate && formatDate(workOrderData.actualStartDate),
                to: workOrderData.actualEndDate && formatDate(workOrderData.actualEndDate),
              }}
            />
            <DateSelection
              requiredText={t('oneTimeJob:endDateFieldReq')}
              control={control}
              fieldName={FieldNames.endDate}
              label={t('calendar:endDate')}
              iconColor="black"
              isExcludePastDate={false}
              showSpecificRangeDates={{
                from: workOrderData.actualStartDate && formatDate(workOrderData.actualStartDate),
                to: workOrderData.actualEndDate && formatDate(workOrderData.actualEndDate),
              }}
            />
          </DatePickerContainer>
        </Box>
      )}
      <>
        {!showTeamCard ? (
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              disabled={disableAssignButton()}
              sx={{
                ...createJobButton,
                opacity: disableAssignButton() ? '0.5' : '1',
                marginTop: '2em',
                width: '100%',
                maxWidth: '350px',
                textAlign: 'center',
              }}
              onClick={handleNextButton}>
              {isButtonLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
              ) : (
                <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
                  {assignmentType === Role.Associate
                    ? t('assignJob:assignAssociates')
                    : t('assignJob:assignSupervisors')}
                </Typography>
              )}
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              disabled={disableAssignButton()}
              sx={{ ...cancelButton, cursor: 'pointer', maxWidth: '350px', marginTop: '2em', width: '100%' }}
              onClick={() => onClose(assignmentType)}>
              <Typography sx={{ ...buttonText, color: theme.palette.primary.dark }}>{t('assignJob:cancel')}</Typography>
            </Button>
            <Button
              disabled={disablehandleButton()}
              sx={{
                ...createJobButton,
                maxWidth: '350px',
                marginTop: '2em',
                width: '100%',
                opacity: disablehandleButton() ? '0.5' : '1',
              }}
              onClick={() => {
                const startDate = getValues(FieldNames.startDate);
                const endDate = getValues(FieldNames.endDate);
                handleAddTeamMember(startDate, endDate);
              }}>
              {isButtonLoading ? (
                <CircularProgress size={30} sx={{ color: theme.palette.common.white }} />
              ) : (
                <Typography sx={{ ...buttonText, color: theme.palette.common.white }}>
                  {t('assignJob:confirm')}
                </Typography>
              )}
            </Button>
          </div>
        )}
      </>
      <ModifyPopUp
        open={isDeleteModelOpen}
        handleClose={handleCloseDeleteModal}
        handleOnCloseJob={() => handleDeleteTeamMember(removedTeamMember)}
        loading={isDeleteButtonLoading}
        heading={t('assignJob:removeData')}
        text={t('assignJob:removeTeamDataText')}
      />
    </>
  );
};

export default AddTeamMember;
