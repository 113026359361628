/*
 * <copyright company="Argenbright Innovations Lab">
 *        copyright (c) Argenbright Innovations Lab, an Argenbright Holdings Company.  All rights reserved.
 * </copyright>
 */
import { utc } from 'moment';
import { JobBidSummary, WorkOrderStatus, BidStatus } from '../../../API';
import { JobStatus } from '../../../Shared/Constants/App';
export interface FacilityType {
  facilityType: string;
  facilityName: string;
}
export const getFacilityName = (facilityTypes: FacilityType[], mdFacilityType: string): string | undefined => {
  const facility = facilityTypes.find((facility) => facility.facilityType === mdFacilityType);
  return facility?.facilityName;
};

export const getTotalServiceArea = (
  services: { jobId: string; mdServiceId: string; serviceMeasure: number; mdServiceUnits: string }[]
): number => {
  let totalArea = 0;
  services.forEach((service) => {
    if (service.mdServiceUnits === 'sqft') {
      totalArea += parseFloat(service.serviceMeasure.toString());
    }
  });
  return totalArea;
};

export const getNumberOfDaysLeft = (
  jobDueDates: { jobId: string; mdDueType: string; dueDescription: string; dueDate: string }[]
): number | string => {
  const bidDueDateObj: any = jobDueDates.find((dateObj) => dateObj.mdDueType === 'Bid');
  if (bidDueDateObj) {
    const timeDifference = new Date(bidDueDateObj?.dueDate).getTime() - new Date().getTime();
    return Math.ceil(timeDifference / (1000 * 3600 * 24));
  } else {
    return '-';
  }
};

export const getServiceVolume = (area: number): 'Small' | 'Medium' | 'Large' | undefined => {
  if (area < 5000) {
    return 'Small';
  } else if (area >= 5000 && area < 10000) {
    return 'Medium';
  } else if (area >= 10000) {
    return 'Large';
  }
};

export const getServiceDuration = (startDate: string, stopDate: string): string => {
  const timeDifference = new Date(stopDate).getTime() - new Date(startDate).getTime();
  const numberOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
  if (numberOfDays === 0) {
    return 'One time';
  } else if (numberOfDays === 1) {
    return '1 day';
  } else if (numberOfDays % 7 === 0) {
    return String(numberOfDays / 7) + ' weeks';
  } else if (numberOfDays % 30 === 0 || numberOfDays % 30 === 1) {
    return String(Math.round(numberOfDays / 30)) + ' months';
  } else {
    return String(numberOfDays) + ' days';
  }
};

export const getOTJStatus = (jobStatus: string, jobBids: JobBidSummary[]): JobStatus | string => {
  if (jobStatus === 'OpenToBid') {
    const submittedBids = jobBids.filter((bidItem: JobBidSummary) => bidItem.dataStatus === ('S' as BidStatus));
    const revisonRequested = jobBids.filter((bidItem: JobBidSummary) => bidItem.dataStatus === ('R' as BidStatus));
    // const pendingApprovalBids = jobBids.filter(
    //   (bidItem: JobBidSummary) => bidItem.dataStatus === ('A' as any) && bidItem.draftStatus === ('C' as any)
    // );
    if (revisonRequested?.length) {
      return JobStatus.RevisionRequested;
    } else if (submittedBids?.length) {
      return JobStatus.PendingCustomerApproval;
    } else {
      return JobStatus.PendingEstimation;
    }
  } else if (jobStatus === 'Awarded') {
    return JobStatus.Awarded;
  }

  return jobStatus;
};

export const getWorkOrderStatus = (jobStatus: WorkOrderStatus): string => {
  switch (jobStatus) {
    case WorkOrderStatus.Created:
      return 'Upcoming';
    case WorkOrderStatus.Accepted:
      return 'Accepted';
    case WorkOrderStatus.InProgress:
      return 'Inprogress';
    case WorkOrderStatus.Completed:
      return 'Completed';
    default:
      return '';
  }
};

export const getSubContractedJobStatus = (jobStatus: string, jobBids: JobBidSummary[]): JobStatus | string => {
  if (jobStatus === 'OpenToBid') {
    if (jobBids.length && jobBids.filter((bid: any) => bid.dataStatus === 'R').length) {
      return JobStatus.RevisionRequested;
    } else {
      return 'Open for bids';
    }
  } else if (jobStatus === 'Awarded') {
    return JobStatus.Awarded;
  } else {
    return JobStatus.Draft;
  }
};
export const formatDateCell = (cell: { getValue: () => any }): string => {
  const cellValue = cell.getValue();
  if (cellValue) {
    return utc(cellValue).format('MM/DD/YYYY');
  }
  return '-';
};
